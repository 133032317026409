/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProductClassification from './ProductClassification';
import ProductType from './ProductType';

/**
 * The Product model module.
 * @module model/Product
 * @version v1
 */
class Product {
    /**
     * Constructs a new <code>Product</code>.
     * @alias module:model/Product
     */
    constructor() { 
        
        Product.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Product</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Product} obj Optional instance to populate.
     * @return {module:model/Product} The populated <code>Product</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Product();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('statusId')) {
                obj['statusId'] = ApiClient.convertToType(data['statusId'], 'Number');
            }
            if (data.hasOwnProperty('requiresCustomerAccount')) {
                obj['requiresCustomerAccount'] = ApiClient.convertToType(data['requiresCustomerAccount'], 'Boolean');
            }
            if (data.hasOwnProperty('levelId')) {
                obj['levelId'] = ApiClient.convertToType(data['levelId'], 'Number');
            }
            if (data.hasOwnProperty('scopeId')) {
                obj['scopeId'] = ApiClient.convertToType(data['scopeId'], 'Number');
            }
            if (data.hasOwnProperty('productTypeId')) {
                obj['productTypeId'] = ApiClient.convertToType(data['productTypeId'], 'Number');
            }
            if (data.hasOwnProperty('productClassificationId')) {
                obj['productClassificationId'] = ApiClient.convertToType(data['productClassificationId'], 'Number');
            }
            if (data.hasOwnProperty('productType')) {
                obj['productType'] = ProductType.constructFromObject(data['productType']);
            }
            if (data.hasOwnProperty('productClassification')) {
                obj['productClassification'] = ProductClassification.constructFromObject(data['productClassification']);
            }
            if (data.hasOwnProperty('code')) {
                obj['code'] = ApiClient.convertToType(data['code'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
Product.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
Product.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
Product.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
Product.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
Product.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
Product.prototype['tenantId'] = undefined;

/**
 * @member {String} name
 */
Product.prototype['name'] = undefined;

/**
 * @member {String} description
 */
Product.prototype['description'] = undefined;

/**
 * @member {Number} statusId
 */
Product.prototype['statusId'] = undefined;

/**
 * @member {Boolean} requiresCustomerAccount
 */
Product.prototype['requiresCustomerAccount'] = undefined;

/**
 * @member {Number} levelId
 */
Product.prototype['levelId'] = undefined;

/**
 * @member {Number} scopeId
 */
Product.prototype['scopeId'] = undefined;

/**
 * @member {Number} productTypeId
 */
Product.prototype['productTypeId'] = undefined;

/**
 * @member {Number} productClassificationId
 */
Product.prototype['productClassificationId'] = undefined;

/**
 * @member {module:model/ProductType} productType
 */
Product.prototype['productType'] = undefined;

/**
 * @member {module:model/ProductClassification} productClassification
 */
Product.prototype['productClassification'] = undefined;

/**
 * @member {String} code
 */
Product.prototype['code'] = undefined;






export default Product;

