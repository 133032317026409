/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProductClassification from './ProductClassification';
import SpotType from './SpotType';

/**
 * The ProductClassificationWithSpotTypes model module.
 * @module model/ProductClassificationWithSpotTypes
 * @version v1
 */
class ProductClassificationWithSpotTypes {
    /**
     * Constructs a new <code>ProductClassificationWithSpotTypes</code>.
     * @alias module:model/ProductClassificationWithSpotTypes
     */
    constructor() { 
        
        ProductClassificationWithSpotTypes.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ProductClassificationWithSpotTypes</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProductClassificationWithSpotTypes} obj Optional instance to populate.
     * @return {module:model/ProductClassificationWithSpotTypes} The populated <code>ProductClassificationWithSpotTypes</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProductClassificationWithSpotTypes();

            if (data.hasOwnProperty('classification')) {
                obj['classification'] = ProductClassification.constructFromObject(data['classification']);
            }
            if (data.hasOwnProperty('spotTypes')) {
                obj['spotTypes'] = ApiClient.convertToType(data['spotTypes'], [SpotType]);
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/ProductClassification} classification
 */
ProductClassificationWithSpotTypes.prototype['classification'] = undefined;

/**
 * @member {Array.<module:model/SpotType>} spotTypes
 */
ProductClassificationWithSpotTypes.prototype['spotTypes'] = undefined;






export default ProductClassificationWithSpotTypes;

