/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ConvenienceFeeRequest from '../model/ConvenienceFeeRequest';
import DiscountClassEnum from '../model/DiscountClassEnum';
import FeeIEnumerableResponse from '../model/FeeIEnumerableResponse';
import FeeResponse from '../model/FeeResponse';
import SalesChannelEnum from '../model/SalesChannelEnum';

/**
* Pricing service.
* @module api/PricingApi
* @version v1
*/
export default class PricingApi {

    /**
    * Constructs a new PricingApi. 
    * @alias module:api/PricingApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ConvenienceFeeRequest} opts.convenienceFeeRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/FeeResponse} and HTTP response
     */
    v1TenantTenantIdPricingConveniencefeePostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['convenienceFeeRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdPricingConveniencefeePost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = FeeResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Pricing/conveniencefee', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ConvenienceFeeRequest} opts.convenienceFeeRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/FeeResponse}
     */
    v1TenantTenantIdPricingConveniencefeePost(tenantId, opts) {
      return this.v1TenantTenantIdPricingConveniencefeePostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.customerId 
     * @param {Number} opts.oldSpotId 
     * @param {Number} opts.spotId 
     * @param {String} opts.startDate 
     * @param {String} opts.oldStartDate 
     * @param {String} opts.oldEndDate 
     * @param {String} opts.endDate 
     * @param {Number} opts.oldNumberOfAdults 
     * @param {Number} opts.numberOfAdults 
     * @param {Number} opts.numberOfVehicles 
     * @param {Array.<module:model/DiscountClassEnum>} opts.discounts 
     * @param {Array.<module:model/DiscountClassEnum>} opts.oldDiscounts 
     * @param {module:model/SalesChannelEnum} opts.salesChannel 
     * @param {Number} opts.operationId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/FeeIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdPricingFeesGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdPricingFeesGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'CustomerId': opts['customerId'],
        'OldSpotId': opts['oldSpotId'],
        'SpotId': opts['spotId'],
        'StartDate': opts['startDate'],
        'OldStartDate': opts['oldStartDate'],
        'OldEndDate': opts['oldEndDate'],
        'EndDate': opts['endDate'],
        'OldNumberOfAdults': opts['oldNumberOfAdults'],
        'NumberOfAdults': opts['numberOfAdults'],
        'NumberOfVehicles': opts['numberOfVehicles'],
        'Discounts': this.apiClient.buildCollectionParam(opts['discounts'], 'multi'),
        'OldDiscounts': this.apiClient.buildCollectionParam(opts['oldDiscounts'], 'multi'),
        'SalesChannel': opts['salesChannel'],
        'operationId': opts['operationId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = FeeIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Pricing/fees', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.customerId 
     * @param {Number} opts.oldSpotId 
     * @param {Number} opts.spotId 
     * @param {String} opts.startDate 
     * @param {String} opts.oldStartDate 
     * @param {String} opts.oldEndDate 
     * @param {String} opts.endDate 
     * @param {Number} opts.oldNumberOfAdults 
     * @param {Number} opts.numberOfAdults 
     * @param {Number} opts.numberOfVehicles 
     * @param {Array.<module:model/DiscountClassEnum>} opts.discounts 
     * @param {Array.<module:model/DiscountClassEnum>} opts.oldDiscounts 
     * @param {module:model/SalesChannelEnum} opts.salesChannel 
     * @param {Number} opts.operationId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/FeeIEnumerableResponse}
     */
    v1TenantTenantIdPricingFeesGet(tenantId, opts) {
      return this.v1TenantTenantIdPricingFeesGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
