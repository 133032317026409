/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The UserRequest model module.
 * @module model/UserRequest
 * @version v1
 */
class UserRequest {
    /**
     * Constructs a new <code>UserRequest</code>.
     * @alias module:model/UserRequest
     */
    constructor() { 
        
        UserRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>UserRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UserRequest} obj Optional instance to populate.
     * @return {module:model/UserRequest} The populated <code>UserRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserRequest();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('firstName')) {
                obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
            }
            if (data.hasOwnProperty('lastName')) {
                obj['lastName'] = ApiClient.convertToType(data['lastName'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('userRoleId')) {
                obj['userRoleId'] = ApiClient.convertToType(data['userRoleId'], 'Number');
            }
            if (data.hasOwnProperty('userStatusId')) {
                obj['userStatusId'] = ApiClient.convertToType(data['userStatusId'], 'Number');
            }
            if (data.hasOwnProperty('externalUserId')) {
                obj['externalUserId'] = ApiClient.convertToType(data['externalUserId'], 'Number');
            }
            if (data.hasOwnProperty('authTypeId')) {
                obj['authTypeId'] = ApiClient.convertToType(data['authTypeId'], 'Number');
            }
            if (data.hasOwnProperty('receiveReservationEmails')) {
                obj['receiveReservationEmails'] = ApiClient.convertToType(data['receiveReservationEmails'], 'Boolean');
            }
            if (data.hasOwnProperty('userLocationIds')) {
                obj['userLocationIds'] = ApiClient.convertToType(data['userLocationIds'], ['Number']);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
UserRequest.prototype['id'] = undefined;

/**
 * @member {String} firstName
 */
UserRequest.prototype['firstName'] = undefined;

/**
 * @member {String} lastName
 */
UserRequest.prototype['lastName'] = undefined;

/**
 * @member {String} email
 */
UserRequest.prototype['email'] = undefined;

/**
 * @member {String} username
 */
UserRequest.prototype['username'] = undefined;

/**
 * @member {Number} userRoleId
 */
UserRequest.prototype['userRoleId'] = undefined;

/**
 * @member {Number} userStatusId
 */
UserRequest.prototype['userStatusId'] = undefined;

/**
 * @member {Number} externalUserId
 */
UserRequest.prototype['externalUserId'] = undefined;

/**
 * @member {Number} authTypeId
 */
UserRequest.prototype['authTypeId'] = undefined;

/**
 * @member {Boolean} receiveReservationEmails
 */
UserRequest.prototype['receiveReservationEmails'] = undefined;

/**
 * @member {Array.<Number>} userLocationIds
 */
UserRequest.prototype['userLocationIds'] = undefined;






export default UserRequest;

