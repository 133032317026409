/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ReceiptReservation from './ReceiptReservation';

/**
 * The NewOrderReceipt model module.
 * @module model/NewOrderReceipt
 * @version v1
 */
class NewOrderReceipt {
    /**
     * Constructs a new <code>NewOrderReceipt</code>.
     * @alias module:model/NewOrderReceipt
     */
    constructor() { 
        
        NewOrderReceipt.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>NewOrderReceipt</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/NewOrderReceipt} obj Optional instance to populate.
     * @return {module:model/NewOrderReceipt} The populated <code>NewOrderReceipt</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new NewOrderReceipt();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('orderNumber')) {
                obj['orderNumber'] = ApiClient.convertToType(data['orderNumber'], 'String');
            }
            if (data.hasOwnProperty('transactionDate')) {
                obj['transactionDate'] = ApiClient.convertToType(data['transactionDate'], 'String');
            }
            if (data.hasOwnProperty('transactionType')) {
                obj['transactionType'] = ApiClient.convertToType(data['transactionType'], 'String');
            }
            if (data.hasOwnProperty('customerName')) {
                obj['customerName'] = ApiClient.convertToType(data['customerName'], 'String');
            }
            if (data.hasOwnProperty('customerAddress')) {
                obj['customerAddress'] = ApiClient.convertToType(data['customerAddress'], 'String');
            }
            if (data.hasOwnProperty('customerEmail')) {
                obj['customerEmail'] = ApiClient.convertToType(data['customerEmail'], 'String');
            }
            if (data.hasOwnProperty('paymentType')) {
                obj['paymentType'] = ApiClient.convertToType(data['paymentType'], 'String');
            }
            if (data.hasOwnProperty('cardHolderName')) {
                obj['cardHolderName'] = ApiClient.convertToType(data['cardHolderName'], 'String');
            }
            if (data.hasOwnProperty('cardHolderNameLabel')) {
                obj['cardHolderNameLabel'] = ApiClient.convertToType(data['cardHolderNameLabel'], 'String');
            }
            if (data.hasOwnProperty('lastFour')) {
                obj['lastFour'] = ApiClient.convertToType(data['lastFour'], 'String');
            }
            if (data.hasOwnProperty('lastFourLabel')) {
                obj['lastFourLabel'] = ApiClient.convertToType(data['lastFourLabel'], 'String');
            }
            if (data.hasOwnProperty('totalTransactionFeeAmount')) {
                obj['totalTransactionFeeAmount'] = ApiClient.convertToType(data['totalTransactionFeeAmount'], 'String');
            }
            if (data.hasOwnProperty('totalConvenienceFeeAmount')) {
                obj['totalConvenienceFeeAmount'] = ApiClient.convertToType(data['totalConvenienceFeeAmount'], 'String');
            }
            if (data.hasOwnProperty('totalCancelationFeeAmount')) {
                obj['totalCancelationFeeAmount'] = ApiClient.convertToType(data['totalCancelationFeeAmount'], 'String');
            }
            if (data.hasOwnProperty('totalModificationFeeAmount')) {
                obj['totalModificationFeeAmount'] = ApiClient.convertToType(data['totalModificationFeeAmount'], 'String');
            }
            if (data.hasOwnProperty('totalParkUseFee')) {
                obj['totalParkUseFee'] = ApiClient.convertToType(data['totalParkUseFee'], 'String');
            }
            if (data.hasOwnProperty('totalAddOnFeeAmount')) {
                obj['totalAddOnFeeAmount'] = ApiClient.convertToType(data['totalAddOnFeeAmount'], 'String');
            }
            if (data.hasOwnProperty('totalAmountPaid')) {
                obj['totalAmountPaid'] = ApiClient.convertToType(data['totalAmountPaid'], 'String');
            }
            if (data.hasOwnProperty('totalAmountRefunded')) {
                obj['totalAmountRefunded'] = ApiClient.convertToType(data['totalAmountRefunded'], 'String');
            }
            if (data.hasOwnProperty('transactionAmountPaid')) {
                obj['transactionAmountPaid'] = ApiClient.convertToType(data['transactionAmountPaid'], 'String');
            }
            if (data.hasOwnProperty('transactionAmountRefunded')) {
                obj['transactionAmountRefunded'] = ApiClient.convertToType(data['transactionAmountRefunded'], 'String');
            }
            if (data.hasOwnProperty('previousAmountPaid')) {
                obj['previousAmountPaid'] = ApiClient.convertToType(data['previousAmountPaid'], 'String');
            }
            if (data.hasOwnProperty('previousParkFeePaid')) {
                obj['previousParkFeePaid'] = ApiClient.convertToType(data['previousParkFeePaid'], 'String');
            }
            if (data.hasOwnProperty('previousAmountRefunded')) {
                obj['previousAmountRefunded'] = ApiClient.convertToType(data['previousAmountRefunded'], 'String');
            }
            if (data.hasOwnProperty('reservations')) {
                obj['reservations'] = ApiClient.convertToType(data['reservations'], [ReceiptReservation]);
            }
            if (data.hasOwnProperty('bottomAlertContentItems')) {
                obj['bottomAlertContentItems'] = ApiClient.convertToType(data['bottomAlertContentItems'], ['String']);
            }
            if (data.hasOwnProperty('topAlertContentItems')) {
                obj['topAlertContentItems'] = ApiClient.convertToType(data['topAlertContentItems'], ['String']);
            }
            if (data.hasOwnProperty('bottomAlertHeader')) {
                obj['bottomAlertHeader'] = ApiClient.convertToType(data['bottomAlertHeader'], 'String');
            }
            if (data.hasOwnProperty('topAlertHeader')) {
                obj['topAlertHeader'] = ApiClient.convertToType(data['topAlertHeader'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
NewOrderReceipt.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
NewOrderReceipt.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
NewOrderReceipt.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
NewOrderReceipt.prototype['lastModifiedBy'] = undefined;

/**
 * @member {String} orderNumber
 */
NewOrderReceipt.prototype['orderNumber'] = undefined;

/**
 * @member {String} transactionDate
 */
NewOrderReceipt.prototype['transactionDate'] = undefined;

/**
 * @member {String} transactionType
 */
NewOrderReceipt.prototype['transactionType'] = undefined;

/**
 * @member {String} customerName
 */
NewOrderReceipt.prototype['customerName'] = undefined;

/**
 * @member {String} customerAddress
 */
NewOrderReceipt.prototype['customerAddress'] = undefined;

/**
 * @member {String} customerEmail
 */
NewOrderReceipt.prototype['customerEmail'] = undefined;

/**
 * @member {String} paymentType
 */
NewOrderReceipt.prototype['paymentType'] = undefined;

/**
 * @member {String} cardHolderName
 */
NewOrderReceipt.prototype['cardHolderName'] = undefined;

/**
 * @member {String} cardHolderNameLabel
 */
NewOrderReceipt.prototype['cardHolderNameLabel'] = undefined;

/**
 * @member {String} lastFour
 */
NewOrderReceipt.prototype['lastFour'] = undefined;

/**
 * @member {String} lastFourLabel
 */
NewOrderReceipt.prototype['lastFourLabel'] = undefined;

/**
 * @member {String} totalTransactionFeeAmount
 */
NewOrderReceipt.prototype['totalTransactionFeeAmount'] = undefined;

/**
 * @member {String} totalConvenienceFeeAmount
 */
NewOrderReceipt.prototype['totalConvenienceFeeAmount'] = undefined;

/**
 * @member {String} totalCancelationFeeAmount
 */
NewOrderReceipt.prototype['totalCancelationFeeAmount'] = undefined;

/**
 * @member {String} totalModificationFeeAmount
 */
NewOrderReceipt.prototype['totalModificationFeeAmount'] = undefined;

/**
 * @member {String} totalParkUseFee
 */
NewOrderReceipt.prototype['totalParkUseFee'] = undefined;

/**
 * @member {String} totalAddOnFeeAmount
 */
NewOrderReceipt.prototype['totalAddOnFeeAmount'] = undefined;

/**
 * @member {String} totalAmountPaid
 */
NewOrderReceipt.prototype['totalAmountPaid'] = undefined;

/**
 * @member {String} totalAmountRefunded
 */
NewOrderReceipt.prototype['totalAmountRefunded'] = undefined;

/**
 * @member {String} transactionAmountPaid
 */
NewOrderReceipt.prototype['transactionAmountPaid'] = undefined;

/**
 * @member {String} transactionAmountRefunded
 */
NewOrderReceipt.prototype['transactionAmountRefunded'] = undefined;

/**
 * @member {String} previousAmountPaid
 */
NewOrderReceipt.prototype['previousAmountPaid'] = undefined;

/**
 * @member {String} previousParkFeePaid
 */
NewOrderReceipt.prototype['previousParkFeePaid'] = undefined;

/**
 * @member {String} previousAmountRefunded
 */
NewOrderReceipt.prototype['previousAmountRefunded'] = undefined;

/**
 * @member {Array.<module:model/ReceiptReservation>} reservations
 */
NewOrderReceipt.prototype['reservations'] = undefined;

/**
 * @member {Array.<String>} bottomAlertContentItems
 */
NewOrderReceipt.prototype['bottomAlertContentItems'] = undefined;

/**
 * @member {Array.<String>} topAlertContentItems
 */
NewOrderReceipt.prototype['topAlertContentItems'] = undefined;

/**
 * @member {String} bottomAlertHeader
 */
NewOrderReceipt.prototype['bottomAlertHeader'] = undefined;

/**
 * @member {String} topAlertHeader
 */
NewOrderReceipt.prototype['topAlertHeader'] = undefined;






export default NewOrderReceipt;

