/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CaptchaVerifyResponse from '../model/CaptchaVerifyResponse';
import ConfirmUserRegistrationRequest from '../model/ConfirmUserRegistrationRequest';
import ResetPasswordConfirmationRequest from '../model/ResetPasswordConfirmationRequest';
import User from '../model/User';
import UserResponse from '../model/UserResponse';
import UserVerificationResponse from '../model/UserVerificationResponse';

/**
* User service.
* @module api/UserApi
* @version v1
*/
export default class UserApi {

    /**
    * Constructs a new UserApi. 
    * @alias module:api/UserApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.token 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CaptchaVerifyResponse} and HTTP response
     */
    v1TenantTenantIdUserCaptchaVerifyPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdUserCaptchaVerifyPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'token': opts['token']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CaptchaVerifyResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/User/CaptchaVerify', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.token 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CaptchaVerifyResponse}
     */
    v1TenantTenantIdUserCaptchaVerifyPost(tenantId, opts) {
      return this.v1TenantTenantIdUserCaptchaVerifyPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ResetPasswordConfirmationRequest} opts.resetPasswordConfirmationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserResponse} and HTTP response
     */
    v1TenantTenantIdUserConfirmResetPasswordPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['resetPasswordConfirmationRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdUserConfirmResetPasswordPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/User/ConfirmResetPassword', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ResetPasswordConfirmationRequest} opts.resetPasswordConfirmationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserResponse}
     */
    v1TenantTenantIdUserConfirmResetPasswordPut(tenantId, opts) {
      return this.v1TenantTenantIdUserConfirmResetPasswordPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ConfirmUserRegistrationRequest} opts.confirmUserRegistrationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserResponse} and HTTP response
     */
    v1TenantTenantIdUserConfirmUserRegistrationPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['confirmUserRegistrationRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdUserConfirmUserRegistrationPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/User/ConfirmUserRegistration', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ConfirmUserRegistrationRequest} opts.confirmUserRegistrationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserResponse}
     */
    v1TenantTenantIdUserConfirmUserRegistrationPost(tenantId, opts) {
      return this.v1TenantTenantIdUserConfirmUserRegistrationPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} email 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserResponse} and HTTP response
     */
    v1TenantTenantIdUserForgotUsernameEmailPostWithHttpInfo(email, tenantId) {
      let postBody = null;
      // verify the required parameter 'email' is set
      if (email === undefined || email === null) {
        throw new Error("Missing the required parameter 'email' when calling v1TenantTenantIdUserForgotUsernameEmailPost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdUserForgotUsernameEmailPost");
      }

      let pathParams = {
        'email': email,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/User/ForgotUsername/{email}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} email 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserResponse}
     */
    v1TenantTenantIdUserForgotUsernameEmailPost(email, tenantId) {
      return this.v1TenantTenantIdUserForgotUsernameEmailPostWithHttpInfo(email, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/User} opts.user 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserResponse} and HTTP response
     */
    v1TenantTenantIdUserPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['user'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdUserPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/User', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/User} opts.user 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserResponse}
     */
    v1TenantTenantIdUserPost(tenantId, opts) {
      return this.v1TenantTenantIdUserPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} userId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserResponse} and HTTP response
     */
    v1TenantTenantIdUserResendRegistrationCodeUserIdPostWithHttpInfo(userId, tenantId) {
      let postBody = null;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling v1TenantTenantIdUserResendRegistrationCodeUserIdPost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdUserResendRegistrationCodeUserIdPost");
      }

      let pathParams = {
        'userId': userId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/User/ResendRegistrationCode/{userId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} userId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserResponse}
     */
    v1TenantTenantIdUserResendRegistrationCodeUserIdPost(userId, tenantId) {
      return this.v1TenantTenantIdUserResendRegistrationCodeUserIdPostWithHttpInfo(userId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} username 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserResponse} and HTTP response
     */
    v1TenantTenantIdUserResetPasswordUsernamePostWithHttpInfo(username, tenantId) {
      let postBody = null;
      // verify the required parameter 'username' is set
      if (username === undefined || username === null) {
        throw new Error("Missing the required parameter 'username' when calling v1TenantTenantIdUserResetPasswordUsernamePost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdUserResetPasswordUsernamePost");
      }

      let pathParams = {
        'username': username,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/User/ResetPassword/{username}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} username 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserResponse}
     */
    v1TenantTenantIdUserResetPasswordUsernamePost(username, tenantId) {
      return this.v1TenantTenantIdUserResetPasswordUsernamePostWithHttpInfo(username, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} userId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserResponse} and HTTP response
     */
    v1TenantTenantIdUserUserIdGetWithHttpInfo(userId, tenantId) {
      let postBody = null;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling v1TenantTenantIdUserUserIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdUserUserIdGet");
      }

      let pathParams = {
        'userId': userId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/User/{userId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} userId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserResponse}
     */
    v1TenantTenantIdUserUserIdGet(userId, tenantId) {
      return this.v1TenantTenantIdUserUserIdGetWithHttpInfo(userId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.userId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserVerificationResponse} and HTTP response
     */
    v1TenantTenantIdUserUserVerificationGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdUserUserVerificationGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'userId': opts['userId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserVerificationResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/User/UserVerification', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.userId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserVerificationResponse}
     */
    v1TenantTenantIdUserUserVerificationGet(tenantId, opts) {
      return this.v1TenantTenantIdUserUserVerificationGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} userName 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserResponse} and HTTP response
     */
    v1TenantTenantIdUserUsernameUserNameGetWithHttpInfo(userName, tenantId) {
      let postBody = null;
      // verify the required parameter 'userName' is set
      if (userName === undefined || userName === null) {
        throw new Error("Missing the required parameter 'userName' when calling v1TenantTenantIdUserUsernameUserNameGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdUserUsernameUserNameGet");
      }

      let pathParams = {
        'userName': userName,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/User/username/{userName}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} userName 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserResponse}
     */
    v1TenantTenantIdUserUsernameUserNameGet(userName, tenantId) {
      return this.v1TenantTenantIdUserUsernameUserNameGetWithHttpInfo(userName, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
