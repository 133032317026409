/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ContactTypeEnum from './ContactTypeEnum';

/**
 * The ReservationContact model module.
 * @module model/ReservationContact
 * @version v1
 */
class ReservationContact {
    /**
     * Constructs a new <code>ReservationContact</code>.
     * @alias module:model/ReservationContact
     */
    constructor() { 
        
        ReservationContact.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ReservationContact</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ReservationContact} obj Optional instance to populate.
     * @return {module:model/ReservationContact} The populated <code>ReservationContact</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ReservationContact();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('firstName')) {
                obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
            }
            if (data.hasOwnProperty('lastName')) {
                obj['lastName'] = ApiClient.convertToType(data['lastName'], 'String');
            }
            if (data.hasOwnProperty('street1')) {
                obj['street1'] = ApiClient.convertToType(data['street1'], 'String');
            }
            if (data.hasOwnProperty('street2')) {
                obj['street2'] = ApiClient.convertToType(data['street2'], 'String');
            }
            if (data.hasOwnProperty('city')) {
                obj['city'] = ApiClient.convertToType(data['city'], 'String');
            }
            if (data.hasOwnProperty('state')) {
                obj['state'] = ApiClient.convertToType(data['state'], 'String');
            }
            if (data.hasOwnProperty('zipcode')) {
                obj['zipcode'] = ApiClient.convertToType(data['zipcode'], 'String');
            }
            if (data.hasOwnProperty('country')) {
                obj['country'] = ApiClient.convertToType(data['country'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('homePhone')) {
                obj['homePhone'] = ApiClient.convertToType(data['homePhone'], 'String');
            }
            if (data.hasOwnProperty('mobilePhone')) {
                obj['mobilePhone'] = ApiClient.convertToType(data['mobilePhone'], 'String');
            }
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('contactType')) {
                obj['contactType'] = ContactTypeEnum.constructFromObject(data['contactType']);
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
ReservationContact.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
ReservationContact.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
ReservationContact.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
ReservationContact.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
ReservationContact.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
ReservationContact.prototype['tenantId'] = undefined;

/**
 * @member {String} firstName
 */
ReservationContact.prototype['firstName'] = undefined;

/**
 * @member {String} lastName
 */
ReservationContact.prototype['lastName'] = undefined;

/**
 * @member {String} street1
 */
ReservationContact.prototype['street1'] = undefined;

/**
 * @member {String} street2
 */
ReservationContact.prototype['street2'] = undefined;

/**
 * @member {String} city
 */
ReservationContact.prototype['city'] = undefined;

/**
 * @member {String} state
 */
ReservationContact.prototype['state'] = undefined;

/**
 * @member {String} zipcode
 */
ReservationContact.prototype['zipcode'] = undefined;

/**
 * @member {String} country
 */
ReservationContact.prototype['country'] = undefined;

/**
 * @member {String} email
 */
ReservationContact.prototype['email'] = undefined;

/**
 * @member {String} homePhone
 */
ReservationContact.prototype['homePhone'] = undefined;

/**
 * @member {String} mobilePhone
 */
ReservationContact.prototype['mobilePhone'] = undefined;

/**
 * @member {String} username
 */
ReservationContact.prototype['username'] = undefined;

/**
 * @member {module:model/ContactTypeEnum} contactType
 */
ReservationContact.prototype['contactType'] = undefined;






export default ReservationContact;

