import PricingApi from "@/generatedapiclients/src/api/PricingApi.js";

export default class PricingService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._pricingApi = new PricingApi();
  }

  async getFees(feeRequest, operation = 0) {
    const feeResponse = await this._pricingApi.v1TenantTenantIdPricingFeesGet(
      this._tenantId,
      {
        customerId: feeRequest.customerId,
        spotId: feeRequest.spotId,
        oldSpotId: feeRequest.oldSpotId,
        oldStartDate: feeRequest.oldStartDate,
        oldEndDate: feeRequest.oldEndDate,
        startDate: feeRequest.startDate,
        endDate: feeRequest.endDate,
        numberOfAdults: feeRequest.numberOfAdults,
        numberOfVehicles: feeRequest.numberOfVehicles,
        oldNumberOfAdults: feeRequest.oldNumberOfAdults,
        discounts: feeRequest.discounts,
        oldDiscounts: feeRequest.oldDiscounts,
        operationId: operation,
        salesChannel: feeRequest.salesChannel
      }
    );

    return feeResponse;
  }

  async getConvenienceFee(convenienceFeeRequest) {
    return await this._pricingApi.v1TenantTenantIdPricingConveniencefeePost(
      this._tenantId,
      {
        convenienceFeeRequest
      }
    );
  }
}
