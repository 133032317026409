import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./validation";
import { localize } from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import { VueReCaptcha } from "vue-recaptcha-v3";
import "./filters/date.js";
import "./filters/currency.js";
import "./filters/phone.js";
import "./filters/geoLocation.js";
import "./filters/enum.js";
import "./filters/yesNo.js";
import IdleVue from "idle-vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueObserveVisibility from "vue-observe-visibility";
import CKEditor from "ckeditor4-vue/dist/legacy.js";
import VueGtm from "vue-gtm";
import titleMixin from "./mixins/TitleMixin";

//Needed for IE
import "whatwg-fetch";
import "babel-polyfill";
import "intersection-observer";
import "promise-polyfill/src/polyfill";

Vue.mixin(titleMixin);
Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM, // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  //ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
});

Vue.use(CKEditor);
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(VueObserveVisibility);

Vue.config.productionTip = false;
Vue.use(VueReCaptcha, {
  siteKey: "6LcUweIUAAAAADvXUsMdgZbirHcPCZ2VP4sl00up",
  loaderOptions: {
    autoHideBadge: true
  }
});
//Idle after 13 min with a 2 min warning until logout
const eventsHub = new Vue();
const userIdelTime = store.getters["auth/userIdleTime"];
Vue.use(IdleVue, { idleTime: userIdelTime, eventEmitter: eventsHub, store });
localize("en", en);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
