<template>
  <div id="app" tabindex="-1">
    <a href="#content" class="skip-to-content-link">Skip to Content</a>
    <div v-if="tenant.name">
      <TheAuth />
      <TheSpinner v-if="loading" />
      <div>
        <router-view />
      </div>
    </div>
    <div v-else class="mt-5 text-center">
      <TheSpinner v-if="loadingTenant === true" />
      <div v-else>
        <h4>
          There was a connectivity issue loading the page.
          <button class="btn btn-primary" @click="reloadTenant">
            Try Again
          </button>
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import TheSpinner from "@/components/layout/TheSpinner.vue";
import tenantResolver from "@/services/TenantResolver";

import { mapActions } from "vuex";

import "@/assets/css/custom.css";
import "@/assets/css/fonticons.css";
import TheAuth from "@/components/layout/TheAuth.vue";

export default {
  name: "App",
  components: {
    TheSpinner,
    TheAuth
  },
  data() {
    return {
      loadingTenant: false
    };
  },
  computed: {
    tenant() {
      return this.$store.getters["tenant/tenantInfo"];
    },
    username() {
      return this.$store.getters["auth/username"];
    },
    userFirstName() {
      return this.$store.getters["auth/userFirstName"];
    },
    signedIn() {
      return this.$store.getters["auth/signedIn"];
    },
    loading() {
      return this.$store.getters["auth/loading"];
    },
    authSource() {
      return this.$store.getters["auth/authSource"];
    },
    amplifyConfig() {
      return this.$store.getters["tenant/amplifyConfig"];
    }
  },
  methods: {
    ...mapActions("tenant", ["updateTenantInfo", "clear"]),
    async loadTenant() {
      const currentTenantId = tenantResolver();
      this.$store.commit("tenant/setTenantLoaded", false);
      this.loadingTenant = true;
      await this.clear();
      this.updateTenantInfo(currentTenantId)
        .then(() => {
          this.$store.commit("tenant/setTenantLoaded", true);
          this.loadingTenant = false;
        })
        .catch(() => {
          this.loadingTenant = false;
        });
    },
    reloadTenant() {
      this.loadTenant();
    }
  },
  created() {
    this.loadTenant();
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
