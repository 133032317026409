import Vue from "vue";

Vue.filter("convertDMSLat", function(value) {
  if (value) {
    const lat = toDegreesMinutesAndSeconds(value);
    const cardinal = value >= 0 ? "N" : "S";
    return lat + " " + cardinal;
  }
});

Vue.filter("convertDMSLong", function(value) {
  if (value) {
    const long = toDegreesMinutesAndSeconds(value);
    const cardinal = value >= 0 ? "E" : "W";
    return long + " " + cardinal;
  }
});

Vue.filter("zipCode", function(value) {
  if (value) {
    if (value.trim().length === 5) return value;
    else if (value.trim().length > 5 && value.length < 9)
      return value.slice(0, 5);
    else return `${value.trim().slice(0, 5)}-${value.trim().slice(5)}`;
  }
});

//This is a StackSnag, not my code.
function toDegreesMinutesAndSeconds(coordinate) {
  const absolute = Math.abs(coordinate);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return degrees + "° " + minutes + "' " + seconds + '"';
}
