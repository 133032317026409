/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AuditItem from './AuditItem';

/**
 * The AuditHistory model module.
 * @module model/AuditHistory
 * @version v1
 */
class AuditHistory {
    /**
     * Constructs a new <code>AuditHistory</code>.
     * @alias module:model/AuditHistory
     */
    constructor() { 
        
        AuditHistory.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AuditHistory</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AuditHistory} obj Optional instance to populate.
     * @return {module:model/AuditHistory} The populated <code>AuditHistory</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AuditHistory();

            if (data.hasOwnProperty('objectName')) {
                obj['objectName'] = ApiClient.convertToType(data['objectName'], 'String');
            }
            if (data.hasOwnProperty('objectId')) {
                obj['objectId'] = ApiClient.convertToType(data['objectId'], 'String');
            }
            if (data.hasOwnProperty('action')) {
                obj['action'] = ApiClient.convertToType(data['action'], 'String');
            }
            if (data.hasOwnProperty('auditItems')) {
                obj['auditItems'] = ApiClient.convertToType(data['auditItems'], [AuditItem]);
            }
        }
        return obj;
    }


}

/**
 * @member {String} objectName
 */
AuditHistory.prototype['objectName'] = undefined;

/**
 * @member {String} objectId
 */
AuditHistory.prototype['objectId'] = undefined;

/**
 * @member {String} action
 */
AuditHistory.prototype['action'] = undefined;

/**
 * @member {Array.<module:model/AuditItem>} auditItems
 */
AuditHistory.prototype['auditItems'] = undefined;






export default AuditHistory;

