import Amplify, { Auth } from "aws-amplify";
import AdminUserService from "@/services/admin/AdminUserService.js";
//import { AmplifyEventBus } from "aws-amplify-vue";
import router from "../../router";
import AmplifyConfigService from "@/config/amplifyConfigService.js";

const state = {
  signedIn: false,
  username: "",
  userFirstName: "",
  userType: "",
  externalUserId: "",
  userRoleId: "",
  userPermissions: [],
  userLocations: [],
  loading: false,
  authSource: "",
  verifyStatus: "",
  userIdleTime: 780000 //13 mintues
};
const getters = {
  signedIn: state => state.signedIn,
  username: state => state.username,
  userFirstName: state => state.userFirstName,
  userType: state => state.userType,
  externalUserId: state => state.externalUserId,
  userRoleId: state => state.userRoleId,
  userPermissions: state => state.userPermissions,
  loading: state => state.loading,
  authSource: state => state.authSource,
  userIdleTime: state => state.userIdleTime,
  userLocations: state => state.userLocations,
  verifyStatus: state => state.verifyStatus
};
const actions = {
  clear({ commit }) {
    commit("setSignedIn", false);
    commit("setUsername", "");
    commit("setExternalUserId", "");
    commit("setUserRoleId", "");
    commit("setUserPermissions", []);
    commit("setUserLocations", []);
    commit("setLoading", false);
    commit("setUserFirstName", "");
    commit("setUserType", "");
    commit("setAuthSource", "");
    commit("setVerifyStatus", "");
  },
  async logOut({ commit, rootGetters, dispatch, getters }) {
    const userType = getters["userType"];
    const authSource = getters["authSource"];
    commit("setLoading", true);
    try {
      const session = await Auth.currentSession();
      const tenantId = rootGetters["tenant/tenantId"];
      const userService = new AdminUserService(tenantId);
      const authToken = session.getIdToken();
      const jwtToken = authToken.jwtToken;
      await userService.logout(jwtToken);
      await Auth.signOut();
    } catch (e) {
      dispatch("clear");
      dispatch("transaction/clear", null, { root: true });
      return;
    } finally {
      if (userType === "Customer" || authSource == "local") {
        router.push("/login").catch(() => {});
      } else {
        router.push("/admin/login").catch(() => {});
      }
      dispatch("clear");
      dispatch("transaction/clear", null, { root: true });
    }
  },

  async azureAdLogin({ commit, rootGetters }) {
    const amplifyConfig = rootGetters["tenant/amplifyConfig"];
    const amplifyConfigService = new AmplifyConfigService(amplifyConfig);
    const config = amplifyConfigService.getADAdminConfig();

    Amplify.configure({
      Auth: config
    });
    //Note: most store settings are set in TheAuth.vue after authenticating
    commit("setAuthSource", "admin");
    Auth.federatedSignIn({ provider: "AzureAD" });
  },

  async login({ commit, rootGetters, dispatch }, credentials) {
    try {
      dispatch("clear");
      let config = null;
      const amplifyConfig = rootGetters["tenant/amplifyConfig"];
      const amplifyConfigService = new AmplifyConfigService(amplifyConfig);
      if (credentials.authSource === "admin") {
        config = amplifyConfigService.getAdminConfig();
      } else {
        config = amplifyConfigService.getPublicConfig();
      }

      Amplify.configure({
        Auth: config
      });
      //Note: most store settings are set in TheAuth.vue after authenticating
      commit("setLoading", true);
      commit("setAuthSource", credentials.authSource);
      await Auth.signIn(
        credentials.loginUsername.toLowerCase(),
        credentials.loginPassword
      );
    } catch (err) {
      commit("setLoading", false);
      if (err.code === "UserLambdaValidationException") {
        return "Account is temporarily locked. Please try again later.";
      } else if (err.code === "UserNotFoundException") {
        return "Invalid username or password.";
      } else if (
        err.message ===
        "Temporary password has expired and must be reset by an administrator."
      ) {
        return "The password reset link has expired. Navigate to the Forgot Your Login Information page to request a new password reset link.";
      } else {
        return err.message;
      }
    }
  },

  async getAuthToken() {
    let session = await Auth.currentSession();
    if (!session.isValid()) {
      const user = await Auth.currentAuthenticatedUser();
      session = user.refreshSession(session.getRefreshToken(), () => {});
    }
    const authToken = session.getIdToken();
    return authToken.jwtToken;
  },

  clearTokens() {
    const localStorageKeys = Object.keys(localStorage);
    for (let i = 0; i < localStorageKeys.length; i++) {
      let item = localStorageKeys[i];
      if (item.startsWith("CognitoIdentityServiceProvider")) {
        localStorage.removeItem(item);
      }
    }
  },

  async isAuthenticated({ getters, dispatch }) {
    const signedIn = getters["signedIn"];
    if (!signedIn) return;

    const localStorageKeys = Object.keys(localStorage);
    for (let i = 0; i < localStorageKeys.length; i++) {
      let item = localStorageKeys[i];
      if (item.startsWith("CognitoIdentityServiceProvider")) {
        return;
      }
    }
    dispatch("logOut");
  },

  setLoading({ commit }, loading) {
    commit("setLoading", loading);
  }
};
const mutations = {
  setSignedIn(state, signedIn) {
    state.signedIn = signedIn;
  },
  setUsername(state, username) {
    state.username = username;
  },
  setExternalUserId(state, externalUserId) {
    state.externalUserId = externalUserId;
  },
  setUserRoleId(state, userRoleId) {
    state.userRoleId = userRoleId;
  },
  setUserPermissions(state, userPermissions) {
    state.userPermissions = userPermissions;
  },
  setUserLocations(state, userLocations) {
    state.userLocations = userLocations;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setUserFirstName(state, userFirstName) {
    state.userFirstName = userFirstName;
  },
  setUserType(state, userType) {
    state.userType = userType;
  },
  setAuthSource(state, authSource) {
    state.authSource = authSource;
  },
  setUserIdleTime(state, userIdleTime) {
    state.userIdleTime = userIdleTime;
  },
  setVerifyStatus(state, status) {
    state.verifyStatus = status;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
