export default class AmplifyConfigService {
  constructor(config) {
    this.config = config;
  }

  getADAdminConfig() {
    const config = {
      region: this.config.awsRegion,
      userPoolId: this.config.adminUserPoolId,
      userPoolWebClientId: this.config.adminClientId,
      oauth: {
        domain: this.config.adminOauthNicAdDomain,
        scope: ["phone", "email", "profile", "openid"],
        responseType: "code"
      }
    };
    if (window.webpackHotUpdate) {
      config.oauth.redirectSignIn = "http://localhost:8080/admin";
      config.oauth.redirectSignOut = "http://localhost:8080/admin/login";
    } else {
      config.oauth.redirectSignIn = this.config.adminOauthNicAdRedirectSignInUrl;
      config.oauth.redirectSignOut = this.config.adminOauthNicAdRedirectSignOutUrl;
    }
    return config;
  }

  getPublicConfig() {
    return {
      region: this.config.awsRegion,
      userPoolId: this.config.publicUserPoolId,
      userPoolWebClientId: this.config.publicClientId
    };
  }

  getAdminConfig() {
    return {
      region: this.config.awsRegion,
      userPoolId: this.config.tenantAdminUserPoolId,
      userPoolWebClientId: this.config.tenantAdminClientId
    };
  }
}
