/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AuditHistoryIEnumerableResponse from '../model/AuditHistoryIEnumerableResponse';
import BooleanResponse from '../model/BooleanResponse';
import CustomerCommentResponse from '../model/CustomerCommentResponse';
import CustomerDiscounts from '../model/CustomerDiscounts';
import CustomerHistoryItemIEnumerableResponse from '../model/CustomerHistoryItemIEnumerableResponse';
import CustomerResponse from '../model/CustomerResponse';
import EditCustomerRequest from '../model/EditCustomerRequest';
import NewCustomerRequest from '../model/NewCustomerRequest';
import ResendConfirmationEmailRequest from '../model/ResendConfirmationEmailRequest';
import Response from '../model/Response';

/**
* AdminCustomer service.
* @module api/AdminCustomerApi
* @version v1
*/
export default class AdminCustomerApi {

    /**
    * Constructs a new AdminCustomerApi. 
    * @alias module:api/AdminCustomerApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {Number} customerId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.comment 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CustomerCommentResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminCustomerCustomerIdAddCommentPostWithHttpInfo(customerId, tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'customerId' is set
      if (customerId === undefined || customerId === null) {
        throw new Error("Missing the required parameter 'customerId' when calling v1TenantTenantIdAdminAdminCustomerCustomerIdAddCommentPost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCustomerCustomerIdAddCommentPost");
      }

      let pathParams = {
        'customerId': customerId,
        'tenantId': tenantId
      };
      let queryParams = {
        'comment': opts['comment']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CustomerCommentResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCustomer/{customerId}/add-comment', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} customerId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.comment 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CustomerCommentResponse}
     */
    v1TenantTenantIdAdminAdminCustomerCustomerIdAddCommentPost(customerId, tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminCustomerCustomerIdAddCommentPostWithHttpInfo(customerId, tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} customerId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CustomerResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminCustomerCustomerIdGetWithHttpInfo(customerId, tenantId) {
      let postBody = null;
      // verify the required parameter 'customerId' is set
      if (customerId === undefined || customerId === null) {
        throw new Error("Missing the required parameter 'customerId' when calling v1TenantTenantIdAdminAdminCustomerCustomerIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCustomerCustomerIdGet");
      }

      let pathParams = {
        'customerId': customerId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CustomerResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCustomer/{customerId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} customerId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CustomerResponse}
     */
    v1TenantTenantIdAdminAdminCustomerCustomerIdGet(customerId, tenantId) {
      return this.v1TenantTenantIdAdminAdminCustomerCustomerIdGetWithHttpInfo(customerId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} auditTransactionId 
     * @param {String} tenantId 
     * @param {String} customerId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AuditHistoryIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminCustomerCustomerIdHistoryAuditTransactionIdChangesGetWithHttpInfo(auditTransactionId, tenantId, customerId) {
      let postBody = null;
      // verify the required parameter 'auditTransactionId' is set
      if (auditTransactionId === undefined || auditTransactionId === null) {
        throw new Error("Missing the required parameter 'auditTransactionId' when calling v1TenantTenantIdAdminAdminCustomerCustomerIdHistoryAuditTransactionIdChangesGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCustomerCustomerIdHistoryAuditTransactionIdChangesGet");
      }
      // verify the required parameter 'customerId' is set
      if (customerId === undefined || customerId === null) {
        throw new Error("Missing the required parameter 'customerId' when calling v1TenantTenantIdAdminAdminCustomerCustomerIdHistoryAuditTransactionIdChangesGet");
      }

      let pathParams = {
        'auditTransactionId': auditTransactionId,
        'tenantId': tenantId,
        'customerId': customerId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AuditHistoryIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCustomer/{customerId}/history/{auditTransactionId}/changes', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} auditTransactionId 
     * @param {String} tenantId 
     * @param {String} customerId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AuditHistoryIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminCustomerCustomerIdHistoryAuditTransactionIdChangesGet(auditTransactionId, tenantId, customerId) {
      return this.v1TenantTenantIdAdminAdminCustomerCustomerIdHistoryAuditTransactionIdChangesGetWithHttpInfo(auditTransactionId, tenantId, customerId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} customerId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CustomerHistoryItemIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminCustomerCustomerIdHistoryGetWithHttpInfo(customerId, tenantId) {
      let postBody = null;
      // verify the required parameter 'customerId' is set
      if (customerId === undefined || customerId === null) {
        throw new Error("Missing the required parameter 'customerId' when calling v1TenantTenantIdAdminAdminCustomerCustomerIdHistoryGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCustomerCustomerIdHistoryGet");
      }

      let pathParams = {
        'customerId': customerId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CustomerHistoryItemIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCustomer/{customerId}/history', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} customerId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CustomerHistoryItemIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminCustomerCustomerIdHistoryGet(customerId, tenantId) {
      return this.v1TenantTenantIdAdminAdminCustomerCustomerIdHistoryGetWithHttpInfo(customerId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} customerId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/CustomerDiscounts} opts.customerDiscounts 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BooleanResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminCustomerCustomerIdVerifydiscountsPostWithHttpInfo(customerId, tenantId, opts) {
      opts = opts || {};
      let postBody = opts['customerDiscounts'];
      // verify the required parameter 'customerId' is set
      if (customerId === undefined || customerId === null) {
        throw new Error("Missing the required parameter 'customerId' when calling v1TenantTenantIdAdminAdminCustomerCustomerIdVerifydiscountsPost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCustomerCustomerIdVerifydiscountsPost");
      }

      let pathParams = {
        'customerId': customerId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCustomer/{customerId}/verifydiscounts', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} customerId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/CustomerDiscounts} opts.customerDiscounts 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BooleanResponse}
     */
    v1TenantTenantIdAdminAdminCustomerCustomerIdVerifydiscountsPost(customerId, tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminCustomerCustomerIdVerifydiscountsPostWithHttpInfo(customerId, tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    v1TenantTenantIdAdminAdminCustomerCustomerstatusesAllGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCustomerCustomerstatusesAllGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCustomer/customerstatuses/all', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    v1TenantTenantIdAdminAdminCustomerCustomerstatusesAllGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminCustomerCustomerstatusesAllGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.createUser  (default to true)
     * @param {module:model/NewCustomerRequest} opts.newCustomerRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CustomerResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminCustomerPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['newCustomerRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCustomerPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'createUser': opts['createUser']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CustomerResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCustomer', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.createUser  (default to true)
     * @param {module:model/NewCustomerRequest} opts.newCustomerRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CustomerResponse}
     */
    v1TenantTenantIdAdminAdminCustomerPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminCustomerPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/EditCustomerRequest} opts.editCustomerRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CustomerResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminCustomerPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['editCustomerRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCustomerPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CustomerResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCustomer', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/EditCustomerRequest} opts.editCustomerRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CustomerResponse}
     */
    v1TenantTenantIdAdminAdminCustomerPut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminCustomerPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ResendConfirmationEmailRequest} opts.resendConfirmationEmailRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdAdminAdminCustomerSendconfirmationemailPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['resendConfirmationEmailRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCustomerSendconfirmationemailPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCustomer/sendconfirmationemail', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ResendConfirmationEmailRequest} opts.resendConfirmationEmailRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdAdminAdminCustomerSendconfirmationemailPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminCustomerSendconfirmationemailPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.username 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdAdminAdminCustomerUsernameAvailabilityGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCustomerUsernameAvailabilityGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'username': opts['username']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCustomer/usernameAvailability', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.username 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdAdminAdminCustomerUsernameAvailabilityGet(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminCustomerUsernameAvailabilityGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
