import AdminSpotReservationApi from "@/generatedapiclients/src/api/AdminSpotReservationApi.js";
import AdminSearchApi from "@/generatedapiclients/src/api/AdminSearchApi.js";
import moment from "moment";

export default class AdminSpotReservationService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._adminSpotReservationApi = new AdminSpotReservationApi();
    this._adminSearchApi = new AdminSearchApi();
  }

  claimSpot(
    spotId,
    reservationStartDate,
    reservationEndDate,
    lockCode,
    spotReservationId,
    customerId,
    walkInReservation
  ) {
    return this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationSpotSpotIdClaimPost(
      spotId,
      this._tenantId,
      {
        adminClaimSpotRequest: {
          reservationStartDate,
          reservationEndDate,
          lockCode,
          spotReservationId,
          customerId,
          walkInReservation,
          spotId
        }
      }
    );
  }

  async search(searchCriteria, paging) {
    return this._adminSearchApi.v1TenantTenantIdAdminAdminSearchReservationsGet(
      this._tenantId,
      {
        ...searchCriteria,
        ...paging
      }
    );
  }

  getReservation(id) {
    return this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationReservationIdGet(
      id,
      this._tenantId
    );
  }

  getReservationDetail(id) {
    return this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationDetailReservationIdGet(
      id,
      this._tenantId
    );
  }

  cancelReservation(reservationId, adminCancellationRequest) {
    adminCancellationRequest.pricingRequest.salesChannel = 2;
    return this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationReservationIdCancelPost(
      this._tenantId,
      reservationId,
      {
        adminCancellationRequest
      }
    );
  }

  cancelReservationWithoutRefund(reservationId, reason) {
    return this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationReservationIdCancelWitoutRefundPost(
      reservationId,
      this._tenantId,
      {
        reason
      }
    );
  }

  modifyReservation(reservationId, adminEditReservationRequest, paymentTypeId) {
    adminEditReservationRequest.newReservationRequest.salesChannel = 2;
    return this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationReservationIdChangePost(
      this._tenantId,
      reservationId,
      {
        paymentTypeId,
        adminEditReservationRequest
      }
    );
  }

  getReservationComment(reservationId) {
    return this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationReservationIdCommentGet(
      reservationId,
      this._tenantId
    );
  }

  updateReservationComment(reservationId, reservationComment) {
    return this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationReservationIdCommentPost(
      this._tenantId,
      reservationId,
      {
        reservationCommment: reservationComment
      }
    );
  }

  getHistory(reservationId) {
    return this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationReservationIdHistoryGet(
      reservationId,
      this._tenantId
    );
  }

  getChangeHistory(reservationId, transactionId) {
    return this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationReservationIdHistoryAuditTransactionIdChangesGet(
      transactionId,
      this._tenantId,
      reservationId
    );
  }

  async validateSpotReservation(request) {
    request.salesChannel = 2;
    return this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationValidatePost(
      this._tenantId,
      {
        adminNewReservationRequest: { ...request }
      }
    );
  }

  async validateSpotReservationEdit(adminEditReservationRequest) {
    adminEditReservationRequest.newReservationRequest.salesChannel = 2;
    return this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationValidateEditPost(
      this._tenantId,
      {
        adminEditReservationRequest
      }
    );
  }

  releaseSpotClaim(spotId, lockCode) {
    return this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationSpotSpotIdReleaseclaimPost(
      spotId,
      this._tenantId,
      {
        lockCode: lockCode
      }
    );
  }

  checkIn(adminCheckInRequest) {
    return this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationCheckinReservationIdPost(
      this._tenantId,
      adminCheckInRequest.reservationId,
      {
        adminCheckInRequest
      }
    );
  }

  checkOut(adminCheckOutRequest) {
    return this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationCheckoutReservationIdPost(
      this._tenantId,
      adminCheckOutRequest.reservationId,
      {
        adminCheckOutRequest
      }
    );
  }

  undoCheckin(reservationId) {
    return this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationUndocheckinReservationIdPost(
      reservationId,
      this._tenantId
    );
  }

  undoCheckout(reservationId) {
    return this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationUndocheckoutReservationIdPost(
      reservationId,
      this._tenantId
    );
  }

  searchCheckIn(locationId, searchCriteria) {
    return this._adminSearchApi.v1TenantTenantIdAdminAdminSearchCheckinGet(
      this._tenantId,
      {
        ...searchCriteria,
        today: moment().format("L"),
        locationIds: [locationId]
      }
    );
  }
  searchCheckOut(locationId, searchCriteria) {
    return this._adminSearchApi.v1TenantTenantIdAdminAdminSearchCheckoutGet(
      this._tenantId,
      {
        ...searchCriteria,
        today: moment().format("L"),
        locationIds: [locationId]
      }
    );
  }
  bulkCheckIn(adminBulkCheckInOutRequest) {
    return this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationBulkcheckinPost(
      this._tenantId,
      {
        adminBulkCheckInOutRequest
      }
    );
  }
  bulkCheckOut(adminBulkCheckInOutRequest) {
    return this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationBulkcheckoutPost(
      this._tenantId,
      {
        adminBulkCheckInOutRequest
      }
    );
  }
  getModificationRefundBreakdown(fees, reservationId) {
    return this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationModificationRefundBreakdownPost(
      this._tenantId,
      {
        reservationId,
        fee: fees
      }
    );
  }
  getCancellationRefundBreakdown(fees, reservationId, refundOption) {
    return this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationCancellationRefundBreakdownPost(
      this._tenantId,
      {
        reservationId,
        fee: fees,
        refundOption
      }
    );
  }

  async getReservationsByDate(spotId, date) {
    const response = await this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationSpotIdReservationsByDateDateGet(
      spotId,
      date,
      this._tenantId
    );
    return response.data;
  }

  async chargebackReservation(chargebackRequest) {
    const response = await this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationChargebackPost(
      this._tenantId,
      {
        chargebackRequest
      }
    );
    return response;
  }

  async undoChargebackReservation(reservationId) {
    const response = await this._adminSpotReservationApi.v1TenantTenantIdAdminAdminSpotReservationUndoChargebackReservationIdGet(
      reservationId,
      this._tenantId
    );
    return response;
  }
}
