/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ReceiptReservation model module.
 * @module model/ReceiptReservation
 * @version v1
 */
class ReceiptReservation {
    /**
     * Constructs a new <code>ReceiptReservation</code>.
     * @alias module:model/ReceiptReservation
     */
    constructor() { 
        
        ReceiptReservation.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ReceiptReservation</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ReceiptReservation} obj Optional instance to populate.
     * @return {module:model/ReceiptReservation} The populated <code>ReceiptReservation</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ReceiptReservation();

            if (data.hasOwnProperty('reservationNumber')) {
                obj['reservationNumber'] = ApiClient.convertToType(data['reservationNumber'], 'String');
            }
            if (data.hasOwnProperty('checkIn')) {
                obj['checkIn'] = ApiClient.convertToType(data['checkIn'], 'String');
            }
            if (data.hasOwnProperty('checkOut')) {
                obj['checkOut'] = ApiClient.convertToType(data['checkOut'], 'String');
            }
            if (data.hasOwnProperty('numberOfOccupants')) {
                obj['numberOfOccupants'] = ApiClient.convertToType(data['numberOfOccupants'], 'Number');
            }
            if (data.hasOwnProperty('numberOfVehicles')) {
                obj['numberOfVehicles'] = ApiClient.convertToType(data['numberOfVehicles'], 'Number');
            }
            if (data.hasOwnProperty('primaryOccupantName')) {
                obj['primaryOccupantName'] = ApiClient.convertToType(data['primaryOccupantName'], 'String');
            }
            if (data.hasOwnProperty('primaryOccupantAddress')) {
                obj['primaryOccupantAddress'] = ApiClient.convertToType(data['primaryOccupantAddress'], 'String');
            }
            if (data.hasOwnProperty('parkName')) {
                obj['parkName'] = ApiClient.convertToType(data['parkName'], 'String');
            }
            if (data.hasOwnProperty('parkAddress')) {
                obj['parkAddress'] = ApiClient.convertToType(data['parkAddress'], 'String');
            }
            if (data.hasOwnProperty('parkPhone')) {
                obj['parkPhone'] = ApiClient.convertToType(data['parkPhone'], 'String');
            }
            if (data.hasOwnProperty('parkPhoneNumber')) {
                obj['parkPhoneNumber'] = ApiClient.convertToType(data['parkPhoneNumber'], 'String');
            }
            if (data.hasOwnProperty('parkWebsite')) {
                obj['parkWebsite'] = ApiClient.convertToType(data['parkWebsite'], 'String');
            }
            if (data.hasOwnProperty('area')) {
                obj['area'] = ApiClient.convertToType(data['area'], 'String');
            }
            if (data.hasOwnProperty('site')) {
                obj['site'] = ApiClient.convertToType(data['site'], 'String');
            }
            if (data.hasOwnProperty('siteTypes')) {
                obj['siteTypes'] = ApiClient.convertToType(data['siteTypes'], 'String');
            }
            if (data.hasOwnProperty('reservationFeeAmount')) {
                obj['reservationFeeAmount'] = ApiClient.convertToType(data['reservationFeeAmount'], 'String');
            }
            if (data.hasOwnProperty('transactionFeeAmount')) {
                obj['transactionFeeAmount'] = ApiClient.convertToType(data['transactionFeeAmount'], 'String');
            }
            if (data.hasOwnProperty('convenienceFeeAmount')) {
                obj['convenienceFeeAmount'] = ApiClient.convertToType(data['convenienceFeeAmount'], 'String');
            }
            if (data.hasOwnProperty('amountPaid')) {
                obj['amountPaid'] = ApiClient.convertToType(data['amountPaid'], 'String');
            }
            if (data.hasOwnProperty('numberOfNights')) {
                obj['numberOfNights'] = ApiClient.convertToType(data['numberOfNights'], 'Number');
            }
            if (data.hasOwnProperty('addOnFeeAmount')) {
                obj['addOnFeeAmount'] = ApiClient.convertToType(data['addOnFeeAmount'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} reservationNumber
 */
ReceiptReservation.prototype['reservationNumber'] = undefined;

/**
 * @member {String} checkIn
 */
ReceiptReservation.prototype['checkIn'] = undefined;

/**
 * @member {String} checkOut
 */
ReceiptReservation.prototype['checkOut'] = undefined;

/**
 * @member {Number} numberOfOccupants
 */
ReceiptReservation.prototype['numberOfOccupants'] = undefined;

/**
 * @member {Number} numberOfVehicles
 */
ReceiptReservation.prototype['numberOfVehicles'] = undefined;

/**
 * @member {String} primaryOccupantName
 */
ReceiptReservation.prototype['primaryOccupantName'] = undefined;

/**
 * @member {String} primaryOccupantAddress
 */
ReceiptReservation.prototype['primaryOccupantAddress'] = undefined;

/**
 * @member {String} parkName
 */
ReceiptReservation.prototype['parkName'] = undefined;

/**
 * @member {String} parkAddress
 */
ReceiptReservation.prototype['parkAddress'] = undefined;

/**
 * @member {String} parkPhone
 */
ReceiptReservation.prototype['parkPhone'] = undefined;

/**
 * @member {String} parkPhoneNumber
 */
ReceiptReservation.prototype['parkPhoneNumber'] = undefined;

/**
 * @member {String} parkWebsite
 */
ReceiptReservation.prototype['parkWebsite'] = undefined;

/**
 * @member {String} area
 */
ReceiptReservation.prototype['area'] = undefined;

/**
 * @member {String} site
 */
ReceiptReservation.prototype['site'] = undefined;

/**
 * @member {String} siteTypes
 */
ReceiptReservation.prototype['siteTypes'] = undefined;

/**
 * @member {String} reservationFeeAmount
 */
ReceiptReservation.prototype['reservationFeeAmount'] = undefined;

/**
 * @member {String} transactionFeeAmount
 */
ReceiptReservation.prototype['transactionFeeAmount'] = undefined;

/**
 * @member {String} convenienceFeeAmount
 */
ReceiptReservation.prototype['convenienceFeeAmount'] = undefined;

/**
 * @member {String} amountPaid
 */
ReceiptReservation.prototype['amountPaid'] = undefined;

/**
 * @member {Number} numberOfNights
 */
ReceiptReservation.prototype['numberOfNights'] = undefined;

/**
 * @member {String} addOnFeeAmount
 */
ReceiptReservation.prototype['addOnFeeAmount'] = undefined;






export default ReceiptReservation;

