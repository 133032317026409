/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The LocationPointOfInterestSummary model module.
 * @module model/LocationPointOfInterestSummary
 * @version v1
 */
class LocationPointOfInterestSummary {
    /**
     * Constructs a new <code>LocationPointOfInterestSummary</code>.
     * @alias module:model/LocationPointOfInterestSummary
     */
    constructor() { 
        
        LocationPointOfInterestSummary.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>LocationPointOfInterestSummary</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/LocationPointOfInterestSummary} obj Optional instance to populate.
     * @return {module:model/LocationPointOfInterestSummary} The populated <code>LocationPointOfInterestSummary</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LocationPointOfInterestSummary();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('poiId')) {
                obj['poiId'] = ApiClient.convertToType(data['poiId'], 'Number');
            }
            if (data.hasOwnProperty('latitude')) {
                obj['latitude'] = ApiClient.convertToType(data['latitude'], 'Number');
            }
            if (data.hasOwnProperty('longitude')) {
                obj['longitude'] = ApiClient.convertToType(data['longitude'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('icon')) {
                obj['icon'] = ApiClient.convertToType(data['icon'], 'String');
            }
            if (data.hasOwnProperty('distanceDescriptionId')) {
                obj['distanceDescriptionId'] = ApiClient.convertToType(data['distanceDescriptionId'], 'Number');
            }
            if (data.hasOwnProperty('distanceDescriptionName')) {
                obj['distanceDescriptionName'] = ApiClient.convertToType(data['distanceDescriptionName'], 'String');
            }
            if (data.hasOwnProperty('distanceDescriptionDescription')) {
                obj['distanceDescriptionDescription'] = ApiClient.convertToType(data['distanceDescriptionDescription'], 'String');
            }
            if (data.hasOwnProperty('categoryId')) {
                obj['categoryId'] = ApiClient.convertToType(data['categoryId'], 'Number');
            }
            if (data.hasOwnProperty('categoryName')) {
                obj['categoryName'] = ApiClient.convertToType(data['categoryName'], 'String');
            }
            if (data.hasOwnProperty('categoryDescription')) {
                obj['categoryDescription'] = ApiClient.convertToType(data['categoryDescription'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
LocationPointOfInterestSummary.prototype['id'] = undefined;

/**
 * @member {Number} poiId
 */
LocationPointOfInterestSummary.prototype['poiId'] = undefined;

/**
 * @member {Number} latitude
 */
LocationPointOfInterestSummary.prototype['latitude'] = undefined;

/**
 * @member {Number} longitude
 */
LocationPointOfInterestSummary.prototype['longitude'] = undefined;

/**
 * @member {String} name
 */
LocationPointOfInterestSummary.prototype['name'] = undefined;

/**
 * @member {String} description
 */
LocationPointOfInterestSummary.prototype['description'] = undefined;

/**
 * @member {String} icon
 */
LocationPointOfInterestSummary.prototype['icon'] = undefined;

/**
 * @member {Number} distanceDescriptionId
 */
LocationPointOfInterestSummary.prototype['distanceDescriptionId'] = undefined;

/**
 * @member {String} distanceDescriptionName
 */
LocationPointOfInterestSummary.prototype['distanceDescriptionName'] = undefined;

/**
 * @member {String} distanceDescriptionDescription
 */
LocationPointOfInterestSummary.prototype['distanceDescriptionDescription'] = undefined;

/**
 * @member {Number} categoryId
 */
LocationPointOfInterestSummary.prototype['categoryId'] = undefined;

/**
 * @member {String} categoryName
 */
LocationPointOfInterestSummary.prototype['categoryName'] = undefined;

/**
 * @member {String} categoryDescription
 */
LocationPointOfInterestSummary.prototype['categoryDescription'] = undefined;






export default LocationPointOfInterestSummary;

