/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class PermissionEnum.
* @enum {}
* @readonly
*/
export default class PermissionEnum {
    
        /**
         * value: 1
         * @const
         */
        "1" = 1;

    
        /**
         * value: 2
         * @const
         */
        "2" = 2;

    
        /**
         * value: 3
         * @const
         */
        "3" = 3;

    
        /**
         * value: 4
         * @const
         */
        "4" = 4;

    
        /**
         * value: 5
         * @const
         */
        "5" = 5;

    
        /**
         * value: 6
         * @const
         */
        "6" = 6;

    
        /**
         * value: 7
         * @const
         */
        "7" = 7;

    
        /**
         * value: 8
         * @const
         */
        "8" = 8;

    
        /**
         * value: 9
         * @const
         */
        "9" = 9;

    
        /**
         * value: 10
         * @const
         */
        "10" = 10;

    
        /**
         * value: 11
         * @const
         */
        "11" = 11;

    
        /**
         * value: 12
         * @const
         */
        "12" = 12;

    
        /**
         * value: 13
         * @const
         */
        "13" = 13;

    
        /**
         * value: 14
         * @const
         */
        "14" = 14;

    
        /**
         * value: 15
         * @const
         */
        "15" = 15;

    
        /**
         * value: 16
         * @const
         */
        "16" = 16;

    
        /**
         * value: 17
         * @const
         */
        "17" = 17;

    
        /**
         * value: 18
         * @const
         */
        "18" = 18;

    
        /**
         * value: 19
         * @const
         */
        "19" = 19;

    
        /**
         * value: 20
         * @const
         */
        "20" = 20;

    
        /**
         * value: 21
         * @const
         */
        "21" = 21;

    
        /**
         * value: 22
         * @const
         */
        "22" = 22;

    
        /**
         * value: 23
         * @const
         */
        "23" = 23;

    
        /**
         * value: 24
         * @const
         */
        "24" = 24;

    
        /**
         * value: 25
         * @const
         */
        "25" = 25;

    
        /**
         * value: 26
         * @const
         */
        "26" = 26;

    
        /**
         * value: 27
         * @const
         */
        "27" = 27;

    
        /**
         * value: 28
         * @const
         */
        "28" = 28;

    
        /**
         * value: 29
         * @const
         */
        "29" = 29;

    
        /**
         * value: 30
         * @const
         */
        "30" = 30;

    
        /**
         * value: 31
         * @const
         */
        "31" = 31;

    
        /**
         * value: 32
         * @const
         */
        "32" = 32;

    
        /**
         * value: 33
         * @const
         */
        "33" = 33;

    
        /**
         * value: 34
         * @const
         */
        "34" = 34;

    
        /**
         * value: 35
         * @const
         */
        "35" = 35;

    
        /**
         * value: 36
         * @const
         */
        "36" = 36;

    
        /**
         * value: 900
         * @const
         */
        "900" = 900;

    

    /**
    * Returns a <code>PermissionEnum</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/PermissionEnum} The enum <code>PermissionEnum</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

