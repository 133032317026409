/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The TenantSupportInfo model module.
 * @module model/TenantSupportInfo
 * @version v1
 */
class TenantSupportInfo {
    /**
     * Constructs a new <code>TenantSupportInfo</code>.
     * @alias module:model/TenantSupportInfo
     */
    constructor() { 
        
        TenantSupportInfo.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>TenantSupportInfo</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/TenantSupportInfo} obj Optional instance to populate.
     * @return {module:model/TenantSupportInfo} The populated <code>TenantSupportInfo</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TenantSupportInfo();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('phoneNumber')) {
                obj['phoneNumber'] = ApiClient.convertToType(data['phoneNumber'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('address_1')) {
                obj['address_1'] = ApiClient.convertToType(data['address_1'], 'String');
            }
            if (data.hasOwnProperty('address_2')) {
                obj['address_2'] = ApiClient.convertToType(data['address_2'], 'String');
            }
            if (data.hasOwnProperty('city')) {
                obj['city'] = ApiClient.convertToType(data['city'], 'String');
            }
            if (data.hasOwnProperty('state')) {
                obj['state'] = ApiClient.convertToType(data['state'], 'String');
            }
            if (data.hasOwnProperty('zip')) {
                obj['zip'] = ApiClient.convertToType(data['zip'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
TenantSupportInfo.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
TenantSupportInfo.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
TenantSupportInfo.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
TenantSupportInfo.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
TenantSupportInfo.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
TenantSupportInfo.prototype['tenantId'] = undefined;

/**
 * @member {String} url
 */
TenantSupportInfo.prototype['url'] = undefined;

/**
 * @member {String} phoneNumber
 */
TenantSupportInfo.prototype['phoneNumber'] = undefined;

/**
 * @member {String} email
 */
TenantSupportInfo.prototype['email'] = undefined;

/**
 * @member {String} address_1
 */
TenantSupportInfo.prototype['address_1'] = undefined;

/**
 * @member {String} address_2
 */
TenantSupportInfo.prototype['address_2'] = undefined;

/**
 * @member {String} city
 */
TenantSupportInfo.prototype['city'] = undefined;

/**
 * @member {String} state
 */
TenantSupportInfo.prototype['state'] = undefined;

/**
 * @member {Number} zip
 */
TenantSupportInfo.prototype['zip'] = undefined;






export default TenantSupportInfo;

