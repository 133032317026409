/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Fee from './Fee';
import NewReservationRequest from './NewReservationRequest';

/**
 * The AdminEditReservationRequest model module.
 * @module model/AdminEditReservationRequest
 * @version v1
 */
class AdminEditReservationRequest {
    /**
     * Constructs a new <code>AdminEditReservationRequest</code>.
     * @alias module:model/AdminEditReservationRequest
     */
    constructor() { 
        
        AdminEditReservationRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AdminEditReservationRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AdminEditReservationRequest} obj Optional instance to populate.
     * @return {module:model/AdminEditReservationRequest} The populated <code>AdminEditReservationRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AdminEditReservationRequest();

            if (data.hasOwnProperty('newReservationRequest')) {
                obj['newReservationRequest'] = NewReservationRequest.constructFromObject(data['newReservationRequest']);
            }
            if (data.hasOwnProperty('feeOverrides')) {
                obj['feeOverrides'] = ApiClient.convertToType(data['feeOverrides'], [Fee]);
            }
            if (data.hasOwnProperty('reservationId')) {
                obj['reservationId'] = ApiClient.convertToType(data['reservationId'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/NewReservationRequest} newReservationRequest
 */
AdminEditReservationRequest.prototype['newReservationRequest'] = undefined;

/**
 * @member {Array.<module:model/Fee>} feeOverrides
 */
AdminEditReservationRequest.prototype['feeOverrides'] = undefined;

/**
 * @member {Number} reservationId
 */
AdminEditReservationRequest.prototype['reservationId'] = undefined;






export default AdminEditReservationRequest;

