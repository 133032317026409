import store from "../store";

export function checkPermission(permission) {
  const userRoleId = store.getters["auth/userRoleId"];
  if (userRoleId === 1)
    //NICAdmin
    return true;

  const permissions = store.getters["auth/userPermissions"];
  const perm = permissions.find(p => p.name === permission);
  if (perm != null) {
    return true;
  }
  return false;
}

export function checkLocationPermission(permission, locationId) {
  const userRoleId = store.getters["auth/userRoleId"];
  if (userRoleId === 1)
    //NICAdmin
    return true;

  const permissions = store.getters["auth/userPermissions"];
  const perm = permissions.find(p => p.name === permission);
  if (perm != null) {
    if (userRoleId === 2) {
      //Agency Admin's have access to all parks
      return true;
    }
    const locations = store.getters["auth/userLocations"];
    if (locations.includes(locationId)) {
      return true;
    }
  }

  return false;
}

export function checkLocationsPermission(permission, locationIds) {
  const userRoleId = store.getters["auth/userRoleId"];
  if (userRoleId === 1)
    //NICAdmin
    return true;

  const permissions = store.getters["auth/userPermissions"];
  const perm = permissions.find(p => p.name === permission);
  if (perm != null) {
    if (userRoleId === 2) {
      //Agency Admin's have access to all parks
      return true;
    }
    const locations = store.getters["auth/userLocations"];
    if (locationIds.every(locationId => locations.includes(locationId))) {
      return true;
    }
  }

  return false;
}

export default {
  methods: {
    checkPermission,
    checkLocationPermission,
    checkLocationsPermission
  }
};
