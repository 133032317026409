import mbxClient from "@mapbox/mapbox-sdk";
import mbxGeocode from "@mapbox/mapbox-sdk/services/geocoding";
import LocationApi from "@/generatedapiclients/src/api/LocationApi.js";
import SpotApi from "@/generatedapiclients/src/api/SpotApi.js";
import TenantApi from "@/generatedapiclients/src/api/TenantApi.js";

export default class ParkSearchService {
  constructor(mapboxUrl, mapboxApiKey, tenantId) {
    this._mapboxUrl = mapboxUrl;
    this._mapboxApiKey = mapboxApiKey;
    this._tenantId = tenantId;
    this._locationApi = new LocationApi();
    this._spotApi = new SpotApi();
    this._tenantApi = new TenantApi();
  }

  searchParksByLocation(latitude, longitude, pageNumber, itemsPerPage) {
    return this._locationApi.v1TenantTenantIdLocationSearchSummaryItemsPost(
      this._tenantId,
      {
        locationAndSpotSearchRequest: {
          latitude: latitude,
          longitude: longitude,
          pageNumber: pageNumber,
          itemsPerPage: itemsPerPage,
          sortByMilesFromLatLong: true
        }
      }
    );
  }

  async searchParks(searchParams) {
    const response = await this._locationApi.v1TenantTenantIdLocationSearchSummaryItemsPost(
      this._tenantId,
      {
        locationAndSpotSearchRequest: {
          ...searchParams
        }
      }
    );
    return response.locationSummaryItemsPage.data;
  }

  getParks(pageNumber, itemsPerPage) {
    return this._locationApi.v1TenantTenantIdLocationSearchSummaryItemsPost(
      this._tenantId,
      {
        locationAndSpotSearchRequest: {
          pageNumber: pageNumber,
          itemsPerPage: itemsPerPage
        }
      }
    );
  }

  async getSpotTypes() {
    const response = await this._spotApi.v1TenantTenantIdSpotSpotTypesGet(
      this._tenantId
    );
    return response.spotTypes;
  }
  async getPointsOfInterest(request) {
    const response = await this._locationApi.v1TenantTenantIdLocationPointsofinterestGet(
      this._tenantId,
      {
        ...request
      }
    );
    return response.pointsOfInterest;
  }
  async getAttributes(request) {
    const response = await this._locationApi.v1TenantTenantIdLocationAttributesGet(
      this._tenantId,
      {
        ...request
      }
    );
    return response.attributes;
  }
  async getRegions() {
    const response = await this._tenantApi.v1TenantTenantIdTenantRegionsGet(
      this._tenantId
    );
    return response.regions;
  }
  mapboxSearch(searchTerm, latitude, longitude, mapSearchStateFilter) {
    let baseClient = mbxClient({ accessToken: this._mapboxApiKey });
    let geocodeClient = mbxGeocode(baseClient);
    return geocodeClient
      .forwardGeocode({
        query: searchTerm,
        limit: 10,
        proximity: [longitude, latitude]
      })
      .send()
      .then(response => {
        let filteredResponses = response.body.features.filter(item => {
          let contextItems = item.context.filter(contextItem => {
            return contextItem.short_code == mapSearchStateFilter;
          });
          if (contextItems.length > 0) {
            return true;
          }
        });

        return filteredResponses;
      });
  }
}
