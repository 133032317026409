import AdminCartApi from "@/generatedapiclients/src/api/AdminCartApi.js";

export default class AdminCartService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._cartApi = new AdminCartApi();
  }

  async addReservationToCart(newReservationRequest) {
    newReservationRequest.salesChannel = 2;
    return await this._cartApi.v1TenantTenantIdAdminAdminCartAddreservationPost(
      this._tenantId,
      {
        adminNewReservationRequest: newReservationRequest
      }
    );
  }

  async removeItemFromCart(itemId, releaseItemLock) {
    return await this._cartApi.v1TenantTenantIdAdminAdminCartRemoveitemPost(
      this._tenantId,
      {
        id: itemId,
        releaseItemLock
      }
    );
  }

  async checkout(paymentTypeId) {
    const checkoutResponse = await this._cartApi.v1TenantTenantIdAdminAdminCartCheckoutPost(
      this._tenantId,
      {
        paymentTypeId
      }
    );
    return checkoutResponse;
  }

  async getReceipt(ccpToken, orderId) {
    const receiptResponse = await this._cartApi.v1TenantTenantIdAdminAdminCartReceiptPost(
      this._tenantId,
      {
        ccpToken,
        orderId
      }
    );
    return receiptResponse;
  }

  async getCancellationReceipt(orderId) {
    const receiptResponse = await this._cartApi.v1TenantTenantIdAdminAdminCartCancellationReceiptPost(
      this._tenantId,
      {
        orderId
      }
    );
    return receiptResponse;
  }

  async getModificationReceipt(ccpToken, orderId) {
    const receiptResponse = await this._cartApi.v1TenantTenantIdAdminAdminCartModificationReceiptPost(
      this._tenantId,
      {
        ccpToken,
        orderId
      }
    );
    return receiptResponse;
  }

  async extendUserCartTimeout() {
    const receiptResponse = await this._cartApi.v1TenantTenantIdAdminAdminCartExtendtimeoutPost(
      this._tenantId
    );
    return receiptResponse;
  }

  async getUserCart() {
    const receiptResponse = await this._cartApi.v1TenantTenantIdAdminAdminCartGet(
      this._tenantId
    );
    return receiptResponse;
  }

  async clearUserCart() {
    const response = await this._cartApi.v1TenantTenantIdAdminAdminCartClearPost(
      this._tenantId
    );
    return response;
  }
}
