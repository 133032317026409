const state = {
  errorAlert: { id: "", type: "", message: "", layer: "", persist: false },
  errorQueue: []
};
const getters = {
  errorAlert: state => state.errorAlert,
  errorQueue: state => state.errorQueue
};
const actions = {
  clear({ commit }) {
    commit("setErrorAlert", {
      id: "",
      type: "",
      message: "",
      layer: "",
      persist: false
    });
  }
};
const mutations = {
  setErrorAlert(state, errorAlert) {
    errorAlert.id = Date.now();
    state.errorAlert.type = errorAlert.type;
    state.errorAlert.message = errorAlert.message;
    state.errorAlert.layer = errorAlert.layer;
    if (errorAlert.persist) {
      state.errorAlert.persist = errorAlert.persist;
    } else {
      state.errorAlert.persist = false;
    }
  },
  setErrorQueue(state, errorQueue) {
    state.errorQueue = errorQueue;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
