/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The NewCustomerRequest model module.
 * @module model/NewCustomerRequest
 * @version v1
 */
class NewCustomerRequest {
    /**
     * Constructs a new <code>NewCustomerRequest</code>.
     * @alias module:model/NewCustomerRequest
     * @param firstName {String} 
     * @param lastName {String} 
     * @param email {String} 
     * @param primaryPhoneNumber {String} 
     * @param street1 {String} 
     * @param city {String} 
     * @param state {String} 
     * @param zipcode {String} 
     * @param country {String} 
     */
    constructor(firstName, lastName, email, primaryPhoneNumber, street1, city, state, zipcode, country) { 
        
        NewCustomerRequest.initialize(this, firstName, lastName, email, primaryPhoneNumber, street1, city, state, zipcode, country);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, firstName, lastName, email, primaryPhoneNumber, street1, city, state, zipcode, country) { 
        obj['firstName'] = firstName;
        obj['lastName'] = lastName;
        obj['email'] = email;
        obj['primaryPhoneNumber'] = primaryPhoneNumber;
        obj['street1'] = street1;
        obj['city'] = city;
        obj['state'] = state;
        obj['zipcode'] = zipcode;
        obj['country'] = country;
    }

    /**
     * Constructs a <code>NewCustomerRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/NewCustomerRequest} obj Optional instance to populate.
     * @return {module:model/NewCustomerRequest} The populated <code>NewCustomerRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new NewCustomerRequest();

            if (data.hasOwnProperty('prefixName')) {
                obj['prefixName'] = ApiClient.convertToType(data['prefixName'], 'String');
            }
            if (data.hasOwnProperty('firstName')) {
                obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
            }
            if (data.hasOwnProperty('middleName')) {
                obj['middleName'] = ApiClient.convertToType(data['middleName'], 'String');
            }
            if (data.hasOwnProperty('lastName')) {
                obj['lastName'] = ApiClient.convertToType(data['lastName'], 'String');
            }
            if (data.hasOwnProperty('suffixName')) {
                obj['suffixName'] = ApiClient.convertToType(data['suffixName'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('primaryPhoneNumber')) {
                obj['primaryPhoneNumber'] = ApiClient.convertToType(data['primaryPhoneNumber'], 'String');
            }
            if (data.hasOwnProperty('alternatePhoneNumber')) {
                obj['alternatePhoneNumber'] = ApiClient.convertToType(data['alternatePhoneNumber'], 'String');
            }
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('timezoneId')) {
                obj['timezoneId'] = ApiClient.convertToType(data['timezoneId'], 'String');
            }
            if (data.hasOwnProperty('street1')) {
                obj['street1'] = ApiClient.convertToType(data['street1'], 'String');
            }
            if (data.hasOwnProperty('street2')) {
                obj['street2'] = ApiClient.convertToType(data['street2'], 'String');
            }
            if (data.hasOwnProperty('city')) {
                obj['city'] = ApiClient.convertToType(data['city'], 'String');
            }
            if (data.hasOwnProperty('state')) {
                obj['state'] = ApiClient.convertToType(data['state'], 'String');
            }
            if (data.hasOwnProperty('zipcode')) {
                obj['zipcode'] = ApiClient.convertToType(data['zipcode'], 'String');
            }
            if (data.hasOwnProperty('zipcodePlus4')) {
                obj['zipcodePlus4'] = ApiClient.convertToType(data['zipcodePlus4'], 'String');
            }
            if (data.hasOwnProperty('country')) {
                obj['country'] = ApiClient.convertToType(data['country'], 'String');
            }
            if (data.hasOwnProperty('comment')) {
                obj['comment'] = ApiClient.convertToType(data['comment'], 'String');
            }
            if (data.hasOwnProperty('createdByAdmin')) {
                obj['createdByAdmin'] = ApiClient.convertToType(data['createdByAdmin'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {String} prefixName
 */
NewCustomerRequest.prototype['prefixName'] = undefined;

/**
 * @member {String} firstName
 */
NewCustomerRequest.prototype['firstName'] = undefined;

/**
 * @member {String} middleName
 */
NewCustomerRequest.prototype['middleName'] = undefined;

/**
 * @member {String} lastName
 */
NewCustomerRequest.prototype['lastName'] = undefined;

/**
 * @member {String} suffixName
 */
NewCustomerRequest.prototype['suffixName'] = undefined;

/**
 * @member {String} email
 */
NewCustomerRequest.prototype['email'] = undefined;

/**
 * @member {String} primaryPhoneNumber
 */
NewCustomerRequest.prototype['primaryPhoneNumber'] = undefined;

/**
 * @member {String} alternatePhoneNumber
 */
NewCustomerRequest.prototype['alternatePhoneNumber'] = undefined;

/**
 * @member {String} username
 */
NewCustomerRequest.prototype['username'] = undefined;

/**
 * @member {String} timezoneId
 */
NewCustomerRequest.prototype['timezoneId'] = undefined;

/**
 * @member {String} street1
 */
NewCustomerRequest.prototype['street1'] = undefined;

/**
 * @member {String} street2
 */
NewCustomerRequest.prototype['street2'] = undefined;

/**
 * @member {String} city
 */
NewCustomerRequest.prototype['city'] = undefined;

/**
 * @member {String} state
 */
NewCustomerRequest.prototype['state'] = undefined;

/**
 * @member {String} zipcode
 */
NewCustomerRequest.prototype['zipcode'] = undefined;

/**
 * @member {String} zipcodePlus4
 */
NewCustomerRequest.prototype['zipcodePlus4'] = undefined;

/**
 * @member {String} country
 */
NewCustomerRequest.prototype['country'] = undefined;

/**
 * @member {String} comment
 */
NewCustomerRequest.prototype['comment'] = undefined;

/**
 * @member {Boolean} createdByAdmin
 */
NewCustomerRequest.prototype['createdByAdmin'] = undefined;






export default NewCustomerRequest;

