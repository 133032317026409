/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import SalesChannelEnum from './SalesChannelEnum';
import SpotAvailability from './SpotAvailability';
import SpotSearchResultAttribute from './SpotSearchResultAttribute';
import SpotSearchResultDescription from './SpotSearchResultDescription';
import SpotSearchResultLookUp from './SpotSearchResultLookUp';
import SpotSearchResultPhoto from './SpotSearchResultPhoto';
import SpotSearchResultProduct from './SpotSearchResultProduct';

/**
 * The SpotSearchResult model module.
 * @module model/SpotSearchResult
 * @version v1
 */
class SpotSearchResult {
    /**
     * Constructs a new <code>SpotSearchResult</code>.
     * @alias module:model/SpotSearchResult
     */
    constructor() { 
        
        SpotSearchResult.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SpotSearchResult</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SpotSearchResult} obj Optional instance to populate.
     * @return {module:model/SpotSearchResult} The populated <code>SpotSearchResult</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SpotSearchResult();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('locationId')) {
                obj['locationId'] = ApiClient.convertToType(data['locationId'], 'Number');
            }
            if (data.hasOwnProperty('locationName')) {
                obj['locationName'] = ApiClient.convertToType(data['locationName'], 'String');
            }
            if (data.hasOwnProperty('latitude')) {
                obj['latitude'] = ApiClient.convertToType(data['latitude'], 'Number');
            }
            if (data.hasOwnProperty('longitude')) {
                obj['longitude'] = ApiClient.convertToType(data['longitude'], 'Number');
            }
            if (data.hasOwnProperty('area')) {
                obj['area'] = ApiClient.convertToType(data['area'], 'String');
            }
            if (data.hasOwnProperty('areaDescription')) {
                obj['areaDescription'] = ApiClient.convertToType(data['areaDescription'], 'String');
            }
            if (data.hasOwnProperty('productId')) {
                obj['productId'] = ApiClient.convertToType(data['productId'], 'Number');
            }
            if (data.hasOwnProperty('loop')) {
                obj['loop'] = ApiClient.convertToType(data['loop'], 'String');
            }
            if (data.hasOwnProperty('product')) {
                obj['product'] = SpotSearchResultProduct.constructFromObject(data['product']);
            }
            if (data.hasOwnProperty('photos')) {
                obj['photos'] = ApiClient.convertToType(data['photos'], [SpotSearchResultPhoto]);
            }
            if (data.hasOwnProperty('spotAttributes')) {
                obj['spotAttributes'] = ApiClient.convertToType(data['spotAttributes'], [SpotSearchResultAttribute]);
            }
            if (data.hasOwnProperty('spotDescriptions')) {
                obj['spotDescriptions'] = ApiClient.convertToType(data['spotDescriptions'], [SpotSearchResultDescription]);
            }
            if (data.hasOwnProperty('spotTypes')) {
                obj['spotTypes'] = ApiClient.convertToType(data['spotTypes'], [SpotSearchResultLookUp]);
            }
            if (data.hasOwnProperty('equipmentTypes')) {
                obj['equipmentTypes'] = ApiClient.convertToType(data['equipmentTypes'], [SpotSearchResultLookUp]);
            }
            if (data.hasOwnProperty('isSpotAvailable')) {
                obj['isSpotAvailable'] = ApiClient.convertToType(data['isSpotAvailable'], 'Boolean');
            }
            if (data.hasOwnProperty('spotAvailabilities')) {
                obj['spotAvailabilities'] = ApiClient.convertToType(data['spotAvailabilities'], [SpotAvailability]);
            }
            if (data.hasOwnProperty('featuredImageUrl')) {
                obj['featuredImageUrl'] = ApiClient.convertToType(data['featuredImageUrl'], 'String');
            }
            if (data.hasOwnProperty('salesChannel')) {
                obj['salesChannel'] = SalesChannelEnum.constructFromObject(data['salesChannel']);
            }
            if (data.hasOwnProperty('lowerDailyRate')) {
                obj['lowerDailyRate'] = ApiClient.convertToType(data['lowerDailyRate'], 'Number');
            }
            if (data.hasOwnProperty('higherDailyRate')) {
                obj['higherDailyRate'] = ApiClient.convertToType(data['higherDailyRate'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
SpotSearchResult.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
SpotSearchResult.prototype['tenantId'] = undefined;

/**
 * @member {String} name
 */
SpotSearchResult.prototype['name'] = undefined;

/**
 * @member {Number} locationId
 */
SpotSearchResult.prototype['locationId'] = undefined;

/**
 * @member {String} locationName
 */
SpotSearchResult.prototype['locationName'] = undefined;

/**
 * @member {Number} latitude
 */
SpotSearchResult.prototype['latitude'] = undefined;

/**
 * @member {Number} longitude
 */
SpotSearchResult.prototype['longitude'] = undefined;

/**
 * @member {String} area
 */
SpotSearchResult.prototype['area'] = undefined;

/**
 * @member {String} areaDescription
 */
SpotSearchResult.prototype['areaDescription'] = undefined;

/**
 * @member {Number} productId
 */
SpotSearchResult.prototype['productId'] = undefined;

/**
 * @member {String} loop
 */
SpotSearchResult.prototype['loop'] = undefined;

/**
 * @member {module:model/SpotSearchResultProduct} product
 */
SpotSearchResult.prototype['product'] = undefined;

/**
 * @member {Array.<module:model/SpotSearchResultPhoto>} photos
 */
SpotSearchResult.prototype['photos'] = undefined;

/**
 * @member {Array.<module:model/SpotSearchResultAttribute>} spotAttributes
 */
SpotSearchResult.prototype['spotAttributes'] = undefined;

/**
 * @member {Array.<module:model/SpotSearchResultDescription>} spotDescriptions
 */
SpotSearchResult.prototype['spotDescriptions'] = undefined;

/**
 * @member {Array.<module:model/SpotSearchResultLookUp>} spotTypes
 */
SpotSearchResult.prototype['spotTypes'] = undefined;

/**
 * @member {Array.<module:model/SpotSearchResultLookUp>} equipmentTypes
 */
SpotSearchResult.prototype['equipmentTypes'] = undefined;

/**
 * @member {Boolean} isSpotAvailable
 */
SpotSearchResult.prototype['isSpotAvailable'] = undefined;

/**
 * @member {Array.<module:model/SpotAvailability>} spotAvailabilities
 */
SpotSearchResult.prototype['spotAvailabilities'] = undefined;

/**
 * @member {String} featuredImageUrl
 */
SpotSearchResult.prototype['featuredImageUrl'] = undefined;

/**
 * @member {module:model/SalesChannelEnum} salesChannel
 */
SpotSearchResult.prototype['salesChannel'] = undefined;

/**
 * @member {Number} lowerDailyRate
 */
SpotSearchResult.prototype['lowerDailyRate'] = undefined;

/**
 * @member {Number} higherDailyRate
 */
SpotSearchResult.prototype['higherDailyRate'] = undefined;






export default SpotSearchResult;

