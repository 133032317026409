/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The TenantExternalLink model module.
 * @module model/TenantExternalLink
 * @version v1
 */
class TenantExternalLink {
    /**
     * Constructs a new <code>TenantExternalLink</code>.
     * @alias module:model/TenantExternalLink
     */
    constructor() { 
        
        TenantExternalLink.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>TenantExternalLink</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/TenantExternalLink} obj Optional instance to populate.
     * @return {module:model/TenantExternalLink} The populated <code>TenantExternalLink</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TenantExternalLink();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
TenantExternalLink.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
TenantExternalLink.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
TenantExternalLink.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
TenantExternalLink.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
TenantExternalLink.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
TenantExternalLink.prototype['tenantId'] = undefined;

/**
 * @member {String} name
 */
TenantExternalLink.prototype['name'] = undefined;

/**
 * @member {String} description
 */
TenantExternalLink.prototype['description'] = undefined;

/**
 * @member {String} url
 */
TenantExternalLink.prototype['url'] = undefined;






export default TenantExternalLink;

