/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AddReservationToCartResponse from '../model/AddReservationToCartResponse';
import Cart from '../model/Cart';
import CartItem from '../model/CartItem';
import ChangeOrderReceiptResponse from '../model/ChangeOrderReceiptResponse';
import CheckoutResponse from '../model/CheckoutResponse';
import ClaimSpotResultResponse from '../model/ClaimSpotResultResponse';
import NewReservationRequest from '../model/NewReservationRequest';
import OrderReceiptResponse from '../model/OrderReceiptResponse';

/**
* Cart service.
* @module api/CartApi
* @version v1
*/
export default class CartApi {

    /**
    * Constructs a new CartApi. 
    * @alias module:api/CartApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/CartItem} opts.cartItem 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CartItem} and HTTP response
     */
    v1TenantTenantIdCartAdditemPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['cartItem'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdCartAdditemPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CartItem;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Cart/additem', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/CartItem} opts.cartItem 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CartItem}
     */
    v1TenantTenantIdCartAdditemPost(tenantId, opts) {
      return this.v1TenantTenantIdCartAdditemPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/NewReservationRequest} opts.newReservationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AddReservationToCartResponse} and HTTP response
     */
    v1TenantTenantIdCartAddreservationPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['newReservationRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdCartAddreservationPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AddReservationToCartResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Cart/addreservation', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/NewReservationRequest} opts.newReservationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AddReservationToCartResponse}
     */
    v1TenantTenantIdCartAddreservationPost(tenantId, opts) {
      return this.v1TenantTenantIdCartAddreservationPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.orderId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ChangeOrderReceiptResponse} and HTTP response
     */
    v1TenantTenantIdCartCancellationReceiptPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdCartCancellationReceiptPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'orderId': opts['orderId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ChangeOrderReceiptResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Cart/cancellation/receipt', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.orderId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ChangeOrderReceiptResponse}
     */
    v1TenantTenantIdCartCancellationReceiptPost(tenantId, opts) {
      return this.v1TenantTenantIdCartCancellationReceiptPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.paymentTypeId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CheckoutResponse} and HTTP response
     */
    v1TenantTenantIdCartCheckoutPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdCartCheckoutPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'paymentTypeId': opts['paymentTypeId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CheckoutResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Cart/checkout', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.paymentTypeId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CheckoutResponse}
     */
    v1TenantTenantIdCartCheckoutPost(tenantId, opts) {
      return this.v1TenantTenantIdCartCheckoutPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    v1TenantTenantIdCartClearPostWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdCartClearPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Cart/clear', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    v1TenantTenantIdCartClearPost(tenantId) {
      return this.v1TenantTenantIdCartClearPostWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ClaimSpotResultResponse} and HTTP response
     */
    v1TenantTenantIdCartExtendtimeoutPostWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdCartExtendtimeoutPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ClaimSpotResultResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Cart/extendtimeout', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ClaimSpotResultResponse}
     */
    v1TenantTenantIdCartExtendtimeoutPost(tenantId) {
      return this.v1TenantTenantIdCartExtendtimeoutPostWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Cart} and HTTP response
     */
    v1TenantTenantIdCartGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdCartGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Cart;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Cart', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Cart}
     */
    v1TenantTenantIdCartGet(tenantId) {
      return this.v1TenantTenantIdCartGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.ccpToken 
     * @param {Number} opts.orderId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ChangeOrderReceiptResponse} and HTTP response
     */
    v1TenantTenantIdCartModificationReceiptPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdCartModificationReceiptPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'ccpToken': opts['ccpToken'],
        'orderId': opts['orderId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ChangeOrderReceiptResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Cart/modification/receipt', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.ccpToken 
     * @param {Number} opts.orderId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ChangeOrderReceiptResponse}
     */
    v1TenantTenantIdCartModificationReceiptPost(tenantId, opts) {
      return this.v1TenantTenantIdCartModificationReceiptPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.ccpToken 
     * @param {Number} opts.orderId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OrderReceiptResponse} and HTTP response
     */
    v1TenantTenantIdCartReceiptPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdCartReceiptPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'ccpToken': opts['ccpToken'],
        'orderId': opts['orderId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = OrderReceiptResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Cart/receipt', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.ccpToken 
     * @param {Number} opts.orderId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OrderReceiptResponse}
     */
    v1TenantTenantIdCartReceiptPost(tenantId, opts) {
      return this.v1TenantTenantIdCartReceiptPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.id 
     * @param {Boolean} opts.releaseItemLock 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    v1TenantTenantIdCartRemoveitemPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdCartRemoveitemPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'id': opts['id'],
        'releaseItemLock': opts['releaseItemLock']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Cart/removeitem', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.id 
     * @param {Boolean} opts.releaseItemLock 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    v1TenantTenantIdCartRemoveitemPost(tenantId, opts) {
      return this.v1TenantTenantIdCartRemoveitemPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
