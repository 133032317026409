/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Captcha model module.
 * @module model/Captcha
 * @version v1
 */
class Captcha {
    /**
     * Constructs a new <code>Captcha</code>.
     * @alias module:model/Captcha
     */
    constructor() { 
        
        Captcha.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Captcha</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Captcha} obj Optional instance to populate.
     * @return {module:model/Captcha} The populated <code>Captcha</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Captcha();

            if (data.hasOwnProperty('success')) {
                obj['success'] = ApiClient.convertToType(data['success'], 'Boolean');
            }
            if (data.hasOwnProperty('score')) {
                obj['score'] = ApiClient.convertToType(data['score'], 'Number');
            }
            if (data.hasOwnProperty('error-codes')) {
                obj['error-codes'] = ApiClient.convertToType(data['error-codes'], ['String']);
            }
        }
        return obj;
    }


}

/**
 * @member {Boolean} success
 */
Captcha.prototype['success'] = undefined;

/**
 * @member {Number} score
 */
Captcha.prototype['score'] = undefined;

/**
 * @member {Array.<String>} error-codes
 */
Captcha.prototype['error-codes'] = undefined;






export default Captcha;

