/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The AdminSpotSearchResult model module.
 * @module model/AdminSpotSearchResult
 * @version v1
 */
class AdminSpotSearchResult {
    /**
     * Constructs a new <code>AdminSpotSearchResult</code>.
     * @alias module:model/AdminSpotSearchResult
     */
    constructor() { 
        
        AdminSpotSearchResult.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AdminSpotSearchResult</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AdminSpotSearchResult} obj Optional instance to populate.
     * @return {module:model/AdminSpotSearchResult} The populated <code>AdminSpotSearchResult</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AdminSpotSearchResult();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('spotName')) {
                obj['spotName'] = ApiClient.convertToType(data['spotName'], 'String');
            }
            if (data.hasOwnProperty('area')) {
                obj['area'] = ApiClient.convertToType(data['area'], 'String');
            }
            if (data.hasOwnProperty('loop')) {
                obj['loop'] = ApiClient.convertToType(data['loop'], 'String');
            }
            if (data.hasOwnProperty('productClassification')) {
                obj['productClassification'] = ApiClient.convertToType(data['productClassification'], 'String');
            }
            if (data.hasOwnProperty('productClassificationId')) {
                obj['productClassificationId'] = ApiClient.convertToType(data['productClassificationId'], 'Number');
            }
            if (data.hasOwnProperty('basePrice')) {
                obj['basePrice'] = ApiClient.convertToType(data['basePrice'], 'Number');
            }
            if (data.hasOwnProperty('maxPeople')) {
                obj['maxPeople'] = ApiClient.convertToType(data['maxPeople'], 'Number');
            }
            if (data.hasOwnProperty('maxVehicles')) {
                obj['maxVehicles'] = ApiClient.convertToType(data['maxVehicles'], 'Number');
            }
            if (data.hasOwnProperty('maxVehicleLength')) {
                obj['maxVehicleLength'] = ApiClient.convertToType(data['maxVehicleLength'], 'Number');
            }
            if (data.hasOwnProperty('petsAllowed')) {
                obj['petsAllowed'] = ApiClient.convertToType(data['petsAllowed'], 'Boolean');
            }
            if (data.hasOwnProperty('adaAccessible')) {
                obj['adaAccessible'] = ApiClient.convertToType(data['adaAccessible'], 'Boolean');
            }
            if (data.hasOwnProperty('salesChannel')) {
                obj['salesChannel'] = ApiClient.convertToType(data['salesChannel'], 'String');
            }
            if (data.hasOwnProperty('electricHookup')) {
                obj['electricHookup'] = ApiClient.convertToType(data['electricHookup'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
AdminSpotSearchResult.prototype['id'] = undefined;

/**
 * @member {String} spotName
 */
AdminSpotSearchResult.prototype['spotName'] = undefined;

/**
 * @member {String} area
 */
AdminSpotSearchResult.prototype['area'] = undefined;

/**
 * @member {String} loop
 */
AdminSpotSearchResult.prototype['loop'] = undefined;

/**
 * @member {String} productClassification
 */
AdminSpotSearchResult.prototype['productClassification'] = undefined;

/**
 * @member {Number} productClassificationId
 */
AdminSpotSearchResult.prototype['productClassificationId'] = undefined;

/**
 * @member {Number} basePrice
 */
AdminSpotSearchResult.prototype['basePrice'] = undefined;

/**
 * @member {Number} maxPeople
 */
AdminSpotSearchResult.prototype['maxPeople'] = undefined;

/**
 * @member {Number} maxVehicles
 */
AdminSpotSearchResult.prototype['maxVehicles'] = undefined;

/**
 * @member {Number} maxVehicleLength
 */
AdminSpotSearchResult.prototype['maxVehicleLength'] = undefined;

/**
 * @member {Boolean} petsAllowed
 */
AdminSpotSearchResult.prototype['petsAllowed'] = undefined;

/**
 * @member {Boolean} adaAccessible
 */
AdminSpotSearchResult.prototype['adaAccessible'] = undefined;

/**
 * @member {String} salesChannel
 */
AdminSpotSearchResult.prototype['salesChannel'] = undefined;

/**
 * @member {String} electricHookup
 */
AdminSpotSearchResult.prototype['electricHookup'] = undefined;






export default AdminSpotSearchResult;

