import Vue from "vue";
import Vuex from "vuex";
import alert from "@/store/modules/alert";
import search from "@/store/modules/search";
import tenant from "@/store/modules/tenant";
import auth from "@/store/modules/auth";
import admin from "@/store/modules/admin";
import transaction from "@/store/modules/transaction";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    alert,
    search,
    tenant,
    auth,
    admin,
    transaction
  },
  plugins: [vuexLocal.plugin]
});
