/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AddReservationToCartResponse from '../model/AddReservationToCartResponse';
import AdminNewReservationRequest from '../model/AdminNewReservationRequest';
import Cart from '../model/Cart';
import CartItem from '../model/CartItem';
import ChangeOrderReceiptResponse from '../model/ChangeOrderReceiptResponse';
import CheckoutResponse from '../model/CheckoutResponse';
import ClaimSpotResultResponse from '../model/ClaimSpotResultResponse';
import OrderReceiptResponse from '../model/OrderReceiptResponse';

/**
* AdminCart service.
* @module api/AdminCartApi
* @version v1
*/
export default class AdminCartApi {

    /**
    * Constructs a new AdminCartApi. 
    * @alias module:api/AdminCartApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/CartItem} opts.cartItem 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CartItem} and HTTP response
     */
    v1TenantTenantIdAdminAdminCartAdditemPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['cartItem'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCartAdditemPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CartItem;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCart/additem', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/CartItem} opts.cartItem 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CartItem}
     */
    v1TenantTenantIdAdminAdminCartAdditemPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminCartAdditemPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminNewReservationRequest} opts.adminNewReservationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AddReservationToCartResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminCartAddreservationPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['adminNewReservationRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCartAddreservationPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AddReservationToCartResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCart/addreservation', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminNewReservationRequest} opts.adminNewReservationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AddReservationToCartResponse}
     */
    v1TenantTenantIdAdminAdminCartAddreservationPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminCartAddreservationPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.orderId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ChangeOrderReceiptResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminCartCancellationReceiptPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCartCancellationReceiptPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'orderId': opts['orderId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ChangeOrderReceiptResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCart/cancellation/receipt', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.orderId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ChangeOrderReceiptResponse}
     */
    v1TenantTenantIdAdminAdminCartCancellationReceiptPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminCartCancellationReceiptPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.paymentTypeId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CheckoutResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminCartCheckoutPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCartCheckoutPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'paymentTypeId': opts['paymentTypeId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CheckoutResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCart/checkout', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.paymentTypeId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CheckoutResponse}
     */
    v1TenantTenantIdAdminAdminCartCheckoutPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminCartCheckoutPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    v1TenantTenantIdAdminAdminCartClearPostWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCartClearPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCart/clear', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    v1TenantTenantIdAdminAdminCartClearPost(tenantId) {
      return this.v1TenantTenantIdAdminAdminCartClearPostWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ClaimSpotResultResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminCartExtendtimeoutPostWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCartExtendtimeoutPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ClaimSpotResultResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCart/extendtimeout', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ClaimSpotResultResponse}
     */
    v1TenantTenantIdAdminAdminCartExtendtimeoutPost(tenantId) {
      return this.v1TenantTenantIdAdminAdminCartExtendtimeoutPostWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Cart} and HTTP response
     */
    v1TenantTenantIdAdminAdminCartGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCartGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Cart;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCart', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Cart}
     */
    v1TenantTenantIdAdminAdminCartGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminCartGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.ccpToken 
     * @param {Number} opts.orderId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ChangeOrderReceiptResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminCartModificationReceiptPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCartModificationReceiptPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'ccpToken': opts['ccpToken'],
        'orderId': opts['orderId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ChangeOrderReceiptResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCart/modification/receipt', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.ccpToken 
     * @param {Number} opts.orderId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ChangeOrderReceiptResponse}
     */
    v1TenantTenantIdAdminAdminCartModificationReceiptPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminCartModificationReceiptPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.ccpToken 
     * @param {Number} opts.orderId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OrderReceiptResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminCartReceiptPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCartReceiptPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'ccpToken': opts['ccpToken'],
        'orderId': opts['orderId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = OrderReceiptResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCart/receipt', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.ccpToken 
     * @param {Number} opts.orderId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OrderReceiptResponse}
     */
    v1TenantTenantIdAdminAdminCartReceiptPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminCartReceiptPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.id 
     * @param {Boolean} opts.releaseItemLock 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    v1TenantTenantIdAdminAdminCartRemoveitemPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCartRemoveitemPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'id': opts['id'],
        'releaseItemLock': opts['releaseItemLock']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCart/removeitem', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.id 
     * @param {Boolean} opts.releaseItemLock 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    v1TenantTenantIdAdminAdminCartRemoveitemPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminCartRemoveitemPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
