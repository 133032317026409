/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AvailabilityEnum from './AvailabilityEnum';

/**
 * The SpotAvailability model module.
 * @module model/SpotAvailability
 * @version v1
 */
class SpotAvailability {
    /**
     * Constructs a new <code>SpotAvailability</code>.
     * @alias module:model/SpotAvailability
     */
    constructor() { 
        
        SpotAvailability.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SpotAvailability</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SpotAvailability} obj Optional instance to populate.
     * @return {module:model/SpotAvailability} The populated <code>SpotAvailability</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SpotAvailability();

            if (data.hasOwnProperty('availabilityCode')) {
                obj['availabilityCode'] = AvailabilityEnum.constructFromObject(data['availabilityCode']);
            }
            if (data.hasOwnProperty('availabilityDate')) {
                obj['availabilityDate'] = ApiClient.convertToType(data['availabilityDate'], 'String');
            }
            if (data.hasOwnProperty('baseDailyRate')) {
                obj['baseDailyRate'] = ApiClient.convertToType(data['baseDailyRate'], 'Number');
            }
            if (data.hasOwnProperty('availableAfterMaxArrival')) {
                obj['availableAfterMaxArrival'] = ApiClient.convertToType(data['availableAfterMaxArrival'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/AvailabilityEnum} availabilityCode
 */
SpotAvailability.prototype['availabilityCode'] = undefined;

/**
 * @member {String} availabilityDate
 */
SpotAvailability.prototype['availabilityDate'] = undefined;

/**
 * @member {Number} baseDailyRate
 */
SpotAvailability.prototype['baseDailyRate'] = undefined;

/**
 * @member {Boolean} availableAfterMaxArrival
 */
SpotAvailability.prototype['availableAfterMaxArrival'] = undefined;






export default SpotAvailability;

