<template>
  <div id="spinnerOverlay">
    <div
      class="w-100 d-flex flex-column align-items-center justify-content-center"
    >
      <div role="status">
        <img
          :src="require('@/assets/images/spinner/camping_animation.svg')"
          alt="campfire loading"
          width="600px"
          aria-hidden="true"
        />
        <span class="sr-only">Loading...</span>
      </div>
      <div class="h2 text-white" aria-hidden="true">{{ selectedPhrase }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheSpinner",
  data() {
    return {
      phrases: [
        "Gathering kindling...",
        "Mapping excursions...",
        "Hanging hammock...",
        "Enjoying s'mores...",
        "Baiting fishing poles...",
        "Setting up tent...",
        "Fluffing sleeping bag...",
        "Changing flashlight batteries...",
        "Unfolding chairs...",
        "Hanging clothes out to dry...",
        "Sharpening axe...",
        "Grabbing duct tape...",
        "Fueling stove...",
        "Filling water bottles...",
        "Stoking coals...",
        "Applying sunscreen...",
        "Spraying bug spray...",
        "Turning off cell phone...",
        "Reticulating splines..."
      ]
    };
  },
  computed: {
    selectedPhrase() {
      return this.phrases[Math.floor(Math.random() * this.phrases.length)];
    }
  },
  beforeCreate() {
    window.scrollTo(0, 0);
    let body = document.getElementsByTagName("BODY")[0];
    body.classList.add("noscroll");
  },
  beforeDestroy() {
    let body = document.getElementsByTagName("BODY")[0];
    body.classList.remove("noscroll");
  }
};
</script>

<style scoped>
.spinner-border {
  animation-duration: 1.25s;
  width: 5rem;
  height: 5rem;
}

#spinnerOverlay {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10000;
}

.h2 {
  font-size: large;
  font-weight: bolder;
  margin-top: 0.5em;
}
</style>
