/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AttributeCategory from './AttributeCategory';
import AttributeOption from './AttributeOption';
import AttributeValueType from './AttributeValueType';
import TenantAttribute from './TenantAttribute';

/**
 * The GlobalAttribute model module.
 * @module model/GlobalAttribute
 * @version v1
 */
class GlobalAttribute {
    /**
     * Constructs a new <code>GlobalAttribute</code>.
     * @alias module:model/GlobalAttribute
     */
    constructor() { 
        
        GlobalAttribute.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>GlobalAttribute</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/GlobalAttribute} obj Optional instance to populate.
     * @return {module:model/GlobalAttribute} The populated <code>GlobalAttribute</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new GlobalAttribute();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('displayName')) {
                obj['displayName'] = ApiClient.convertToType(data['displayName'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('distanceApplies')) {
                obj['distanceApplies'] = ApiClient.convertToType(data['distanceApplies'], 'Boolean');
            }
            if (data.hasOwnProperty('icon')) {
                obj['icon'] = ApiClient.convertToType(data['icon'], 'String');
            }
            if (data.hasOwnProperty('attributeCategory')) {
                obj['attributeCategory'] = AttributeCategory.constructFromObject(data['attributeCategory']);
            }
            if (data.hasOwnProperty('attributeValueType')) {
                obj['attributeValueType'] = AttributeValueType.constructFromObject(data['attributeValueType']);
            }
            if (data.hasOwnProperty('attributeOptions')) {
                obj['attributeOptions'] = ApiClient.convertToType(data['attributeOptions'], [AttributeOption]);
            }
            if (data.hasOwnProperty('tenantAttribute')) {
                obj['tenantAttribute'] = TenantAttribute.constructFromObject(data['tenantAttribute']);
            }
            if (data.hasOwnProperty('attributeCategoryId')) {
                obj['attributeCategoryId'] = ApiClient.convertToType(data['attributeCategoryId'], 'Number');
            }
            if (data.hasOwnProperty('minValue')) {
                obj['minValue'] = ApiClient.convertToType(data['minValue'], 'Number');
            }
            if (data.hasOwnProperty('maxValue')) {
                obj['maxValue'] = ApiClient.convertToType(data['maxValue'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
GlobalAttribute.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
GlobalAttribute.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
GlobalAttribute.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
GlobalAttribute.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
GlobalAttribute.prototype['id'] = undefined;

/**
 * @member {String} name
 */
GlobalAttribute.prototype['name'] = undefined;

/**
 * @member {String} displayName
 */
GlobalAttribute.prototype['displayName'] = undefined;

/**
 * @member {String} description
 */
GlobalAttribute.prototype['description'] = undefined;

/**
 * @member {Boolean} distanceApplies
 */
GlobalAttribute.prototype['distanceApplies'] = undefined;

/**
 * @member {String} icon
 */
GlobalAttribute.prototype['icon'] = undefined;

/**
 * @member {module:model/AttributeCategory} attributeCategory
 */
GlobalAttribute.prototype['attributeCategory'] = undefined;

/**
 * @member {module:model/AttributeValueType} attributeValueType
 */
GlobalAttribute.prototype['attributeValueType'] = undefined;

/**
 * @member {Array.<module:model/AttributeOption>} attributeOptions
 */
GlobalAttribute.prototype['attributeOptions'] = undefined;

/**
 * @member {module:model/TenantAttribute} tenantAttribute
 */
GlobalAttribute.prototype['tenantAttribute'] = undefined;

/**
 * @member {Number} attributeCategoryId
 */
GlobalAttribute.prototype['attributeCategoryId'] = undefined;

/**
 * @member {Number} minValue
 */
GlobalAttribute.prototype['minValue'] = undefined;

/**
 * @member {Number} maxValue
 */
GlobalAttribute.prototype['maxValue'] = undefined;






export default GlobalAttribute;

