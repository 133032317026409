/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import FeeTypeEnum from './FeeTypeEnum';
import Order from './Order';
import OrderPayment from './OrderPayment';
import ReservationFeeBreakdown from './ReservationFeeBreakdown';

/**
 * The ReservationFee model module.
 * @module model/ReservationFee
 * @version v1
 */
class ReservationFee {
    /**
     * Constructs a new <code>ReservationFee</code>.
     * @alias module:model/ReservationFee
     */
    constructor() { 
        
        ReservationFee.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ReservationFee</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ReservationFee} obj Optional instance to populate.
     * @return {module:model/ReservationFee} The populated <code>ReservationFee</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ReservationFee();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('accountingCode')) {
                obj['accountingCode'] = ApiClient.convertToType(data['accountingCode'], 'String');
            }
            if (data.hasOwnProperty('amount')) {
                obj['amount'] = ApiClient.convertToType(data['amount'], 'Number');
            }
            if (data.hasOwnProperty('quantity')) {
                obj['quantity'] = ApiClient.convertToType(data['quantity'], 'Number');
            }
            if (data.hasOwnProperty('feeType')) {
                obj['feeType'] = FeeTypeEnum.constructFromObject(data['feeType']);
            }
            if (data.hasOwnProperty('sku')) {
                obj['sku'] = ApiClient.convertToType(data['sku'], 'String');
            }
            if (data.hasOwnProperty('orderPaymentId')) {
                obj['orderPaymentId'] = ApiClient.convertToType(data['orderPaymentId'], 'Number');
            }
            if (data.hasOwnProperty('orderPayment')) {
                obj['orderPayment'] = OrderPayment.constructFromObject(data['orderPayment']);
            }
            if (data.hasOwnProperty('orderId')) {
                obj['orderId'] = ApiClient.convertToType(data['orderId'], 'Number');
            }
            if (data.hasOwnProperty('order')) {
                obj['order'] = Order.constructFromObject(data['order']);
            }
            if (data.hasOwnProperty('previousTotal')) {
                obj['previousTotal'] = ApiClient.convertToType(data['previousTotal'], 'Number');
            }
            if (data.hasOwnProperty('undiscountedAmount')) {
                obj['undiscountedAmount'] = ApiClient.convertToType(data['undiscountedAmount'], 'Number');
            }
            if (data.hasOwnProperty('preModifiedAmount')) {
                obj['preModifiedAmount'] = ApiClient.convertToType(data['preModifiedAmount'], 'Number');
            }
            if (data.hasOwnProperty('total')) {
                obj['total'] = ApiClient.convertToType(data['total'], 'Number');
            }
            if (data.hasOwnProperty('feeBreakdown')) {
                obj['feeBreakdown'] = ApiClient.convertToType(data['feeBreakdown'], [ReservationFeeBreakdown]);
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
ReservationFee.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
ReservationFee.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
ReservationFee.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
ReservationFee.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
ReservationFee.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
ReservationFee.prototype['tenantId'] = undefined;

/**
 * @member {String} name
 */
ReservationFee.prototype['name'] = undefined;

/**
 * @member {String} description
 */
ReservationFee.prototype['description'] = undefined;

/**
 * @member {String} accountingCode
 */
ReservationFee.prototype['accountingCode'] = undefined;

/**
 * @member {Number} amount
 */
ReservationFee.prototype['amount'] = undefined;

/**
 * @member {Number} quantity
 */
ReservationFee.prototype['quantity'] = undefined;

/**
 * @member {module:model/FeeTypeEnum} feeType
 */
ReservationFee.prototype['feeType'] = undefined;

/**
 * @member {String} sku
 */
ReservationFee.prototype['sku'] = undefined;

/**
 * @member {Number} orderPaymentId
 */
ReservationFee.prototype['orderPaymentId'] = undefined;

/**
 * @member {module:model/OrderPayment} orderPayment
 */
ReservationFee.prototype['orderPayment'] = undefined;

/**
 * @member {Number} orderId
 */
ReservationFee.prototype['orderId'] = undefined;

/**
 * @member {module:model/Order} order
 */
ReservationFee.prototype['order'] = undefined;

/**
 * @member {Number} previousTotal
 */
ReservationFee.prototype['previousTotal'] = undefined;

/**
 * @member {Number} undiscountedAmount
 */
ReservationFee.prototype['undiscountedAmount'] = undefined;

/**
 * @member {Number} preModifiedAmount
 */
ReservationFee.prototype['preModifiedAmount'] = undefined;

/**
 * @member {Number} total
 */
ReservationFee.prototype['total'] = undefined;

/**
 * @member {Array.<module:model/ReservationFeeBreakdown>} feeBreakdown
 */
ReservationFee.prototype['feeBreakdown'] = undefined;






export default ReservationFee;

