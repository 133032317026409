import SpotReservationService from "@/services/SpotReservationService.js";
import AdminSpotReservationService from "@/services/admin/AdminSpotReservationService.js";

const state = {
  reservation: {
    locationName: null,
    locationId: null,
    spotId: null,
    lockCode: null,
    lockedUntil: null,
    startDate: null,
    endDate: null
  },
  reservationInProgress: false,
  fifteenMinuteToastShown: false,
  numberOfItemsInCart: 0,
  cartExpiresDate: null,
  extendTimePopupShown: false
};
const getters = {
  reservationInProgress: state => state.reservationInProgress,
  reservation: state => state.reservation,
  fifteenMinuteToastShown: state => state.fifteenMinuteToastShown,
  numberOfItemsInCart: state => state.numberOfItemsInCart,
  cartExpiresDate: state => state.cartExpiresDate,
  extendTimePopupShown: state => state.extendTimePopupShown,
  spotClaimInfo: state => {
    return {
      spotId: state.reservation.spotId,
      lockedUntil: state.reservation.lockedUntil,
      lockCode: state.reservation.lockCode
    };
  }
};
const actions = {
  setSpotClaimInfo({ commit }, data) {
    commit("setSpotClaimInfo", data);
    commit("setCartExpiresDate", data.lockedUntil);
  },
  clearReservation({ commit }) {
    commit("setReservation", {});
  },
  clearTimer({ commit }) {
    commit("setNumberOfItemsInCart", 0);
    commit("setCartExpiresDate", null);
    commit("setExtendTimePopupShown", false);
  },
  setReservation({ commit }, reservation) {
    commit("setReservation", reservation);
  },
  incrementNumberOfItemsInCart({ state, commit }) {
    let itemsInCart = state.numberOfItemsInCart;
    itemsInCart++;
    commit("setNumberOfItemsInCart", itemsInCart);
  },
  decrementNumberOfItemsInCart({ state, commit }) {
    let itemsInCart = state.numberOfItemsInCart;
    itemsInCart--;
    commit("setNumberOfItemsInCart", itemsInCart);
  },
  clear({ commit }) {
    commit("setReservation", {
      locationName: null,
      locationId: null,
      spotId: null,
      lockCode: null,
      lockedUntil: null,
      startDate: null,
      endDate: null
    });
    commit("setFifteenMinuteToastShown", false);
    commit("setReservationInProgress", false);
    commit("setNumberOfItemsInCart", 0);
    commit("setCartExpiresDate", null);
    commit("setExtendTimePopupShown", false);
  },
  async resetSpotReservation(
    { commit, rootGetters, dispatch, getters },
    admin
  ) {
    const reservation = getters["reservation"];
    if (reservation == {} || reservation.spotId == null) return;

    let spotReservationService = null;
    const tenantId = rootGetters["tenant/tenantId"];
    if (admin) {
      spotReservationService = new AdminSpotReservationService(tenantId);
    } else {
      spotReservationService = new SpotReservationService(tenantId);
    }
    const claimData = getters["spotClaimInfo"];
    const response = await spotReservationService.releaseSpotClaim(
      claimData.spotId,
      claimData.lockCode
    );

    if (response?.statusCode == "Success") {
      dispatch("clearReservation");
      const itemsInCart = getters["numberOfItemsInCart"];
      if (itemsInCart === 0) {
        commit("setReservationInProgress", false);
        commit("setCartExpiresDate", null);
      }
    }
  }
};
const mutations = {
  setReservationInProgress(state, reservationInProgress) {
    state.reservationInProgress = reservationInProgress;
  },
  setFifteenMinuteToastShown(state, fifteenMinuteToastShown) {
    state.fifteenMinuteToastShown = fifteenMinuteToastShown;
  },
  setSpotClaimInfo(state, data) {
    state.reservation.locationName = data.locationName;
    state.reservation.locationId = data.locationId;
    state.reservation.spotId = data.spotId;
    state.reservation.lockCode = data.lockCode;
    state.reservation.lockedUntil = data.lockedUntil;
    state.reservation.walkInReservation = data.walkInReservation;
    state.reservation.adaRequirementAcknowledged =
      data.adaRequirementAcknowledged;
    state.reservation.overrideClosure = data.overrideClosure;
  },
  setReservationDates(state, data) {
    state.reservation.startDate = data.startDate;
    state.reservation.endDate = data.endDate;
  },
  setReservation(state, data) {
    state.reservation = data;
  },
  setNumberOfItemsInCart(state, data) {
    state.numberOfItemsInCart = data;
  },
  setCartExpiresDate(state, data) {
    state.cartExpiresDate = data;
  },
  setExtendTimePopupShown(state, data) {
    state.extendTimePopupShown = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
