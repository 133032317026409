/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Merchandise from './Merchandise';
import ReservationContact from './ReservationContact';
import ReservationDiscountClass from './ReservationDiscountClass';
import ReservationFee from './ReservationFee';
import ReservationOrderFee from './ReservationOrderFee';
import ReservationRefund from './ReservationRefund';
import ReservationStatusEnum from './ReservationStatusEnum';
import ReservationSubStatusEnum from './ReservationSubStatusEnum';
import Spot from './Spot';
import SpotHold from './SpotHold';
import SpotReservationHistory from './SpotReservationHistory';

/**
 * The SpotReservation model module.
 * @module model/SpotReservation
 * @version v1
 */
class SpotReservation {
    /**
     * Constructs a new <code>SpotReservation</code>.
     * @alias module:model/SpotReservation
     */
    constructor() { 
        
        SpotReservation.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SpotReservation</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SpotReservation} obj Optional instance to populate.
     * @return {module:model/SpotReservation} The populated <code>SpotReservation</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SpotReservation();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('spotId')) {
                obj['spotId'] = ApiClient.convertToType(data['spotId'], 'Number');
            }
            if (data.hasOwnProperty('spot')) {
                obj['spot'] = Spot.constructFromObject(data['spot']);
            }
            if (data.hasOwnProperty('numberOfAdults')) {
                obj['numberOfAdults'] = ApiClient.convertToType(data['numberOfAdults'], 'Number');
            }
            if (data.hasOwnProperty('numberOfVehicles')) {
                obj['numberOfVehicles'] = ApiClient.convertToType(data['numberOfVehicles'], 'Number');
            }
            if (data.hasOwnProperty('equipmentLength')) {
                obj['equipmentLength'] = ApiClient.convertToType(data['equipmentLength'], 'Number');
            }
            if (data.hasOwnProperty('equipmentTypeId')) {
                obj['equipmentTypeId'] = ApiClient.convertToType(data['equipmentTypeId'], 'Number');
            }
            if (data.hasOwnProperty('spotHold')) {
                obj['spotHold'] = SpotHold.constructFromObject(data['spotHold']);
            }
            if (data.hasOwnProperty('spotHoldId')) {
                obj['spotHoldId'] = ApiClient.convertToType(data['spotHoldId'], 'Number');
            }
            if (data.hasOwnProperty('customerId')) {
                obj['customerId'] = ApiClient.convertToType(data['customerId'], 'Number');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ReservationStatusEnum.constructFromObject(data['status']);
            }
            if (data.hasOwnProperty('reservationNumber')) {
                obj['reservationNumber'] = ApiClient.convertToType(data['reservationNumber'], 'String');
            }
            if (data.hasOwnProperty('startDate')) {
                obj['startDate'] = ApiClient.convertToType(data['startDate'], 'Date');
            }
            if (data.hasOwnProperty('endDate')) {
                obj['endDate'] = ApiClient.convertToType(data['endDate'], 'Date');
            }
            if (data.hasOwnProperty('isCustomerPrimaryOccupant')) {
                obj['isCustomerPrimaryOccupant'] = ApiClient.convertToType(data['isCustomerPrimaryOccupant'], 'Boolean');
            }
            if (data.hasOwnProperty('isCustomerReservationHolder')) {
                obj['isCustomerReservationHolder'] = ApiClient.convertToType(data['isCustomerReservationHolder'], 'Boolean');
            }
            if (data.hasOwnProperty('totalReservationPrice')) {
                obj['totalReservationPrice'] = ApiClient.convertToType(data['totalReservationPrice'], 'Number');
            }
            if (data.hasOwnProperty('totalFeeAmount')) {
                obj['totalFeeAmount'] = ApiClient.convertToType(data['totalFeeAmount'], 'Number');
            }
            if (data.hasOwnProperty('totalPaidAmount')) {
                obj['totalPaidAmount'] = ApiClient.convertToType(data['totalPaidAmount'], 'Number');
            }
            if (data.hasOwnProperty('totalRefundedAmount')) {
                obj['totalRefundedAmount'] = ApiClient.convertToType(data['totalRefundedAmount'], 'Number');
            }
            if (data.hasOwnProperty('checkedInDate')) {
                obj['checkedInDate'] = ApiClient.convertToType(data['checkedInDate'], 'Date');
            }
            if (data.hasOwnProperty('checkedOutDate')) {
                obj['checkedOutDate'] = ApiClient.convertToType(data['checkedOutDate'], 'Date');
            }
            if (data.hasOwnProperty('subStatus')) {
                obj['subStatus'] = ReservationSubStatusEnum.constructFromObject(data['subStatus']);
            }
            if (data.hasOwnProperty('checkedInBy')) {
                obj['checkedInBy'] = ApiClient.convertToType(data['checkedInBy'], 'String');
            }
            if (data.hasOwnProperty('checkedOutBy')) {
                obj['checkedOutBy'] = ApiClient.convertToType(data['checkedOutBy'], 'String');
            }
            if (data.hasOwnProperty('isSelfCheckin')) {
                obj['isSelfCheckin'] = ApiClient.convertToType(data['isSelfCheckin'], 'Boolean');
            }
            if (data.hasOwnProperty('acknowledgement')) {
                obj['acknowledgement'] = ApiClient.convertToType(data['acknowledgement'], 'Boolean');
            }
            if (data.hasOwnProperty('previousNumberOfAdults')) {
                obj['previousNumberOfAdults'] = ApiClient.convertToType(data['previousNumberOfAdults'], 'Number');
            }
            if (data.hasOwnProperty('previousNumberOfVehicles')) {
                obj['previousNumberOfVehicles'] = ApiClient.convertToType(data['previousNumberOfVehicles'], 'Number');
            }
            if (data.hasOwnProperty('salesChannelId')) {
                obj['salesChannelId'] = ApiClient.convertToType(data['salesChannelId'], 'Number');
            }
            if (data.hasOwnProperty('walkInReservation')) {
                obj['walkInReservation'] = ApiClient.convertToType(data['walkInReservation'], 'Boolean');
            }
            if (data.hasOwnProperty('adaRequirementAcknowledged')) {
                obj['adaRequirementAcknowledged'] = ApiClient.convertToType(data['adaRequirementAcknowledged'], 'Boolean');
            }
            if (data.hasOwnProperty('paymentChargebackCompleted')) {
                obj['paymentChargebackCompleted'] = ApiClient.convertToType(data['paymentChargebackCompleted'], 'Boolean');
            }
            if (data.hasOwnProperty('contacts')) {
                obj['contacts'] = ApiClient.convertToType(data['contacts'], [ReservationContact]);
            }
            if (data.hasOwnProperty('reservationFees')) {
                obj['reservationFees'] = ApiClient.convertToType(data['reservationFees'], [ReservationFee]);
            }
            if (data.hasOwnProperty('reservationRefunds')) {
                obj['reservationRefunds'] = ApiClient.convertToType(data['reservationRefunds'], [ReservationRefund]);
            }
            if (data.hasOwnProperty('reservationOrderFees')) {
                obj['reservationOrderFees'] = ApiClient.convertToType(data['reservationOrderFees'], [ReservationOrderFee]);
            }
            if (data.hasOwnProperty('history')) {
                obj['history'] = ApiClient.convertToType(data['history'], [SpotReservationHistory]);
            }
            if (data.hasOwnProperty('orderId')) {
                obj['orderId'] = ApiClient.convertToType(data['orderId'], 'Number');
            }
            if (data.hasOwnProperty('reservationDiscountClasses')) {
                obj['reservationDiscountClasses'] = ApiClient.convertToType(data['reservationDiscountClasses'], [ReservationDiscountClass]);
            }
            if (data.hasOwnProperty('merchandise')) {
                obj['merchandise'] = ApiClient.convertToType(data['merchandise'], [Merchandise]);
            }
            if (data.hasOwnProperty('primaryOccupant')) {
                obj['primaryOccupant'] = ReservationContact.constructFromObject(data['primaryOccupant']);
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
SpotReservation.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
SpotReservation.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
SpotReservation.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
SpotReservation.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
SpotReservation.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
SpotReservation.prototype['tenantId'] = undefined;

/**
 * @member {Number} spotId
 */
SpotReservation.prototype['spotId'] = undefined;

/**
 * @member {module:model/Spot} spot
 */
SpotReservation.prototype['spot'] = undefined;

/**
 * @member {Number} numberOfAdults
 */
SpotReservation.prototype['numberOfAdults'] = undefined;

/**
 * @member {Number} numberOfVehicles
 */
SpotReservation.prototype['numberOfVehicles'] = undefined;

/**
 * @member {Number} equipmentLength
 */
SpotReservation.prototype['equipmentLength'] = undefined;

/**
 * @member {Number} equipmentTypeId
 */
SpotReservation.prototype['equipmentTypeId'] = undefined;

/**
 * @member {module:model/SpotHold} spotHold
 */
SpotReservation.prototype['spotHold'] = undefined;

/**
 * @member {Number} spotHoldId
 */
SpotReservation.prototype['spotHoldId'] = undefined;

/**
 * @member {Number} customerId
 */
SpotReservation.prototype['customerId'] = undefined;

/**
 * @member {module:model/ReservationStatusEnum} status
 */
SpotReservation.prototype['status'] = undefined;

/**
 * @member {String} reservationNumber
 */
SpotReservation.prototype['reservationNumber'] = undefined;

/**
 * @member {Date} startDate
 */
SpotReservation.prototype['startDate'] = undefined;

/**
 * @member {Date} endDate
 */
SpotReservation.prototype['endDate'] = undefined;

/**
 * @member {Boolean} isCustomerPrimaryOccupant
 */
SpotReservation.prototype['isCustomerPrimaryOccupant'] = undefined;

/**
 * @member {Boolean} isCustomerReservationHolder
 */
SpotReservation.prototype['isCustomerReservationHolder'] = undefined;

/**
 * @member {Number} totalReservationPrice
 */
SpotReservation.prototype['totalReservationPrice'] = undefined;

/**
 * @member {Number} totalFeeAmount
 */
SpotReservation.prototype['totalFeeAmount'] = undefined;

/**
 * @member {Number} totalPaidAmount
 */
SpotReservation.prototype['totalPaidAmount'] = undefined;

/**
 * @member {Number} totalRefundedAmount
 */
SpotReservation.prototype['totalRefundedAmount'] = undefined;

/**
 * @member {Date} checkedInDate
 */
SpotReservation.prototype['checkedInDate'] = undefined;

/**
 * @member {Date} checkedOutDate
 */
SpotReservation.prototype['checkedOutDate'] = undefined;

/**
 * @member {module:model/ReservationSubStatusEnum} subStatus
 */
SpotReservation.prototype['subStatus'] = undefined;

/**
 * @member {String} checkedInBy
 */
SpotReservation.prototype['checkedInBy'] = undefined;

/**
 * @member {String} checkedOutBy
 */
SpotReservation.prototype['checkedOutBy'] = undefined;

/**
 * @member {Boolean} isSelfCheckin
 */
SpotReservation.prototype['isSelfCheckin'] = undefined;

/**
 * @member {Boolean} acknowledgement
 */
SpotReservation.prototype['acknowledgement'] = undefined;

/**
 * @member {Number} previousNumberOfAdults
 */
SpotReservation.prototype['previousNumberOfAdults'] = undefined;

/**
 * @member {Number} previousNumberOfVehicles
 */
SpotReservation.prototype['previousNumberOfVehicles'] = undefined;

/**
 * @member {Number} salesChannelId
 */
SpotReservation.prototype['salesChannelId'] = undefined;

/**
 * @member {Boolean} walkInReservation
 */
SpotReservation.prototype['walkInReservation'] = undefined;

/**
 * @member {Boolean} adaRequirementAcknowledged
 */
SpotReservation.prototype['adaRequirementAcknowledged'] = undefined;

/**
 * @member {Boolean} paymentChargebackCompleted
 */
SpotReservation.prototype['paymentChargebackCompleted'] = undefined;

/**
 * @member {Array.<module:model/ReservationContact>} contacts
 */
SpotReservation.prototype['contacts'] = undefined;

/**
 * @member {Array.<module:model/ReservationFee>} reservationFees
 */
SpotReservation.prototype['reservationFees'] = undefined;

/**
 * @member {Array.<module:model/ReservationRefund>} reservationRefunds
 */
SpotReservation.prototype['reservationRefunds'] = undefined;

/**
 * @member {Array.<module:model/ReservationOrderFee>} reservationOrderFees
 */
SpotReservation.prototype['reservationOrderFees'] = undefined;

/**
 * @member {Array.<module:model/SpotReservationHistory>} history
 */
SpotReservation.prototype['history'] = undefined;

/**
 * @member {Number} orderId
 */
SpotReservation.prototype['orderId'] = undefined;

/**
 * @member {Array.<module:model/ReservationDiscountClass>} reservationDiscountClasses
 */
SpotReservation.prototype['reservationDiscountClasses'] = undefined;

/**
 * @member {Array.<module:model/Merchandise>} merchandise
 */
SpotReservation.prototype['merchandise'] = undefined;

/**
 * @member {module:model/ReservationContact} primaryOccupant
 */
SpotReservation.prototype['primaryOccupant'] = undefined;






export default SpotReservation;

