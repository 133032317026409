/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AdminBulkCheckInOutRequest from '../model/AdminBulkCheckInOutRequest';
import AdminCancellationRequest from '../model/AdminCancellationRequest';
import AdminCheckInRequest from '../model/AdminCheckInRequest';
import AdminCheckOutRequest from '../model/AdminCheckOutRequest';
import AdminClaimSpotRequest from '../model/AdminClaimSpotRequest';
import AdminEditReservationRequest from '../model/AdminEditReservationRequest';
import AdminNewReservationRequest from '../model/AdminNewReservationRequest';
import BooleanResponse from '../model/BooleanResponse';
import ChargebackRequest from '../model/ChargebackRequest';
import ClaimSpotResultResponse from '../model/ClaimSpotResultResponse';
import EditReservationResponse from '../model/EditReservationResponse';
import Fee from '../model/Fee';
import FieldChangeIEnumerableResponse from '../model/FieldChangeIEnumerableResponse';
import OrderRefundIEnumerableResponse from '../model/OrderRefundIEnumerableResponse';
import OrderResponse from '../model/OrderResponse';
import RefundOptionEnum from '../model/RefundOptionEnum';
import ReleaseSpotResponse from '../model/ReleaseSpotResponse';
import ReservationCommment from '../model/ReservationCommment';
import ReservationCommmentListResponse from '../model/ReservationCommmentListResponse';
import ReservationCommmentResponse from '../model/ReservationCommmentResponse';
import Response from '../model/Response';
import SpotReservation from '../model/SpotReservation';
import SpotReservationDetailResponse from '../model/SpotReservationDetailResponse';
import SpotReservationHistoryIEnumerableResponse from '../model/SpotReservationHistoryIEnumerableResponse';
import SpotReservationIEnumerableResponse from '../model/SpotReservationIEnumerableResponse';

/**
* AdminSpotReservation service.
* @module api/AdminSpotReservationApi
* @version v1
*/
export default class AdminSpotReservationApi {

    /**
    * Constructs a new AdminSpotReservationApi. 
    * @alias module:api/AdminSpotReservationApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminBulkCheckInOutRequest} opts.adminBulkCheckInOutRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BooleanResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationBulkcheckinPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['adminBulkCheckInOutRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationBulkcheckinPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/bulkcheckin', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminBulkCheckInOutRequest} opts.adminBulkCheckInOutRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BooleanResponse}
     */
    v1TenantTenantIdAdminAdminSpotReservationBulkcheckinPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminSpotReservationBulkcheckinPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminBulkCheckInOutRequest} opts.adminBulkCheckInOutRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BooleanResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationBulkcheckoutPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['adminBulkCheckInOutRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationBulkcheckoutPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/bulkcheckout', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminBulkCheckInOutRequest} opts.adminBulkCheckInOutRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BooleanResponse}
     */
    v1TenantTenantIdAdminAdminSpotReservationBulkcheckoutPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminSpotReservationBulkcheckoutPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.reservationId 
     * @param {module:model/RefundOptionEnum} opts.refundOption 
     * @param {Array.<module:model/Fee>} opts.fee 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OrderRefundIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationCancellationRefundBreakdownPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['fee'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationCancellationRefundBreakdownPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'reservationId': opts['reservationId'],
        'refundOption': opts['refundOption']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = OrderRefundIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/cancellation-refund-breakdown', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.reservationId 
     * @param {module:model/RefundOptionEnum} opts.refundOption 
     * @param {Array.<module:model/Fee>} opts.fee 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OrderRefundIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminSpotReservationCancellationRefundBreakdownPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminSpotReservationCancellationRefundBreakdownPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ChargebackRequest} opts.chargebackRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationChargebackPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['chargebackRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationChargebackPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/chargeback', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ChargebackRequest} opts.chargebackRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdAdminAdminSpotReservationChargebackPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminSpotReservationChargebackPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {String} reservationId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminCheckInRequest} opts.adminCheckInRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BooleanResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationCheckinReservationIdPostWithHttpInfo(tenantId, reservationId, opts) {
      opts = opts || {};
      let postBody = opts['adminCheckInRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationCheckinReservationIdPost");
      }
      // verify the required parameter 'reservationId' is set
      if (reservationId === undefined || reservationId === null) {
        throw new Error("Missing the required parameter 'reservationId' when calling v1TenantTenantIdAdminAdminSpotReservationCheckinReservationIdPost");
      }

      let pathParams = {
        'tenantId': tenantId,
        'reservationId': reservationId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/checkin/{reservationId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {String} reservationId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminCheckInRequest} opts.adminCheckInRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BooleanResponse}
     */
    v1TenantTenantIdAdminAdminSpotReservationCheckinReservationIdPost(tenantId, reservationId, opts) {
      return this.v1TenantTenantIdAdminAdminSpotReservationCheckinReservationIdPostWithHttpInfo(tenantId, reservationId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {String} reservationId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminCheckOutRequest} opts.adminCheckOutRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BooleanResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationCheckoutReservationIdPostWithHttpInfo(tenantId, reservationId, opts) {
      opts = opts || {};
      let postBody = opts['adminCheckOutRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationCheckoutReservationIdPost");
      }
      // verify the required parameter 'reservationId' is set
      if (reservationId === undefined || reservationId === null) {
        throw new Error("Missing the required parameter 'reservationId' when calling v1TenantTenantIdAdminAdminSpotReservationCheckoutReservationIdPost");
      }

      let pathParams = {
        'tenantId': tenantId,
        'reservationId': reservationId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/checkout/{reservationId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {String} reservationId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminCheckOutRequest} opts.adminCheckOutRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BooleanResponse}
     */
    v1TenantTenantIdAdminAdminSpotReservationCheckoutReservationIdPost(tenantId, reservationId, opts) {
      return this.v1TenantTenantIdAdminAdminSpotReservationCheckoutReservationIdPostWithHttpInfo(tenantId, reservationId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SpotReservationDetailResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationDetailReservationIdGetWithHttpInfo(reservationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'reservationId' is set
      if (reservationId === undefined || reservationId === null) {
        throw new Error("Missing the required parameter 'reservationId' when calling v1TenantTenantIdAdminAdminSpotReservationDetailReservationIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationDetailReservationIdGet");
      }

      let pathParams = {
        'reservationId': reservationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SpotReservationDetailResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/detail/{reservationId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SpotReservationDetailResponse}
     */
    v1TenantTenantIdAdminAdminSpotReservationDetailReservationIdGet(reservationId, tenantId) {
      return this.v1TenantTenantIdAdminAdminSpotReservationDetailReservationIdGetWithHttpInfo(reservationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.reservationId 
     * @param {Array.<module:model/Fee>} opts.fee 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OrderRefundIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationModificationRefundBreakdownPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['fee'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationModificationRefundBreakdownPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'reservationId': opts['reservationId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = OrderRefundIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/modification-refund-breakdown', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.reservationId 
     * @param {Array.<module:model/Fee>} opts.fee 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OrderRefundIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminSpotReservationModificationRefundBreakdownPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminSpotReservationModificationRefundBreakdownPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {String} reservationId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminCancellationRequest} opts.adminCancellationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OrderResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationReservationIdCancelPostWithHttpInfo(tenantId, reservationId, opts) {
      opts = opts || {};
      let postBody = opts['adminCancellationRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationReservationIdCancelPost");
      }
      // verify the required parameter 'reservationId' is set
      if (reservationId === undefined || reservationId === null) {
        throw new Error("Missing the required parameter 'reservationId' when calling v1TenantTenantIdAdminAdminSpotReservationReservationIdCancelPost");
      }

      let pathParams = {
        'tenantId': tenantId,
        'reservationId': reservationId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = OrderResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/{reservationId}/cancel', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {String} reservationId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminCancellationRequest} opts.adminCancellationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OrderResponse}
     */
    v1TenantTenantIdAdminAdminSpotReservationReservationIdCancelPost(tenantId, reservationId, opts) {
      return this.v1TenantTenantIdAdminAdminSpotReservationReservationIdCancelPostWithHttpInfo(tenantId, reservationId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.reason 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OrderResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationReservationIdCancelWitoutRefundPostWithHttpInfo(reservationId, tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'reservationId' is set
      if (reservationId === undefined || reservationId === null) {
        throw new Error("Missing the required parameter 'reservationId' when calling v1TenantTenantIdAdminAdminSpotReservationReservationIdCancelWitoutRefundPost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationReservationIdCancelWitoutRefundPost");
      }

      let pathParams = {
        'reservationId': reservationId,
        'tenantId': tenantId
      };
      let queryParams = {
        'reason': opts['reason']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = OrderResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/{reservationId}/cancel-witout-refund', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.reason 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OrderResponse}
     */
    v1TenantTenantIdAdminAdminSpotReservationReservationIdCancelWitoutRefundPost(reservationId, tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminSpotReservationReservationIdCancelWitoutRefundPostWithHttpInfo(reservationId, tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {String} reservationId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.paymentTypeId 
     * @param {module:model/AdminEditReservationRequest} opts.adminEditReservationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EditReservationResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationReservationIdChangePostWithHttpInfo(tenantId, reservationId, opts) {
      opts = opts || {};
      let postBody = opts['adminEditReservationRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationReservationIdChangePost");
      }
      // verify the required parameter 'reservationId' is set
      if (reservationId === undefined || reservationId === null) {
        throw new Error("Missing the required parameter 'reservationId' when calling v1TenantTenantIdAdminAdminSpotReservationReservationIdChangePost");
      }

      let pathParams = {
        'tenantId': tenantId,
        'reservationId': reservationId
      };
      let queryParams = {
        'paymentTypeId': opts['paymentTypeId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = EditReservationResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/{reservationId}/change', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {String} reservationId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.paymentTypeId 
     * @param {module:model/AdminEditReservationRequest} opts.adminEditReservationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EditReservationResponse}
     */
    v1TenantTenantIdAdminAdminSpotReservationReservationIdChangePost(tenantId, reservationId, opts) {
      return this.v1TenantTenantIdAdminAdminSpotReservationReservationIdChangePostWithHttpInfo(tenantId, reservationId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReservationCommmentListResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationReservationIdCommentGetWithHttpInfo(reservationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'reservationId' is set
      if (reservationId === undefined || reservationId === null) {
        throw new Error("Missing the required parameter 'reservationId' when calling v1TenantTenantIdAdminAdminSpotReservationReservationIdCommentGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationReservationIdCommentGet");
      }

      let pathParams = {
        'reservationId': reservationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ReservationCommmentListResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/{reservationId}/comment', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReservationCommmentListResponse}
     */
    v1TenantTenantIdAdminAdminSpotReservationReservationIdCommentGet(reservationId, tenantId) {
      return this.v1TenantTenantIdAdminAdminSpotReservationReservationIdCommentGetWithHttpInfo(reservationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {String} reservationId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReservationCommment} opts.reservationCommment 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReservationCommmentResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationReservationIdCommentPostWithHttpInfo(tenantId, reservationId, opts) {
      opts = opts || {};
      let postBody = opts['reservationCommment'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationReservationIdCommentPost");
      }
      // verify the required parameter 'reservationId' is set
      if (reservationId === undefined || reservationId === null) {
        throw new Error("Missing the required parameter 'reservationId' when calling v1TenantTenantIdAdminAdminSpotReservationReservationIdCommentPost");
      }

      let pathParams = {
        'tenantId': tenantId,
        'reservationId': reservationId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ReservationCommmentResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/{reservationId}/comment', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {String} reservationId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReservationCommment} opts.reservationCommment 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReservationCommmentResponse}
     */
    v1TenantTenantIdAdminAdminSpotReservationReservationIdCommentPost(tenantId, reservationId, opts) {
      return this.v1TenantTenantIdAdminAdminSpotReservationReservationIdCommentPostWithHttpInfo(tenantId, reservationId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SpotReservation} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationReservationIdGetWithHttpInfo(reservationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'reservationId' is set
      if (reservationId === undefined || reservationId === null) {
        throw new Error("Missing the required parameter 'reservationId' when calling v1TenantTenantIdAdminAdminSpotReservationReservationIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationReservationIdGet");
      }

      let pathParams = {
        'reservationId': reservationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SpotReservation;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/{reservationId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SpotReservation}
     */
    v1TenantTenantIdAdminAdminSpotReservationReservationIdGet(reservationId, tenantId) {
      return this.v1TenantTenantIdAdminAdminSpotReservationReservationIdGetWithHttpInfo(reservationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} auditTransactionId 
     * @param {String} tenantId 
     * @param {String} reservationId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/FieldChangeIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationReservationIdHistoryAuditTransactionIdChangesGetWithHttpInfo(auditTransactionId, tenantId, reservationId) {
      let postBody = null;
      // verify the required parameter 'auditTransactionId' is set
      if (auditTransactionId === undefined || auditTransactionId === null) {
        throw new Error("Missing the required parameter 'auditTransactionId' when calling v1TenantTenantIdAdminAdminSpotReservationReservationIdHistoryAuditTransactionIdChangesGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationReservationIdHistoryAuditTransactionIdChangesGet");
      }
      // verify the required parameter 'reservationId' is set
      if (reservationId === undefined || reservationId === null) {
        throw new Error("Missing the required parameter 'reservationId' when calling v1TenantTenantIdAdminAdminSpotReservationReservationIdHistoryAuditTransactionIdChangesGet");
      }

      let pathParams = {
        'auditTransactionId': auditTransactionId,
        'tenantId': tenantId,
        'reservationId': reservationId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = FieldChangeIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/{reservationId}/history/{auditTransactionId}/changes', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} auditTransactionId 
     * @param {String} tenantId 
     * @param {String} reservationId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/FieldChangeIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminSpotReservationReservationIdHistoryAuditTransactionIdChangesGet(auditTransactionId, tenantId, reservationId) {
      return this.v1TenantTenantIdAdminAdminSpotReservationReservationIdHistoryAuditTransactionIdChangesGetWithHttpInfo(auditTransactionId, tenantId, reservationId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SpotReservationHistoryIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationReservationIdHistoryGetWithHttpInfo(reservationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'reservationId' is set
      if (reservationId === undefined || reservationId === null) {
        throw new Error("Missing the required parameter 'reservationId' when calling v1TenantTenantIdAdminAdminSpotReservationReservationIdHistoryGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationReservationIdHistoryGet");
      }

      let pathParams = {
        'reservationId': reservationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SpotReservationHistoryIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/{reservationId}/history', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SpotReservationHistoryIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminSpotReservationReservationIdHistoryGet(reservationId, tenantId) {
      return this.v1TenantTenantIdAdminAdminSpotReservationReservationIdHistoryGetWithHttpInfo(reservationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} spotId 
     * @param {Date} _date 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SpotReservationIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationSpotIdReservationsByDateDateGetWithHttpInfo(spotId, _date, tenantId) {
      let postBody = null;
      // verify the required parameter 'spotId' is set
      if (spotId === undefined || spotId === null) {
        throw new Error("Missing the required parameter 'spotId' when calling v1TenantTenantIdAdminAdminSpotReservationSpotIdReservationsByDateDateGet");
      }
      // verify the required parameter '_date' is set
      if (_date === undefined || _date === null) {
        throw new Error("Missing the required parameter '_date' when calling v1TenantTenantIdAdminAdminSpotReservationSpotIdReservationsByDateDateGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationSpotIdReservationsByDateDateGet");
      }

      let pathParams = {
        'spotId': spotId,
        'date': _date,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SpotReservationIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/{spotId}/reservations-by-date/{date}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} spotId 
     * @param {Date} _date 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SpotReservationIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminSpotReservationSpotIdReservationsByDateDateGet(spotId, _date, tenantId) {
      return this.v1TenantTenantIdAdminAdminSpotReservationSpotIdReservationsByDateDateGetWithHttpInfo(spotId, _date, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} spotId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminClaimSpotRequest} opts.adminClaimSpotRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ClaimSpotResultResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationSpotSpotIdClaimPostWithHttpInfo(spotId, tenantId, opts) {
      opts = opts || {};
      let postBody = opts['adminClaimSpotRequest'];
      // verify the required parameter 'spotId' is set
      if (spotId === undefined || spotId === null) {
        throw new Error("Missing the required parameter 'spotId' when calling v1TenantTenantIdAdminAdminSpotReservationSpotSpotIdClaimPost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationSpotSpotIdClaimPost");
      }

      let pathParams = {
        'spotId': spotId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ClaimSpotResultResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/spot/{spotId}/claim', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} spotId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminClaimSpotRequest} opts.adminClaimSpotRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ClaimSpotResultResponse}
     */
    v1TenantTenantIdAdminAdminSpotReservationSpotSpotIdClaimPost(spotId, tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminSpotReservationSpotSpotIdClaimPostWithHttpInfo(spotId, tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} spotId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.lockCode 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReleaseSpotResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationSpotSpotIdReleaseclaimPostWithHttpInfo(spotId, tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'spotId' is set
      if (spotId === undefined || spotId === null) {
        throw new Error("Missing the required parameter 'spotId' when calling v1TenantTenantIdAdminAdminSpotReservationSpotSpotIdReleaseclaimPost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationSpotSpotIdReleaseclaimPost");
      }

      let pathParams = {
        'spotId': spotId,
        'tenantId': tenantId
      };
      let queryParams = {
        'lockCode': opts['lockCode']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ReleaseSpotResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/spot/{spotId}/releaseclaim', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} spotId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.lockCode 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReleaseSpotResponse}
     */
    v1TenantTenantIdAdminAdminSpotReservationSpotSpotIdReleaseclaimPost(spotId, tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminSpotReservationSpotSpotIdReleaseclaimPostWithHttpInfo(spotId, tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationUndoChargebackReservationIdGetWithHttpInfo(reservationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'reservationId' is set
      if (reservationId === undefined || reservationId === null) {
        throw new Error("Missing the required parameter 'reservationId' when calling v1TenantTenantIdAdminAdminSpotReservationUndoChargebackReservationIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationUndoChargebackReservationIdGet");
      }

      let pathParams = {
        'reservationId': reservationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/undo-chargeback/{reservationId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdAdminAdminSpotReservationUndoChargebackReservationIdGet(reservationId, tenantId) {
      return this.v1TenantTenantIdAdminAdminSpotReservationUndoChargebackReservationIdGetWithHttpInfo(reservationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BooleanResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationUndocheckinReservationIdPostWithHttpInfo(reservationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'reservationId' is set
      if (reservationId === undefined || reservationId === null) {
        throw new Error("Missing the required parameter 'reservationId' when calling v1TenantTenantIdAdminAdminSpotReservationUndocheckinReservationIdPost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationUndocheckinReservationIdPost");
      }

      let pathParams = {
        'reservationId': reservationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/undocheckin/{reservationId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BooleanResponse}
     */
    v1TenantTenantIdAdminAdminSpotReservationUndocheckinReservationIdPost(reservationId, tenantId) {
      return this.v1TenantTenantIdAdminAdminSpotReservationUndocheckinReservationIdPostWithHttpInfo(reservationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BooleanResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationUndocheckoutReservationIdPostWithHttpInfo(reservationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'reservationId' is set
      if (reservationId === undefined || reservationId === null) {
        throw new Error("Missing the required parameter 'reservationId' when calling v1TenantTenantIdAdminAdminSpotReservationUndocheckoutReservationIdPost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationUndocheckoutReservationIdPost");
      }

      let pathParams = {
        'reservationId': reservationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/undocheckout/{reservationId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BooleanResponse}
     */
    v1TenantTenantIdAdminAdminSpotReservationUndocheckoutReservationIdPost(reservationId, tenantId) {
      return this.v1TenantTenantIdAdminAdminSpotReservationUndocheckoutReservationIdPostWithHttpInfo(reservationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminEditReservationRequest} opts.adminEditReservationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationValidateEditPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['adminEditReservationRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationValidateEditPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/validate-edit', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminEditReservationRequest} opts.adminEditReservationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdAdminAdminSpotReservationValidateEditPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminSpotReservationValidateEditPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminNewReservationRequest} opts.adminNewReservationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotReservationValidatePostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['adminNewReservationRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotReservationValidatePost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpotReservation/validate', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminNewReservationRequest} opts.adminNewReservationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdAdminAdminSpotReservationValidatePost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminSpotReservationValidatePostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
