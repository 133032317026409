import Vue from "vue";

Vue.filter("formatPhoneNumber", function(value) {
  if (value) {
    //Check if the input is of correct length
    let match = value.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return value;
  }
});
