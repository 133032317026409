/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import SpotType from './SpotType';

/**
 * The SpotSpotType model module.
 * @module model/SpotSpotType
 * @version v1
 */
class SpotSpotType {
    /**
     * Constructs a new <code>SpotSpotType</code>.
     * @alias module:model/SpotSpotType
     */
    constructor() { 
        
        SpotSpotType.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SpotSpotType</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SpotSpotType} obj Optional instance to populate.
     * @return {module:model/SpotSpotType} The populated <code>SpotSpotType</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SpotSpotType();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('spotId')) {
                obj['spotId'] = ApiClient.convertToType(data['spotId'], 'Number');
            }
            if (data.hasOwnProperty('isSelected')) {
                obj['isSelected'] = ApiClient.convertToType(data['isSelected'], 'Boolean');
            }
            if (data.hasOwnProperty('spotTypeId')) {
                obj['spotTypeId'] = ApiClient.convertToType(data['spotTypeId'], 'Number');
            }
            if (data.hasOwnProperty('spotType')) {
                obj['spotType'] = SpotType.constructFromObject(data['spotType']);
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
SpotSpotType.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
SpotSpotType.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
SpotSpotType.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
SpotSpotType.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
SpotSpotType.prototype['id'] = undefined;

/**
 * @member {Number} spotId
 */
SpotSpotType.prototype['spotId'] = undefined;

/**
 * @member {Boolean} isSelected
 */
SpotSpotType.prototype['isSelected'] = undefined;

/**
 * @member {Number} spotTypeId
 */
SpotSpotType.prototype['spotTypeId'] = undefined;

/**
 * @member {module:model/SpotType} spotType
 */
SpotSpotType.prototype['spotType'] = undefined;






export default SpotSpotType;

