/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AddOns from './AddOns';
import DiscountClassEnum from './DiscountClassEnum';
import Fee from './Fee';
import ReservationOverride from './ReservationOverride';
import SalesChannelEnum from './SalesChannelEnum';

/**
 * The AdminNewReservationRequest model module.
 * @module model/AdminNewReservationRequest
 * @version v1
 */
class AdminNewReservationRequest {
    /**
     * Constructs a new <code>AdminNewReservationRequest</code>.
     * @alias module:model/AdminNewReservationRequest
     */
    constructor() { 
        
        AdminNewReservationRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AdminNewReservationRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AdminNewReservationRequest} obj Optional instance to populate.
     * @return {module:model/AdminNewReservationRequest} The populated <code>AdminNewReservationRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AdminNewReservationRequest();

            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('customerId')) {
                obj['customerId'] = ApiClient.convertToType(data['customerId'], 'Number');
            }
            if (data.hasOwnProperty('spotId')) {
                obj['spotId'] = ApiClient.convertToType(data['spotId'], 'Number');
            }
            if (data.hasOwnProperty('oldSpotId')) {
                obj['oldSpotId'] = ApiClient.convertToType(data['oldSpotId'], 'Number');
            }
            if (data.hasOwnProperty('startDate')) {
                obj['startDate'] = ApiClient.convertToType(data['startDate'], 'String');
            }
            if (data.hasOwnProperty('oldStartDate')) {
                obj['oldStartDate'] = ApiClient.convertToType(data['oldStartDate'], 'String');
            }
            if (data.hasOwnProperty('oldEndDate')) {
                obj['oldEndDate'] = ApiClient.convertToType(data['oldEndDate'], 'String');
            }
            if (data.hasOwnProperty('endDate')) {
                obj['endDate'] = ApiClient.convertToType(data['endDate'], 'String');
            }
            if (data.hasOwnProperty('oldNumberOfAdults')) {
                obj['oldNumberOfAdults'] = ApiClient.convertToType(data['oldNumberOfAdults'], 'Number');
            }
            if (data.hasOwnProperty('numberOfAdults')) {
                obj['numberOfAdults'] = ApiClient.convertToType(data['numberOfAdults'], 'Number');
            }
            if (data.hasOwnProperty('numberOfVehicles')) {
                obj['numberOfVehicles'] = ApiClient.convertToType(data['numberOfVehicles'], 'Number');
            }
            if (data.hasOwnProperty('equipmentLength')) {
                obj['equipmentLength'] = ApiClient.convertToType(data['equipmentLength'], 'Number');
            }
            if (data.hasOwnProperty('equipmentTypeId')) {
                obj['equipmentTypeId'] = ApiClient.convertToType(data['equipmentTypeId'], 'Number');
            }
            if (data.hasOwnProperty('isCustomerPrimaryOccupant')) {
                obj['isCustomerPrimaryOccupant'] = ApiClient.convertToType(data['isCustomerPrimaryOccupant'], 'Boolean');
            }
            if (data.hasOwnProperty('primaryOccupantFirstName')) {
                obj['primaryOccupantFirstName'] = ApiClient.convertToType(data['primaryOccupantFirstName'], 'String');
            }
            if (data.hasOwnProperty('primaryOccupantLastName')) {
                obj['primaryOccupantLastName'] = ApiClient.convertToType(data['primaryOccupantLastName'], 'String');
            }
            if (data.hasOwnProperty('primaryOccupantStreet1')) {
                obj['primaryOccupantStreet1'] = ApiClient.convertToType(data['primaryOccupantStreet1'], 'String');
            }
            if (data.hasOwnProperty('primaryOccupantStreet2')) {
                obj['primaryOccupantStreet2'] = ApiClient.convertToType(data['primaryOccupantStreet2'], 'String');
            }
            if (data.hasOwnProperty('primaryOccupantCity')) {
                obj['primaryOccupantCity'] = ApiClient.convertToType(data['primaryOccupantCity'], 'String');
            }
            if (data.hasOwnProperty('primaryOccupantState')) {
                obj['primaryOccupantState'] = ApiClient.convertToType(data['primaryOccupantState'], 'String');
            }
            if (data.hasOwnProperty('primaryOccupantZipcode')) {
                obj['primaryOccupantZipcode'] = ApiClient.convertToType(data['primaryOccupantZipcode'], 'String');
            }
            if (data.hasOwnProperty('primaryOccupantCountry')) {
                obj['primaryOccupantCountry'] = ApiClient.convertToType(data['primaryOccupantCountry'], 'String');
            }
            if (data.hasOwnProperty('primaryOccupantEmail')) {
                obj['primaryOccupantEmail'] = ApiClient.convertToType(data['primaryOccupantEmail'], 'String');
            }
            if (data.hasOwnProperty('primaryOccupantHomePhone')) {
                obj['primaryOccupantHomePhone'] = ApiClient.convertToType(data['primaryOccupantHomePhone'], 'String');
            }
            if (data.hasOwnProperty('primaryOccupantMobilePhone')) {
                obj['primaryOccupantMobilePhone'] = ApiClient.convertToType(data['primaryOccupantMobilePhone'], 'String');
            }
            if (data.hasOwnProperty('lockCode')) {
                obj['lockCode'] = ApiClient.convertToType(data['lockCode'], 'String');
            }
            if (data.hasOwnProperty('adaRequirementAcknowledged')) {
                obj['adaRequirementAcknowledged'] = ApiClient.convertToType(data['adaRequirementAcknowledged'], 'Boolean');
            }
            if (data.hasOwnProperty('discounts')) {
                obj['discounts'] = ApiClient.convertToType(data['discounts'], [DiscountClassEnum]);
            }
            if (data.hasOwnProperty('oldDiscounts')) {
                obj['oldDiscounts'] = ApiClient.convertToType(data['oldDiscounts'], [DiscountClassEnum]);
            }
            if (data.hasOwnProperty('salesChannel')) {
                obj['salesChannel'] = SalesChannelEnum.constructFromObject(data['salesChannel']);
            }
            if (data.hasOwnProperty('reservationId')) {
                obj['reservationId'] = ApiClient.convertToType(data['reservationId'], 'Number');
            }
            if (data.hasOwnProperty('previousNumberOfAdults')) {
                obj['previousNumberOfAdults'] = ApiClient.convertToType(data['previousNumberOfAdults'], 'Number');
            }
            if (data.hasOwnProperty('previousNumberOfVehicles')) {
                obj['previousNumberOfVehicles'] = ApiClient.convertToType(data['previousNumberOfVehicles'], 'Number');
            }
            if (data.hasOwnProperty('overrideBusinessRule')) {
                obj['overrideBusinessRule'] = ApiClient.convertToType(data['overrideBusinessRule'], 'Boolean');
            }
            if (data.hasOwnProperty('overrideClosure')) {
                obj['overrideClosure'] = ApiClient.convertToType(data['overrideClosure'], 'Boolean');
            }
            if (data.hasOwnProperty('addOns')) {
                obj['addOns'] = ApiClient.convertToType(data['addOns'], [AddOns]);
            }
            if (data.hasOwnProperty('overrides')) {
                obj['overrides'] = ApiClient.convertToType(data['overrides'], [ReservationOverride]);
            }
            if (data.hasOwnProperty('fees')) {
                obj['fees'] = ApiClient.convertToType(data['fees'], [Fee]);
            }
            if (data.hasOwnProperty('disableDiscounts')) {
                obj['disableDiscounts'] = ApiClient.convertToType(data['disableDiscounts'], 'Boolean');
            }
            if (data.hasOwnProperty('feeOverrides')) {
                obj['feeOverrides'] = ApiClient.convertToType(data['feeOverrides'], [Fee]);
            }
            if (data.hasOwnProperty('walkInReservation')) {
                obj['walkInReservation'] = ApiClient.convertToType(data['walkInReservation'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {String} tenantId
 */
AdminNewReservationRequest.prototype['tenantId'] = undefined;

/**
 * @member {Number} customerId
 */
AdminNewReservationRequest.prototype['customerId'] = undefined;

/**
 * @member {Number} spotId
 */
AdminNewReservationRequest.prototype['spotId'] = undefined;

/**
 * @member {Number} oldSpotId
 */
AdminNewReservationRequest.prototype['oldSpotId'] = undefined;

/**
 * @member {String} startDate
 */
AdminNewReservationRequest.prototype['startDate'] = undefined;

/**
 * @member {String} oldStartDate
 */
AdminNewReservationRequest.prototype['oldStartDate'] = undefined;

/**
 * @member {String} oldEndDate
 */
AdminNewReservationRequest.prototype['oldEndDate'] = undefined;

/**
 * @member {String} endDate
 */
AdminNewReservationRequest.prototype['endDate'] = undefined;

/**
 * @member {Number} oldNumberOfAdults
 */
AdminNewReservationRequest.prototype['oldNumberOfAdults'] = undefined;

/**
 * @member {Number} numberOfAdults
 */
AdminNewReservationRequest.prototype['numberOfAdults'] = undefined;

/**
 * @member {Number} numberOfVehicles
 */
AdminNewReservationRequest.prototype['numberOfVehicles'] = undefined;

/**
 * @member {Number} equipmentLength
 */
AdminNewReservationRequest.prototype['equipmentLength'] = undefined;

/**
 * @member {Number} equipmentTypeId
 */
AdminNewReservationRequest.prototype['equipmentTypeId'] = undefined;

/**
 * @member {Boolean} isCustomerPrimaryOccupant
 */
AdminNewReservationRequest.prototype['isCustomerPrimaryOccupant'] = undefined;

/**
 * @member {String} primaryOccupantFirstName
 */
AdminNewReservationRequest.prototype['primaryOccupantFirstName'] = undefined;

/**
 * @member {String} primaryOccupantLastName
 */
AdminNewReservationRequest.prototype['primaryOccupantLastName'] = undefined;

/**
 * @member {String} primaryOccupantStreet1
 */
AdminNewReservationRequest.prototype['primaryOccupantStreet1'] = undefined;

/**
 * @member {String} primaryOccupantStreet2
 */
AdminNewReservationRequest.prototype['primaryOccupantStreet2'] = undefined;

/**
 * @member {String} primaryOccupantCity
 */
AdminNewReservationRequest.prototype['primaryOccupantCity'] = undefined;

/**
 * @member {String} primaryOccupantState
 */
AdminNewReservationRequest.prototype['primaryOccupantState'] = undefined;

/**
 * @member {String} primaryOccupantZipcode
 */
AdminNewReservationRequest.prototype['primaryOccupantZipcode'] = undefined;

/**
 * @member {String} primaryOccupantCountry
 */
AdminNewReservationRequest.prototype['primaryOccupantCountry'] = undefined;

/**
 * @member {String} primaryOccupantEmail
 */
AdminNewReservationRequest.prototype['primaryOccupantEmail'] = undefined;

/**
 * @member {String} primaryOccupantHomePhone
 */
AdminNewReservationRequest.prototype['primaryOccupantHomePhone'] = undefined;

/**
 * @member {String} primaryOccupantMobilePhone
 */
AdminNewReservationRequest.prototype['primaryOccupantMobilePhone'] = undefined;

/**
 * @member {String} lockCode
 */
AdminNewReservationRequest.prototype['lockCode'] = undefined;

/**
 * @member {Boolean} adaRequirementAcknowledged
 */
AdminNewReservationRequest.prototype['adaRequirementAcknowledged'] = undefined;

/**
 * @member {Array.<module:model/DiscountClassEnum>} discounts
 */
AdminNewReservationRequest.prototype['discounts'] = undefined;

/**
 * @member {Array.<module:model/DiscountClassEnum>} oldDiscounts
 */
AdminNewReservationRequest.prototype['oldDiscounts'] = undefined;

/**
 * @member {module:model/SalesChannelEnum} salesChannel
 */
AdminNewReservationRequest.prototype['salesChannel'] = undefined;

/**
 * @member {Number} reservationId
 */
AdminNewReservationRequest.prototype['reservationId'] = undefined;

/**
 * @member {Number} previousNumberOfAdults
 */
AdminNewReservationRequest.prototype['previousNumberOfAdults'] = undefined;

/**
 * @member {Number} previousNumberOfVehicles
 */
AdminNewReservationRequest.prototype['previousNumberOfVehicles'] = undefined;

/**
 * @member {Boolean} overrideBusinessRule
 */
AdminNewReservationRequest.prototype['overrideBusinessRule'] = undefined;

/**
 * @member {Boolean} overrideClosure
 */
AdminNewReservationRequest.prototype['overrideClosure'] = undefined;

/**
 * @member {Array.<module:model/AddOns>} addOns
 */
AdminNewReservationRequest.prototype['addOns'] = undefined;

/**
 * @member {Array.<module:model/ReservationOverride>} overrides
 */
AdminNewReservationRequest.prototype['overrides'] = undefined;

/**
 * @member {Array.<module:model/Fee>} fees
 */
AdminNewReservationRequest.prototype['fees'] = undefined;

/**
 * @member {Boolean} disableDiscounts
 */
AdminNewReservationRequest.prototype['disableDiscounts'] = undefined;

/**
 * @member {Array.<module:model/Fee>} feeOverrides
 */
AdminNewReservationRequest.prototype['feeOverrides'] = undefined;

/**
 * @member {Boolean} walkInReservation
 */
AdminNewReservationRequest.prototype['walkInReservation'] = undefined;






export default AdminNewReservationRequest;

