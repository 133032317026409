/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AdminChangePasswordRequest from '../model/AdminChangePasswordRequest';
import AdminUserSearchResponse from '../model/AdminUserSearchResponse';
import AdminUsersRequest from '../model/AdminUsersRequest';
import BooleanResponse from '../model/BooleanResponse';
import Int32IEnumerableResponse from '../model/Int32IEnumerableResponse';
import LookUpModelIEnumerableResponse from '../model/LookUpModelIEnumerableResponse';
import Response from '../model/Response';
import UserIEnumerableResponse from '../model/UserIEnumerableResponse';
import UserListResponse from '../model/UserListResponse';
import UserRequest from '../model/UserRequest';
import UserResponse from '../model/UserResponse';
import UserRoleIEnumerableResponse from '../model/UserRoleIEnumerableResponse';
import UserRoleRequest from '../model/UserRoleRequest';
import UserRoleResponse from '../model/UserRoleResponse';

/**
* AdminUser service.
* @module api/AdminUserApi
* @version v1
*/
export default class AdminUserApi {

    /**
    * Constructs a new AdminUserApi. 
    * @alias module:api/AdminUserApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {Number} userId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdAdminAdminUserActivateUserIdPostWithHttpInfo(userId, tenantId) {
      let postBody = null;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling v1TenantTenantIdAdminAdminUserActivateUserIdPost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminUserActivateUserIdPost");
      }

      let pathParams = {
        'userId': userId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminUser/activate/{userId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} userId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdAdminAdminUserActivateUserIdPost(userId, tenantId) {
      return this.v1TenantTenantIdAdminAdminUserActivateUserIdPostWithHttpInfo(userId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} userId 
     * @param {Number} agencyId 
     * @param {Number} identityProviderId 
     * @param {String} clientId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BooleanResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminUserAddUserToMspUserIdPutWithHttpInfo(userId, agencyId, identityProviderId, clientId, tenantId) {
      let postBody = null;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling v1TenantTenantIdAdminAdminUserAddUserToMspUserIdPut");
      }
      // verify the required parameter 'agencyId' is set
      if (agencyId === undefined || agencyId === null) {
        throw new Error("Missing the required parameter 'agencyId' when calling v1TenantTenantIdAdminAdminUserAddUserToMspUserIdPut");
      }
      // verify the required parameter 'identityProviderId' is set
      if (identityProviderId === undefined || identityProviderId === null) {
        throw new Error("Missing the required parameter 'identityProviderId' when calling v1TenantTenantIdAdminAdminUserAddUserToMspUserIdPut");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling v1TenantTenantIdAdminAdminUserAddUserToMspUserIdPut");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminUserAddUserToMspUserIdPut");
      }

      let pathParams = {
        'userId': userId,
        'agencyId': agencyId,
        'identityProviderId': identityProviderId,
        'clientId': clientId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminUser/addUserToMsp/{userId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} userId 
     * @param {Number} agencyId 
     * @param {Number} identityProviderId 
     * @param {String} clientId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BooleanResponse}
     */
    v1TenantTenantIdAdminAdminUserAddUserToMspUserIdPut(userId, agencyId, identityProviderId, clientId, tenantId) {
      return this.v1TenantTenantIdAdminAdminUserAddUserToMspUserIdPutWithHttpInfo(userId, agencyId, identityProviderId, clientId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminChangePasswordRequest} opts.adminChangePasswordRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdAdminAdminUserChangePasswordPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['adminChangePasswordRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminUserChangePasswordPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminUser/changePassword', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminChangePasswordRequest} opts.adminChangePasswordRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdAdminAdminUserChangePasswordPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminUserChangePasswordPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} userId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdAdminAdminUserDeactivateUserIdPostWithHttpInfo(userId, tenantId) {
      let postBody = null;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling v1TenantTenantIdAdminAdminUserDeactivateUserIdPost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminUserDeactivateUserIdPost");
      }

      let pathParams = {
        'userId': userId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminUser/deactivate/{userId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} userId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdAdminAdminUserDeactivateUserIdPost(userId, tenantId) {
      return this.v1TenantTenantIdAdminAdminUserDeactivateUserIdPostWithHttpInfo(userId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} email 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminUserEmailEmailGetWithHttpInfo(email, tenantId) {
      let postBody = null;
      // verify the required parameter 'email' is set
      if (email === undefined || email === null) {
        throw new Error("Missing the required parameter 'email' when calling v1TenantTenantIdAdminAdminUserEmailEmailGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminUserEmailEmailGet");
      }

      let pathParams = {
        'email': email,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminUser/email/{email}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} email 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminUserEmailEmailGet(email, tenantId) {
      return this.v1TenantTenantIdAdminAdminUserEmailEmailGetWithHttpInfo(email, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/UserRequest} opts.userRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminUserPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['userRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminUserPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminUser', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/UserRequest} opts.userRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserResponse}
     */
    v1TenantTenantIdAdminAdminUserPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminUserPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/UserRequest} opts.userRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminUserPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['userRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminUserPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminUser', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/UserRequest} opts.userRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserResponse}
     */
    v1TenantTenantIdAdminAdminUserPut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminUserPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.firstName 
     * @param {String} opts.lastName 
     * @param {String} opts.username 
     * @param {String} opts.email 
     * @param {Array.<Number>} opts.roleIds 
     * @param {Array.<Number>} opts.locationIds 
     * @param {Number} opts.userStatusId 
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.itemsPerPage 
     * @param {Boolean} opts.sortDescending 
     * @param {String} opts.sortField 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AdminUserSearchResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminUserSearchGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminUserSearchGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'FirstName': opts['firstName'],
        'LastName': opts['lastName'],
        'Username': opts['username'],
        'Email': opts['email'],
        'RoleIds': this.apiClient.buildCollectionParam(opts['roleIds'], 'multi'),
        'LocationIds': this.apiClient.buildCollectionParam(opts['locationIds'], 'multi'),
        'UserStatusId': opts['userStatusId'],
        'PageNumber': opts['pageNumber'],
        'ItemsPerPage': opts['itemsPerPage'],
        'SortDescending': opts['sortDescending'],
        'SortField': opts['sortField']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AdminUserSearchResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminUser/search', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.firstName 
     * @param {String} opts.lastName 
     * @param {String} opts.username 
     * @param {String} opts.email 
     * @param {Array.<Number>} opts.roleIds 
     * @param {Array.<Number>} opts.locationIds 
     * @param {Number} opts.userStatusId 
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.itemsPerPage 
     * @param {Boolean} opts.sortDescending 
     * @param {String} opts.sortField 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AdminUserSearchResponse}
     */
    v1TenantTenantIdAdminAdminUserSearchGet(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminUserSearchGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} userId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdAdminAdminUserUserIdDeleteWithHttpInfo(userId, tenantId) {
      let postBody = null;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling v1TenantTenantIdAdminAdminUserUserIdDelete");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminUserUserIdDelete");
      }

      let pathParams = {
        'userId': userId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminUser/{userId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} userId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdAdminAdminUserUserIdDelete(userId, tenantId) {
      return this.v1TenantTenantIdAdminAdminUserUserIdDeleteWithHttpInfo(userId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} userId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminUserUserIdGetWithHttpInfo(userId, tenantId) {
      let postBody = null;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling v1TenantTenantIdAdminAdminUserUserIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminUserUserIdGet");
      }

      let pathParams = {
        'userId': userId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminUser/{userId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} userId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserResponse}
     */
    v1TenantTenantIdAdminAdminUserUserIdGet(userId, tenantId) {
      return this.v1TenantTenantIdAdminAdminUserUserIdGetWithHttpInfo(userId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/UserRoleRequest} opts.userRoleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserRoleResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminUserUserRolePutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['userRoleRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminUserUserRolePut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserRoleResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminUser/userRole', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/UserRoleRequest} opts.userRoleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserRoleResponse}
     */
    v1TenantTenantIdAdminAdminUserUserRolePut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminUserUserRolePutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/UserRoleRequest>} opts.userRoleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserRoleIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminUserUserRolesPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['userRoleRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminUserUserRolesPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserRoleIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminUser/userRoles', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/UserRoleRequest>} opts.userRoleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserRoleIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminUserUserRolesPut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminUserUserRolesPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} userName 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminUserUsernameUserNameGetWithHttpInfo(userName, tenantId) {
      let postBody = null;
      // verify the required parameter 'userName' is set
      if (userName === undefined || userName === null) {
        throw new Error("Missing the required parameter 'userName' when calling v1TenantTenantIdAdminAdminUserUsernameUserNameGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminUserUsernameUserNameGet");
      }

      let pathParams = {
        'userName': userName,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminUser/username/{userName}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} userName 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserResponse}
     */
    v1TenantTenantIdAdminAdminUserUsernameUserNameGet(userName, tenantId) {
      return this.v1TenantTenantIdAdminAdminUserUsernameUserNameGetWithHttpInfo(userName, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserRoleIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminUserUserrolesGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminUserUserrolesGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserRoleIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminUser/userroles', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserRoleIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminUserUserrolesGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminUserUserrolesGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Int32IEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminUserUsersNotInMspGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminUserUsersNotInMspGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Int32IEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminUser/usersNotInMsp', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Int32IEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminUserUsersNotInMspGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminUserUsersNotInMspGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminUsersRequest} opts.adminUsersRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserListResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminUserUsersPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['adminUsersRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminUserUsersPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserListResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminUser/users', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminUsersRequest} opts.adminUsersRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserListResponse}
     */
    v1TenantTenantIdAdminAdminUserUsersPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminUserUsersPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LookUpModelIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminUserUserstatusesGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminUserUserstatusesGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LookUpModelIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminUser/userstatuses', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LookUpModelIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminUserUserstatusesGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminUserUserstatusesGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
