/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import FeeTypeEnum from './FeeTypeEnum';
import OrderRefund from './OrderRefund';
import SpotReservation from './SpotReservation';

/**
 * The ReservationRefund model module.
 * @module model/ReservationRefund
 * @version v1
 */
class ReservationRefund {
    /**
     * Constructs a new <code>ReservationRefund</code>.
     * @alias module:model/ReservationRefund
     */
    constructor() { 
        
        ReservationRefund.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ReservationRefund</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ReservationRefund} obj Optional instance to populate.
     * @return {module:model/ReservationRefund} The populated <code>ReservationRefund</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ReservationRefund();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('accountingCode')) {
                obj['accountingCode'] = ApiClient.convertToType(data['accountingCode'], 'String');
            }
            if (data.hasOwnProperty('undiscountedAmount')) {
                obj['undiscountedAmount'] = ApiClient.convertToType(data['undiscountedAmount'], 'Number');
            }
            if (data.hasOwnProperty('preModifiedAmount')) {
                obj['preModifiedAmount'] = ApiClient.convertToType(data['preModifiedAmount'], 'Number');
            }
            if (data.hasOwnProperty('amount')) {
                obj['amount'] = ApiClient.convertToType(data['amount'], 'Number');
            }
            if (data.hasOwnProperty('quantity')) {
                obj['quantity'] = ApiClient.convertToType(data['quantity'], 'Number');
            }
            if (data.hasOwnProperty('sku')) {
                obj['sku'] = ApiClient.convertToType(data['sku'], 'String');
            }
            if (data.hasOwnProperty('orderRefund')) {
                obj['orderRefund'] = OrderRefund.constructFromObject(data['orderRefund']);
            }
            if (data.hasOwnProperty('orderRefundId')) {
                obj['orderRefundId'] = ApiClient.convertToType(data['orderRefundId'], 'Number');
            }
            if (data.hasOwnProperty('spotReservation')) {
                obj['spotReservation'] = SpotReservation.constructFromObject(data['spotReservation']);
            }
            if (data.hasOwnProperty('feeType')) {
                obj['feeType'] = FeeTypeEnum.constructFromObject(data['feeType']);
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
ReservationRefund.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
ReservationRefund.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
ReservationRefund.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
ReservationRefund.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
ReservationRefund.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
ReservationRefund.prototype['tenantId'] = undefined;

/**
 * @member {String} name
 */
ReservationRefund.prototype['name'] = undefined;

/**
 * @member {String} description
 */
ReservationRefund.prototype['description'] = undefined;

/**
 * @member {String} accountingCode
 */
ReservationRefund.prototype['accountingCode'] = undefined;

/**
 * @member {Number} undiscountedAmount
 */
ReservationRefund.prototype['undiscountedAmount'] = undefined;

/**
 * @member {Number} preModifiedAmount
 */
ReservationRefund.prototype['preModifiedAmount'] = undefined;

/**
 * @member {Number} amount
 */
ReservationRefund.prototype['amount'] = undefined;

/**
 * @member {Number} quantity
 */
ReservationRefund.prototype['quantity'] = undefined;

/**
 * @member {String} sku
 */
ReservationRefund.prototype['sku'] = undefined;

/**
 * @member {module:model/OrderRefund} orderRefund
 */
ReservationRefund.prototype['orderRefund'] = undefined;

/**
 * @member {Number} orderRefundId
 */
ReservationRefund.prototype['orderRefundId'] = undefined;

/**
 * @member {module:model/SpotReservation} spotReservation
 */
ReservationRefund.prototype['spotReservation'] = undefined;

/**
 * @member {module:model/FeeTypeEnum} feeType
 */
ReservationRefund.prototype['feeType'] = undefined;






export default ReservationRefund;

