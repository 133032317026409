/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ClaimSpotRequest model module.
 * @module model/ClaimSpotRequest
 * @version v1
 */
class ClaimSpotRequest {
    /**
     * Constructs a new <code>ClaimSpotRequest</code>.
     * @alias module:model/ClaimSpotRequest
     */
    constructor() { 
        
        ClaimSpotRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ClaimSpotRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ClaimSpotRequest} obj Optional instance to populate.
     * @return {module:model/ClaimSpotRequest} The populated <code>ClaimSpotRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ClaimSpotRequest();

            if (data.hasOwnProperty('reservationStartDate')) {
                obj['reservationStartDate'] = ApiClient.convertToType(data['reservationStartDate'], 'String');
            }
            if (data.hasOwnProperty('reservationEndDate')) {
                obj['reservationEndDate'] = ApiClient.convertToType(data['reservationEndDate'], 'String');
            }
            if (data.hasOwnProperty('lockCode')) {
                obj['lockCode'] = ApiClient.convertToType(data['lockCode'], 'String');
            }
            if (data.hasOwnProperty('spotReservationId')) {
                obj['spotReservationId'] = ApiClient.convertToType(data['spotReservationId'], 'Number');
            }
            if (data.hasOwnProperty('customerId')) {
                obj['customerId'] = ApiClient.convertToType(data['customerId'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {String} reservationStartDate
 */
ClaimSpotRequest.prototype['reservationStartDate'] = undefined;

/**
 * @member {String} reservationEndDate
 */
ClaimSpotRequest.prototype['reservationEndDate'] = undefined;

/**
 * @member {String} lockCode
 */
ClaimSpotRequest.prototype['lockCode'] = undefined;

/**
 * @member {Number} spotReservationId
 */
ClaimSpotRequest.prototype['spotReservationId'] = undefined;

/**
 * @member {Number} customerId
 */
ClaimSpotRequest.prototype['customerId'] = undefined;






export default ClaimSpotRequest;

