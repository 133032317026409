/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The SpotSearchResultProduct model module.
 * @module model/SpotSearchResultProduct
 * @version v1
 */
class SpotSearchResultProduct {
    /**
     * Constructs a new <code>SpotSearchResultProduct</code>.
     * @alias module:model/SpotSearchResultProduct
     */
    constructor() { 
        
        SpotSearchResultProduct.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SpotSearchResultProduct</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SpotSearchResultProduct} obj Optional instance to populate.
     * @return {module:model/SpotSearchResultProduct} The populated <code>SpotSearchResultProduct</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SpotSearchResultProduct();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('productTypeId')) {
                obj['productTypeId'] = ApiClient.convertToType(data['productTypeId'], 'Number');
            }
            if (data.hasOwnProperty('productTypeName')) {
                obj['productTypeName'] = ApiClient.convertToType(data['productTypeName'], 'String');
            }
            if (data.hasOwnProperty('bookingTypeName')) {
                obj['bookingTypeName'] = ApiClient.convertToType(data['bookingTypeName'], 'String');
            }
            if (data.hasOwnProperty('bookingTypeId')) {
                obj['bookingTypeId'] = ApiClient.convertToType(data['bookingTypeId'], 'Number');
            }
            if (data.hasOwnProperty('productTypeIcon')) {
                obj['productTypeIcon'] = ApiClient.convertToType(data['productTypeIcon'], 'String');
            }
            if (data.hasOwnProperty('productClassificationId')) {
                obj['productClassificationId'] = ApiClient.convertToType(data['productClassificationId'], 'Number');
            }
            if (data.hasOwnProperty('productClassificationName')) {
                obj['productClassificationName'] = ApiClient.convertToType(data['productClassificationName'], 'String');
            }
            if (data.hasOwnProperty('productClassificationDescription')) {
                obj['productClassificationDescription'] = ApiClient.convertToType(data['productClassificationDescription'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
SpotSearchResultProduct.prototype['id'] = undefined;

/**
 * @member {String} name
 */
SpotSearchResultProduct.prototype['name'] = undefined;

/**
 * @member {Number} productTypeId
 */
SpotSearchResultProduct.prototype['productTypeId'] = undefined;

/**
 * @member {String} productTypeName
 */
SpotSearchResultProduct.prototype['productTypeName'] = undefined;

/**
 * @member {String} bookingTypeName
 */
SpotSearchResultProduct.prototype['bookingTypeName'] = undefined;

/**
 * @member {Number} bookingTypeId
 */
SpotSearchResultProduct.prototype['bookingTypeId'] = undefined;

/**
 * @member {String} productTypeIcon
 */
SpotSearchResultProduct.prototype['productTypeIcon'] = undefined;

/**
 * @member {Number} productClassificationId
 */
SpotSearchResultProduct.prototype['productClassificationId'] = undefined;

/**
 * @member {String} productClassificationName
 */
SpotSearchResultProduct.prototype['productClassificationName'] = undefined;

/**
 * @member {String} productClassificationDescription
 */
SpotSearchResultProduct.prototype['productClassificationDescription'] = undefined;






export default SpotSearchResultProduct;

