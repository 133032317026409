/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ResetPasswordConfirmationRequest model module.
 * @module model/ResetPasswordConfirmationRequest
 * @version v1
 */
class ResetPasswordConfirmationRequest {
    /**
     * Constructs a new <code>ResetPasswordConfirmationRequest</code>.
     * @alias module:model/ResetPasswordConfirmationRequest
     * @param username {String} 
     * @param password {String} 
     * @param confirmPassword {String} 
     * @param confirmationCode {String} 
     */
    constructor(username, password, confirmPassword, confirmationCode) { 
        
        ResetPasswordConfirmationRequest.initialize(this, username, password, confirmPassword, confirmationCode);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, username, password, confirmPassword, confirmationCode) { 
        obj['username'] = username;
        obj['password'] = password;
        obj['confirmPassword'] = confirmPassword;
        obj['confirmationCode'] = confirmationCode;
    }

    /**
     * Constructs a <code>ResetPasswordConfirmationRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ResetPasswordConfirmationRequest} obj Optional instance to populate.
     * @return {module:model/ResetPasswordConfirmationRequest} The populated <code>ResetPasswordConfirmationRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ResetPasswordConfirmationRequest();

            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('password')) {
                obj['password'] = ApiClient.convertToType(data['password'], 'String');
            }
            if (data.hasOwnProperty('confirmPassword')) {
                obj['confirmPassword'] = ApiClient.convertToType(data['confirmPassword'], 'String');
            }
            if (data.hasOwnProperty('confirmationCode')) {
                obj['confirmationCode'] = ApiClient.convertToType(data['confirmationCode'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} username
 */
ResetPasswordConfirmationRequest.prototype['username'] = undefined;

/**
 * @member {String} password
 */
ResetPasswordConfirmationRequest.prototype['password'] = undefined;

/**
 * @member {String} confirmPassword
 */
ResetPasswordConfirmationRequest.prototype['confirmPassword'] = undefined;

/**
 * @member {String} confirmationCode
 */
ResetPasswordConfirmationRequest.prototype['confirmationCode'] = undefined;






export default ResetPasswordConfirmationRequest;

