/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Area from './Area';
import Image from './Image';
import LocationDescription from './LocationDescription';
import LocationPointOfInterest from './LocationPointOfInterest';
import ProductTypeCount from './ProductTypeCount';
import TenantRegion from './TenantRegion';

/**
 * The Location model module.
 * @module model/Location
 * @version v1
 */
class Location {
    /**
     * Constructs a new <code>Location</code>.
     * @alias module:model/Location
     */
    constructor() { 
        
        Location.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Location</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Location} obj Optional instance to populate.
     * @return {module:model/Location} The populated <code>Location</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Location();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('code')) {
                obj['code'] = ApiClient.convertToType(data['code'], 'String');
            }
            if (data.hasOwnProperty('longName')) {
                obj['longName'] = ApiClient.convertToType(data['longName'], 'String');
            }
            if (data.hasOwnProperty('shortName')) {
                obj['shortName'] = ApiClient.convertToType(data['shortName'], 'String');
            }
            if (data.hasOwnProperty('annualVisitors')) {
                obj['annualVisitors'] = ApiClient.convertToType(data['annualVisitors'], 'Number');
            }
            if (data.hasOwnProperty('acreage')) {
                obj['acreage'] = ApiClient.convertToType(data['acreage'], 'Number');
            }
            if (data.hasOwnProperty('elevation')) {
                obj['elevation'] = ApiClient.convertToType(data['elevation'], 'Number');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('region')) {
                obj['region'] = TenantRegion.constructFromObject(data['region']);
            }
            if (data.hasOwnProperty('timezone')) {
                obj['timezone'] = ApiClient.convertToType(data['timezone'], 'String');
            }
            if (data.hasOwnProperty('helpUrl')) {
                obj['helpUrl'] = ApiClient.convertToType(data['helpUrl'], 'String');
            }
            if (data.hasOwnProperty('helpUrlName')) {
                obj['helpUrlName'] = ApiClient.convertToType(data['helpUrlName'], 'String');
            }
            if (data.hasOwnProperty('directions')) {
                obj['directions'] = ApiClient.convertToType(data['directions'], 'String');
            }
            if (data.hasOwnProperty('latitude')) {
                obj['latitude'] = ApiClient.convertToType(data['latitude'], 'Number');
            }
            if (data.hasOwnProperty('longitude')) {
                obj['longitude'] = ApiClient.convertToType(data['longitude'], 'Number');
            }
            if (data.hasOwnProperty('mapLatitude')) {
                obj['mapLatitude'] = ApiClient.convertToType(data['mapLatitude'], 'Number');
            }
            if (data.hasOwnProperty('mapLongitude')) {
                obj['mapLongitude'] = ApiClient.convertToType(data['mapLongitude'], 'Number');
            }
            if (data.hasOwnProperty('mapZoomLevel')) {
                obj['mapZoomLevel'] = ApiClient.convertToType(data['mapZoomLevel'], 'Number');
            }
            if (data.hasOwnProperty('street1')) {
                obj['street1'] = ApiClient.convertToType(data['street1'], 'String');
            }
            if (data.hasOwnProperty('street2')) {
                obj['street2'] = ApiClient.convertToType(data['street2'], 'String');
            }
            if (data.hasOwnProperty('city')) {
                obj['city'] = ApiClient.convertToType(data['city'], 'String');
            }
            if (data.hasOwnProperty('state')) {
                obj['state'] = ApiClient.convertToType(data['state'], 'String');
            }
            if (data.hasOwnProperty('zipcode')) {
                obj['zipcode'] = ApiClient.convertToType(data['zipcode'], 'String');
            }
            if (data.hasOwnProperty('primaryContactName')) {
                obj['primaryContactName'] = ApiClient.convertToType(data['primaryContactName'], 'String');
            }
            if (data.hasOwnProperty('primaryContactEmail')) {
                obj['primaryContactEmail'] = ApiClient.convertToType(data['primaryContactEmail'], 'String');
            }
            if (data.hasOwnProperty('secondaryContactName')) {
                obj['secondaryContactName'] = ApiClient.convertToType(data['secondaryContactName'], 'String');
            }
            if (data.hasOwnProperty('secondaryContactEmail')) {
                obj['secondaryContactEmail'] = ApiClient.convertToType(data['secondaryContactEmail'], 'String');
            }
            if (data.hasOwnProperty('reportingEmail')) {
                obj['reportingEmail'] = ApiClient.convertToType(data['reportingEmail'], 'String');
            }
            if (data.hasOwnProperty('phoneNumber')) {
                obj['phoneNumber'] = ApiClient.convertToType(data['phoneNumber'], 'String');
            }
            if (data.hasOwnProperty('faxNumber')) {
                obj['faxNumber'] = ApiClient.convertToType(data['faxNumber'], 'String');
            }
            if (data.hasOwnProperty('featured')) {
                obj['featured'] = ApiClient.convertToType(data['featured'], 'Boolean');
            }
            if (data.hasOwnProperty('featuredDescription')) {
                obj['featuredDescription'] = ApiClient.convertToType(data['featuredDescription'], 'String');
            }
            if (data.hasOwnProperty('featuredImageUrl')) {
                obj['featuredImageUrl'] = ApiClient.convertToType(data['featuredImageUrl'], 'String');
            }
            if (data.hasOwnProperty('meetsSearchCriteria')) {
                obj['meetsSearchCriteria'] = ApiClient.convertToType(data['meetsSearchCriteria'], 'Boolean');
            }
            if (data.hasOwnProperty('operationalSeasonId')) {
                obj['operationalSeasonId'] = ApiClient.convertToType(data['operationalSeasonId'], 'Number');
            }
            if (data.hasOwnProperty('regionId')) {
                obj['regionId'] = ApiClient.convertToType(data['regionId'], 'Number');
            }
            if (data.hasOwnProperty('isActive')) {
                obj['isActive'] = ApiClient.convertToType(data['isActive'], 'Boolean');
            }
            if (data.hasOwnProperty('importantDates')) {
                obj['importantDates'] = ApiClient.convertToType(data['importantDates'], 'String');
            }
            if (data.hasOwnProperty('areas')) {
                obj['areas'] = ApiClient.convertToType(data['areas'], [Area]);
            }
            if (data.hasOwnProperty('images')) {
                obj['images'] = ApiClient.convertToType(data['images'], [Image]);
            }
            if (data.hasOwnProperty('locationDescriptions')) {
                obj['locationDescriptions'] = ApiClient.convertToType(data['locationDescriptions'], [LocationDescription]);
            }
            if (data.hasOwnProperty('locationPointOfInterests')) {
                obj['locationPointOfInterests'] = ApiClient.convertToType(data['locationPointOfInterests'], [LocationPointOfInterest]);
            }
            if (data.hasOwnProperty('productTypeCounts')) {
                obj['productTypeCounts'] = ApiClient.convertToType(data['productTypeCounts'], [ProductTypeCount]);
            }
            if (data.hasOwnProperty('locationId')) {
                obj['locationId'] = ApiClient.convertToType(data['locationId'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
Location.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
Location.prototype['tenantId'] = undefined;

/**
 * @member {String} code
 */
Location.prototype['code'] = undefined;

/**
 * @member {String} longName
 */
Location.prototype['longName'] = undefined;

/**
 * @member {String} shortName
 */
Location.prototype['shortName'] = undefined;

/**
 * @member {Number} annualVisitors
 */
Location.prototype['annualVisitors'] = undefined;

/**
 * @member {Number} acreage
 */
Location.prototype['acreage'] = undefined;

/**
 * @member {Number} elevation
 */
Location.prototype['elevation'] = undefined;

/**
 * @member {String} url
 */
Location.prototype['url'] = undefined;

/**
 * @member {module:model/TenantRegion} region
 */
Location.prototype['region'] = undefined;

/**
 * @member {String} timezone
 */
Location.prototype['timezone'] = undefined;

/**
 * @member {String} helpUrl
 */
Location.prototype['helpUrl'] = undefined;

/**
 * @member {String} helpUrlName
 */
Location.prototype['helpUrlName'] = undefined;

/**
 * @member {String} directions
 */
Location.prototype['directions'] = undefined;

/**
 * @member {Number} latitude
 */
Location.prototype['latitude'] = undefined;

/**
 * @member {Number} longitude
 */
Location.prototype['longitude'] = undefined;

/**
 * @member {Number} mapLatitude
 */
Location.prototype['mapLatitude'] = undefined;

/**
 * @member {Number} mapLongitude
 */
Location.prototype['mapLongitude'] = undefined;

/**
 * @member {Number} mapZoomLevel
 */
Location.prototype['mapZoomLevel'] = undefined;

/**
 * @member {String} street1
 */
Location.prototype['street1'] = undefined;

/**
 * @member {String} street2
 */
Location.prototype['street2'] = undefined;

/**
 * @member {String} city
 */
Location.prototype['city'] = undefined;

/**
 * @member {String} state
 */
Location.prototype['state'] = undefined;

/**
 * @member {String} zipcode
 */
Location.prototype['zipcode'] = undefined;

/**
 * @member {String} primaryContactName
 */
Location.prototype['primaryContactName'] = undefined;

/**
 * @member {String} primaryContactEmail
 */
Location.prototype['primaryContactEmail'] = undefined;

/**
 * @member {String} secondaryContactName
 */
Location.prototype['secondaryContactName'] = undefined;

/**
 * @member {String} secondaryContactEmail
 */
Location.prototype['secondaryContactEmail'] = undefined;

/**
 * @member {String} reportingEmail
 */
Location.prototype['reportingEmail'] = undefined;

/**
 * @member {String} phoneNumber
 */
Location.prototype['phoneNumber'] = undefined;

/**
 * @member {String} faxNumber
 */
Location.prototype['faxNumber'] = undefined;

/**
 * @member {Boolean} featured
 */
Location.prototype['featured'] = undefined;

/**
 * @member {String} featuredDescription
 */
Location.prototype['featuredDescription'] = undefined;

/**
 * @member {String} featuredImageUrl
 */
Location.prototype['featuredImageUrl'] = undefined;

/**
 * @member {Boolean} meetsSearchCriteria
 */
Location.prototype['meetsSearchCriteria'] = undefined;

/**
 * @member {Number} operationalSeasonId
 */
Location.prototype['operationalSeasonId'] = undefined;

/**
 * @member {Number} regionId
 */
Location.prototype['regionId'] = undefined;

/**
 * @member {Boolean} isActive
 */
Location.prototype['isActive'] = undefined;

/**
 * @member {String} importantDates
 */
Location.prototype['importantDates'] = undefined;

/**
 * @member {Array.<module:model/Area>} areas
 */
Location.prototype['areas'] = undefined;

/**
 * @member {Array.<module:model/Image>} images
 */
Location.prototype['images'] = undefined;

/**
 * @member {Array.<module:model/LocationDescription>} locationDescriptions
 */
Location.prototype['locationDescriptions'] = undefined;

/**
 * @member {Array.<module:model/LocationPointOfInterest>} locationPointOfInterests
 */
Location.prototype['locationPointOfInterests'] = undefined;

/**
 * @member {Array.<module:model/ProductTypeCount>} productTypeCounts
 */
Location.prototype['productTypeCounts'] = undefined;

/**
 * @member {Number} locationId
 */
Location.prototype['locationId'] = undefined;






export default Location;

