const state = {
  spotSearchParams: null,
  closureSearchLocation: null,
  alertSearchLocation: null,
  checkSearchParams: {},
  reservationSearchParams: null,
  customerSearchParams: {},
  userSearchParams: {
    userStatusId: 1
  }
};
const getters = {
  spotSearchParams: state => state.spotSearchParams,
  closureSearchLocation: state => state.closureSearchLocation,
  alertSearchLocation: state => state.alertSearchLocation,
  checkSearchParams: state => state.checkSearchParams,
  reservationSearchParams: state => state.reservationSearchParams,
  customerSearchParams: state => state.customerSearchParams,
  userSearchParams: state => state.userSearchParams
};
const actions = {
  clearSpotSearch({ commit }) {
    commit("setSpotSearchParams", null);
  },
  clearClosureSearch({ commit }) {
    commit("setClosureSearchLocation", null);
  },
  clearAlertSearch({ commit }) {
    commit("setAlertSearchLocation", null);
  },
  clearCheckSearch({ commit }) {
    commit("setCheckSearchParams", {});
  },
  clearReservationSearch({ commit }) {
    commit("setReservationSearchParams", null);
  },
  clearCustomerSearch({ commit }) {
    commit("setCustomerSearchParams", {});
  },
  clearUserSearch({ commit }) {
    commit("setUserSearchParams", {
      userStatusId: 1
    });
  }
};
const mutations = {
  setSpotSearchParams(state, spotSearchParams) {
    state.spotSearchParams = spotSearchParams;
  },
  setClosureSearchLocation(state, closureSearchLocation) {
    state.closureSearchLocation = closureSearchLocation;
  },
  setAlertSearchLocation(state, alertSearchLocation) {
    state.alertSearchLocation = alertSearchLocation;
  },
  setCheckSearchParams(state, checkSearchParams) {
    state.checkSearchParams = checkSearchParams;
  },
  setReservationSearchParams(state, reservationSearchParams) {
    state.reservationSearchParams = reservationSearchParams;
  },
  setCustomerSearchParams(state, customerSearchParams) {
    state.customerSearchParams = customerSearchParams;
  },
  setUserSearchParams(state, userSearchParams) {
    state.userSearchParams = userSearchParams;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
