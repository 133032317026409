/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Order from './Order';
import PaymentType from './PaymentType';

/**
 * The OrderRefund model module.
 * @module model/OrderRefund
 * @version v1
 */
class OrderRefund {
    /**
     * Constructs a new <code>OrderRefund</code>.
     * @alias module:model/OrderRefund
     */
    constructor() { 
        
        OrderRefund.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>OrderRefund</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/OrderRefund} obj Optional instance to populate.
     * @return {module:model/OrderRefund} The populated <code>OrderRefund</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrderRefund();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('dateRefunded')) {
                obj['dateRefunded'] = ApiClient.convertToType(data['dateRefunded'], 'Date');
            }
            if (data.hasOwnProperty('amount')) {
                obj['amount'] = ApiClient.convertToType(data['amount'], 'Number');
            }
            if (data.hasOwnProperty('paymentTypeId')) {
                obj['paymentTypeId'] = ApiClient.convertToType(data['paymentTypeId'], 'Number');
            }
            if (data.hasOwnProperty('paymentType')) {
                obj['paymentType'] = PaymentType.constructFromObject(data['paymentType']);
            }
            if (data.hasOwnProperty('tpeOrderId')) {
                obj['tpeOrderId'] = ApiClient.convertToType(data['tpeOrderId'], 'String');
            }
            if (data.hasOwnProperty('ccpOrderToken')) {
                obj['ccpOrderToken'] = ApiClient.convertToType(data['ccpOrderToken'], 'String');
            }
            if (data.hasOwnProperty('lastFour')) {
                obj['lastFour'] = ApiClient.convertToType(data['lastFour'], 'String');
            }
            if (data.hasOwnProperty('ccpOrderStatus')) {
                obj['ccpOrderStatus'] = ApiClient.convertToType(data['ccpOrderStatus'], 'String');
            }
            if (data.hasOwnProperty('cardholderName')) {
                obj['cardholderName'] = ApiClient.convertToType(data['cardholderName'], 'String');
            }
            if (data.hasOwnProperty('orderId')) {
                obj['orderId'] = ApiClient.convertToType(data['orderId'], 'Number');
            }
            if (data.hasOwnProperty('order')) {
                obj['order'] = Order.constructFromObject(data['order']);
            }
            if (data.hasOwnProperty('orderPaymentId')) {
                obj['orderPaymentId'] = ApiClient.convertToType(data['orderPaymentId'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
OrderRefund.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
OrderRefund.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
OrderRefund.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
OrderRefund.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
OrderRefund.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
OrderRefund.prototype['tenantId'] = undefined;

/**
 * @member {Date} dateRefunded
 */
OrderRefund.prototype['dateRefunded'] = undefined;

/**
 * @member {Number} amount
 */
OrderRefund.prototype['amount'] = undefined;

/**
 * @member {Number} paymentTypeId
 */
OrderRefund.prototype['paymentTypeId'] = undefined;

/**
 * @member {module:model/PaymentType} paymentType
 */
OrderRefund.prototype['paymentType'] = undefined;

/**
 * @member {String} tpeOrderId
 */
OrderRefund.prototype['tpeOrderId'] = undefined;

/**
 * @member {String} ccpOrderToken
 */
OrderRefund.prototype['ccpOrderToken'] = undefined;

/**
 * @member {String} lastFour
 */
OrderRefund.prototype['lastFour'] = undefined;

/**
 * @member {String} ccpOrderStatus
 */
OrderRefund.prototype['ccpOrderStatus'] = undefined;

/**
 * @member {String} cardholderName
 */
OrderRefund.prototype['cardholderName'] = undefined;

/**
 * @member {Number} orderId
 */
OrderRefund.prototype['orderId'] = undefined;

/**
 * @member {module:model/Order} order
 */
OrderRefund.prototype['order'] = undefined;

/**
 * @member {Number} orderPaymentId
 */
OrderRefund.prototype['orderPaymentId'] = undefined;






export default OrderRefund;

