/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PointOfInterest from './PointOfInterest';
import PointOfInterestDistanceDescription from './PointOfInterestDistanceDescription';

/**
 * The LocationPointOfInterest model module.
 * @module model/LocationPointOfInterest
 * @version v1
 */
class LocationPointOfInterest {
    /**
     * Constructs a new <code>LocationPointOfInterest</code>.
     * @alias module:model/LocationPointOfInterest
     */
    constructor() { 
        
        LocationPointOfInterest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>LocationPointOfInterest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/LocationPointOfInterest} obj Optional instance to populate.
     * @return {module:model/LocationPointOfInterest} The populated <code>LocationPointOfInterest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LocationPointOfInterest();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('locationId')) {
                obj['locationId'] = ApiClient.convertToType(data['locationId'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('latitude')) {
                obj['latitude'] = ApiClient.convertToType(data['latitude'], 'Number');
            }
            if (data.hasOwnProperty('longitude')) {
                obj['longitude'] = ApiClient.convertToType(data['longitude'], 'Number');
            }
            if (data.hasOwnProperty('pointOfInterest')) {
                obj['pointOfInterest'] = PointOfInterest.constructFromObject(data['pointOfInterest']);
            }
            if (data.hasOwnProperty('pointOfInterestDistanceDescription')) {
                obj['pointOfInterestDistanceDescription'] = PointOfInterestDistanceDescription.constructFromObject(data['pointOfInterestDistanceDescription']);
            }
            if (data.hasOwnProperty('pointOfInterestId')) {
                obj['pointOfInterestId'] = ApiClient.convertToType(data['pointOfInterestId'], 'Number');
            }
            if (data.hasOwnProperty('pointOfInterestDistanceDescriptionId')) {
                obj['pointOfInterestDistanceDescriptionId'] = ApiClient.convertToType(data['pointOfInterestDistanceDescriptionId'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
LocationPointOfInterest.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
LocationPointOfInterest.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
LocationPointOfInterest.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
LocationPointOfInterest.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
LocationPointOfInterest.prototype['id'] = undefined;

/**
 * @member {Number} locationId
 */
LocationPointOfInterest.prototype['locationId'] = undefined;

/**
 * @member {String} tenantId
 */
LocationPointOfInterest.prototype['tenantId'] = undefined;

/**
 * @member {Number} latitude
 */
LocationPointOfInterest.prototype['latitude'] = undefined;

/**
 * @member {Number} longitude
 */
LocationPointOfInterest.prototype['longitude'] = undefined;

/**
 * @member {module:model/PointOfInterest} pointOfInterest
 */
LocationPointOfInterest.prototype['pointOfInterest'] = undefined;

/**
 * @member {module:model/PointOfInterestDistanceDescription} pointOfInterestDistanceDescription
 */
LocationPointOfInterest.prototype['pointOfInterestDistanceDescription'] = undefined;

/**
 * @member {Number} pointOfInterestId
 */
LocationPointOfInterest.prototype['pointOfInterestId'] = undefined;

/**
 * @member {Number} pointOfInterestDistanceDescriptionId
 */
LocationPointOfInterest.prototype['pointOfInterestDistanceDescriptionId'] = undefined;






export default LocationPointOfInterest;

