/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PaymentTypeEnum from './PaymentTypeEnum';
import ReservationContact from './ReservationContact';
import ReservationDiscountClass from './ReservationDiscountClass';
import ReservationFee from './ReservationFee';
import ReservationStatusEnum from './ReservationStatusEnum';
import ReservationSubStatusEnum from './ReservationSubStatusEnum';
import SalesChannelEnum from './SalesChannelEnum';

/**
 * The SpotReservationDetail model module.
 * @module model/SpotReservationDetail
 * @version v1
 */
class SpotReservationDetail {
    /**
     * Constructs a new <code>SpotReservationDetail</code>.
     * @alias module:model/SpotReservationDetail
     */
    constructor() { 
        
        SpotReservationDetail.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SpotReservationDetail</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SpotReservationDetail} obj Optional instance to populate.
     * @return {module:model/SpotReservationDetail} The populated <code>SpotReservationDetail</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SpotReservationDetail();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('locationId')) {
                obj['locationId'] = ApiClient.convertToType(data['locationId'], 'Number');
            }
            if (data.hasOwnProperty('parkName')) {
                obj['parkName'] = ApiClient.convertToType(data['parkName'], 'String');
            }
            if (data.hasOwnProperty('loopName')) {
                obj['loopName'] = ApiClient.convertToType(data['loopName'], 'String');
            }
            if (data.hasOwnProperty('spotName')) {
                obj['spotName'] = ApiClient.convertToType(data['spotName'], 'String');
            }
            if (data.hasOwnProperty('spotId')) {
                obj['spotId'] = ApiClient.convertToType(data['spotId'], 'Number');
            }
            if (data.hasOwnProperty('classification')) {
                obj['classification'] = ApiClient.convertToType(data['classification'], 'String');
            }
            if (data.hasOwnProperty('classificationId')) {
                obj['classificationId'] = ApiClient.convertToType(data['classificationId'], 'Number');
            }
            if (data.hasOwnProperty('productType')) {
                obj['productType'] = ApiClient.convertToType(data['productType'], 'String');
            }
            if (data.hasOwnProperty('bookingType')) {
                obj['bookingType'] = ApiClient.convertToType(data['bookingType'], 'String');
            }
            if (data.hasOwnProperty('transactionDate')) {
                obj['transactionDate'] = ApiClient.convertToType(data['transactionDate'], 'String');
            }
            if (data.hasOwnProperty('primaryOccupant')) {
                obj['primaryOccupant'] = ReservationContact.constructFromObject(data['primaryOccupant']);
            }
            if (data.hasOwnProperty('customerContact')) {
                obj['customerContact'] = ReservationContact.constructFromObject(data['customerContact']);
            }
            if (data.hasOwnProperty('customerId')) {
                obj['customerId'] = ApiClient.convertToType(data['customerId'], 'Number');
            }
            if (data.hasOwnProperty('externalCustomerId')) {
                obj['externalCustomerId'] = ApiClient.convertToType(data['externalCustomerId'], 'Number');
            }
            if (data.hasOwnProperty('lockCode')) {
                obj['lockCode'] = ApiClient.convertToType(data['lockCode'], 'String');
            }
            if (data.hasOwnProperty('primaryOccupantName')) {
                obj['primaryOccupantName'] = ApiClient.convertToType(data['primaryOccupantName'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ReservationStatusEnum.constructFromObject(data['status']);
            }
            if (data.hasOwnProperty('salesChannel')) {
                obj['salesChannel'] = SalesChannelEnum.constructFromObject(data['salesChannel']);
            }
            if (data.hasOwnProperty('reservationNumber')) {
                obj['reservationNumber'] = ApiClient.convertToType(data['reservationNumber'], 'String');
            }
            if (data.hasOwnProperty('orderNumber')) {
                obj['orderNumber'] = ApiClient.convertToType(data['orderNumber'], 'String');
            }
            if (data.hasOwnProperty('arrivalDate')) {
                obj['arrivalDate'] = ApiClient.convertToType(data['arrivalDate'], 'String');
            }
            if (data.hasOwnProperty('departureDate')) {
                obj['departureDate'] = ApiClient.convertToType(data['departureDate'], 'String');
            }
            if (data.hasOwnProperty('numberOfAdults')) {
                obj['numberOfAdults'] = ApiClient.convertToType(data['numberOfAdults'], 'Number');
            }
            if (data.hasOwnProperty('numberOfVehicles')) {
                obj['numberOfVehicles'] = ApiClient.convertToType(data['numberOfVehicles'], 'Number');
            }
            if (data.hasOwnProperty('equipmentType')) {
                obj['equipmentType'] = ApiClient.convertToType(data['equipmentType'], 'String');
            }
            if (data.hasOwnProperty('equipmentLength')) {
                obj['equipmentLength'] = ApiClient.convertToType(data['equipmentLength'], 'Number');
            }
            if (data.hasOwnProperty('checkedInDate')) {
                obj['checkedInDate'] = ApiClient.convertToType(data['checkedInDate'], 'String');
            }
            if (data.hasOwnProperty('checkedOutDate')) {
                obj['checkedOutDate'] = ApiClient.convertToType(data['checkedOutDate'], 'String');
            }
            if (data.hasOwnProperty('customerModifiable')) {
                obj['customerModifiable'] = ApiClient.convertToType(data['customerModifiable'], 'Boolean');
            }
            if (data.hasOwnProperty('walkInReservation')) {
                obj['walkInReservation'] = ApiClient.convertToType(data['walkInReservation'], 'Boolean');
            }
            if (data.hasOwnProperty('subStatus')) {
                obj['subStatus'] = ReservationSubStatusEnum.constructFromObject(data['subStatus']);
            }
            if (data.hasOwnProperty('discounts')) {
                obj['discounts'] = ApiClient.convertToType(data['discounts'], [ReservationDiscountClass]);
            }
            if (data.hasOwnProperty('paymentType')) {
                obj['paymentType'] = PaymentTypeEnum.constructFromObject(data['paymentType']);
            }
            if (data.hasOwnProperty('reservationFees')) {
                obj['reservationFees'] = ApiClient.convertToType(data['reservationFees'], [ReservationFee]);
            }
            if (data.hasOwnProperty('isDayUse')) {
                obj['isDayUse'] = ApiClient.convertToType(data['isDayUse'], 'Boolean');
            }
            if (data.hasOwnProperty('orderHasMultipleReservations')) {
                obj['orderHasMultipleReservations'] = ApiClient.convertToType(data['orderHasMultipleReservations'], 'Boolean');
            }
            if (data.hasOwnProperty('paymentChargebackCompleted')) {
                obj['paymentChargebackCompleted'] = ApiClient.convertToType(data['paymentChargebackCompleted'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
SpotReservationDetail.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
SpotReservationDetail.prototype['tenantId'] = undefined;

/**
 * @member {Number} locationId
 */
SpotReservationDetail.prototype['locationId'] = undefined;

/**
 * @member {String} parkName
 */
SpotReservationDetail.prototype['parkName'] = undefined;

/**
 * @member {String} loopName
 */
SpotReservationDetail.prototype['loopName'] = undefined;

/**
 * @member {String} spotName
 */
SpotReservationDetail.prototype['spotName'] = undefined;

/**
 * @member {Number} spotId
 */
SpotReservationDetail.prototype['spotId'] = undefined;

/**
 * @member {String} classification
 */
SpotReservationDetail.prototype['classification'] = undefined;

/**
 * @member {Number} classificationId
 */
SpotReservationDetail.prototype['classificationId'] = undefined;

/**
 * @member {String} productType
 */
SpotReservationDetail.prototype['productType'] = undefined;

/**
 * @member {String} bookingType
 */
SpotReservationDetail.prototype['bookingType'] = undefined;

/**
 * @member {String} transactionDate
 */
SpotReservationDetail.prototype['transactionDate'] = undefined;

/**
 * @member {module:model/ReservationContact} primaryOccupant
 */
SpotReservationDetail.prototype['primaryOccupant'] = undefined;

/**
 * @member {module:model/ReservationContact} customerContact
 */
SpotReservationDetail.prototype['customerContact'] = undefined;

/**
 * @member {Number} customerId
 */
SpotReservationDetail.prototype['customerId'] = undefined;

/**
 * @member {Number} externalCustomerId
 */
SpotReservationDetail.prototype['externalCustomerId'] = undefined;

/**
 * @member {String} lockCode
 */
SpotReservationDetail.prototype['lockCode'] = undefined;

/**
 * @member {String} primaryOccupantName
 */
SpotReservationDetail.prototype['primaryOccupantName'] = undefined;

/**
 * @member {module:model/ReservationStatusEnum} status
 */
SpotReservationDetail.prototype['status'] = undefined;

/**
 * @member {module:model/SalesChannelEnum} salesChannel
 */
SpotReservationDetail.prototype['salesChannel'] = undefined;

/**
 * @member {String} reservationNumber
 */
SpotReservationDetail.prototype['reservationNumber'] = undefined;

/**
 * @member {String} orderNumber
 */
SpotReservationDetail.prototype['orderNumber'] = undefined;

/**
 * @member {String} arrivalDate
 */
SpotReservationDetail.prototype['arrivalDate'] = undefined;

/**
 * @member {String} departureDate
 */
SpotReservationDetail.prototype['departureDate'] = undefined;

/**
 * @member {Number} numberOfAdults
 */
SpotReservationDetail.prototype['numberOfAdults'] = undefined;

/**
 * @member {Number} numberOfVehicles
 */
SpotReservationDetail.prototype['numberOfVehicles'] = undefined;

/**
 * @member {String} equipmentType
 */
SpotReservationDetail.prototype['equipmentType'] = undefined;

/**
 * @member {Number} equipmentLength
 */
SpotReservationDetail.prototype['equipmentLength'] = undefined;

/**
 * @member {String} checkedInDate
 */
SpotReservationDetail.prototype['checkedInDate'] = undefined;

/**
 * @member {String} checkedOutDate
 */
SpotReservationDetail.prototype['checkedOutDate'] = undefined;

/**
 * @member {Boolean} customerModifiable
 */
SpotReservationDetail.prototype['customerModifiable'] = undefined;

/**
 * @member {Boolean} walkInReservation
 */
SpotReservationDetail.prototype['walkInReservation'] = undefined;

/**
 * @member {module:model/ReservationSubStatusEnum} subStatus
 */
SpotReservationDetail.prototype['subStatus'] = undefined;

/**
 * @member {Array.<module:model/ReservationDiscountClass>} discounts
 */
SpotReservationDetail.prototype['discounts'] = undefined;

/**
 * @member {module:model/PaymentTypeEnum} paymentType
 */
SpotReservationDetail.prototype['paymentType'] = undefined;

/**
 * @member {Array.<module:model/ReservationFee>} reservationFees
 */
SpotReservationDetail.prototype['reservationFees'] = undefined;

/**
 * @member {Boolean} isDayUse
 */
SpotReservationDetail.prototype['isDayUse'] = undefined;

/**
 * @member {Boolean} orderHasMultipleReservations
 */
SpotReservationDetail.prototype['orderHasMultipleReservations'] = undefined;

/**
 * @member {Boolean} paymentChargebackCompleted
 */
SpotReservationDetail.prototype['paymentChargebackCompleted'] = undefined;






export default SpotReservationDetail;

