/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The TenantRegion model module.
 * @module model/TenantRegion
 * @version v1
 */
class TenantRegion {
    /**
     * Constructs a new <code>TenantRegion</code>.
     * @alias module:model/TenantRegion
     */
    constructor() { 
        
        TenantRegion.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>TenantRegion</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/TenantRegion} obj Optional instance to populate.
     * @return {module:model/TenantRegion} The populated <code>TenantRegion</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TenantRegion();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('shortName')) {
                obj['shortName'] = ApiClient.convertToType(data['shortName'], 'String');
            }
            if (data.hasOwnProperty('longName')) {
                obj['longName'] = ApiClient.convertToType(data['longName'], 'String');
            }
            if (data.hasOwnProperty('regionNumber')) {
                obj['regionNumber'] = ApiClient.convertToType(data['regionNumber'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
TenantRegion.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
TenantRegion.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
TenantRegion.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
TenantRegion.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
TenantRegion.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
TenantRegion.prototype['tenantId'] = undefined;

/**
 * @member {String} shortName
 */
TenantRegion.prototype['shortName'] = undefined;

/**
 * @member {String} longName
 */
TenantRegion.prototype['longName'] = undefined;

/**
 * @member {Number} regionNumber
 */
TenantRegion.prototype['regionNumber'] = undefined;






export default TenantRegion;

