/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import SpotAttributeFilter from './SpotAttributeFilter';

/**
 * The LocationAndSpotSearchRequest model module.
 * @module model/LocationAndSpotSearchRequest
 * @version v1
 */
class LocationAndSpotSearchRequest {
    /**
     * Constructs a new <code>LocationAndSpotSearchRequest</code>.
     * @alias module:model/LocationAndSpotSearchRequest
     */
    constructor() { 
        
        LocationAndSpotSearchRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>LocationAndSpotSearchRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/LocationAndSpotSearchRequest} obj Optional instance to populate.
     * @return {module:model/LocationAndSpotSearchRequest} The populated <code>LocationAndSpotSearchRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LocationAndSpotSearchRequest();

            if (data.hasOwnProperty('locationName')) {
                obj['locationName'] = ApiClient.convertToType(data['locationName'], 'String');
            }
            if (data.hasOwnProperty('spotAttributes')) {
                obj['spotAttributes'] = ApiClient.convertToType(data['spotAttributes'], [SpotAttributeFilter]);
            }
            if (data.hasOwnProperty('locationIds')) {
                obj['locationIds'] = ApiClient.convertToType(data['locationIds'], ['Number']);
            }
            if (data.hasOwnProperty('spotTypeIds')) {
                obj['spotTypeIds'] = ApiClient.convertToType(data['spotTypeIds'], ['Number']);
            }
            if (data.hasOwnProperty('regionIds')) {
                obj['regionIds'] = ApiClient.convertToType(data['regionIds'], ['Number']);
            }
            if (data.hasOwnProperty('pointsOfInterestIds')) {
                obj['pointsOfInterestIds'] = ApiClient.convertToType(data['pointsOfInterestIds'], ['Number']);
            }
            if (data.hasOwnProperty('zipcode')) {
                obj['zipcode'] = ApiClient.convertToType(data['zipcode'], 'String');
            }
            if (data.hasOwnProperty('milesFromZipcode')) {
                obj['milesFromZipcode'] = ApiClient.convertToType(data['milesFromZipcode'], 'Number');
            }
            if (data.hasOwnProperty('startDate')) {
                obj['startDate'] = ApiClient.convertToType(data['startDate'], 'Date');
            }
            if (data.hasOwnProperty('endDate')) {
                obj['endDate'] = ApiClient.convertToType(data['endDate'], 'Date');
            }
            if (data.hasOwnProperty('numberOfPeople')) {
                obj['numberOfPeople'] = ApiClient.convertToType(data['numberOfPeople'], 'Number');
            }
            if (data.hasOwnProperty('numberOfVehicles')) {
                obj['numberOfVehicles'] = ApiClient.convertToType(data['numberOfVehicles'], 'Number');
            }
            if (data.hasOwnProperty('vehicleLength')) {
                obj['vehicleLength'] = ApiClient.convertToType(data['vehicleLength'], 'Number');
            }
            if (data.hasOwnProperty('latitude')) {
                obj['latitude'] = ApiClient.convertToType(data['latitude'], 'Number');
            }
            if (data.hasOwnProperty('longitude')) {
                obj['longitude'] = ApiClient.convertToType(data['longitude'], 'Number');
            }
            if (data.hasOwnProperty('milesFromLatLong')) {
                obj['milesFromLatLong'] = ApiClient.convertToType(data['milesFromLatLong'], 'Number');
            }
            if (data.hasOwnProperty('sortByMilesFromLatLong')) {
                obj['sortByMilesFromLatLong'] = ApiClient.convertToType(data['sortByMilesFromLatLong'], 'Boolean');
            }
            if (data.hasOwnProperty('pageNumber')) {
                obj['pageNumber'] = ApiClient.convertToType(data['pageNumber'], 'Number');
            }
            if (data.hasOwnProperty('itemsPerPage')) {
                obj['itemsPerPage'] = ApiClient.convertToType(data['itemsPerPage'], 'Number');
            }
            if (data.hasOwnProperty('includeNonMatchingResults')) {
                obj['includeNonMatchingResults'] = ApiClient.convertToType(data['includeNonMatchingResults'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {String} locationName
 */
LocationAndSpotSearchRequest.prototype['locationName'] = undefined;

/**
 * @member {Array.<module:model/SpotAttributeFilter>} spotAttributes
 */
LocationAndSpotSearchRequest.prototype['spotAttributes'] = undefined;

/**
 * @member {Array.<Number>} locationIds
 */
LocationAndSpotSearchRequest.prototype['locationIds'] = undefined;

/**
 * @member {Array.<Number>} spotTypeIds
 */
LocationAndSpotSearchRequest.prototype['spotTypeIds'] = undefined;

/**
 * @member {Array.<Number>} regionIds
 */
LocationAndSpotSearchRequest.prototype['regionIds'] = undefined;

/**
 * @member {Array.<Number>} pointsOfInterestIds
 */
LocationAndSpotSearchRequest.prototype['pointsOfInterestIds'] = undefined;

/**
 * @member {String} zipcode
 */
LocationAndSpotSearchRequest.prototype['zipcode'] = undefined;

/**
 * @member {Number} milesFromZipcode
 */
LocationAndSpotSearchRequest.prototype['milesFromZipcode'] = undefined;

/**
 * @member {Date} startDate
 */
LocationAndSpotSearchRequest.prototype['startDate'] = undefined;

/**
 * @member {Date} endDate
 */
LocationAndSpotSearchRequest.prototype['endDate'] = undefined;

/**
 * @member {Number} numberOfPeople
 */
LocationAndSpotSearchRequest.prototype['numberOfPeople'] = undefined;

/**
 * @member {Number} numberOfVehicles
 */
LocationAndSpotSearchRequest.prototype['numberOfVehicles'] = undefined;

/**
 * @member {Number} vehicleLength
 */
LocationAndSpotSearchRequest.prototype['vehicleLength'] = undefined;

/**
 * @member {Number} latitude
 */
LocationAndSpotSearchRequest.prototype['latitude'] = undefined;

/**
 * @member {Number} longitude
 */
LocationAndSpotSearchRequest.prototype['longitude'] = undefined;

/**
 * @member {Number} milesFromLatLong
 */
LocationAndSpotSearchRequest.prototype['milesFromLatLong'] = undefined;

/**
 * @member {Boolean} sortByMilesFromLatLong
 */
LocationAndSpotSearchRequest.prototype['sortByMilesFromLatLong'] = undefined;

/**
 * @member {Number} pageNumber
 */
LocationAndSpotSearchRequest.prototype['pageNumber'] = undefined;

/**
 * @member {Number} itemsPerPage
 */
LocationAndSpotSearchRequest.prototype['itemsPerPage'] = undefined;

/**
 * @member {Boolean} includeNonMatchingResults
 */
LocationAndSpotSearchRequest.prototype['includeNonMatchingResults'] = undefined;






export default LocationAndSpotSearchRequest;

