/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AuthTypeEnum from './AuthTypeEnum';
import UserLocation from './UserLocation';
import UserRole from './UserRole';
import UserStatusEnum from './UserStatusEnum';
import UserTypeEnum from './UserTypeEnum';

/**
 * The User model module.
 * @module model/User
 * @version v1
 */
class User {
    /**
     * Constructs a new <code>User</code>.
     * @alias module:model/User
     * @param firstName {String} 
     * @param lastName {String} 
     * @param email {String} 
     */
    constructor(firstName, lastName, email) { 
        
        User.initialize(this, firstName, lastName, email);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, firstName, lastName, email) { 
        obj['firstName'] = firstName;
        obj['lastName'] = lastName;
        obj['email'] = email;
    }

    /**
     * Constructs a <code>User</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/User} obj Optional instance to populate.
     * @return {module:model/User} The populated <code>User</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new User();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('firstName')) {
                obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
            }
            if (data.hasOwnProperty('lastName')) {
                obj['lastName'] = ApiClient.convertToType(data['lastName'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('externalUserId')) {
                obj['externalUserId'] = ApiClient.convertToType(data['externalUserId'], 'Number');
            }
            if (data.hasOwnProperty('verified')) {
                obj['verified'] = ApiClient.convertToType(data['verified'], 'Boolean');
            }
            if (data.hasOwnProperty('locations')) {
                obj['locations'] = ApiClient.convertToType(data['locations'], [UserLocation]);
            }
            if (data.hasOwnProperty('userType')) {
                obj['userType'] = UserTypeEnum.constructFromObject(data['userType']);
            }
            if (data.hasOwnProperty('authType')) {
                obj['authType'] = AuthTypeEnum.constructFromObject(data['authType']);
            }
            if (data.hasOwnProperty('userStatus')) {
                obj['userStatus'] = UserStatusEnum.constructFromObject(data['userStatus']);
            }
            if (data.hasOwnProperty('userRoleId')) {
                obj['userRoleId'] = ApiClient.convertToType(data['userRoleId'], 'Number');
            }
            if (data.hasOwnProperty('role')) {
                obj['role'] = UserRole.constructFromObject(data['role']);
            }
            if (data.hasOwnProperty('externalAgencyId')) {
                obj['externalAgencyId'] = ApiClient.convertToType(data['externalAgencyId'], 'Number');
            }
            if (data.hasOwnProperty('addedToMsp')) {
                obj['addedToMsp'] = ApiClient.convertToType(data['addedToMsp'], 'Boolean');
            }
            if (data.hasOwnProperty('receiveReservationEmails')) {
                obj['receiveReservationEmails'] = ApiClient.convertToType(data['receiveReservationEmails'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
User.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
User.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
User.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
User.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
User.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
User.prototype['tenantId'] = undefined;

/**
 * @member {String} firstName
 */
User.prototype['firstName'] = undefined;

/**
 * @member {String} lastName
 */
User.prototype['lastName'] = undefined;

/**
 * @member {String} email
 */
User.prototype['email'] = undefined;

/**
 * @member {String} username
 */
User.prototype['username'] = undefined;

/**
 * @member {Number} externalUserId
 */
User.prototype['externalUserId'] = undefined;

/**
 * @member {Boolean} verified
 */
User.prototype['verified'] = undefined;

/**
 * @member {Array.<module:model/UserLocation>} locations
 */
User.prototype['locations'] = undefined;

/**
 * @member {module:model/UserTypeEnum} userType
 */
User.prototype['userType'] = undefined;

/**
 * @member {module:model/AuthTypeEnum} authType
 */
User.prototype['authType'] = undefined;

/**
 * @member {module:model/UserStatusEnum} userStatus
 */
User.prototype['userStatus'] = undefined;

/**
 * @member {Number} userRoleId
 */
User.prototype['userRoleId'] = undefined;

/**
 * @member {module:model/UserRole} role
 */
User.prototype['role'] = undefined;

/**
 * @member {Number} externalAgencyId
 */
User.prototype['externalAgencyId'] = undefined;

/**
 * @member {Boolean} addedToMsp
 */
User.prototype['addedToMsp'] = undefined;

/**
 * @member {Boolean} receiveReservationEmails
 */
User.prototype['receiveReservationEmails'] = undefined;






export default User;

