/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Spot from './Spot';

/**
 * The Area model module.
 * @module model/Area
 * @version v1
 */
class Area {
    /**
     * Constructs a new <code>Area</code>.
     * @alias module:model/Area
     */
    constructor() { 
        
        Area.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Area</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Area} obj Optional instance to populate.
     * @return {module:model/Area} The populated <code>Area</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Area();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('locationId')) {
                obj['locationId'] = ApiClient.convertToType(data['locationId'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('code')) {
                obj['code'] = ApiClient.convertToType(data['code'], 'String');
            }
            if (data.hasOwnProperty('elevation')) {
                obj['elevation'] = ApiClient.convertToType(data['elevation'], 'Number');
            }
            if (data.hasOwnProperty('directions')) {
                obj['directions'] = ApiClient.convertToType(data['directions'], 'String');
            }
            if (data.hasOwnProperty('spots')) {
                obj['spots'] = ApiClient.convertToType(data['spots'], [Spot]);
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
Area.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
Area.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
Area.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
Area.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
Area.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
Area.prototype['tenantId'] = undefined;

/**
 * @member {Number} locationId
 */
Area.prototype['locationId'] = undefined;

/**
 * @member {String} name
 */
Area.prototype['name'] = undefined;

/**
 * @member {String} description
 */
Area.prototype['description'] = undefined;

/**
 * @member {String} code
 */
Area.prototype['code'] = undefined;

/**
 * @member {Number} elevation
 */
Area.prototype['elevation'] = undefined;

/**
 * @member {String} directions
 */
Area.prototype['directions'] = undefined;

/**
 * @member {Array.<module:model/Spot>} spots
 */
Area.prototype['spots'] = undefined;






export default Area;

