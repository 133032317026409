/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Merchandise from './Merchandise';
import OrderPayment from './OrderPayment';
import OrderRefund from './OrderRefund';
import OrderSpotReservation from './OrderSpotReservation';
import OrderStatusEnum from './OrderStatusEnum';
import PendingReservationEdit from './PendingReservationEdit';
import SpotReservation from './SpotReservation';
import TransactionType from './TransactionType';

/**
 * The Order model module.
 * @module model/Order
 * @version v1
 */
class Order {
    /**
     * Constructs a new <code>Order</code>.
     * @alias module:model/Order
     */
    constructor() { 
        
        Order.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Order</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Order} obj Optional instance to populate.
     * @return {module:model/Order} The populated <code>Order</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Order();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('orderNumber')) {
                obj['orderNumber'] = ApiClient.convertToType(data['orderNumber'], 'String');
            }
            if (data.hasOwnProperty('customerId')) {
                obj['customerId'] = ApiClient.convertToType(data['customerId'], 'Number');
            }
            if (data.hasOwnProperty('cartId')) {
                obj['cartId'] = ApiClient.convertToType(data['cartId'], 'String');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('totalOrderAmount')) {
                obj['totalOrderAmount'] = ApiClient.convertToType(data['totalOrderAmount'], 'Number');
            }
            if (data.hasOwnProperty('totalAmountPaid')) {
                obj['totalAmountPaid'] = ApiClient.convertToType(data['totalAmountPaid'], 'Number');
            }
            if (data.hasOwnProperty('totalAmountRefunded')) {
                obj['totalAmountRefunded'] = ApiClient.convertToType(data['totalAmountRefunded'], 'Number');
            }
            if (data.hasOwnProperty('emailAddress')) {
                obj['emailAddress'] = ApiClient.convertToType(data['emailAddress'], 'String');
            }
            if (data.hasOwnProperty('orderStatus')) {
                obj['orderStatus'] = OrderStatusEnum.constructFromObject(data['orderStatus']);
            }
            if (data.hasOwnProperty('orderPayments')) {
                obj['orderPayments'] = ApiClient.convertToType(data['orderPayments'], [OrderPayment]);
            }
            if (data.hasOwnProperty('orderRefunds')) {
                obj['orderRefunds'] = ApiClient.convertToType(data['orderRefunds'], [OrderRefund]);
            }
            if (data.hasOwnProperty('spotReservations')) {
                obj['spotReservations'] = ApiClient.convertToType(data['spotReservations'], [SpotReservation]);
            }
            if (data.hasOwnProperty('relatedReservations')) {
                obj['relatedReservations'] = ApiClient.convertToType(data['relatedReservations'], [OrderSpotReservation]);
            }
            if (data.hasOwnProperty('pendingReservationEdits')) {
                obj['pendingReservationEdits'] = ApiClient.convertToType(data['pendingReservationEdits'], [PendingReservationEdit]);
            }
            if (data.hasOwnProperty('merchandise')) {
                obj['merchandise'] = ApiClient.convertToType(data['merchandise'], [Merchandise]);
            }
            if (data.hasOwnProperty('transactionType')) {
                obj['transactionType'] = TransactionType.constructFromObject(data['transactionType']);
            }
            if (data.hasOwnProperty('transactionDate')) {
                obj['transactionDate'] = ApiClient.convertToType(data['transactionDate'], 'Date');
            }
            if (data.hasOwnProperty('transactionTypeId')) {
                obj['transactionTypeId'] = ApiClient.convertToType(data['transactionTypeId'], 'Number');
            }
            if (data.hasOwnProperty('salesChannelId')) {
                obj['salesChannelId'] = ApiClient.convertToType(data['salesChannelId'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
Order.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
Order.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
Order.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
Order.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
Order.prototype['id'] = undefined;

/**
 * @member {String} orderNumber
 */
Order.prototype['orderNumber'] = undefined;

/**
 * @member {Number} customerId
 */
Order.prototype['customerId'] = undefined;

/**
 * @member {String} cartId
 */
Order.prototype['cartId'] = undefined;

/**
 * @member {String} tenantId
 */
Order.prototype['tenantId'] = undefined;

/**
 * @member {Number} totalOrderAmount
 */
Order.prototype['totalOrderAmount'] = undefined;

/**
 * @member {Number} totalAmountPaid
 */
Order.prototype['totalAmountPaid'] = undefined;

/**
 * @member {Number} totalAmountRefunded
 */
Order.prototype['totalAmountRefunded'] = undefined;

/**
 * @member {String} emailAddress
 */
Order.prototype['emailAddress'] = undefined;

/**
 * @member {module:model/OrderStatusEnum} orderStatus
 */
Order.prototype['orderStatus'] = undefined;

/**
 * @member {Array.<module:model/OrderPayment>} orderPayments
 */
Order.prototype['orderPayments'] = undefined;

/**
 * @member {Array.<module:model/OrderRefund>} orderRefunds
 */
Order.prototype['orderRefunds'] = undefined;

/**
 * @member {Array.<module:model/SpotReservation>} spotReservations
 */
Order.prototype['spotReservations'] = undefined;

/**
 * @member {Array.<module:model/OrderSpotReservation>} relatedReservations
 */
Order.prototype['relatedReservations'] = undefined;

/**
 * @member {Array.<module:model/PendingReservationEdit>} pendingReservationEdits
 */
Order.prototype['pendingReservationEdits'] = undefined;

/**
 * @member {Array.<module:model/Merchandise>} merchandise
 */
Order.prototype['merchandise'] = undefined;

/**
 * @member {module:model/TransactionType} transactionType
 */
Order.prototype['transactionType'] = undefined;

/**
 * @member {Date} transactionDate
 */
Order.prototype['transactionDate'] = undefined;

/**
 * @member {Number} transactionTypeId
 */
Order.prototype['transactionTypeId'] = undefined;

/**
 * @member {Number} salesChannelId
 */
Order.prototype['salesChannelId'] = undefined;






export default Order;

