/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AttributesResponse from '../model/AttributesResponse';
import Location from '../model/Location';
import LocationAndSpotSearchRequest from '../model/LocationAndSpotSearchRequest';
import LocationPointsOfInterestResponse from '../model/LocationPointsOfInterestResponse';
import LocationSummaryResponse from '../model/LocationSummaryResponse';
import LookupMinimalModelLookupResponse from '../model/LookupMinimalModelLookupResponse';
import PointsOfInterestResponse from '../model/PointsOfInterestResponse';
import SpotAttributeFilter from '../model/SpotAttributeFilter';

/**
* Location service.
* @module api/LocationApi
* @version v1
*/
export default class LocationApi {

    /**
    * Constructs a new LocationApi. 
    * @alias module:api/LocationApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.isSearchable 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AttributesResponse} and HTTP response
     */
    v1TenantTenantIdLocationAttributesGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdLocationAttributesGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'IsSearchable': opts['isSearchable']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AttributesResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Location/attributes', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.isSearchable 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AttributesResponse}
     */
    v1TenantTenantIdLocationAttributesGet(tenantId, opts) {
      return this.v1TenantTenantIdLocationAttributesGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Gets all featured locations for a given tenant.
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Location>} and HTTP response
     */
    v1TenantTenantIdLocationFeaturedGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdLocationFeaturedGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [Location];
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Location/featured', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Gets all featured locations for a given tenant.
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Location>}
     */
    v1TenantTenantIdLocationFeaturedGet(tenantId) {
      return this.v1TenantTenantIdLocationFeaturedGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Gets all locations for a given tenant.
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Location>} and HTTP response
     */
    v1TenantTenantIdLocationGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdLocationGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [Location];
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Location', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Gets all locations for a given tenant.
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Location>}
     */
    v1TenantTenantIdLocationGet(tenantId) {
      return this.v1TenantTenantIdLocationGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Retreives a single location.
     * @param {Number} locationId ID of location to retreive.
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Location} and HTTP response
     */
    v1TenantTenantIdLocationLocationIdGetWithHttpInfo(locationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'locationId' is set
      if (locationId === undefined || locationId === null) {
        throw new Error("Missing the required parameter 'locationId' when calling v1TenantTenantIdLocationLocationIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdLocationLocationIdGet");
      }

      let pathParams = {
        'locationId': locationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Location;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Location/{locationId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Retreives a single location.
     * @param {Number} locationId ID of location to retreive.
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Location}
     */
    v1TenantTenantIdLocationLocationIdGet(locationId, tenantId) {
      return this.v1TenantTenantIdLocationLocationIdGetWithHttpInfo(locationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Gets all loops for a location.
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LookupMinimalModelLookupResponse} and HTTP response
     */
    v1TenantTenantIdLocationLocationIdLoopGetWithHttpInfo(locationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'locationId' is set
      if (locationId === undefined || locationId === null) {
        throw new Error("Missing the required parameter 'locationId' when calling v1TenantTenantIdLocationLocationIdLoopGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdLocationLocationIdLoopGet");
      }

      let pathParams = {
        'locationId': locationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LookupMinimalModelLookupResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Location/{locationId}/loop', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Gets all loops for a location.
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LookupMinimalModelLookupResponse}
     */
    v1TenantTenantIdLocationLocationIdLoopGet(locationId, tenantId) {
      return this.v1TenantTenantIdLocationLocationIdLoopGetWithHttpInfo(locationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Gets all points of interest for a location.
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LocationPointsOfInterestResponse} and HTTP response
     */
    v1TenantTenantIdLocationLocationIdPointsofinterestGetWithHttpInfo(locationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'locationId' is set
      if (locationId === undefined || locationId === null) {
        throw new Error("Missing the required parameter 'locationId' when calling v1TenantTenantIdLocationLocationIdPointsofinterestGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdLocationLocationIdPointsofinterestGet");
      }

      let pathParams = {
        'locationId': locationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LocationPointsOfInterestResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Location/{locationId}/pointsofinterest', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Gets all points of interest for a location.
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LocationPointsOfInterestResponse}
     */
    v1TenantTenantIdLocationLocationIdPointsofinterestGet(locationId, tenantId) {
      return this.v1TenantTenantIdLocationLocationIdPointsofinterestGetWithHttpInfo(locationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.isSearchable 
     * @param {Boolean} opts.isMappable 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PointsOfInterestResponse} and HTTP response
     */
    v1TenantTenantIdLocationPointsofinterestGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdLocationPointsofinterestGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'IsSearchable': opts['isSearchable'],
        'IsMappable': opts['isMappable']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PointsOfInterestResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Location/pointsofinterest', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.isSearchable 
     * @param {Boolean} opts.isMappable 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PointsOfInterestResponse}
     */
    v1TenantTenantIdLocationPointsofinterestGet(tenantId, opts) {
      return this.v1TenantTenantIdLocationPointsofinterestGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Allows searching for a location based on location, spot, and reservation filters.
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.locationName 
     * @param {Array.<module:model/SpotAttributeFilter>} opts.spotAttributes 
     * @param {Array.<Number>} opts.locationIds 
     * @param {Array.<Number>} opts.spotTypeIds 
     * @param {Array.<Number>} opts.regionIds 
     * @param {Array.<Number>} opts.pointsOfInterestIds 
     * @param {String} opts.zipcode 
     * @param {Number} opts.milesFromZipcode 
     * @param {Date} opts.startDate 
     * @param {Date} opts.endDate 
     * @param {Number} opts.numberOfPeople 
     * @param {Number} opts.numberOfVehicles 
     * @param {Number} opts.vehicleLength 
     * @param {Number} opts.latitude 
     * @param {Number} opts.longitude 
     * @param {Number} opts.milesFromLatLong 
     * @param {Boolean} opts.sortByMilesFromLatLong 
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.itemsPerPage 
     * @param {Boolean} opts.includeNonMatchingResults 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Location>} and HTTP response
     */
    v1TenantTenantIdLocationSearchGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdLocationSearchGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'LocationName': opts['locationName'],
        'SpotAttributes': this.apiClient.buildCollectionParam(opts['spotAttributes'], 'multi'),
        'LocationIds': this.apiClient.buildCollectionParam(opts['locationIds'], 'multi'),
        'SpotTypeIds': this.apiClient.buildCollectionParam(opts['spotTypeIds'], 'multi'),
        'RegionIds': this.apiClient.buildCollectionParam(opts['regionIds'], 'multi'),
        'PointsOfInterestIds': this.apiClient.buildCollectionParam(opts['pointsOfInterestIds'], 'multi'),
        'Zipcode': opts['zipcode'],
        'MilesFromZipcode': opts['milesFromZipcode'],
        'StartDate': opts['startDate'],
        'EndDate': opts['endDate'],
        'NumberOfPeople': opts['numberOfPeople'],
        'NumberOfVehicles': opts['numberOfVehicles'],
        'VehicleLength': opts['vehicleLength'],
        'Latitude': opts['latitude'],
        'Longitude': opts['longitude'],
        'MilesFromLatLong': opts['milesFromLatLong'],
        'SortByMilesFromLatLong': opts['sortByMilesFromLatLong'],
        'PageNumber': opts['pageNumber'],
        'ItemsPerPage': opts['itemsPerPage'],
        'IncludeNonMatchingResults': opts['includeNonMatchingResults']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [Location];
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Location/search', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Allows searching for a location based on location, spot, and reservation filters.
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.locationName 
     * @param {Array.<module:model/SpotAttributeFilter>} opts.spotAttributes 
     * @param {Array.<Number>} opts.locationIds 
     * @param {Array.<Number>} opts.spotTypeIds 
     * @param {Array.<Number>} opts.regionIds 
     * @param {Array.<Number>} opts.pointsOfInterestIds 
     * @param {String} opts.zipcode 
     * @param {Number} opts.milesFromZipcode 
     * @param {Date} opts.startDate 
     * @param {Date} opts.endDate 
     * @param {Number} opts.numberOfPeople 
     * @param {Number} opts.numberOfVehicles 
     * @param {Number} opts.vehicleLength 
     * @param {Number} opts.latitude 
     * @param {Number} opts.longitude 
     * @param {Number} opts.milesFromLatLong 
     * @param {Boolean} opts.sortByMilesFromLatLong 
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.itemsPerPage 
     * @param {Boolean} opts.includeNonMatchingResults 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Location>}
     */
    v1TenantTenantIdLocationSearchGet(tenantId, opts) {
      return this.v1TenantTenantIdLocationSearchGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Retreives the number of spots available at each location that match the given search criteria.
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/LocationAndSpotSearchRequest} opts.locationAndSpotSearchRequest Filters used to search
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LocationSummaryResponse} and HTTP response
     */
    v1TenantTenantIdLocationSearchSummaryItemsPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['locationAndSpotSearchRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdLocationSearchSummaryItemsPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LocationSummaryResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Location/search/summary_items', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Retreives the number of spots available at each location that match the given search criteria.
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/LocationAndSpotSearchRequest} opts.locationAndSpotSearchRequest Filters used to search
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LocationSummaryResponse}
     */
    v1TenantTenantIdLocationSearchSummaryItemsPost(tenantId, opts) {
      return this.v1TenantTenantIdLocationSearchSummaryItemsPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} regionId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Location>} and HTTP response
     */
    v1TenantTenantIdRegionRegionIdLocationsGetWithHttpInfo(regionId, tenantId) {
      let postBody = null;
      // verify the required parameter 'regionId' is set
      if (regionId === undefined || regionId === null) {
        throw new Error("Missing the required parameter 'regionId' when calling v1TenantTenantIdRegionRegionIdLocationsGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdRegionRegionIdLocationsGet");
      }

      let pathParams = {
        'regionId': regionId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [Location];
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/region/{regionId}/locations', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} regionId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Location>}
     */
    v1TenantTenantIdRegionRegionIdLocationsGet(regionId, tenantId) {
      return this.v1TenantTenantIdRegionRegionIdLocationsGetWithHttpInfo(regionId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
