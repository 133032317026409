import CustomerApi from "@/generatedapiclients/src/api/CustomerApi.js";
import AdminSearchApi from "@/generatedapiclients/src/api/AdminSearchApi.js";
import AdminCustomerApi from "@/generatedapiclients/src/api/AdminCustomerApi.js";
import UserApi from "@/generatedapiclients/src/api/UserApi.js";
import store from "../store";
import moment from "moment-timezone";

export default class ProfileService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._customerApi = new CustomerApi();
    this._userApi = new UserApi();
    this._adminSearchApi = new AdminSearchApi();
    this._adminCustomerApi = new AdminCustomerApi();
  }

  async getCurrentReservationsForCustomer(customerId, paging) {
    return this._customerApi.v1TenantTenantIdCustomerCustomerIdReservationsCurrentGet(
      customerId,
      this._tenantId,
      {
        now: moment().format("L"),
        ...paging
      }
    );
  }
  async getPastReservationsForCustomer(customerId, paging) {
    return this._customerApi.v1TenantTenantIdCustomerCustomerIdReservationsPastGet(
      customerId,
      this._tenantId,
      {
        now: moment().format("L"),
        ...paging
      }
    );
  }

  async getReservationDetailForCustomer(customerId, reservationId) {
    return this._customerApi.v1TenantTenantIdCustomerCustomerIdReservationDetailReservationIdGet(
      customerId,
      reservationId,
      this._tenantId
    );
  }

  async sendReservationConfirmationEmail(customerId, reservationId) {
    return this._customerApi.v1TenantTenantIdCustomerCustomerIdSendconfirmationemailReservationIdPost(
      customerId,
      reservationId,
      this._tenantId
    );
  }

  async createCustomerProfile(newCustomerRequest) {
    newCustomerRequest.timezoneId = moment.tz.guess();
    const response = await this._customerApi.v1TenantTenantIdCustomerPost(
      this._tenantId,
      { newCustomerRequest }
    );
    return response;
  }

  async editCustomerProfile(editCustomerRequest) {
    const response = await this._customerApi.v1TenantTenantIdCustomerPut(
      this._tenantId,
      { editCustomerRequest }
    );
    return response;
  }

  async confirmUserRegistration(confirmUserRegistrationRequest) {
    const response = await this._userApi.v1TenantTenantIdUserConfirmUserRegistrationPost(
      this._tenantId,
      { confirmUserRegistrationRequest }
    );
    return response;
  }

  async resendRegistrationCode(userId) {
    await this._userApi.v1TenantTenantIdUserResendRegistrationCodeUserIdPost(
      userId,
      this._tenantId
    );
  }

  async forgotUsername(email) {
    await this._userApi.v1TenantTenantIdUserForgotUsernameEmailPost(
      email,
      this._tenantId
    );
  }

  async forgotPassword(username) {
    const response = await this._userApi.v1TenantTenantIdUserResetPasswordUsernamePost(
      username,
      this._tenantId
    );
    return response.user;
  }

  async forgotPasswordConfirmation(resetPasswordConfirmationRequest) {
    const response = await this._userApi.v1TenantTenantIdUserConfirmResetPasswordPut(
      this._tenantId,
      { resetPasswordConfirmationRequest }
    );
    return response;
  }

  async userVerification(userId) {
    const response = await this._userApi.v1TenantTenantIdUserUserVerificationGet(
      this._tenantId,
      { userId }
    );
    return response.verified;
  }

  async getCustomerByExternalUserId(externalUserId) {
    const response = await this._customerApi.v1TenantTenantIdCustomerUserExternalUserIdExternalUserIdGet(
      externalUserId,
      this._tenantId
    );
    return response;
  }

  async previousCustomerDiscountCheck(customerId, startDate, endDate) {
    const response = await this._customerApi.v1TenantTenantIdCustomerCustomerIdCheckPreviousDiscountsPost(
      customerId,
      this._tenantId,
      {
        customerReservationDiscountsRequest: {
          startDate,
          endDate
        }
      }
    );
    return response;
  }

  isUserCustomer() {
    const userType = store.getters["auth/userType"];
    if (userType === "Customer" || userType === "") {
      return true;
    }
    store.commit("alert/setErrorAlert", {
      type: "alert-danger",
      message:
        "This action requires a customer account. Sign in to a customer account to proceed.",
      layer: "public"
    });
    return false;
  }

  async changeCustomerPassword(userId, currentPassword, newPassword) {
    const response = await this._customerApi.v1TenantTenantIdCustomerChangePasswordPost(
      this._tenantId,
      {
        customerChangePasswordRequest: {
          userId,
          currentPassword,
          newPassword
        }
      }
    );
    return response;
  }
  async checkUsernameAvailability(username) {
    return await this._customerApi.v1TenantTenantIdCustomerUsernameAvailabilityGet(
      this._tenantId,
      {
        username
      }
    );
  }
  async checkEmailAvailability(email) {
    return await this._customerApi.v1TenantTenantIdCustomerEmailAvailabilityGet(
      this._tenantId,
      {
        email
      }
    );
  }
  async doesCustomerQualifyForDiscounts(customerId, customerDiscounts) {
    return await this._customerApi.v1TenantTenantIdCustomerCustomerIdVerifydiscountsPost(
      customerId,
      this._tenantId,
      {
        customerDiscounts: { ...customerDiscounts }
      }
    );
  }

  async getUserByUsername(username) {
    const response = await this._userApi.v1TenantTenantIdUserUsernameUserNameGet(
      username,
      this._tenantId
    );
    return response.user;
  }
}
