/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The SpotHold model module.
 * @module model/SpotHold
 * @version v1
 */
class SpotHold {
    /**
     * Constructs a new <code>SpotHold</code>.
     * @alias module:model/SpotHold
     */
    constructor() { 
        
        SpotHold.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SpotHold</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SpotHold} obj Optional instance to populate.
     * @return {module:model/SpotHold} The populated <code>SpotHold</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SpotHold();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('spotId')) {
                obj['spotId'] = ApiClient.convertToType(data['spotId'], 'Number');
            }
            if (data.hasOwnProperty('holdStartDate')) {
                obj['holdStartDate'] = ApiClient.convertToType(data['holdStartDate'], 'Date');
            }
            if (data.hasOwnProperty('holdEndDate')) {
                obj['holdEndDate'] = ApiClient.convertToType(data['holdEndDate'], 'Date');
            }
            if (data.hasOwnProperty('reservationStartDate')) {
                obj['reservationStartDate'] = ApiClient.convertToType(data['reservationStartDate'], 'Date');
            }
            if (data.hasOwnProperty('reservationEndDate')) {
                obj['reservationEndDate'] = ApiClient.convertToType(data['reservationEndDate'], 'Date');
            }
            if (data.hasOwnProperty('dateReleased')) {
                obj['dateReleased'] = ApiClient.convertToType(data['dateReleased'], 'Date');
            }
            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lockCode')) {
                obj['lockCode'] = ApiClient.convertToType(data['lockCode'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
SpotHold.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
SpotHold.prototype['tenantId'] = undefined;

/**
 * @member {Number} spotId
 */
SpotHold.prototype['spotId'] = undefined;

/**
 * @member {Date} holdStartDate
 */
SpotHold.prototype['holdStartDate'] = undefined;

/**
 * @member {Date} holdEndDate
 */
SpotHold.prototype['holdEndDate'] = undefined;

/**
 * @member {Date} reservationStartDate
 */
SpotHold.prototype['reservationStartDate'] = undefined;

/**
 * @member {Date} reservationEndDate
 */
SpotHold.prototype['reservationEndDate'] = undefined;

/**
 * @member {Date} dateReleased
 */
SpotHold.prototype['dateReleased'] = undefined;

/**
 * @member {Date} dateCreated
 */
SpotHold.prototype['dateCreated'] = undefined;

/**
 * @member {Date} dateLastModified
 */
SpotHold.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lockCode
 */
SpotHold.prototype['lockCode'] = undefined;






export default SpotHold;

