import Vue from "vue";

Vue.filter("formatCurrency", function(value) {
  if (value >= 0) {
    if (typeof value != "number") {
      value = parseFloat(value);
    }

    if (isNaN(value)) {
      value = 0;
    }
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2
    });
  } else {
    if (typeof value != "number") {
      value = parseFloat(value);
    }

    if (isNaN(value)) {
      value = 0;
    }

    var stringValue = (value * -1).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2
    });
    return `(${stringValue})`;
  }
});
