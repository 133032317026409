/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AuthTypeEnum from './AuthTypeEnum';
import SelectedLocations from './SelectedLocations';
import UserStatusEnum from './UserStatusEnum';
import UserTypeEnum from './UserTypeEnum';

/**
 * The AdminUserSearchResult model module.
 * @module model/AdminUserSearchResult
 * @version v1
 */
class AdminUserSearchResult {
    /**
     * Constructs a new <code>AdminUserSearchResult</code>.
     * @alias module:model/AdminUserSearchResult
     */
    constructor() { 
        
        AdminUserSearchResult.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AdminUserSearchResult</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AdminUserSearchResult} obj Optional instance to populate.
     * @return {module:model/AdminUserSearchResult} The populated <code>AdminUserSearchResult</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AdminUserSearchResult();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('firstName')) {
                obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
            }
            if (data.hasOwnProperty('lastName')) {
                obj['lastName'] = ApiClient.convertToType(data['lastName'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('locations')) {
                obj['locations'] = ApiClient.convertToType(data['locations'], [SelectedLocations]);
            }
            if (data.hasOwnProperty('userTypeId')) {
                obj['userTypeId'] = ApiClient.convertToType(data['userTypeId'], 'Number');
            }
            if (data.hasOwnProperty('userTypeName')) {
                obj['userTypeName'] = UserTypeEnum.constructFromObject(data['userTypeName']);
            }
            if (data.hasOwnProperty('authTypeId')) {
                obj['authTypeId'] = ApiClient.convertToType(data['authTypeId'], 'Number');
            }
            if (data.hasOwnProperty('authTypeName')) {
                obj['authTypeName'] = AuthTypeEnum.constructFromObject(data['authTypeName']);
            }
            if (data.hasOwnProperty('userStatusId')) {
                obj['userStatusId'] = ApiClient.convertToType(data['userStatusId'], 'Number');
            }
            if (data.hasOwnProperty('userStatusName')) {
                obj['userStatusName'] = UserStatusEnum.constructFromObject(data['userStatusName']);
            }
            if (data.hasOwnProperty('userRoleId')) {
                obj['userRoleId'] = ApiClient.convertToType(data['userRoleId'], 'Number');
            }
            if (data.hasOwnProperty('userRoleName')) {
                obj['userRoleName'] = ApiClient.convertToType(data['userRoleName'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
AdminUserSearchResult.prototype['id'] = undefined;

/**
 * @member {String} firstName
 */
AdminUserSearchResult.prototype['firstName'] = undefined;

/**
 * @member {String} lastName
 */
AdminUserSearchResult.prototype['lastName'] = undefined;

/**
 * @member {String} email
 */
AdminUserSearchResult.prototype['email'] = undefined;

/**
 * @member {String} username
 */
AdminUserSearchResult.prototype['username'] = undefined;

/**
 * @member {Array.<module:model/SelectedLocations>} locations
 */
AdminUserSearchResult.prototype['locations'] = undefined;

/**
 * @member {Number} userTypeId
 */
AdminUserSearchResult.prototype['userTypeId'] = undefined;

/**
 * @member {module:model/UserTypeEnum} userTypeName
 */
AdminUserSearchResult.prototype['userTypeName'] = undefined;

/**
 * @member {Number} authTypeId
 */
AdminUserSearchResult.prototype['authTypeId'] = undefined;

/**
 * @member {module:model/AuthTypeEnum} authTypeName
 */
AdminUserSearchResult.prototype['authTypeName'] = undefined;

/**
 * @member {Number} userStatusId
 */
AdminUserSearchResult.prototype['userStatusId'] = undefined;

/**
 * @member {module:model/UserStatusEnum} userStatusName
 */
AdminUserSearchResult.prototype['userStatusName'] = undefined;

/**
 * @member {Number} userRoleId
 */
AdminUserSearchResult.prototype['userRoleId'] = undefined;

/**
 * @member {String} userRoleName
 */
AdminUserSearchResult.prototype['userRoleName'] = undefined;






export default AdminUserSearchResult;

