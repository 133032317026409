/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AttributeSelectedOption from './AttributeSelectedOption';

/**
 * The SpotAttribute model module.
 * @module model/SpotAttribute
 * @version v1
 */
class SpotAttribute {
    /**
     * Constructs a new <code>SpotAttribute</code>.
     * @alias module:model/SpotAttribute
     */
    constructor() { 
        
        SpotAttribute.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SpotAttribute</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SpotAttribute} obj Optional instance to populate.
     * @return {module:model/SpotAttribute} The populated <code>SpotAttribute</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SpotAttribute();

            if (data.hasOwnProperty('tenantAttributeId')) {
                obj['tenantAttributeId'] = ApiClient.convertToType(data['tenantAttributeId'], 'Number');
            }
            if (data.hasOwnProperty('globalAttributeId')) {
                obj['globalAttributeId'] = ApiClient.convertToType(data['globalAttributeId'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('icon')) {
                obj['icon'] = ApiClient.convertToType(data['icon'], 'String');
            }
            if (data.hasOwnProperty('attributeCategoryId')) {
                obj['attributeCategoryId'] = ApiClient.convertToType(data['attributeCategoryId'], 'String');
            }
            if (data.hasOwnProperty('attributeCategoryName')) {
                obj['attributeCategoryName'] = ApiClient.convertToType(data['attributeCategoryName'], 'String');
            }
            if (data.hasOwnProperty('isSearchable')) {
                obj['isSearchable'] = ApiClient.convertToType(data['isSearchable'], 'Boolean');
            }
            if (data.hasOwnProperty('showIcon')) {
                obj['showIcon'] = ApiClient.convertToType(data['showIcon'], 'Boolean');
            }
            if (data.hasOwnProperty('featured')) {
                obj['featured'] = ApiClient.convertToType(data['featured'], 'Boolean');
            }
            if (data.hasOwnProperty('value')) {
                obj['value'] = ApiClient.convertToType(data['value'], 'String');
            }
            if (data.hasOwnProperty('isOverridden')) {
                obj['isOverridden'] = ApiClient.convertToType(data['isOverridden'], 'Boolean');
            }
            if (data.hasOwnProperty('hasNoValue')) {
                obj['hasNoValue'] = ApiClient.convertToType(data['hasNoValue'], 'Boolean');
            }
            if (data.hasOwnProperty('attributeSelectedOptions')) {
                obj['attributeSelectedOptions'] = ApiClient.convertToType(data['attributeSelectedOptions'], [AttributeSelectedOption]);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} tenantAttributeId
 */
SpotAttribute.prototype['tenantAttributeId'] = undefined;

/**
 * @member {Number} globalAttributeId
 */
SpotAttribute.prototype['globalAttributeId'] = undefined;

/**
 * @member {String} name
 */
SpotAttribute.prototype['name'] = undefined;

/**
 * @member {String} description
 */
SpotAttribute.prototype['description'] = undefined;

/**
 * @member {String} icon
 */
SpotAttribute.prototype['icon'] = undefined;

/**
 * @member {String} attributeCategoryId
 */
SpotAttribute.prototype['attributeCategoryId'] = undefined;

/**
 * @member {String} attributeCategoryName
 */
SpotAttribute.prototype['attributeCategoryName'] = undefined;

/**
 * @member {Boolean} isSearchable
 */
SpotAttribute.prototype['isSearchable'] = undefined;

/**
 * @member {Boolean} showIcon
 */
SpotAttribute.prototype['showIcon'] = undefined;

/**
 * @member {Boolean} featured
 */
SpotAttribute.prototype['featured'] = undefined;

/**
 * @member {String} value
 */
SpotAttribute.prototype['value'] = undefined;

/**
 * @member {Boolean} isOverridden
 */
SpotAttribute.prototype['isOverridden'] = undefined;

/**
 * @member {Boolean} hasNoValue
 */
SpotAttribute.prototype['hasNoValue'] = undefined;

/**
 * @member {Array.<module:model/AttributeSelectedOption>} attributeSelectedOptions
 */
SpotAttribute.prototype['attributeSelectedOptions'] = undefined;






export default SpotAttribute;

