import { extend } from "vee-validate";
import {
  required,
  required_if,
  email,
  min,
  max,
  confirmed,
  numeric,
  between,
  alpha_num
} from "vee-validate/dist/rules";
import moment from "moment";

// included rules with custom messages
extend("required", {
  ...required,
  message: "{_field_} is required"
});
extend("required_if", {
  ...required_if,
  message: "{_field_} is required if {target} is not selected"
});
extend("email", { ...email, message: "{_field_} must be a valid email" });
extend("confirmed", {
  ...confirmed,
  message: "{_field_} must match the {target} field"
});
extend("min", {
  ...min,
  message: "{_field_} must be at least {length} characters"
});
extend("max", {
  ...max,
  message: "{_field_} must not exceed {length} characters"
});
extend("numeric", {
  ...numeric,
  message: "{_field_} must only contain numbers"
});
extend("alpha_num", {
  ...alpha_num,
  message: "{_field_} must only contain alphanumeric characters"
});
extend("between", {
  ...between,
  message: "{_field_} must be between {min} and {max}"
});

// custom validation
extend("alphaNumSpaces", {
  validate: value => {
    value = value.replace(/<[^>]+>/g, ""); //Strip HTML from Rich Text
    return value.match(/^[A-Za-z0-9\s]+$/);
  },
  message: "{_field_} only allows numbers, letters, and spaces"
});
extend("username", {
  validate: value => {
    return value.match(/^[A-Za-z0-9-_=.]+$/);
  },
  message:
    "Username allows letters, numbers, and the following special characters: - _ = ."
});
extend("atLeastOneLowercase", {
  validate: value => {
    return value.match(/(?=.*[a-z])/);
  },
  message: "Password must contain at least one lowercase letter"
});
extend("atLeastOneUppercase", {
  validate: value => {
    return value.match(/(?=.*[A-Z])/);
  },
  message: "Password must contain at least one uppercase letter"
});
extend("atLeastOneNumber", {
  validate: value => {
    return value.match(/(?=.*\d)/);
  },
  message: "Password must contain at least one number"
});
extend("atLeastOneSpecialCharacter", {
  validate: value => {
    return value.match(/(?=.*[$*.{}()?"!@#%&/,><':;|_~`^[\]\-\\])/);
  },
  message:
    "Password must contain at least one of the following special characters: ^ $ * . [ ] { } ( ) ? \" ! @ # % & / \\ , > < ' : ; | _ ~ `"
});
extend("uniquePassword", {
  params: ["target"],
  validate: (value, { target }) => {
    return value !== target;
  },
  message: "Password must not match username"
});
extend("requiredOr", {
  params: ["target"],
  validate: (value, { target }) => {
    return {
      required: true,
      valid:
        ["", null, undefined].indexOf(value) === -1 ||
        ["", null, undefined].indexOf(target) === -1
    };
  },
  computesRequired: true,
  message: "{_field_} is required if {target} is blank"
});
extend("arrivalDate", {
  validate: value => {
    return moment().isSameOrBefore(moment(value, "MM-DD-YYYY"), "day");
  },
  message: "{_field_} must be greater than or equal to today"
});
extend("departureDate", {
  params: ["target"],
  validate: (value, { target }) => {
    if (target && target != "") {
      return moment(target, "MM-DD-YYYY").isBefore(
        moment(value, "MM-DD-YYYY"),
        "day"
      );
    } else {
      return true;
    }
  },
  message: "{_field_} must be greater than arrival date"
});
extend("betweenDates", {
  params: ["target1", "target2"],
  validate: (value, { target1, target2 }) => {
    return moment(value, "MM/DD/YYYY").isBetween(
      moment(target1, "MM/DD/YYYY"),
      moment(target2, "MM/DD/YYYY"),
      "days",
      "[]"
    );
  },
  message: "{_field_} must be between {target1} and {target2}"
});
extend("departureDateMinusOne", {
  params: ["target"],
  validate: (value, { target }) => {
    if (target && target != "") {
      return moment(target, "MM-DD-YYYY").isSameOrBefore(
        moment(value, "MM-DD-YYYY"),
        "day"
      );
    } else {
      return true;
    }
  },
  message: "{_field_} must be greater than arrival date"
});
extend("arrivalDateWithinDays", {
  params: ["target", "days"],
  validate: (value, { target, days }) => {
    if (target && target != "") {
      return moment(value, "MM-DD-YYYY").isSameOrBefore(
        moment(target, "MM-DD-YYYY").add(days, "days"),
        "day"
      );
    } else {
      return true;
    }
  },
  message: "{_field_} must be within {days} days"
});
extend("isDate", {
  validate: value => {
    return (
      !!value &&
      value.match(
        /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/
      )
    );
  },
  message: "{_field_} must be formatted as 'MM/DD/YYYY'"
});
extend("isMonthDay", {
  validate: value => {
    return (
      !!value && value.match(/(0[1-9]|1[012])[/.](0[1-9]|[12][0-9]|3[01])/)
    );
  },
  message: "{_field_} must be formatted as 'MM/DD'"
});
extend("isTodayOrPrior", {
  validate: value => {
    return moment().isSameOrAfter(moment(value, "MM-DD-YYYY"), "day");
  },
  message: "{_field_} must be today or prior to today"
});
extend("maskNumMin", {
  params: ["length"],
  validate: (value, { length }) => {
    let newVal = value.replace(/[^\d]/g, "");
    return newVal.length >= length;
  },
  message: "{_field_} must be greater than {length} characters"
});
extend("address", {
  validate: value => {
    return value.match(/^[A-Za-z0-9'.,\-\s]+$/);
  },
  message:
    "{_field_} must only contain alphanumeric characters, spaces, or the following special characters: ' . - ,"
});
extend("city", {
  validate: value => {
    return value.match(/^[A-Za-z'.,\-\s]+$/);
  },
  message:
    "{_field_} must only contain alphabetic characters, spaces, or the following special characters: ' . - ,"
});
extend("customerName", {
  validate: value => {
    return value.match(/^[A-Za-z'~\-\s]+$/);
  },
  message:
    "{_field_} must only contain alphabetic characters, spaces, or the following special characters: ' - ~"
});
extend("zipCode", {
  validate: value => {
    return value.match(/^[0-9]{5}(?:-[0-9]{4})?$/);
  },
  message: "{_field_} must either be a 5 or 9 digit zip code"
});
extend("minNumber", {
  params: ["minimum"],
  validate: (value, { minimum }) => {
    if (!isNaN(value)) {
      return +value >= +minimum;
    } else {
      return false;
    }
  },
  message: "{_field_} must be greater than or equal to {minimum}"
});
extend("maxNumber", {
  params: ["maximum"],
  validate: (value, { maximum }) => {
    if (!isNaN(value)) {
      return +value <= +maximum;
    } else {
      return false;
    }
  },
  message: "{_field_} must be less than or equal to {maximum}"
});
extend("accountingCodeSuffix", {
  validate: value => {
    return value.match(/^[A-Za-z0-9#/-\s]+$/);
  },
  message:
    "{_field_} must only contain letters, numbers, or the following special characters: # / -"
});
extend("alpha_num_special", {
  validate: value => {
    value = value.replace(/<[^>]+>/g, ""); //Strip HTML from Rich Text
    return value.match(/^[A-Za-z0-9:,./[\]{}()\-#'"%$*?+!&™\s]+$/);
  },
  message:
    "{_field_} must only contain letters, numbers, or the following special characters: : , . / [ ] { } ( ) - # ' \" % $ * ? + ! (Space) &"
});
extend("tenantSiteName", {
  validate: value => {
    return value.match(/^[A-Za-z0-9-,.'\s]+$/);
  },
  message:
    "{_field_} must only contain letters, numbers, or the following special characters: - , . ' (Space)"
});
extend("decimal", {
  validate: value => {
    return value.toString().match(/^[0-9-.]+$/);
  },
  message: "{_field_} must contain a decimal"
});
extend("time", {
  validate: value => {
    return value
      .toString()
      .match(/\b((1[0-2]|0?[1-9]):([0-5][0-9]) ([AaPp][Mm]))/);
  },
  message: "{_field_} must be time formatted HH:MM AM/PM"
});
extend("exclusiveNoValue", {
  validate: value => {
    return (
      value.length == 1 || value.filter(item => item.id == -99).length == 0
    );
  },
  message: "{_field_} cannot contain 'No Value' with other options"
});
extend("phone", {
  validate: value => {
    return value.match(
      /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/
    );
  },
  message: "{_field_} must be a phone number."
});
extend("image", {
  validate: value => {
    return value.match(
      /^.+\.(jpg|JPG|png|PNG)$/
    );
  },
  message: "{_field_} must be a JPG or PNG."
});
