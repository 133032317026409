/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AuthConfig from './AuthConfig';
import ResponseStatusEnum from './ResponseStatusEnum';
import Tenant from './Tenant';

/**
 * The TenantResponse model module.
 * @module model/TenantResponse
 * @version v1
 */
class TenantResponse {
    /**
     * Constructs a new <code>TenantResponse</code>.
     * @alias module:model/TenantResponse
     */
    constructor() { 
        
        TenantResponse.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>TenantResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/TenantResponse} obj Optional instance to populate.
     * @return {module:model/TenantResponse} The populated <code>TenantResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TenantResponse();

            if (data.hasOwnProperty('statusCode')) {
                obj['statusCode'] = ResponseStatusEnum.constructFromObject(data['statusCode']);
            }
            if (data.hasOwnProperty('statusMessage')) {
                obj['statusMessage'] = ApiClient.convertToType(data['statusMessage'], 'String');
            }
            if (data.hasOwnProperty('messages')) {
                obj['messages'] = ApiClient.convertToType(data['messages'], ['String']);
            }
            if (data.hasOwnProperty('tenant')) {
                obj['tenant'] = Tenant.constructFromObject(data['tenant']);
            }
            if (data.hasOwnProperty('authConfig')) {
                obj['authConfig'] = AuthConfig.constructFromObject(data['authConfig']);
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/ResponseStatusEnum} statusCode
 */
TenantResponse.prototype['statusCode'] = undefined;

/**
 * @member {String} statusMessage
 */
TenantResponse.prototype['statusMessage'] = undefined;

/**
 * @member {Array.<String>} messages
 */
TenantResponse.prototype['messages'] = undefined;

/**
 * @member {module:model/Tenant} tenant
 */
TenantResponse.prototype['tenant'] = undefined;

/**
 * @member {module:model/AuthConfig} authConfig
 */
TenantResponse.prototype['authConfig'] = undefined;






export default TenantResponse;

