/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AccountingCode from './AccountingCode';
import TenantExternalLink from './TenantExternalLink';
import TenantPolicy from './TenantPolicy';
import TenantSupportInfo from './TenantSupportInfo';

/**
 * The Tenant model module.
 * @module model/Tenant
 * @version v1
 */
class Tenant {
    /**
     * Constructs a new <code>Tenant</code>.
     * @alias module:model/Tenant
     */
    constructor() { 
        
        Tenant.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Tenant</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Tenant} obj Optional instance to populate.
     * @return {module:model/Tenant} The populated <code>Tenant</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Tenant();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('stateName')) {
                obj['stateName'] = ApiClient.convertToType(data['stateName'], 'String');
            }
            if (data.hasOwnProperty('departmentName')) {
                obj['departmentName'] = ApiClient.convertToType(data['departmentName'], 'String');
            }
            if (data.hasOwnProperty('siteName')) {
                obj['siteName'] = ApiClient.convertToType(data['siteName'], 'String');
            }
            if (data.hasOwnProperty('siteTitle')) {
                obj['siteTitle'] = ApiClient.convertToType(data['siteTitle'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('departmentWebsiteUrl')) {
                obj['departmentWebsiteUrl'] = ApiClient.convertToType(data['departmentWebsiteUrl'], 'String');
            }
            if (data.hasOwnProperty('stateLogoUrl')) {
                obj['stateLogoUrl'] = ApiClient.convertToType(data['stateLogoUrl'], 'String');
            }
            if (data.hasOwnProperty('departmentLogoUrl')) {
                obj['departmentLogoUrl'] = ApiClient.convertToType(data['departmentLogoUrl'], 'String');
            }
            if (data.hasOwnProperty('websiteLogoUrl')) {
                obj['websiteLogoUrl'] = ApiClient.convertToType(data['websiteLogoUrl'], 'String');
            }
            if (data.hasOwnProperty('bannerImageUrl')) {
                obj['bannerImageUrl'] = ApiClient.convertToType(data['bannerImageUrl'], 'String');
            }
            if (data.hasOwnProperty('fiscalYearStartDate')) {
                obj['fiscalYearStartDate'] = ApiClient.convertToType(data['fiscalYearStartDate'], 'Date');
            }
            if (data.hasOwnProperty('defaultLocationImageUrl')) {
                obj['defaultLocationImageUrl'] = ApiClient.convertToType(data['defaultLocationImageUrl'], 'String');
            }
            if (data.hasOwnProperty('defaultProductImageUrl')) {
                obj['defaultProductImageUrl'] = ApiClient.convertToType(data['defaultProductImageUrl'], 'String');
            }
            if (data.hasOwnProperty('facebookUrl')) {
                obj['facebookUrl'] = ApiClient.convertToType(data['facebookUrl'], 'String');
            }
            if (data.hasOwnProperty('instagramUrl')) {
                obj['instagramUrl'] = ApiClient.convertToType(data['instagramUrl'], 'String');
            }
            if (data.hasOwnProperty('twitterUrl')) {
                obj['twitterUrl'] = ApiClient.convertToType(data['twitterUrl'], 'String');
            }
            if (data.hasOwnProperty('pinterestUrl')) {
                obj['pinterestUrl'] = ApiClient.convertToType(data['pinterestUrl'], 'String');
            }
            if (data.hasOwnProperty('externalLinks')) {
                obj['externalLinks'] = ApiClient.convertToType(data['externalLinks'], [TenantExternalLink]);
            }
            if (data.hasOwnProperty('policies')) {
                obj['policies'] = ApiClient.convertToType(data['policies'], [TenantPolicy]);
            }
            if (data.hasOwnProperty('supportInfo')) {
                obj['supportInfo'] = TenantSupportInfo.constructFromObject(data['supportInfo']);
            }
            if (data.hasOwnProperty('defaultAccountingCode')) {
                obj['defaultAccountingCode'] = AccountingCode.constructFromObject(data['defaultAccountingCode']);
            }
            if (data.hasOwnProperty('latitude')) {
                obj['latitude'] = ApiClient.convertToType(data['latitude'], 'Number');
            }
            if (data.hasOwnProperty('longitude')) {
                obj['longitude'] = ApiClient.convertToType(data['longitude'], 'Number');
            }
            if (data.hasOwnProperty('mapSearchStateFilter')) {
                obj['mapSearchStateFilter'] = ApiClient.convertToType(data['mapSearchStateFilter'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('tagline')) {
                obj['tagline'] = ApiClient.convertToType(data['tagline'], 'String');
            }
            if (data.hasOwnProperty('searchBackgroundImageUrl')) {
                obj['searchBackgroundImageUrl'] = ApiClient.convertToType(data['searchBackgroundImageUrl'], 'String');
            }
            if (data.hasOwnProperty('adminOnlineHelpUrl')) {
                obj['adminOnlineHelpUrl'] = ApiClient.convertToType(data['adminOnlineHelpUrl'], 'String');
            }
            if (data.hasOwnProperty('customerOnlineHelpUrl')) {
                obj['customerOnlineHelpUrl'] = ApiClient.convertToType(data['customerOnlineHelpUrl'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
Tenant.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
Tenant.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
Tenant.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
Tenant.prototype['lastModifiedBy'] = undefined;

/**
 * @member {String} id
 */
Tenant.prototype['id'] = undefined;

/**
 * @member {String} stateName
 */
Tenant.prototype['stateName'] = undefined;

/**
 * @member {String} departmentName
 */
Tenant.prototype['departmentName'] = undefined;

/**
 * @member {String} siteName
 */
Tenant.prototype['siteName'] = undefined;

/**
 * @member {String} siteTitle
 */
Tenant.prototype['siteTitle'] = undefined;

/**
 * @member {String} description
 */
Tenant.prototype['description'] = undefined;

/**
 * @member {String} departmentWebsiteUrl
 */
Tenant.prototype['departmentWebsiteUrl'] = undefined;

/**
 * @member {String} stateLogoUrl
 */
Tenant.prototype['stateLogoUrl'] = undefined;

/**
 * @member {String} departmentLogoUrl
 */
Tenant.prototype['departmentLogoUrl'] = undefined;

/**
 * @member {String} websiteLogoUrl
 */
Tenant.prototype['websiteLogoUrl'] = undefined;

/**
 * @member {String} bannerImageUrl
 */
Tenant.prototype['bannerImageUrl'] = undefined;

/**
 * @member {Date} fiscalYearStartDate
 */
Tenant.prototype['fiscalYearStartDate'] = undefined;

/**
 * @member {String} defaultLocationImageUrl
 */
Tenant.prototype['defaultLocationImageUrl'] = undefined;

/**
 * @member {String} defaultProductImageUrl
 */
Tenant.prototype['defaultProductImageUrl'] = undefined;

/**
 * @member {String} facebookUrl
 */
Tenant.prototype['facebookUrl'] = undefined;

/**
 * @member {String} instagramUrl
 */
Tenant.prototype['instagramUrl'] = undefined;

/**
 * @member {String} twitterUrl
 */
Tenant.prototype['twitterUrl'] = undefined;

/**
 * @member {String} pinterestUrl
 */
Tenant.prototype['pinterestUrl'] = undefined;

/**
 * @member {Array.<module:model/TenantExternalLink>} externalLinks
 */
Tenant.prototype['externalLinks'] = undefined;

/**
 * @member {Array.<module:model/TenantPolicy>} policies
 */
Tenant.prototype['policies'] = undefined;

/**
 * @member {module:model/TenantSupportInfo} supportInfo
 */
Tenant.prototype['supportInfo'] = undefined;

/**
 * @member {module:model/AccountingCode} defaultAccountingCode
 */
Tenant.prototype['defaultAccountingCode'] = undefined;

/**
 * @member {Number} latitude
 */
Tenant.prototype['latitude'] = undefined;

/**
 * @member {Number} longitude
 */
Tenant.prototype['longitude'] = undefined;

/**
 * @member {String} mapSearchStateFilter
 */
Tenant.prototype['mapSearchStateFilter'] = undefined;

/**
 * @member {String} name
 */
Tenant.prototype['name'] = undefined;

/**
 * @member {String} tagline
 */
Tenant.prototype['tagline'] = undefined;

/**
 * @member {String} searchBackgroundImageUrl
 */
Tenant.prototype['searchBackgroundImageUrl'] = undefined;

/**
 * @member {String} adminOnlineHelpUrl
 */
Tenant.prototype['adminOnlineHelpUrl'] = undefined;

/**
 * @member {String} customerOnlineHelpUrl
 */
Tenant.prototype['customerOnlineHelpUrl'] = undefined;






export default Tenant;

