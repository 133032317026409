import TenantApi from "@/generatedapiclients/src/api/TenantApi";

const state = {
  tenantId: "",
  tenantInfo: {},
  tenantLoaded: false,
  amplifyConfig: {}
};
const getters = {
  tenantId: state => state.tenantId,
  tenantInfo: state => state.tenantInfo,
  amplifyConfig: state => state.amplifyConfig
};
const actions = {
  async updateTenantInfo({ commit }, tenantId) {
    commit("setTenantId", tenantId);
    const api = new TenantApi();
    const tenantResp = await api.v1TenantTenantIdTenantGet(tenantId);
    commit("setTenantInfo", tenantResp.tenant);
    commit("setAmplifyConfig", tenantResp.authConfig);
  },
  clear({ commit }) {
    commit("setTenantId", "");
    commit("setTenantInfo", {});
  }
};
const mutations = {
  setTenantId(state, tenantId) {
    state.tenantId = tenantId;
  },
  setTenantInfo(state, tenantInfo) {
    state.tenantInfo = tenantInfo;
    state.tenantLoaded = true;
  },
  setTenantLoaded(state, isLoaded) {
    state.tenantLoaded = isLoaded;
  },
  setAmplifyConfig(state, config) {
    state.amplifyConfig = config;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
