/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CustomerSearchResult model module.
 * @module model/CustomerSearchResult
 * @version v1
 */
class CustomerSearchResult {
    /**
     * Constructs a new <code>CustomerSearchResult</code>.
     * @alias module:model/CustomerSearchResult
     */
    constructor() { 
        
        CustomerSearchResult.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CustomerSearchResult</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CustomerSearchResult} obj Optional instance to populate.
     * @return {module:model/CustomerSearchResult} The populated <code>CustomerSearchResult</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CustomerSearchResult();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('firstName')) {
                obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
            }
            if (data.hasOwnProperty('lastName')) {
                obj['lastName'] = ApiClient.convertToType(data['lastName'], 'String');
            }
            if (data.hasOwnProperty('emailAddress')) {
                obj['emailAddress'] = ApiClient.convertToType(data['emailAddress'], 'String');
            }
            if (data.hasOwnProperty('street1')) {
                obj['street1'] = ApiClient.convertToType(data['street1'], 'String');
            }
            if (data.hasOwnProperty('street2')) {
                obj['street2'] = ApiClient.convertToType(data['street2'], 'String');
            }
            if (data.hasOwnProperty('city')) {
                obj['city'] = ApiClient.convertToType(data['city'], 'String');
            }
            if (data.hasOwnProperty('state')) {
                obj['state'] = ApiClient.convertToType(data['state'], 'String');
            }
            if (data.hasOwnProperty('zip')) {
                obj['zip'] = ApiClient.convertToType(data['zip'], 'String');
            }
            if (data.hasOwnProperty('country')) {
                obj['country'] = ApiClient.convertToType(data['country'], 'String');
            }
            if (data.hasOwnProperty('phone')) {
                obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
            }
            if (data.hasOwnProperty('comments')) {
                obj['comments'] = ApiClient.convertToType(data['comments'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
CustomerSearchResult.prototype['id'] = undefined;

/**
 * @member {String} firstName
 */
CustomerSearchResult.prototype['firstName'] = undefined;

/**
 * @member {String} lastName
 */
CustomerSearchResult.prototype['lastName'] = undefined;

/**
 * @member {String} emailAddress
 */
CustomerSearchResult.prototype['emailAddress'] = undefined;

/**
 * @member {String} street1
 */
CustomerSearchResult.prototype['street1'] = undefined;

/**
 * @member {String} street2
 */
CustomerSearchResult.prototype['street2'] = undefined;

/**
 * @member {String} city
 */
CustomerSearchResult.prototype['city'] = undefined;

/**
 * @member {String} state
 */
CustomerSearchResult.prototype['state'] = undefined;

/**
 * @member {String} zip
 */
CustomerSearchResult.prototype['zip'] = undefined;

/**
 * @member {String} country
 */
CustomerSearchResult.prototype['country'] = undefined;

/**
 * @member {String} phone
 */
CustomerSearchResult.prototype['phone'] = undefined;

/**
 * @member {String} comments
 */
CustomerSearchResult.prototype['comments'] = undefined;






export default CustomerSearchResult;

