/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The RefundReceiptItem model module.
 * @module model/RefundReceiptItem
 * @version v1
 */
class RefundReceiptItem {
    /**
     * Constructs a new <code>RefundReceiptItem</code>.
     * @alias module:model/RefundReceiptItem
     */
    constructor() { 
        
        RefundReceiptItem.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>RefundReceiptItem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/RefundReceiptItem} obj Optional instance to populate.
     * @return {module:model/RefundReceiptItem} The populated <code>RefundReceiptItem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RefundReceiptItem();

            if (data.hasOwnProperty('paymentType')) {
                obj['paymentType'] = ApiClient.convertToType(data['paymentType'], 'String');
            }
            if (data.hasOwnProperty('cardHolderName')) {
                obj['cardHolderName'] = ApiClient.convertToType(data['cardHolderName'], 'String');
            }
            if (data.hasOwnProperty('cardHolderNameLabel')) {
                obj['cardHolderNameLabel'] = ApiClient.convertToType(data['cardHolderNameLabel'], 'String');
            }
            if (data.hasOwnProperty('lastFour')) {
                obj['lastFour'] = ApiClient.convertToType(data['lastFour'], 'String');
            }
            if (data.hasOwnProperty('lastFourLabel')) {
                obj['lastFourLabel'] = ApiClient.convertToType(data['lastFourLabel'], 'String');
            }
            if (data.hasOwnProperty('amountRefunded')) {
                obj['amountRefunded'] = ApiClient.convertToType(data['amountRefunded'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} paymentType
 */
RefundReceiptItem.prototype['paymentType'] = undefined;

/**
 * @member {String} cardHolderName
 */
RefundReceiptItem.prototype['cardHolderName'] = undefined;

/**
 * @member {String} cardHolderNameLabel
 */
RefundReceiptItem.prototype['cardHolderNameLabel'] = undefined;

/**
 * @member {String} lastFour
 */
RefundReceiptItem.prototype['lastFour'] = undefined;

/**
 * @member {String} lastFourLabel
 */
RefundReceiptItem.prototype['lastFourLabel'] = undefined;

/**
 * @member {String} amountRefunded
 */
RefundReceiptItem.prototype['amountRefunded'] = undefined;






export default RefundReceiptItem;

