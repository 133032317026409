/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ConfirmUserRegistrationRequest model module.
 * @module model/ConfirmUserRegistrationRequest
 * @version v1
 */
class ConfirmUserRegistrationRequest {
    /**
     * Constructs a new <code>ConfirmUserRegistrationRequest</code>.
     * @alias module:model/ConfirmUserRegistrationRequest
     * @param userId {Number} 
     * @param password {String} 
     * @param confirmPassword {String} 
     * @param confirmationCode {String} 
     */
    constructor(userId, password, confirmPassword, confirmationCode) { 
        
        ConfirmUserRegistrationRequest.initialize(this, userId, password, confirmPassword, confirmationCode);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, userId, password, confirmPassword, confirmationCode) { 
        obj['userId'] = userId;
        obj['password'] = password;
        obj['confirmPassword'] = confirmPassword;
        obj['confirmationCode'] = confirmationCode;
    }

    /**
     * Constructs a <code>ConfirmUserRegistrationRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ConfirmUserRegistrationRequest} obj Optional instance to populate.
     * @return {module:model/ConfirmUserRegistrationRequest} The populated <code>ConfirmUserRegistrationRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ConfirmUserRegistrationRequest();

            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'Number');
            }
            if (data.hasOwnProperty('password')) {
                obj['password'] = ApiClient.convertToType(data['password'], 'String');
            }
            if (data.hasOwnProperty('confirmPassword')) {
                obj['confirmPassword'] = ApiClient.convertToType(data['confirmPassword'], 'String');
            }
            if (data.hasOwnProperty('confirmationCode')) {
                obj['confirmationCode'] = ApiClient.convertToType(data['confirmationCode'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} userId
 */
ConfirmUserRegistrationRequest.prototype['userId'] = undefined;

/**
 * @member {String} password
 */
ConfirmUserRegistrationRequest.prototype['password'] = undefined;

/**
 * @member {String} confirmPassword
 */
ConfirmUserRegistrationRequest.prototype['confirmPassword'] = undefined;

/**
 * @member {String} confirmationCode
 */
ConfirmUserRegistrationRequest.prototype['confirmationCode'] = undefined;






export default ConfirmUserRegistrationRequest;

