/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProductItemTypeEnum from './ProductItemTypeEnum';

/**
 * The CartItem model module.
 * @module model/CartItem
 * @version v1
 */
class CartItem {
    /**
     * Constructs a new <code>CartItem</code>.
     * @alias module:model/CartItem
     */
    constructor() { 
        
        CartItem.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CartItem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CartItem} obj Optional instance to populate.
     * @return {module:model/CartItem} The populated <code>CartItem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CartItem();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('productId')) {
                obj['productId'] = ApiClient.convertToType(data['productId'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('imageUrl')) {
                obj['imageUrl'] = ApiClient.convertToType(data['imageUrl'], 'String');
            }
            if (data.hasOwnProperty('price')) {
                obj['price'] = ApiClient.convertToType(data['price'], 'Number');
            }
            if (data.hasOwnProperty('quantity')) {
                obj['quantity'] = ApiClient.convertToType(data['quantity'], 'Number');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('productItemId')) {
                obj['productItemId'] = ApiClient.convertToType(data['productItemId'], 'String');
            }
            if (data.hasOwnProperty('productItemType')) {
                obj['productItemType'] = ProductItemTypeEnum.constructFromObject(data['productItemType']);
            }
            if (data.hasOwnProperty('numberOfTimesTimeoutExtended')) {
                obj['numberOfTimesTimeoutExtended'] = ApiClient.convertToType(data['numberOfTimesTimeoutExtended'], 'Number');
            }
            if (data.hasOwnProperty('hasCartTimeoutBeenExtended')) {
                obj['hasCartTimeoutBeenExtended'] = ApiClient.convertToType(data['hasCartTimeoutBeenExtended'], 'Boolean');
            }
            if (data.hasOwnProperty('lockedUntilDate')) {
                obj['lockedUntilDate'] = ApiClient.convertToType(data['lockedUntilDate'], 'Date');
            }
            if (data.hasOwnProperty('spotId')) {
                obj['spotId'] = ApiClient.convertToType(data['spotId'], 'Number');
            }
            if (data.hasOwnProperty('reservationStartDate')) {
                obj['reservationStartDate'] = ApiClient.convertToType(data['reservationStartDate'], 'String');
            }
            if (data.hasOwnProperty('reservationEndDate')) {
                obj['reservationEndDate'] = ApiClient.convertToType(data['reservationEndDate'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
CartItem.prototype['id'] = undefined;

/**
 * @member {String} productId
 */
CartItem.prototype['productId'] = undefined;

/**
 * @member {String} description
 */
CartItem.prototype['description'] = undefined;

/**
 * @member {String} imageUrl
 */
CartItem.prototype['imageUrl'] = undefined;

/**
 * @member {Number} price
 */
CartItem.prototype['price'] = undefined;

/**
 * @member {Number} quantity
 */
CartItem.prototype['quantity'] = undefined;

/**
 * @member {String} url
 */
CartItem.prototype['url'] = undefined;

/**
 * @member {String} productItemId
 */
CartItem.prototype['productItemId'] = undefined;

/**
 * @member {module:model/ProductItemTypeEnum} productItemType
 */
CartItem.prototype['productItemType'] = undefined;

/**
 * @member {Number} numberOfTimesTimeoutExtended
 */
CartItem.prototype['numberOfTimesTimeoutExtended'] = undefined;

/**
 * @member {Boolean} hasCartTimeoutBeenExtended
 */
CartItem.prototype['hasCartTimeoutBeenExtended'] = undefined;

/**
 * @member {Date} lockedUntilDate
 */
CartItem.prototype['lockedUntilDate'] = undefined;

/**
 * @member {Number} spotId
 */
CartItem.prototype['spotId'] = undefined;

/**
 * @member {String} reservationStartDate
 */
CartItem.prototype['reservationStartDate'] = undefined;

/**
 * @member {String} reservationEndDate
 */
CartItem.prototype['reservationEndDate'] = undefined;






export default CartItem;

