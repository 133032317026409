<template>
  <div></div>
</template>

<script>
import ProfileService from "@/services/ProfileService.js";

import { Hub } from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";
import Amplify, * as AmplifyModules from "aws-amplify";
import Vue from "vue";
import AmplifyConfigService from "@/config/amplifyConfigService.js";
import CartService from "@/services/CartService.js";
import AdminCartService from "@/services/admin/AdminCartService.js";
import AdminUserService from "@/services/admin/AdminUserService.js";

export default {
  name: "App",
  components: {},
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    username() {
      return this.$store.getters["auth/username"];
    },
    userFirstName() {
      return this.$store.getters["auth/userFirstName"];
    },
    signedIn() {
      return this.$store.getters["auth/signedIn"];
    },
    loading() {
      return this.$store.getters["auth/loading"];
    },
    authSource() {
      return this.$store.getters["auth/authSource"];
    },
    amplifyConfig() {
      return this.$store.getters["tenant/amplifyConfig"];
    },
    userType() {
      return this.$store.getters["auth/userType"];
    }
  },
  methods: {
    async setUserFirstName() {
      if (
        this.username != "" &&
        (this.userFirstName == "" || this.userFirstName == "LOADING...")
      ) {
        try {
          let user = null;
          if (this.authSource === "admin") {
            const userService = new AdminUserService(this.tenantId);
            user = await userService.getUserByUsername(this.username);
          } else {
            const profileService = new ProfileService(this.tenantId);
            user = await profileService.getUserByUsername(this.username);
          }
          if (user) {
            const firstName = user.firstName;
            const userType = user.userType;
            const userRoleId = user.userRoleId;
            this.$store.commit("auth/setUserFirstName", firstName);
            this.$store.commit("auth/setUserType", userType);
            this.$store.commit("auth/setUserRoleId", userRoleId);
            this.$store.commit("auth/setVerifyStatus", "verified");
            if (user.role != null) {
              this.$store.commit(
                "auth/setUserPermissions",
                user.role.permissions
              );
            }
            if (user.locations.length > 0) {
              this.$store.commit(
                "auth/setUserLocations",
                user.locations.map(loc => loc.locationId)
              );
            }
          }
        } catch (err) {
          this.$store.commit("auth/setVerifyStatus", "failed");
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Invalid account",
            layer: "admin",
            persist: "true"
          });
          this.$store.dispatch("auth/logOut");
        }
      }
    },
    loadCart() {
      //Grab existing cart
      let cartService = null;
      if (this.authSource == "admin") {
        cartService = new AdminCartService(this.tenantId);
      } else {
        cartService = new CartService(this.tenantId);
      }
      cartService.getUserCart().then(userCart => {
        if (userCart && userCart.items.length > 0) {
          //Get max lockedUntilDate of items
          var lockedUntilDate = userCart.lockedUntilDate;

          if (lockedUntilDate) {
            this.$store.commit(
              "transaction/setCartExpiresDate",
              lockedUntilDate
            );
            this.$store.commit(
              "transaction/setNumberOfItemsInCart",
              userCart.items.length
            );
          }
        }
      });
    },
    configureAmplify() {
      const amplifyConfigService = new AmplifyConfigService(this.amplifyConfig);
      //Check to see what auth settings to use
      let config = null;
      if (this.authSource == "admin" && this.userType == "PartnerAdmin") {
        config = amplifyConfigService.getAdminConfig();
      } else if (this.authSource == "admin") {
        config = amplifyConfigService.getADAdminConfig();
      } else if (this.authSource == "local") {
        config = amplifyConfigService.getPublicConfig();
      }
      if (config != null) {
        Amplify.configure({
          Auth: config
        });
        Vue.use(AmplifyPlugin, AmplifyModules);
      }
    },
    routeAfterAuth() {
      let redirectString = "";
      let redirectUrl = this.$route.query.redirect;
      if (this.$route.query.redirect) {
        redirectString = "?redirect=" + redirectUrl;
        //this.$router.push({ path: this.$route.query.redirect }).catch(() => {});
      }
      if (this.authSource == "admin") {
        this.$router
          .push("/admin/verify-login" + redirectString)
          .catch(() => {});
      } else if (redirectUrl) {
        this.$router.push(redirectUrl).catch(() => {});
      } else {
        this.$router.push("/" + redirectString).catch(() => {});
      }
    },
    redirectAfterLogout() {
      let authSource = this.authSource;
      if (authSource == "admin") {
        this.$router.push("/admin/login").catch(() => {});
      } else {
        this.$router.push("/login").catch(() => {});
      }
    },
    setAuthListeners() {
      Hub.listen("auth", data => {
        switch (data.payload.event) {
          case "signIn": {
            const previouslySignedIn = this.$store.getters["auth/signedIn"];
            const authSource = this.$store.getters["auth/authSource"];
            const username = data.payload.data.username;
            this.$store.commit("auth/setSignedIn", true);
            this.$store.commit("auth/setUsername", username);
            this.$store.commit("auth/setUserFirstName", "LOADING...");
            if (
              data.payload.data.attributes &&
              data.payload.data.attributes["custom:mspEntityUserId"]
            ) {
              this.$store.commit(
                "auth/setExternalUserId",
                data.payload.data.attributes["custom:mspEntityUserId"]
              );
            }
            if (!previouslySignedIn) {
              this.routeAfterAuth();
            }

            if (authSource === "admin") {
              this.$store.commit("auth/setUserIdleTime", 3480000); //58 minutes
            } else {
              this.$store.commit("auth/setUserIdleTime", 780000); //13 minutes
            }

            break;
          }
          case "signIn_failure":
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: data.payload.data.message,
              layer: "public"
            });
            break;
          case "signOut":
            this.$store.commit("auth/setSignedIn", false);
            this.redirectAfterLogout();
            this.$store.dispatch("auth/clear");
            this.$store.dispatch("search/clear");
            this.$store.dispatch("transaction/clear");
            break;
          default:
            break;
        }
      });
    }
  },
  watch: {
    username() {
      this.setUserFirstName();
      this.loadCart();
    }
  },

  created() {
    this.configureAmplify();
    this.setAuthListeners();
    this.setUserFirstName();
  }
};
</script>
