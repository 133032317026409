/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import SalesChannelEnum from './SalesChannelEnum';
import SpotAttributeFilter from './SpotAttributeFilter';

/**
 * The SpotAvailabilityRequest model module.
 * @module model/SpotAvailabilityRequest
 * @version v1
 */
class SpotAvailabilityRequest {
    /**
     * Constructs a new <code>SpotAvailabilityRequest</code>.
     * @alias module:model/SpotAvailabilityRequest
     */
    constructor() { 
        
        SpotAvailabilityRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SpotAvailabilityRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SpotAvailabilityRequest} obj Optional instance to populate.
     * @return {module:model/SpotAvailabilityRequest} The populated <code>SpotAvailabilityRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SpotAvailabilityRequest();

            if (data.hasOwnProperty('locationId')) {
                obj['locationId'] = ApiClient.convertToType(data['locationId'], 'Number');
            }
            if (data.hasOwnProperty('loopId')) {
                obj['loopId'] = ApiClient.convertToType(data['loopId'], 'Number');
            }
            if (data.hasOwnProperty('startDate')) {
                obj['startDate'] = ApiClient.convertToType(data['startDate'], 'Date');
            }
            if (data.hasOwnProperty('endDate')) {
                obj['endDate'] = ApiClient.convertToType(data['endDate'], 'Date');
            }
            if (data.hasOwnProperty('arrivalDate')) {
                obj['arrivalDate'] = ApiClient.convertToType(data['arrivalDate'], 'Date');
            }
            if (data.hasOwnProperty('departureDate')) {
                obj['departureDate'] = ApiClient.convertToType(data['departureDate'], 'Date');
            }
            if (data.hasOwnProperty('lockCode')) {
                obj['lockCode'] = ApiClient.convertToType(data['lockCode'], 'String');
            }
            if (data.hasOwnProperty('includeDayAvailability')) {
                obj['includeDayAvailability'] = ApiClient.convertToType(data['includeDayAvailability'], 'Boolean');
            }
            if (data.hasOwnProperty('pastDatesAvailable')) {
                obj['pastDatesAvailable'] = ApiClient.convertToType(data['pastDatesAvailable'], 'Boolean');
            }
            if (data.hasOwnProperty('spotReservationId')) {
                obj['spotReservationId'] = ApiClient.convertToType(data['spotReservationId'], 'Number');
            }
            if (data.hasOwnProperty('customerId')) {
                obj['customerId'] = ApiClient.convertToType(data['customerId'], 'Number');
            }
            if (data.hasOwnProperty('salesChannel')) {
                obj['salesChannel'] = SalesChannelEnum.constructFromObject(data['salesChannel']);
            }
            if (data.hasOwnProperty('selectedSpotTypes')) {
                obj['selectedSpotTypes'] = ApiClient.convertToType(data['selectedSpotTypes'], ['Number']);
            }
            if (data.hasOwnProperty('selectedProductClassifications')) {
                obj['selectedProductClassifications'] = ApiClient.convertToType(data['selectedProductClassifications'], ['Number']);
            }
            if (data.hasOwnProperty('selectedAttributes')) {
                obj['selectedAttributes'] = ApiClient.convertToType(data['selectedAttributes'], [SpotAttributeFilter]);
            }
            if (data.hasOwnProperty('selectedSpotId')) {
                obj['selectedSpotId'] = ApiClient.convertToType(data['selectedSpotId'], 'Number');
            }
            if (data.hasOwnProperty('onlyShowAvailable')) {
                obj['onlyShowAvailable'] = ApiClient.convertToType(data['onlyShowAvailable'], 'Boolean');
            }
            if (data.hasOwnProperty('spotName')) {
                obj['spotName'] = ApiClient.convertToType(data['spotName'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} locationId
 */
SpotAvailabilityRequest.prototype['locationId'] = undefined;

/**
 * @member {Number} loopId
 */
SpotAvailabilityRequest.prototype['loopId'] = undefined;

/**
 * @member {Date} startDate
 */
SpotAvailabilityRequest.prototype['startDate'] = undefined;

/**
 * @member {Date} endDate
 */
SpotAvailabilityRequest.prototype['endDate'] = undefined;

/**
 * @member {Date} arrivalDate
 */
SpotAvailabilityRequest.prototype['arrivalDate'] = undefined;

/**
 * @member {Date} departureDate
 */
SpotAvailabilityRequest.prototype['departureDate'] = undefined;

/**
 * @member {String} lockCode
 */
SpotAvailabilityRequest.prototype['lockCode'] = undefined;

/**
 * @member {Boolean} includeDayAvailability
 */
SpotAvailabilityRequest.prototype['includeDayAvailability'] = undefined;

/**
 * @member {Boolean} pastDatesAvailable
 */
SpotAvailabilityRequest.prototype['pastDatesAvailable'] = undefined;

/**
 * @member {Number} spotReservationId
 */
SpotAvailabilityRequest.prototype['spotReservationId'] = undefined;

/**
 * @member {Number} customerId
 */
SpotAvailabilityRequest.prototype['customerId'] = undefined;

/**
 * @member {module:model/SalesChannelEnum} salesChannel
 */
SpotAvailabilityRequest.prototype['salesChannel'] = undefined;

/**
 * @member {Array.<Number>} selectedSpotTypes
 */
SpotAvailabilityRequest.prototype['selectedSpotTypes'] = undefined;

/**
 * @member {Array.<Number>} selectedProductClassifications
 */
SpotAvailabilityRequest.prototype['selectedProductClassifications'] = undefined;

/**
 * @member {Array.<module:model/SpotAttributeFilter>} selectedAttributes
 */
SpotAvailabilityRequest.prototype['selectedAttributes'] = undefined;

/**
 * @member {Number} selectedSpotId
 */
SpotAvailabilityRequest.prototype['selectedSpotId'] = undefined;

/**
 * @member {Boolean} onlyShowAvailable
 */
SpotAvailabilityRequest.prototype['onlyShowAvailable'] = undefined;

/**
 * @member {String} spotName
 */
SpotAvailabilityRequest.prototype['spotName'] = undefined;






export default SpotAvailabilityRequest;

