import SpotReservationApi from "@/generatedapiclients/src/api/SpotReservationApi.js";
import AdminSpotReservationApi from "@/generatedapiclients/src/api/AdminSpotReservationApi.js";
import LookupApi from "@/generatedapiclients/src/api/LookupApi.js";
import AdminSearchApi from "@/generatedapiclients/src/api/AdminSearchApi.js";
import ProfileService from "@/services/ProfileService.js";

export default class SpotReservationService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._spotReservationApi = new SpotReservationApi();
    this._adminSpotReservationApi = new AdminSpotReservationApi();
    this._lookupApi = new LookupApi();
    this._profileService = new ProfileService();
    this._adminSearchApi = new AdminSearchApi();
  }

  claimSpot(
    spotId,
    reservationStartDate,
    reservationEndDate,
    lockCode,
    spotReservationId,
    customerId
  ) {
    if (!this._profileService.isUserCustomer()) return Promise.resolve();

    return this._spotReservationApi.v1TenantTenantIdSpotReservationSpotSpotIdClaimPost(
      spotId,
      this._tenantId,
      {
        claimSpotRequest: {
          reservationStartDate,
          reservationEndDate,
          lockCode,
          spotReservationId,
          customerId
        }
      }
    );
  }

  releaseSpotClaim(spotId, lockCode) {
    if (!this._profileService.isUserCustomer()) return;

    return this._spotReservationApi.v1TenantTenantIdSpotReservationSpotSpotIdReleaseclaimPost(
      spotId,
      this._tenantId,
      {
        lockCode: lockCode
      }
    );
  }

  getAllRervationStatuses() {
    return this._lookupApi.v1TenantTenantIdLookupReservationstatusesGet(
      this._tenantId
    );
  }

  async validateSpotReservation(request) {
    request.salesChannel = 1;
    return this._spotReservationApi.v1TenantTenantIdSpotReservationValidatePost(
      this._tenantId,
      {
        newReservationRequest: { ...request }
      }
    );
  }

  async validateSpotReservationEdit(editReservationRequest) {
    editReservationRequest.newReservationRequest.salesChannel = 1;
    return this._spotReservationApi.v1TenantTenantIdSpotReservationValidateEditPost(
      this._tenantId,
      {
        editReservationRequest
      }
    );
  }

  async search(searchCriteria, paging) {
    return this._adminSearchApi.v1TenantTenantIdAdminAdminSearchReservationsGet(
      this._tenantId,
      {
        ...searchCriteria,
        ...paging
      }
    );
  }
  getReservation(id) {
    return this._spotReservationApi.v1TenantTenantIdSpotReservationReservationIdGet(
      id,
      this._tenantId
    );
  }
  cancelReservation(customerId, reservationId, cancellationRequest) {
    cancellationRequest.pricingRequest.salesChannel = 1;
    return this._spotReservationApi.v1TenantTenantIdSpotReservationCustomerIdCancelReservationIdPost(
      this._tenantId,
      customerId,
      reservationId,
      {
        cancellationRequest
      }
    );
  }
  modifyReservation(customerId, editReservationRequest, paymentTypeId) {
    editReservationRequest.newReservationRequest.salesChannel = 1;
    return this._spotReservationApi.v1TenantTenantIdSpotReservationCustomerIdChangePost(
      customerId,
      this._tenantId,
      {
        paymentTypeId,
        editReservationRequest
      }
    );
  }
}
