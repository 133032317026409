/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CustomerAddress from './CustomerAddress';
import CustomerComment from './CustomerComment';
import CustomerHistory from './CustomerHistory';
import CustomerPhoneNumber from './CustomerPhoneNumber';
import CustomerStatusEnum from './CustomerStatusEnum';
import User from './User';

/**
 * The Customer model module.
 * @module model/Customer
 * @version v1
 */
class Customer {
    /**
     * Constructs a new <code>Customer</code>.
     * @alias module:model/Customer
     */
    constructor() { 
        
        Customer.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Customer</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Customer} obj Optional instance to populate.
     * @return {module:model/Customer} The populated <code>Customer</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Customer();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('prefixName')) {
                obj['prefixName'] = ApiClient.convertToType(data['prefixName'], 'String');
            }
            if (data.hasOwnProperty('firstName')) {
                obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
            }
            if (data.hasOwnProperty('middleName')) {
                obj['middleName'] = ApiClient.convertToType(data['middleName'], 'String');
            }
            if (data.hasOwnProperty('lastName')) {
                obj['lastName'] = ApiClient.convertToType(data['lastName'], 'String');
            }
            if (data.hasOwnProperty('suffixName')) {
                obj['suffixName'] = ApiClient.convertToType(data['suffixName'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('customerPhoneNumbers')) {
                obj['customerPhoneNumbers'] = ApiClient.convertToType(data['customerPhoneNumbers'], [CustomerPhoneNumber]);
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'Number');
            }
            if (data.hasOwnProperty('user')) {
                obj['user'] = User.constructFromObject(data['user']);
            }
            if (data.hasOwnProperty('addresses')) {
                obj['addresses'] = ApiClient.convertToType(data['addresses'], [CustomerAddress]);
            }
            if (data.hasOwnProperty('timezoneId')) {
                obj['timezoneId'] = ApiClient.convertToType(data['timezoneId'], 'String');
            }
            if (data.hasOwnProperty('comments')) {
                obj['comments'] = ApiClient.convertToType(data['comments'], [CustomerComment]);
            }
            if (data.hasOwnProperty('customerStatus')) {
                obj['customerStatus'] = CustomerStatusEnum.constructFromObject(data['customerStatus']);
            }
            if (data.hasOwnProperty('history')) {
                obj['history'] = ApiClient.convertToType(data['history'], [CustomerHistory]);
            }
            if (data.hasOwnProperty('discountClassId')) {
                obj['discountClassId'] = ApiClient.convertToType(data['discountClassId'], 'Number');
            }
            if (data.hasOwnProperty('createdByAdmin')) {
                obj['createdByAdmin'] = ApiClient.convertToType(data['createdByAdmin'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
Customer.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
Customer.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
Customer.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
Customer.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
Customer.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
Customer.prototype['tenantId'] = undefined;

/**
 * @member {String} prefixName
 */
Customer.prototype['prefixName'] = undefined;

/**
 * @member {String} firstName
 */
Customer.prototype['firstName'] = undefined;

/**
 * @member {String} middleName
 */
Customer.prototype['middleName'] = undefined;

/**
 * @member {String} lastName
 */
Customer.prototype['lastName'] = undefined;

/**
 * @member {String} suffixName
 */
Customer.prototype['suffixName'] = undefined;

/**
 * @member {String} email
 */
Customer.prototype['email'] = undefined;

/**
 * @member {Array.<module:model/CustomerPhoneNumber>} customerPhoneNumbers
 */
Customer.prototype['customerPhoneNumbers'] = undefined;

/**
 * @member {Number} userId
 */
Customer.prototype['userId'] = undefined;

/**
 * @member {module:model/User} user
 */
Customer.prototype['user'] = undefined;

/**
 * @member {Array.<module:model/CustomerAddress>} addresses
 */
Customer.prototype['addresses'] = undefined;

/**
 * @member {String} timezoneId
 */
Customer.prototype['timezoneId'] = undefined;

/**
 * @member {Array.<module:model/CustomerComment>} comments
 */
Customer.prototype['comments'] = undefined;

/**
 * @member {module:model/CustomerStatusEnum} customerStatus
 */
Customer.prototype['customerStatus'] = undefined;

/**
 * @member {Array.<module:model/CustomerHistory>} history
 */
Customer.prototype['history'] = undefined;

/**
 * @member {Number} discountClassId
 */
Customer.prototype['discountClassId'] = undefined;

/**
 * @member {Boolean} createdByAdmin
 */
Customer.prototype['createdByAdmin'] = undefined;






export default Customer;

