/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ClassificationWithSpotTypesResponse from '../model/ClassificationWithSpotTypesResponse';
import Spot from '../model/Spot';
import SpotAvailabilityRequest from '../model/SpotAvailabilityRequest';
import SpotDetailsResponse from '../model/SpotDetailsResponse';
import SpotSearchResponse from '../model/SpotSearchResponse';
import SpotTypesResponse from '../model/SpotTypesResponse';

/**
* Spot service.
* @module api/SpotApi
* @version v1
*/
export default class SpotApi {

    /**
    * Constructs a new SpotApi. 
    * @alias module:api/SpotApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Spot>} and HTTP response
     */
    v1TenantTenantIdLocationLocationIdSpotGetWithHttpInfo(locationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'locationId' is set
      if (locationId === undefined || locationId === null) {
        throw new Error("Missing the required parameter 'locationId' when calling v1TenantTenantIdLocationLocationIdSpotGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdLocationLocationIdSpotGet");
      }

      let pathParams = {
        'locationId': locationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [Spot];
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/location/{locationId}/spot', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Spot>}
     */
    v1TenantTenantIdLocationLocationIdSpotGet(locationId, tenantId) {
      return this.v1TenantTenantIdLocationLocationIdSpotGetWithHttpInfo(locationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/SpotAvailabilityRequest} opts.spotAvailabilityRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SpotSearchResponse} and HTTP response
     */
    v1TenantTenantIdSpotAvailabilityPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['spotAvailabilityRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdSpotAvailabilityPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SpotSearchResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Spot/availability', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/SpotAvailabilityRequest} opts.spotAvailabilityRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SpotSearchResponse}
     */
    v1TenantTenantIdSpotAvailabilityPost(tenantId, opts) {
      return this.v1TenantTenantIdSpotAvailabilityPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.locationId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ClassificationWithSpotTypesResponse} and HTTP response
     */
    v1TenantTenantIdSpotProductClassificationsGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdSpotProductClassificationsGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'locationId': opts['locationId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ClassificationWithSpotTypesResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Spot/productClassifications', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.locationId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ClassificationWithSpotTypesResponse}
     */
    v1TenantTenantIdSpotProductClassificationsGet(tenantId, opts) {
      return this.v1TenantTenantIdSpotProductClassificationsGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} spotId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SpotDetailsResponse} and HTTP response
     */
    v1TenantTenantIdSpotSpotIdGetWithHttpInfo(spotId, tenantId) {
      let postBody = null;
      // verify the required parameter 'spotId' is set
      if (spotId === undefined || spotId === null) {
        throw new Error("Missing the required parameter 'spotId' when calling v1TenantTenantIdSpotSpotIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdSpotSpotIdGet");
      }

      let pathParams = {
        'spotId': spotId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SpotDetailsResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Spot/{spotId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} spotId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SpotDetailsResponse}
     */
    v1TenantTenantIdSpotSpotIdGet(spotId, tenantId) {
      return this.v1TenantTenantIdSpotSpotIdGetWithHttpInfo(spotId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SpotTypesResponse} and HTTP response
     */
    v1TenantTenantIdSpotSpotTypesGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdSpotSpotTypesGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SpotTypesResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Spot/spotTypes', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SpotTypesResponse}
     */
    v1TenantTenantIdSpotSpotTypesGet(tenantId) {
      return this.v1TenantTenantIdSpotSpotTypesGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
