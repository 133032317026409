/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ReservationFeeBreakdown model module.
 * @module model/ReservationFeeBreakdown
 * @version v1
 */
class ReservationFeeBreakdown {
    /**
     * Constructs a new <code>ReservationFeeBreakdown</code>.
     * @alias module:model/ReservationFeeBreakdown
     */
    constructor() { 
        
        ReservationFeeBreakdown.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ReservationFeeBreakdown</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ReservationFeeBreakdown} obj Optional instance to populate.
     * @return {module:model/ReservationFeeBreakdown} The populated <code>ReservationFeeBreakdown</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ReservationFeeBreakdown();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('amount')) {
                obj['amount'] = ApiClient.convertToType(data['amount'], 'Number');
            }
            if (data.hasOwnProperty('undiscountedAmount')) {
                obj['undiscountedAmount'] = ApiClient.convertToType(data['undiscountedAmount'], 'Number');
            }
            if (data.hasOwnProperty('preModifiedAmount')) {
                obj['preModifiedAmount'] = ApiClient.convertToType(data['preModifiedAmount'], 'Number');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('quantity')) {
                obj['quantity'] = ApiClient.convertToType(data['quantity'], 'Number');
            }
            if (data.hasOwnProperty('feeTypeId')) {
                obj['feeTypeId'] = ApiClient.convertToType(data['feeTypeId'], 'Number');
            }
            if (data.hasOwnProperty('dateBlockDayId')) {
                obj['dateBlockDayId'] = ApiClient.convertToType(data['dateBlockDayId'], 'Number');
            }
            if (data.hasOwnProperty('dayBlockId')) {
                obj['dayBlockId'] = ApiClient.convertToType(data['dayBlockId'], 'Number');
            }
            if (data.hasOwnProperty('discountClassId')) {
                obj['discountClassId'] = ApiClient.convertToType(data['discountClassId'], 'Number');
            }
            if (data.hasOwnProperty('date')) {
                obj['date'] = ApiClient.convertToType(data['date'], 'Date');
            }
            if (data.hasOwnProperty('reservationFeeId')) {
                obj['reservationFeeId'] = ApiClient.convertToType(data['reservationFeeId'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
ReservationFeeBreakdown.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
ReservationFeeBreakdown.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
ReservationFeeBreakdown.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
ReservationFeeBreakdown.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
ReservationFeeBreakdown.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
ReservationFeeBreakdown.prototype['tenantId'] = undefined;

/**
 * @member {String} name
 */
ReservationFeeBreakdown.prototype['name'] = undefined;

/**
 * @member {Number} amount
 */
ReservationFeeBreakdown.prototype['amount'] = undefined;

/**
 * @member {Number} undiscountedAmount
 */
ReservationFeeBreakdown.prototype['undiscountedAmount'] = undefined;

/**
 * @member {Number} preModifiedAmount
 */
ReservationFeeBreakdown.prototype['preModifiedAmount'] = undefined;

/**
 * @member {String} description
 */
ReservationFeeBreakdown.prototype['description'] = undefined;

/**
 * @member {Number} quantity
 */
ReservationFeeBreakdown.prototype['quantity'] = undefined;

/**
 * @member {Number} feeTypeId
 */
ReservationFeeBreakdown.prototype['feeTypeId'] = undefined;

/**
 * @member {Number} dateBlockDayId
 */
ReservationFeeBreakdown.prototype['dateBlockDayId'] = undefined;

/**
 * @member {Number} dayBlockId
 */
ReservationFeeBreakdown.prototype['dayBlockId'] = undefined;

/**
 * @member {Number} discountClassId
 */
ReservationFeeBreakdown.prototype['discountClassId'] = undefined;

/**
 * @member {Date} date
 */
ReservationFeeBreakdown.prototype['date'] = undefined;

/**
 * @member {Number} reservationFeeId
 */
ReservationFeeBreakdown.prototype['reservationFeeId'] = undefined;






export default ReservationFeeBreakdown;

