/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProductClassificationEquipmentType from './ProductClassificationEquipmentType';
import ProductClassificationIcon from './ProductClassificationIcon';
import ProductClassificationSpotType from './ProductClassificationSpotType';
import SpotAttribute from './SpotAttribute';

/**
 * The ProductClassification model module.
 * @module model/ProductClassification
 * @version v1
 */
class ProductClassification {
    /**
     * Constructs a new <code>ProductClassification</code>.
     * @alias module:model/ProductClassification
     */
    constructor() { 
        
        ProductClassification.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ProductClassification</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProductClassification} obj Optional instance to populate.
     * @return {module:model/ProductClassification} The populated <code>ProductClassification</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProductClassification();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('internalDescription')) {
                obj['internalDescription'] = ApiClient.convertToType(data['internalDescription'], 'String');
            }
            if (data.hasOwnProperty('message')) {
                obj['message'] = ApiClient.convertToType(data['message'], 'String');
            }
            if (data.hasOwnProperty('applyMessageToWebsite')) {
                obj['applyMessageToWebsite'] = ApiClient.convertToType(data['applyMessageToWebsite'], 'Boolean');
            }
            if (data.hasOwnProperty('applyMessageToEmail')) {
                obj['applyMessageToEmail'] = ApiClient.convertToType(data['applyMessageToEmail'], 'Boolean');
            }
            if (data.hasOwnProperty('showMessageAtTop')) {
                obj['showMessageAtTop'] = ApiClient.convertToType(data['showMessageAtTop'], 'Boolean');
            }
            if (data.hasOwnProperty('productIds')) {
                obj['productIds'] = ApiClient.convertToType(data['productIds'], ['Number']);
            }
            if (data.hasOwnProperty('icons')) {
                obj['icons'] = ApiClient.convertToType(data['icons'], [ProductClassificationIcon]);
            }
            if (data.hasOwnProperty('attributes')) {
                obj['attributes'] = ApiClient.convertToType(data['attributes'], [SpotAttribute]);
            }
            if (data.hasOwnProperty('productClassificationSpotTypes')) {
                obj['productClassificationSpotTypes'] = ApiClient.convertToType(data['productClassificationSpotTypes'], [ProductClassificationSpotType]);
            }
            if (data.hasOwnProperty('productClassificationEquipmentTypes')) {
                obj['productClassificationEquipmentTypes'] = ApiClient.convertToType(data['productClassificationEquipmentTypes'], [ProductClassificationEquipmentType]);
            }
            if (data.hasOwnProperty('spotTypeIds')) {
                obj['spotTypeIds'] = ApiClient.convertToType(data['spotTypeIds'], ['Number']);
            }
            if (data.hasOwnProperty('equipmentTypeIds')) {
                obj['equipmentTypeIds'] = ApiClient.convertToType(data['equipmentTypeIds'], ['Number']);
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
ProductClassification.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
ProductClassification.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
ProductClassification.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
ProductClassification.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
ProductClassification.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
ProductClassification.prototype['tenantId'] = undefined;

/**
 * @member {String} name
 */
ProductClassification.prototype['name'] = undefined;

/**
 * @member {String} description
 */
ProductClassification.prototype['description'] = undefined;

/**
 * @member {String} internalDescription
 */
ProductClassification.prototype['internalDescription'] = undefined;

/**
 * @member {String} message
 */
ProductClassification.prototype['message'] = undefined;

/**
 * @member {Boolean} applyMessageToWebsite
 */
ProductClassification.prototype['applyMessageToWebsite'] = undefined;

/**
 * @member {Boolean} applyMessageToEmail
 */
ProductClassification.prototype['applyMessageToEmail'] = undefined;

/**
 * @member {Boolean} showMessageAtTop
 */
ProductClassification.prototype['showMessageAtTop'] = undefined;

/**
 * @member {Array.<Number>} productIds
 */
ProductClassification.prototype['productIds'] = undefined;

/**
 * @member {Array.<module:model/ProductClassificationIcon>} icons
 */
ProductClassification.prototype['icons'] = undefined;

/**
 * @member {Array.<module:model/SpotAttribute>} attributes
 */
ProductClassification.prototype['attributes'] = undefined;

/**
 * @member {Array.<module:model/ProductClassificationSpotType>} productClassificationSpotTypes
 */
ProductClassification.prototype['productClassificationSpotTypes'] = undefined;

/**
 * @member {Array.<module:model/ProductClassificationEquipmentType>} productClassificationEquipmentTypes
 */
ProductClassification.prototype['productClassificationEquipmentTypes'] = undefined;

/**
 * @member {Array.<Number>} spotTypeIds
 */
ProductClassification.prototype['spotTypeIds'] = undefined;

/**
 * @member {Array.<Number>} equipmentTypeIds
 */
ProductClassification.prototype['equipmentTypeIds'] = undefined;






export default ProductClassification;

