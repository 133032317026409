/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The OrderInfo model module.
 * @module model/OrderInfo
 * @version v1
 */
class OrderInfo {
    /**
     * Constructs a new <code>OrderInfo</code>.
     * @alias module:model/OrderInfo
     */
    constructor() { 
        
        OrderInfo.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>OrderInfo</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/OrderInfo} obj Optional instance to populate.
     * @return {module:model/OrderInfo} The populated <code>OrderInfo</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrderInfo();

            if (data.hasOwnProperty('stateCode')) {
                obj['stateCode'] = ApiClient.convertToType(data['stateCode'], 'String');
            }
            if (data.hasOwnProperty('serviceCode')) {
                obj['serviceCode'] = ApiClient.convertToType(data['serviceCode'], 'String');
            }
            if (data.hasOwnProperty('localRef')) {
                obj['localRef'] = ApiClient.convertToType(data['localRef'], 'String');
            }
            if (data.hasOwnProperty('token')) {
                obj['token'] = ApiClient.convertToType(data['token'], 'String');
            }
            if (data.hasOwnProperty('utcOrderDateTime')) {
                obj['utcOrderDateTime'] = ApiClient.convertToType(data['utcOrderDateTime'], 'Date');
            }
            if (data.hasOwnProperty('orderId')) {
                obj['orderId'] = ApiClient.convertToType(data['orderId'], 'Number');
            }
            if (data.hasOwnProperty('orderStatus')) {
                obj['orderStatus'] = ApiClient.convertToType(data['orderStatus'], 'String');
            }
            if (data.hasOwnProperty('subTotal')) {
                obj['subTotal'] = ApiClient.convertToType(data['subTotal'], 'Number');
            }
            if (data.hasOwnProperty('total')) {
                obj['total'] = ApiClient.convertToType(data['total'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {String} stateCode
 */
OrderInfo.prototype['stateCode'] = undefined;

/**
 * @member {String} serviceCode
 */
OrderInfo.prototype['serviceCode'] = undefined;

/**
 * @member {String} localRef
 */
OrderInfo.prototype['localRef'] = undefined;

/**
 * @member {String} token
 */
OrderInfo.prototype['token'] = undefined;

/**
 * @member {Date} utcOrderDateTime
 */
OrderInfo.prototype['utcOrderDateTime'] = undefined;

/**
 * @member {Number} orderId
 */
OrderInfo.prototype['orderId'] = undefined;

/**
 * @member {String} orderStatus
 */
OrderInfo.prototype['orderStatus'] = undefined;

/**
 * @member {Number} subTotal
 */
OrderInfo.prototype['subTotal'] = undefined;

/**
 * @member {Number} total
 */
OrderInfo.prototype['total'] = undefined;






export default OrderInfo;

