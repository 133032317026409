/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ReservationOverride model module.
 * @module model/ReservationOverride
 * @version v1
 */
class ReservationOverride {
    /**
     * Constructs a new <code>ReservationOverride</code>.
     * @alias module:model/ReservationOverride
     */
    constructor() { 
        
        ReservationOverride.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ReservationOverride</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ReservationOverride} obj Optional instance to populate.
     * @return {module:model/ReservationOverride} The populated <code>ReservationOverride</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ReservationOverride();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('spotReservationId')) {
                obj['spotReservationId'] = ApiClient.convertToType(data['spotReservationId'], 'Number');
            }
            if (data.hasOwnProperty('locationId')) {
                obj['locationId'] = ApiClient.convertToType(data['locationId'], 'Number');
            }
            if (data.hasOwnProperty('fieldOverridden')) {
                obj['fieldOverridden'] = ApiClient.convertToType(data['fieldOverridden'], 'String');
            }
            if (data.hasOwnProperty('previousValue')) {
                obj['previousValue'] = ApiClient.convertToType(data['previousValue'], 'String');
            }
            if (data.hasOwnProperty('overriddenValue')) {
                obj['overriddenValue'] = ApiClient.convertToType(data['overriddenValue'], 'String');
            }
            if (data.hasOwnProperty('transactionTypeId')) {
                obj['transactionTypeId'] = ApiClient.convertToType(data['transactionTypeId'], 'Number');
            }
            if (data.hasOwnProperty('orderId')) {
                obj['orderId'] = ApiClient.convertToType(data['orderId'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
ReservationOverride.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
ReservationOverride.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
ReservationOverride.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
ReservationOverride.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
ReservationOverride.prototype['id'] = undefined;

/**
 * @member {Number} spotReservationId
 */
ReservationOverride.prototype['spotReservationId'] = undefined;

/**
 * @member {Number} locationId
 */
ReservationOverride.prototype['locationId'] = undefined;

/**
 * @member {String} fieldOverridden
 */
ReservationOverride.prototype['fieldOverridden'] = undefined;

/**
 * @member {String} previousValue
 */
ReservationOverride.prototype['previousValue'] = undefined;

/**
 * @member {String} overriddenValue
 */
ReservationOverride.prototype['overriddenValue'] = undefined;

/**
 * @member {Number} transactionTypeId
 */
ReservationOverride.prototype['transactionTypeId'] = undefined;

/**
 * @member {Number} orderId
 */
ReservationOverride.prototype['orderId'] = undefined;






export default ReservationOverride;

