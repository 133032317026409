/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Area from './Area';
import Image from './Image';
import Location from './Location';
import Loop from './Loop';
import Product from './Product';
import SalesChannelEnum from './SalesChannelEnum';
import SpotAttribute from './SpotAttribute';
import SpotDescription from './SpotDescription';
import SpotEquipmentType from './SpotEquipmentType';
import SpotSpotType from './SpotSpotType';

/**
 * The Spot model module.
 * @module model/Spot
 * @version v1
 */
class Spot {
    /**
     * Constructs a new <code>Spot</code>.
     * @alias module:model/Spot
     */
    constructor() { 
        
        Spot.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Spot</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Spot} obj Optional instance to populate.
     * @return {module:model/Spot} The populated <code>Spot</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Spot();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('locationId')) {
                obj['locationId'] = ApiClient.convertToType(data['locationId'], 'Number');
            }
            if (data.hasOwnProperty('latitude')) {
                obj['latitude'] = ApiClient.convertToType(data['latitude'], 'Number');
            }
            if (data.hasOwnProperty('longitude')) {
                obj['longitude'] = ApiClient.convertToType(data['longitude'], 'Number');
            }
            if (data.hasOwnProperty('area')) {
                obj['area'] = Area.constructFromObject(data['area']);
            }
            if (data.hasOwnProperty('areaId')) {
                obj['areaId'] = ApiClient.convertToType(data['areaId'], 'Number');
            }
            if (data.hasOwnProperty('productId')) {
                obj['productId'] = ApiClient.convertToType(data['productId'], 'Number');
            }
            if (data.hasOwnProperty('loop')) {
                obj['loop'] = Loop.constructFromObject(data['loop']);
            }
            if (data.hasOwnProperty('loopId')) {
                obj['loopId'] = ApiClient.convertToType(data['loopId'], 'Number');
            }
            if (data.hasOwnProperty('location')) {
                obj['location'] = Location.constructFromObject(data['location']);
            }
            if (data.hasOwnProperty('product')) {
                obj['product'] = Product.constructFromObject(data['product']);
            }
            if (data.hasOwnProperty('images')) {
                obj['images'] = ApiClient.convertToType(data['images'], [Image]);
            }
            if (data.hasOwnProperty('attributes')) {
                obj['attributes'] = ApiClient.convertToType(data['attributes'], [SpotAttribute]);
            }
            if (data.hasOwnProperty('spotDescriptions')) {
                obj['spotDescriptions'] = ApiClient.convertToType(data['spotDescriptions'], [SpotDescription]);
            }
            if (data.hasOwnProperty('spotSpotTypes')) {
                obj['spotSpotTypes'] = ApiClient.convertToType(data['spotSpotTypes'], [SpotSpotType]);
            }
            if (data.hasOwnProperty('spotEquipmentTypes')) {
                obj['spotEquipmentTypes'] = ApiClient.convertToType(data['spotEquipmentTypes'], [SpotEquipmentType]);
            }
            if (data.hasOwnProperty('featuredImageUrl')) {
                obj['featuredImageUrl'] = ApiClient.convertToType(data['featuredImageUrl'], 'String');
            }
            if (data.hasOwnProperty('isSpotAvailable')) {
                obj['isSpotAvailable'] = ApiClient.convertToType(data['isSpotAvailable'], 'Boolean');
            }
            if (data.hasOwnProperty('salesChannel')) {
                obj['salesChannel'] = SalesChannelEnum.constructFromObject(data['salesChannel']);
            }
            if (data.hasOwnProperty('showAsWalkIn')) {
                obj['showAsWalkIn'] = ApiClient.convertToType(data['showAsWalkIn'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
Spot.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
Spot.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
Spot.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
Spot.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
Spot.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
Spot.prototype['tenantId'] = undefined;

/**
 * @member {String} name
 */
Spot.prototype['name'] = undefined;

/**
 * @member {Number} locationId
 */
Spot.prototype['locationId'] = undefined;

/**
 * @member {Number} latitude
 */
Spot.prototype['latitude'] = undefined;

/**
 * @member {Number} longitude
 */
Spot.prototype['longitude'] = undefined;

/**
 * @member {module:model/Area} area
 */
Spot.prototype['area'] = undefined;

/**
 * @member {Number} areaId
 */
Spot.prototype['areaId'] = undefined;

/**
 * @member {Number} productId
 */
Spot.prototype['productId'] = undefined;

/**
 * @member {module:model/Loop} loop
 */
Spot.prototype['loop'] = undefined;

/**
 * @member {Number} loopId
 */
Spot.prototype['loopId'] = undefined;

/**
 * @member {module:model/Location} location
 */
Spot.prototype['location'] = undefined;

/**
 * @member {module:model/Product} product
 */
Spot.prototype['product'] = undefined;

/**
 * @member {Array.<module:model/Image>} images
 */
Spot.prototype['images'] = undefined;

/**
 * @member {Array.<module:model/SpotAttribute>} attributes
 */
Spot.prototype['attributes'] = undefined;

/**
 * @member {Array.<module:model/SpotDescription>} spotDescriptions
 */
Spot.prototype['spotDescriptions'] = undefined;

/**
 * @member {Array.<module:model/SpotSpotType>} spotSpotTypes
 */
Spot.prototype['spotSpotTypes'] = undefined;

/**
 * @member {Array.<module:model/SpotEquipmentType>} spotEquipmentTypes
 */
Spot.prototype['spotEquipmentTypes'] = undefined;

/**
 * @member {String} featuredImageUrl
 */
Spot.prototype['featuredImageUrl'] = undefined;

/**
 * @member {Boolean} isSpotAvailable
 */
Spot.prototype['isSpotAvailable'] = undefined;

/**
 * @member {module:model/SalesChannelEnum} salesChannel
 */
Spot.prototype['salesChannel'] = undefined;

/**
 * @member {Boolean} showAsWalkIn
 */
Spot.prototype['showAsWalkIn'] = undefined;






export default Spot;

