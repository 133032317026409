/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AttributeOption from './AttributeOption';
import GlobalAttribute from './GlobalAttribute';

/**
 * The TenantAttribute model module.
 * @module model/TenantAttribute
 * @version v1
 */
class TenantAttribute {
    /**
     * Constructs a new <code>TenantAttribute</code>.
     * @alias module:model/TenantAttribute
     */
    constructor() { 
        
        TenantAttribute.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>TenantAttribute</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/TenantAttribute} obj Optional instance to populate.
     * @return {module:model/TenantAttribute} The populated <code>TenantAttribute</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TenantAttribute();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('globalAttribute')) {
                obj['globalAttribute'] = GlobalAttribute.constructFromObject(data['globalAttribute']);
            }
            if (data.hasOwnProperty('isSearchable')) {
                obj['isSearchable'] = ApiClient.convertToType(data['isSearchable'], 'Boolean');
            }
            if (data.hasOwnProperty('overridableAtSpot')) {
                obj['overridableAtSpot'] = ApiClient.convertToType(data['overridableAtSpot'], 'Boolean');
            }
            if (data.hasOwnProperty('showIcon')) {
                obj['showIcon'] = ApiClient.convertToType(data['showIcon'], 'Boolean');
            }
            if (data.hasOwnProperty('includedInAvailability')) {
                obj['includedInAvailability'] = ApiClient.convertToType(data['includedInAvailability'], 'Boolean');
            }
            if (data.hasOwnProperty('attributeOptions')) {
                obj['attributeOptions'] = ApiClient.convertToType(data['attributeOptions'], [AttributeOption]);
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
TenantAttribute.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
TenantAttribute.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
TenantAttribute.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
TenantAttribute.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
TenantAttribute.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
TenantAttribute.prototype['tenantId'] = undefined;

/**
 * @member {module:model/GlobalAttribute} globalAttribute
 */
TenantAttribute.prototype['globalAttribute'] = undefined;

/**
 * @member {Boolean} isSearchable
 */
TenantAttribute.prototype['isSearchable'] = undefined;

/**
 * @member {Boolean} overridableAtSpot
 */
TenantAttribute.prototype['overridableAtSpot'] = undefined;

/**
 * @member {Boolean} showIcon
 */
TenantAttribute.prototype['showIcon'] = undefined;

/**
 * @member {Boolean} includedInAvailability
 */
TenantAttribute.prototype['includedInAvailability'] = undefined;

/**
 * @member {Array.<module:model/AttributeOption>} attributeOptions
 */
TenantAttribute.prototype['attributeOptions'] = undefined;






export default TenantAttribute;

