import Vue from "vue";
import moment from "moment";

Vue.filter("formatDate", function(value, inputFormat) {
  if (value) {
    return moment(String(value), inputFormat).format("L");
  }
});

Vue.filter("formatDateTime", function(value, inputFormat = null) {
  if (value && inputFormat) {
    return moment(String(value), inputFormat).format("MM/DD/YYYY hh:mm A");
  } else if (value) {
    return moment(String(value)).format("MM/DD/YYYY hh:mm A");
  }
});

Vue.filter("formatUTCDate", function(value) {
  if (value) {
    const utcDate = moment.utc(String(value));
    const localDate = moment(utcDate).local();
    return localDate.format("MM/DD/YYYY");
  }
});

Vue.filter("formatDateAsUTCDate", function(value) {
  if (value) {
    var isoDateString = value.toISOString();
    const date = moment(isoDateString);
    return date.utc().format("MM/DD/YYYY");
  }
});

Vue.filter("formatUTCDateTime", function(value) {
  if (value) {
    const utcDate = moment.utc(String(value));
    const localDate = moment(utcDate).local();
    return localDate.format("MM/DD/YYYY hh:mm A");
  }
});

Vue.filter("formatTime", function(value) {
  if (value) {
    return moment(String(value), [moment.ISO_8601, "HH:mm"]).format("hh:mm A");
  }
});

Vue.filter("seasonDate", function(value) {
  if (value) {
    return moment(String(value)).format("MMMM D");
  }
});
