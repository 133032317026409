/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import FeeTypeEnum from './FeeTypeEnum';

/**
 * The Fee model module.
 * @module model/Fee
 * @version v1
 */
class Fee {
    /**
     * Constructs a new <code>Fee</code>.
     * @alias module:model/Fee
     */
    constructor() { 
        
        Fee.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Fee</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Fee} obj Optional instance to populate.
     * @return {module:model/Fee} The populated <code>Fee</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Fee();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('amount')) {
                obj['amount'] = ApiClient.convertToType(data['amount'], 'Number');
            }
            if (data.hasOwnProperty('undiscountedAmount')) {
                obj['undiscountedAmount'] = ApiClient.convertToType(data['undiscountedAmount'], 'Number');
            }
            if (data.hasOwnProperty('preModifiedAmount')) {
                obj['preModifiedAmount'] = ApiClient.convertToType(data['preModifiedAmount'], 'Number');
            }
            if (data.hasOwnProperty('creditCardAmount')) {
                obj['creditCardAmount'] = ApiClient.convertToType(data['creditCardAmount'], 'Number');
            }
            if (data.hasOwnProperty('creditCardMinAmount')) {
                obj['creditCardMinAmount'] = ApiClient.convertToType(data['creditCardMinAmount'], 'Number');
            }
            if (data.hasOwnProperty('creditCardPercentage')) {
                obj['creditCardPercentage'] = ApiClient.convertToType(data['creditCardPercentage'], 'Number');
            }
            if (data.hasOwnProperty('echeckAmount')) {
                obj['echeckAmount'] = ApiClient.convertToType(data['echeckAmount'], 'Number');
            }
            if (data.hasOwnProperty('echeckMinAmount')) {
                obj['echeckMinAmount'] = ApiClient.convertToType(data['echeckMinAmount'], 'Number');
            }
            if (data.hasOwnProperty('echeckPercentage')) {
                obj['echeckPercentage'] = ApiClient.convertToType(data['echeckPercentage'], 'Number');
            }
            if (data.hasOwnProperty('cashAmount')) {
                obj['cashAmount'] = ApiClient.convertToType(data['cashAmount'], 'Number');
            }
            if (data.hasOwnProperty('cashMinAmount')) {
                obj['cashMinAmount'] = ApiClient.convertToType(data['cashMinAmount'], 'Number');
            }
            if (data.hasOwnProperty('cashPercentage')) {
                obj['cashPercentage'] = ApiClient.convertToType(data['cashPercentage'], 'Number');
            }
            if (data.hasOwnProperty('accountingCode')) {
                obj['accountingCode'] = ApiClient.convertToType(data['accountingCode'], 'String');
            }
            if (data.hasOwnProperty('sku')) {
                obj['sku'] = ApiClient.convertToType(data['sku'], 'String');
            }
            if (data.hasOwnProperty('quantity')) {
                obj['quantity'] = ApiClient.convertToType(data['quantity'], 'Number');
            }
            if (data.hasOwnProperty('feeType')) {
                obj['feeType'] = FeeTypeEnum.constructFromObject(data['feeType']);
            }
            if (data.hasOwnProperty('discountClassId')) {
                obj['discountClassId'] = ApiClient.convertToType(data['discountClassId'], 'Number');
            }
            if (data.hasOwnProperty('dateBlockDayId')) {
                obj['dateBlockDayId'] = ApiClient.convertToType(data['dateBlockDayId'], 'Number');
            }
            if (data.hasOwnProperty('dayBlockId')) {
                obj['dayBlockId'] = ApiClient.convertToType(data['dayBlockId'], 'Number');
            }
            if (data.hasOwnProperty('date')) {
                obj['date'] = ApiClient.convertToType(data['date'], 'Date');
            }
            if (data.hasOwnProperty('feeBreakdown')) {
                obj['feeBreakdown'] = ApiClient.convertToType(data['feeBreakdown'], [Fee]);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
Fee.prototype['id'] = undefined;

/**
 * @member {String} name
 */
Fee.prototype['name'] = undefined;

/**
 * @member {String} description
 */
Fee.prototype['description'] = undefined;

/**
 * @member {Number} amount
 */
Fee.prototype['amount'] = undefined;

/**
 * @member {Number} undiscountedAmount
 */
Fee.prototype['undiscountedAmount'] = undefined;

/**
 * @member {Number} preModifiedAmount
 */
Fee.prototype['preModifiedAmount'] = undefined;

/**
 * @member {Number} creditCardAmount
 */
Fee.prototype['creditCardAmount'] = undefined;

/**
 * @member {Number} creditCardMinAmount
 */
Fee.prototype['creditCardMinAmount'] = undefined;

/**
 * @member {Number} creditCardPercentage
 */
Fee.prototype['creditCardPercentage'] = undefined;

/**
 * @member {Number} echeckAmount
 */
Fee.prototype['echeckAmount'] = undefined;

/**
 * @member {Number} echeckMinAmount
 */
Fee.prototype['echeckMinAmount'] = undefined;

/**
 * @member {Number} echeckPercentage
 */
Fee.prototype['echeckPercentage'] = undefined;

/**
 * @member {Number} cashAmount
 */
Fee.prototype['cashAmount'] = undefined;

/**
 * @member {Number} cashMinAmount
 */
Fee.prototype['cashMinAmount'] = undefined;

/**
 * @member {Number} cashPercentage
 */
Fee.prototype['cashPercentage'] = undefined;

/**
 * @member {String} accountingCode
 */
Fee.prototype['accountingCode'] = undefined;

/**
 * @member {String} sku
 */
Fee.prototype['sku'] = undefined;

/**
 * @member {Number} quantity
 */
Fee.prototype['quantity'] = undefined;

/**
 * @member {module:model/FeeTypeEnum} feeType
 */
Fee.prototype['feeType'] = undefined;

/**
 * @member {Number} discountClassId
 */
Fee.prototype['discountClassId'] = undefined;

/**
 * @member {Number} dateBlockDayId
 */
Fee.prototype['dateBlockDayId'] = undefined;

/**
 * @member {Number} dayBlockId
 */
Fee.prototype['dayBlockId'] = undefined;

/**
 * @member {Date} date
 */
Fee.prototype['date'] = undefined;

/**
 * @member {Array.<module:model/Fee>} feeBreakdown
 */
Fee.prototype['feeBreakdown'] = undefined;






export default Fee;

