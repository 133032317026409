/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProductTypeCount from './ProductTypeCount';

/**
 * The LocationSummaryItem model module.
 * @module model/LocationSummaryItem
 * @version v1
 */
class LocationSummaryItem {
    /**
     * Constructs a new <code>LocationSummaryItem</code>.
     * @alias module:model/LocationSummaryItem
     */
    constructor() { 
        
        LocationSummaryItem.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>LocationSummaryItem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/LocationSummaryItem} obj Optional instance to populate.
     * @return {module:model/LocationSummaryItem} The populated <code>LocationSummaryItem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LocationSummaryItem();

            if (data.hasOwnProperty('locationId')) {
                obj['locationId'] = ApiClient.convertToType(data['locationId'], 'Number');
            }
            if (data.hasOwnProperty('locationName')) {
                obj['locationName'] = ApiClient.convertToType(data['locationName'], 'String');
            }
            if (data.hasOwnProperty('matchingAreaName')) {
                obj['matchingAreaName'] = ApiClient.convertToType(data['matchingAreaName'], 'String');
            }
            if (data.hasOwnProperty('minPrice')) {
                obj['minPrice'] = ApiClient.convertToType(data['minPrice'], 'Number');
            }
            if (data.hasOwnProperty('maxPrice')) {
                obj['maxPrice'] = ApiClient.convertToType(data['maxPrice'], 'Number');
            }
            if (data.hasOwnProperty('featuredImageUrl')) {
                obj['featuredImageUrl'] = ApiClient.convertToType(data['featuredImageUrl'], 'String');
            }
            if (data.hasOwnProperty('latitude')) {
                obj['latitude'] = ApiClient.convertToType(data['latitude'], 'Number');
            }
            if (data.hasOwnProperty('longitude')) {
                obj['longitude'] = ApiClient.convertToType(data['longitude'], 'Number');
            }
            if (data.hasOwnProperty('mapLatitude')) {
                obj['mapLatitude'] = ApiClient.convertToType(data['mapLatitude'], 'Number');
            }
            if (data.hasOwnProperty('mapLongitude')) {
                obj['mapLongitude'] = ApiClient.convertToType(data['mapLongitude'], 'Number');
            }
            if (data.hasOwnProperty('meetsSearchCriteria')) {
                obj['meetsSearchCriteria'] = ApiClient.convertToType(data['meetsSearchCriteria'], 'Boolean');
            }
            if (data.hasOwnProperty('availabilityStatus')) {
                obj['availabilityStatus'] = ApiClient.convertToType(data['availabilityStatus'], 'String');
            }
            if (data.hasOwnProperty('productTypeCounts')) {
                obj['productTypeCounts'] = ApiClient.convertToType(data['productTypeCounts'], [ProductTypeCount]);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} locationId
 */
LocationSummaryItem.prototype['locationId'] = undefined;

/**
 * @member {String} locationName
 */
LocationSummaryItem.prototype['locationName'] = undefined;

/**
 * @member {String} matchingAreaName
 */
LocationSummaryItem.prototype['matchingAreaName'] = undefined;

/**
 * @member {Number} minPrice
 */
LocationSummaryItem.prototype['minPrice'] = undefined;

/**
 * @member {Number} maxPrice
 */
LocationSummaryItem.prototype['maxPrice'] = undefined;

/**
 * @member {String} featuredImageUrl
 */
LocationSummaryItem.prototype['featuredImageUrl'] = undefined;

/**
 * @member {Number} latitude
 */
LocationSummaryItem.prototype['latitude'] = undefined;

/**
 * @member {Number} longitude
 */
LocationSummaryItem.prototype['longitude'] = undefined;

/**
 * @member {Number} mapLatitude
 */
LocationSummaryItem.prototype['mapLatitude'] = undefined;

/**
 * @member {Number} mapLongitude
 */
LocationSummaryItem.prototype['mapLongitude'] = undefined;

/**
 * @member {Boolean} meetsSearchCriteria
 */
LocationSummaryItem.prototype['meetsSearchCriteria'] = undefined;

/**
 * @member {String} availabilityStatus
 */
LocationSummaryItem.prototype['availabilityStatus'] = undefined;

/**
 * @member {Array.<module:model/ProductTypeCount>} productTypeCounts
 */
LocationSummaryItem.prototype['productTypeCounts'] = undefined;






export default LocationSummaryItem;

