/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CustomerSearchResult from './CustomerSearchResult';

/**
 * The CustomerSearchResultIEnumerablePageResult model module.
 * @module model/CustomerSearchResultIEnumerablePageResult
 * @version v1
 */
class CustomerSearchResultIEnumerablePageResult {
    /**
     * Constructs a new <code>CustomerSearchResultIEnumerablePageResult</code>.
     * @alias module:model/CustomerSearchResultIEnumerablePageResult
     */
    constructor() { 
        
        CustomerSearchResultIEnumerablePageResult.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CustomerSearchResultIEnumerablePageResult</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CustomerSearchResultIEnumerablePageResult} obj Optional instance to populate.
     * @return {module:model/CustomerSearchResultIEnumerablePageResult} The populated <code>CustomerSearchResultIEnumerablePageResult</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CustomerSearchResultIEnumerablePageResult();

            if (data.hasOwnProperty('data')) {
                obj['data'] = ApiClient.convertToType(data['data'], [CustomerSearchResult]);
            }
            if (data.hasOwnProperty('pageNumber')) {
                obj['pageNumber'] = ApiClient.convertToType(data['pageNumber'], 'Number');
            }
            if (data.hasOwnProperty('itemsPerPage')) {
                obj['itemsPerPage'] = ApiClient.convertToType(data['itemsPerPage'], 'Number');
            }
            if (data.hasOwnProperty('totalItems')) {
                obj['totalItems'] = ApiClient.convertToType(data['totalItems'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<module:model/CustomerSearchResult>} data
 */
CustomerSearchResultIEnumerablePageResult.prototype['data'] = undefined;

/**
 * @member {Number} pageNumber
 */
CustomerSearchResultIEnumerablePageResult.prototype['pageNumber'] = undefined;

/**
 * @member {Number} itemsPerPage
 */
CustomerSearchResultIEnumerablePageResult.prototype['itemsPerPage'] = undefined;

/**
 * @member {Number} totalItems
 */
CustomerSearchResultIEnumerablePageResult.prototype['totalItems'] = undefined;






export default CustomerSearchResultIEnumerablePageResult;

