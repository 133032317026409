import Vue from "vue";
import VueRouter from "vue-router";
import ProfileService from "@/services/ProfileService.js";
import SpotReservationService from "@/services/SpotReservationService.js";
import PricingService from "@/services/PricingService.js";
import { checkPermission } from "@/mixins/PermissionCheckMixin.js";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "",
    component: () => import("@/views/PublicApp.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/views/Home.vue")
      },
      {
        path: "/parksearchresults",
        name: "ParkSearchResults",
        component: () => import("@/views/ParkSearchResults.vue")
      },
      {
        path: "/login",
        name: "Login",
        component: () => import("@/views/Login.vue"),
        beforeEnter: (to, from, next) => {
          const signedIn = store.getters["auth/signedIn"];
          signedIn ? next("/") : next();
        }
      },
      {
        path: "/create-account",
        name: "CreateAccount",
        component: () => import("@/views/CreateAccount.vue"),
        beforeEnter: (to, from, next) => {
          const signedIn = store.getters["auth/signedIn"];
          signedIn ? next("/") : next();
        }
      },
      {
        path: "/account-activation/:userId",
        name: "AccountActivation",
        props: true,
        component: () => import("@/views/AccountActivation.vue"),
        beforeEnter: (to, from, next) => {
          const signedIn = store.getters["auth/signedIn"];
          signedIn ? next("/") : next();
        }
      },
      {
        path: "/confirm-account",
        name: "CreateAccountConfirm",
        component: () => import("@/views/CreateAccountConfirm.vue"),
        beforeEnter: (to, from, next) => {
          store.dispatch("alert/clear");
          const signedIn = store.getters["auth/signedIn"];
          signedIn ? next("/") : next();
        }
      },
      {
        path: "/reset-password",
        name: "ResetPassword",
        component: () => import("@/views/ResetPassword.vue"),
        beforeEnter: (to, from, next) => {
          const signedIn = store.getters["auth/signedIn"];
          signedIn ? next("/") : next();
        }
      },
      {
        path: "/forgot-login",
        name: "ForgotLogin",
        component: () => import("@/views/ForgotLogin.vue")
      },
      {
        path: "/create-reservation",
        name: "CreateReservation",
        component: () => import("@/views/CreateReservation.vue"),
        beforeEnter: (to, from, next) => {
          store.dispatch("alert/clear");
          const tenantId = store.getters["tenant/tenantId"];
          const profileService = new ProfileService(tenantId);
          const isUserCustomer = profileService.isUserCustomer();
          const signedIn = store.getters["auth/signedIn"];
          if (!signedIn || !isUserCustomer) {
            next({ path: "/login", query: { redirect: to.path } });
          } else {
            store.commit("auth/setLoading", true);
            const reservation = store.getters["transaction/reservation"];
            const externalUserId = store.getters["auth/externalUserId"];
            const spotReservationService = new SpotReservationService(tenantId);
            profileService
              .getCustomerByExternalUserId(externalUserId)
              .then(response => {
                spotReservationService
                  .validateSpotReservation({
                    spotId: reservation.spotId,
                    startDate: reservation.startDate,
                    endDate: reservation.endDate,
                    lockCode: reservation.lockCode,
                    customerId: response.data.id,
                    reservationId: reservation.id
                  })
                  .then(response => {
                    if (response?.statusCode == "Failure") {
                      store.commit("alert/setErrorAlert", {
                        type: "alert-danger",
                        message: response.messages[0],
                        layer: "detail"
                      });
                      store.commit(
                        "transaction/setReservationInProgress",
                        false
                      );
                      if (
                        from.path == "/login" &&
                        from.query.redirect == "/create-reservation"
                      ) {
                        store.commit("alert/setErrorQueue", response.messages);
                        next({ path: `/location/${reservation.locationId}` });
                      } else {
                        next(false);
                      }
                    } else {
                      next();
                    }
                  })
                  .finally(() => {
                    store.commit("auth/setLoading", false);
                  });
              });
          }
        }
      },
      {
        path: "/reservation-receipt",
        name: "ReservationReceipt",
        component: () => import("@/views/ReservationReceipt.vue"),
        beforeEnter: (to, from, next) => {
          const profileService = new ProfileService();
          const isUserCustomer = profileService.isUserCustomer();
          const signedIn = store.getters["auth/signedIn"];
          !signedIn || !isUserCustomer ? next("/login") : next();
        }
      },

      {
        path: "/shopping-cart",
        name: "ShoppingCart",
        component: () => import("@/views/ShoppingCart.vue"),
        beforeEnter: (to, from, next) => {
          const profileService = new ProfileService();
          const isUserCustomer = profileService.isUserCustomer();
          const signedIn = store.getters["auth/signedIn"];
          !signedIn || !isUserCustomer ? next("/login") : next();
        }
      },
      {
        path: "/customer-profile/:externalUserId",
        props: true,
        name: "CustomerProfile",
        component: () => import("@/views/CustomerProfile.vue"),
        beforeEnter: (to, from, next) => {
          const profileService = new ProfileService();
          const isUserCustomer = profileService.isUserCustomer();
          const signedIn = store.getters["auth/signedIn"];
          !signedIn || !isUserCustomer ? next("/login") : next();
        }
      },
      {
        path: "/reservation-edit",
        props: route => ({
          customerId: route.query.c,
          reservationId: route.query.r,
          locationId: route.query.l,
          externalUserId: route.query.e
        }),
        name: "CustomerProfileReservationEdit",
        component: () => import("@/views/CustomerProfileReservationEdit.vue"),
        beforeEnter: (to, from, next) => {
          const profileService = new ProfileService();
          const isUserCustomer = profileService.isUserCustomer();
          const signedIn = store.getters["auth/signedIn"];
          !signedIn || !isUserCustomer ? next("/login") : next();
        }
      },
      {
        path: "/reservation-edit-occupant",
        name: "CustomerProfileReservationEditOccupant",
        component: () =>
          import("@/views/CustomerProfileReservationEditOccupant.vue"),
        beforeEnter: async (to, from, next) => {
          const profileService = new ProfileService();
          const isUserCustomer = profileService.isUserCustomer();
          const signedIn = store.getters["auth/signedIn"];
          if (!signedIn || !isUserCustomer) {
            next({ path: "/login", query: { redirect: to.path } });
          } else {
            store.commit("auth/setLoading", true);
            const tenantId = store.getters["tenant/tenantId"];
            const reservation = store.getters["transaction/reservation"];
            const pricingService = new PricingService(tenantId);
            const feeResponse = await pricingService.getFees(
              {
                spotId: +reservation.spotId,
                oldStartDate: reservation.oldStartDate,
                oldEndDate: reservation.oldEndDate,
                oldSpotId: reservation.oldSpotId,
                startDate: reservation.startDate,
                endDate: reservation.endDate,
                numberOfAdults: reservation.numberOfAdults,
                numberOfVehicles: reservation.numberOfVehicles,
                discounts: reservation.discounts,
                oldDiscounts: reservation.discounts,
                customerId: reservation.customerId,
                salesChannel: 1
              },
              2
            );
            const fees = feeResponse.data;
            const spotReservationService = new SpotReservationService(tenantId);
            spotReservationService
              .validateSpotReservationEdit({
                newReservationRequest: {
                  spotId: reservation.spotId,
                  startDate: reservation.startDate,
                  endDate: reservation.endDate,
                  oldStartDate: reservation.oldStartDate,
                  oldEndDate: reservation.oldEndDate,
                  lockCode: reservation.lockCode,
                  customerId: reservation.customerId
                },
                reservationId: reservation.id,
                customerId: reservation.customerId,
                feeOverrides: fees
              })
              .then(response => {
                if (response?.statusCode == "Failure") {
                  store.commit("alert/setErrorAlert", {
                    type: "alert-danger",
                    message: response.messages[0],
                    layer: "detail"
                  });
                  next(false);
                } else {
                  next();
                }
              })
              .finally(() => {
                store.commit("auth/setLoading", false);
              });
          }
        }
      },
      {
        path: "/reservation-cancel-receipt/:orderId",
        props: true,
        name: "ReservationCancelReceipt",
        component: () => import("@/views/ReservationCancelReceipt.vue"),
        beforeEnter: (to, from, next) => {
          const profileService = new ProfileService();
          const isUserCustomer = profileService.isUserCustomer();
          const signedIn = store.getters["auth/signedIn"];
          !signedIn || !isUserCustomer ? next("/login") : next();
        }
      },
      {
        path: "reservation-edit-review",
        props: true,
        name: "CustomerProfileReservationEditReview",
        component: () =>
          import("@/views/CustomerProfileReservationEditReview.vue"),
        beforeEnter: (to, from, next) => {
          const profileService = new ProfileService();
          const isUserCustomer = profileService.isUserCustomer();
          const signedIn = store.getters["auth/signedIn"];
          !signedIn || !isUserCustomer ? next("/login") : next();
        }
      },
      {
        path: "reservation-edit-receipt",
        props: true,
        name: "ReservationEditReceipt",
        component: () => import("@/views/ReservationEditReceipt.vue"),
        beforeEnter: (to, from, next) => {
          const profileService = new ProfileService();
          const isUserCustomer = profileService.isUserCustomer();
          const signedIn = store.getters["auth/signedIn"];
          !signedIn || !isUserCustomer ? next("/login") : next();
        }
      },
      {
        path: "/location/:locationId",
        name: "LocationDetail",
        props: true,
        component: () => import("@/views/LocationDetail.vue")
      },
      {
        path: "/review-reservation",
        name: "ReviewReservation",
        component: () => import("@/views/ReviewReservation.vue"),
        beforeEnter: (to, from, next) => {
          const profileService = new ProfileService();
          const isUserCustomer = profileService.isUserCustomer();
          const signedIn = store.getters["auth/signedIn"];
          !signedIn || !isUserCustomer
            ? next({ path: "/login", query: { redirect: to.path } })
            : next();
        }
      }
    ]
  },
  {
    path: "/admin",
    component: () => import("@/views/Admin/AdminApp.vue"),
    beforeEnter: (to, from, next) => {
      setTimeout(() => {
        const authSource = store.getters["auth/authSource"];
        const profileService = new ProfileService();
        const isUserCustomer = profileService.isUserCustomer();
        isUserCustomer && authSource !== "admin"
          ? next({
              path: "/",
              query: { ...to.query, redirect: to.path }
            })
          : next();

        const signedIn = store.getters["auth/signedIn"];
        !signedIn
          ? next({
              path: "/admin/login",
              query: { ...to.query, redirect: to.path }
            })
          : next();
      }, 100);
    },
    children: [
      {
        path: "verify-login",
        name: "AdminVerifyLogin",
        component: () => import("@/views/Admin/AdminVerifyLogin.vue")
      },
      {
        path: "",
        name: "Dashboard",
        component: () => import("@/views/Admin/AdminHome.vue")
      },
      {
        path: "construction",
        name: "UnderConstruction",
        component: () => import("@/views/Admin/UnderConstruction.vue")
      },
      {
        path: "reservation-search",
        name: "AdminReservationSearch",
        component: () => import("@/views/Admin/AdminReservationSearch.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ReservationManagementView")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "pos-reservations",
        name: "AdminPosReservations",
        component: () => import("@/views/Admin/AdminPosReservations.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ReservationManagementPointOfSaleManagement")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "add-pos-reservations",
        name: "AdminAddPosReservations",
        component: () => import("@/views/Admin/AdminAddPosReservations.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ReservationManagementPointOfSaleManagement")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "edit-pos-reservations",
        name: "AdminAddPosReservations",
        props: route => ({
          pointOfSaleOrderId: route.query.id,
          print: route.query.p
        }),
        component: () => import("@/views/Admin/AdminAddPosReservations.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ReservationManagementPointOfSaleManagement")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "customer-search",
        name: "AdminCustomerSearch",
        component: () => import("@/views/Admin/AdminCustomerSearch.vue")
      },
      {
        path: "customer-add",
        name: "AdminCustomerAdd",
        component: () => import("@/views/Admin/AdminCustomerAdd.vue")
      },
      {
        path: "customer-view/:customerId",
        name: "AdminCustomerView",
        props: true,
        component: () => import("@/views/Admin/AdminCustomerView.vue")
      },
      {
        path: "customer-edit/:customerId",
        name: "AdminCustomerEdit",
        props: true,
        component: () => import("@/views/Admin/AdminCustomerEdit.vue")
      },
      {
        path: "reservation-details/:reservationId",
        name: "AdminReservationDetails",
        props: true,
        component: () => import("@/views/Admin/AdminReservationDetails.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ReservationManagementView")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "reservation-add/:locationId?",
        name: "AdminReservationAdd",
        props: true,
        component: () => import("@/views/Admin/AdminReservationAdd.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ReservationManagementAdd")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "reservation-add-details",
        name: "AdminReservationAddDetails",
        component: () => import("@/views/Admin/AdminReservationAddDetails.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ReservationManagementAdd")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "reservation-add-occupant",
        name: "AdminReservationAddOccupant",
        component: () =>
          import("@/views/Admin/AdminReservationAddOccupant.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ReservationManagementAdd")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "reservation-review",
        name: "AdminReservationAddReview",
        component: () => import("@/views/Admin/AdminReservationAddReview.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ReservationManagementAdd")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "reservation-receipt",
        name: "AdminReservationAddReceipt",
        component: () => import("@/views/Admin/AdminReservationAddReceipt.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ReservationManagementAdd")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "reservation-edit-spot/:reservationId",
        props: true,
        name: "AdminReservationEditSpot",
        component: () => import("@/views/Admin/AdminReservationEditSpot.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ReservationManagementModify")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "reservation-cancel/:reservationId",
        props: true,
        name: "AdminReservationCancel",
        component: () => import("@/views/Admin/AdminReservationCancel.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ReservationManagementCancel")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "reservation-cancel-receipt/:orderId",
        props: true,
        name: "AdminReservationCancelReceipt",
        component: () =>
          import("@/views/Admin/AdminReservationCancelReceipt.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ReservationManagementCancel")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "reservation-edit-occupant",
        name: "AdminReservationEditOccupant",
        component: () =>
          import("@/views/Admin/AdminReservationEditOccupant.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ReservationManagementModify")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "reservation-edit-review",
        name: "AdminReservationEditReview",
        component: () => import("@/views/Admin/AdminReservationEditReview.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ReservationManagementModify")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "reservation-edit-receipt",
        props: true,
        name: "AdminReservationEditReceipt",
        component: () =>
          import("@/views/Admin/AdminReservationEditReceipt.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ReservationManagementModify")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "pricing-seasons",
        name: "AdminPricingSeasons",
        component: () => import("@/views/Admin/AdminPricingSeasons.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsPricingSeasons")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "operational-season-management",
        name: "AdminOperationalSeasonManagement",
        component: () =>
          import("@/views/Admin/AdminOperationalSeasonManagement.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsOperationalSeasons")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "date-block-management",
        name: "AdminDateBlockManagement",
        component: () => import("@/views/Admin/AdminDateBlockManagement.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsDateManagement")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "discount-class-management",
        name: "AdminDiscountClassManagement",
        component: () =>
          import("@/views/Admin/AdminDiscountClassManagement.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsDiscountClass")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "product-class-management",
        name: "AdminProductClassList",
        component: () => import("@/views/Admin/AdminProductClassList.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsProductClasses")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "product-class-modify/:productId",
        name: "AdminProductClassModify",
        component: () => import("@/views/Admin/AdminProductClassModify.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsProductClasses")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "product-class-view/:productId",
        name: "AdminProductClassView",
        component: () => import("@/views/Admin/AdminProductClassView.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsProductClasses")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "product-class-add",
        name: "AdminProductClassAdd",
        component: () => import("@/views/Admin/AdminProductClassAdd.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsProductClasses")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "spot-type-management",
        name: "AdminSpotTypeManagement",
        component: () => import("@/views/Admin/AdminSpotTypeManagement.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsSpotTypes") ? next("/admin") : next();
        }
      },
      {
        path: "product-type-management",
        name: "AdminProductTypeManagement",
        component: () => import("@/views/Admin/AdminProductTypeManagement.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsProductTypes")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "tenant-settings",
        name: "AdminTenantSettings",
        component: () => import("@/views/Admin/AdminTenantSettings.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsTenantSettings")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "points-of-interest-management",
        name: "AdminPointsOfInterestManagement",
        component: () =>
          import("@/views/Admin/AdminPointsOfInterestManagement.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsActivitesAmenities")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "attribute-management",
        name: "AdminAttributeManagement",
        component: () => import("@/views/Admin/AdminAttributeManagement.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsAttributes")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "shopping-cart",
        name: "AdminShoppingCart",
        component: () => import("@/views/Admin/AdminShoppingCart.vue")
      },
      {
        path: "park-search",
        name: "AdminParkSearch",
        component: () => import("@/views/Admin/AdminParkSearch.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ParkManagementParkSearch")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "park-detail/:locationId",
        name: "AdminParkDetail",
        props: route => ({
          view: route.query.v,
          locationId: route.params.locationId
        }),
        component: () => import("@/views/Admin/AdminParkDetail.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ParkManagementParkSearch")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "add-new-park",
        name: "AdminAddNewPark",
        component: () => import("@/views/Admin/AdminAddNewPark.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ParkManagementAddNewPark")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "park-closure-search",
        name: "AdminLocationClosureSearch",
        component: () => import("@/views/Admin/AdminLocationClosureSearch.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ParkManagementParkClosure")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "park-alert-search",
        name: "AdminAlertSearch",
        component: () => import("@/views/Admin/AdminAlertSearch.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ParkManagementParkAlertsGlobal") &&
          !checkPermission("ParkManagementParkAlertsParkSpot")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "spot-search",
        name: "AdminSpotSearch",
        component: () => import("@/views/Admin/AdminSpotSearch.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ParkManagementSpotSearch")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "add-new-spot",
        name: "AdminAddNewSpot",
        component: () => import("@/views/Admin/AdminAddNewSpot.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ParkManagementAddNewSpot")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "spot-detail/:spotId",
        name: "AdminSpotDetail",
        props: route => ({
          view: route.query.v,
          spotId: route.params.spotId
        }),
        component: () => import("@/views/Admin/AdminSpotDetail.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ParkManagementSpotSearch")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "park-closure-add",
        name: "AdminLocationClosureAdd",
        component: () => import("@/views/Admin/AdminLocationClosureAdd.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ParkManagementParkClosure")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "park-closure-modify/:closureId",
        props: true,
        name: "AdminLocationClosureModify",
        component: () => import("@/views/Admin/AdminLocationClosureModify.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ParkManagementParkClosure")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "park-alert-add",
        name: "AdminAlertAdd",
        component: () => import("@/views/Admin/AdminAlertAdd.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ParkManagementParkAlertsGlobal") &&
          !checkPermission("ParkManagementParkAlertsParkSpot")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "park-alert-modify/:alertId",
        props: true,
        name: "AdminAlertModify",
        component: () => import("@/views/Admin/AdminAlertModify.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ParkManagementParkAlertsGlobal") &&
          !checkPermission("ParkManagementParkAlertsParkSpot")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "communication-template-management",
        name: "AdminCommunicationTemplateManagement",
        component: () =>
          import("@/views/Admin/AdminCommunicationTemplateManagement.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsCommunicationTemplates")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "communication-template-add",
        name: "AdminCommunicationTemplateAddNew",
        component: () => import("@/views/Admin/AdminCommunicationTemplate.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsCommunicationTemplates")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "communication-template-modify/:id",
        props: true,
        name: "AdminCommunicationTemplateModify",
        component: () => import("@/views/Admin/AdminCommunicationTemplate.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsCommunicationTemplates")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "user-profile",
        name: "AdminUserProfile",
        component: () => import("@/views/Admin/AdminUserProfile.vue"),
        beforeEnter: (to, from, next) => {
          const signedIn = store.getters["auth/signedIn"];
          !signedIn ? next("/admin/login") : next();
        }
      },
      {
        path: "user-add",
        name: "AdminUserAdd",
        component: () => import("@/views/Admin/AdminUserAdd.vue")
      },
      {
        path: "user-modify/:userId",
        props: true,
        name: "AdminUserModify",
        component: () => import("@/views/Admin/AdminUserModify.vue")
      },
      {
        path: "user-search",
        name: "AdminUserSearch",
        component: () => import("@/views/Admin/AdminUserSearch.vue")
      },
      {
        path: "user-role-management",
        name: "AdminUserRoleManagement",
        component: () => import("@/views/Admin/AdminUserRoleManagement.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsPermissions")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "reservation-check-in/:reservationId",
        name: "AdminReservationCheckIn",
        props: true,
        component: () => import("@/views/Admin/AdminReservationCheckIn.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ReservationManagementView")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "reservation-check-out/:reservationId",
        name: "AdminReservationCheckOut",
        props: true,
        component: () => import("@/views/Admin/AdminReservationCheckOut.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ReservationManagementView")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "bulk-check-in/:locationId",
        name: "AdminBulkCheckIn",
        props: true,
        component: () => import("@/views/Admin/AdminBulkCheckIn.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ReservationManagementView")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "bulk-check-out/:locationId",
        name: "AdminBulkCheckOut",
        props: true,
        component: () => import("@/views/Admin/AdminBulkCheckOut.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("ReservationManagementView")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "brm/agency-walkin-period",
        name: "AdminAgencyWalkinPeriod",
        component: () =>
          import("@/views/Admin/BusinessRules/AdminAgencyWalkinPeriod.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsBusinessRules")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "brm/agency-walkin-period/:id",
        name: "AdminAgencyWalkinPeriodView",
        props: route => ({
          view: route.query.v,
          id: route.params.id,
          refId: route.query.refId
        }),
        component: () =>
          import("@/views/Admin/BusinessRules/AdminAgencyWalkinPeriodView.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsBusinessRules")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "brm/maximum-arrival-date",
        name: "AdminMaximumArrivalDate",
        component: () =>
          import("@/views/Admin/BusinessRules/AdminMaximumArrivalDate.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsBusinessRules")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "brm/maximum-arrival-date/:id",
        name: "AdminMaximumArrivalDateView",
        props: route => ({
          view: route.query.v,
          id: route.params.id,
          refId: route.query.refId
        }),
        component: () =>
          import("@/views/Admin/BusinessRules/AdminMaximumArrivalDateView.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsBusinessRules")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "brm/minimum-arrival-date",
        name: "AdminMinimumArrivalDate",
        component: () =>
          import("@/views/Admin/BusinessRules/AdminMinimumArrivalDate.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsBusinessRules")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "brm/minimum-arrival-date/:id",
        name: "AdminMinimumArrivalDateView",
        props: route => ({
          view: route.query.v,
          id: route.params.id,
          refId: route.query.refId
        }),
        component: () =>
          import("@/views/Admin/BusinessRules/AdminMinimumArrivalDateView.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsBusinessRules")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "brm/minimum-stay",
        name: "AdminMinimumStay",
        component: () =>
          import("@/views/Admin/BusinessRules/AdminMinimumStay.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsBusinessRules")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "brm/minimum-stay/:id",
        name: "AdminMinimumStayView",
        props: route => ({
          view: route.query.v,
          id: route.params.id,
          refId: route.query.refId
        }),
        component: () =>
          import("@/views/Admin/BusinessRules/AdminMinimumStayView.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsBusinessRules")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "brm/maximum-active-reservations-on-account",
        name: "AdminMaximumActiveReservationsOnAccount",
        component: () =>
          import(
            "@/views/Admin/BusinessRules/AdminMaximumActiveReservationsOnAccount.vue"
          ),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsBusinessRules")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "brm/maximum-active-reservations-on-account/:id",
        name: "AdminMaximumActiveReservationsOnAccountView",
        props: route => ({
          view: route.query.v,
          id: route.params.id,
          refId: route.query.refId
        }),
        component: () =>
          import(
            "@/views/Admin/BusinessRules/AdminMaximumActiveReservationsOnAccountView.vue"
          ),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsBusinessRules")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "brm/maximum-reservations-per-transaction",
        name: "AdminMaximumReservationsPerTransaction",
        component: () =>
          import(
            "@/views/Admin/BusinessRules/AdminMaximumReservationsPerTransaction.vue"
          ),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsBusinessRules")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "brm/maximum-reservations-per-transaction/:id",
        name: "AdminMaximumReservationsPerTransactionView",
        props: route => ({
          view: route.query.v,
          id: route.params.id,
          refId: route.query.refId
        }),
        component: () =>
          import(
            "@/views/Admin/BusinessRules/AdminMaximumReservationsPerTransactionView.vue"
          ),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsBusinessRules")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "brm/maximum-stay",
        name: "AdminMaximumStay",
        component: () =>
          import("@/views/Admin/BusinessRules/AdminMaximumStay.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsBusinessRules")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "brm/maximum-stay/:id",
        name: "AdminMaximumStayView",
        props: route => ({
          view: route.query.v,
          id: route.params.id,
          refId: route.query.refId
        }),
        component: () =>
          import("@/views/Admin/BusinessRules/AdminMaximumStayView.vue"),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsBusinessRules")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "brm/maximum-reservations-same-start-date",
        name: "AdminMaximumReservationsSameStartDate",
        component: () =>
          import(
            "@/views/Admin/BusinessRules/AdminMaximumReservationsSameStartDate.vue"
          ),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsBusinessRules")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "brm/maximum-reservations-same-start-date/:id",
        name: "AdminMaximumReservationsSameStartDateView",
        props: route => ({
          view: route.query.v,
          id: route.params.id,
          refId: route.query.refId
        }),
        component: () =>
          import(
            "@/views/Admin/BusinessRules/AdminMaximumReservationsSameStartDateView.vue"
          ),
        beforeEnter: (to, from, next) => {
          !checkPermission("SystemSettingsBusinessRules")
            ? next("/admin")
            : next();
        }
      },
      {
        path: "park-map",
        name: "AdminSpotAndPoiMap",
        component: () => import("@/views/Admin/AdminSpotAndPoiMap.vue"),
        beforeEnter: (to, from, next) => {
          const authSource = store.getters["auth/authSource"];
          authSource !== "admin" || !checkPermission("ParkManagementParkMap")
            ? next({
                path: "/admin"
              })
            : next();
        }
      },
      {
        path: "/admin/reporting",
        name: "AdminReportList",
        component: () => import("@/views/Admin/AdminReportList.vue"),
        beforeEnter: (to, from, next) => {
          const authSource = store.getters["auth/authSource"];
          authSource !== "admin" ||
          !checkPermission("ReportManagementReportManagement")
            ? next({
                path: "/admin"
              })
            : next();
        }
      },
      {
        path: "/admin/reporting/facilitychangeover",
        name: "FacilityChangeoverReport",
        component: () =>
          import("@/views/Admin/Reporting/FacilityChangeoverReport.vue"),
        beforeEnter: (to, from, next) => {
          const authSource = store.getters["auth/authSource"];
          authSource !== "admin" ||
          !checkPermission("ReportManagementReportManagement")
            ? next({
                path: "/admin"
              })
            : next();
        }
      },
      // {
      //   path: "/admin/reporting/disbursement",
      //   name: "DisbursementReport",
      //   component: () =>
      //     import("@/views/Admin/Reporting/DisbursementReport.vue"),
      //   beforeEnter: (to, from, next) => {
      //     const authSource = store.getters["auth/authSource"];
      //     authSource !== "admin" ||
      //     !checkPermission("ReportManagementReportManagement")
      //       ? next({
      //           path: "/admin"
      //         })
      //       : next();
      //   }
      // },
      {
        path: "/admin/reporting/amenities",
        name: "AmenityReport",
        component: () => import("@/views/Admin/Reporting/AmenityReport.vue"),
        beforeEnter: (to, from, next) => {
          const authSource = store.getters["auth/authSource"];
          authSource !== "admin" ||
          !checkPermission("ReportManagementReportManagement")
            ? next({
                path: "/admin"
              })
            : next();
        }
      },
      {
        path: "/admin/reporting/camper",
        name: "CamperReport",
        component: () => import("@/views/Admin/Reporting/CamperReport.vue"),
        beforeEnter: (to, from, next) => {
          const authSource = store.getters["auth/authSource"];
          authSource !== "admin" ||
          !checkPermission("ReportManagementReportManagement")
            ? next({
                path: "/admin"
              })
            : next();
        }
      },
      {
        path: "/admin/reporting/cancelledreservations",
        name: "CancelledReservationsReport",
        component: () =>
          import("@/views/Admin/Reporting/CancelledReservationsReport.vue"),
        beforeEnter: (to, from, next) => {
          const authSource = store.getters["auth/authSource"];
          authSource !== "admin" ||
          !checkPermission("ReportManagementReportManagement")
            ? next({
                path: "/admin"
              })
            : next();
        }
      },
      {
        path: "/admin/reporting/closure",
        name: "ClosureReport",
        component: () => import("@/views/Admin/Reporting/ClosureReport.vue"),
        beforeEnter: (to, from, next) => {
          const authSource = store.getters["auth/authSource"];
          authSource !== "admin" ||
          !checkPermission("ReportManagementReportManagement")
            ? next({
                path: "/admin"
              })
            : next();
        }
      },
      {
        path: "/admin/reporting/maintenance",
        name: "MaintenanceReport",
        component: () =>
          import("@/views/Admin/Reporting/MaintenanceReport.vue"),
        beforeEnter: (to, from, next) => {
          const authSource = store.getters["auth/authSource"];
          authSource !== "admin" ||
          !checkPermission("ReportManagementReportManagement")
            ? next({
                path: "/admin"
              })
            : next();
        }
      },
      {
        path: "/admin/reporting/occupancybyproductclass",
        name: "OccupancyByProductClassReport",
        component: () =>
          import("@/views/Admin/Reporting/OccupancyByProductClassReport.vue"),
        beforeEnter: (to, from, next) => {
          const authSource = store.getters["auth/authSource"];
          authSource !== "admin" ||
          !checkPermission("ReportManagementReportManagement")
            ? next({
                path: "/admin"
              })
            : next();
        }
      },
      {
        path: "/admin/reporting/occupancybyrevenue",
        name: "OccupancyByRevenueReport",
        component: () =>
          import("@/views/Admin/Reporting/OccupancyByRevenueReport.vue"),
        beforeEnter: (to, from, next) => {
          const authSource = store.getters["auth/authSource"];
          authSource !== "admin" ||
          !checkPermission("ReportManagementReportManagement")
            ? next({
                path: "/admin"
              })
            : next();
        }
      },
      {
        path: "/admin/reporting/parkvisitation",
        name: "ParkVisitationByResidencyReport",
        component: () =>
          import("@/views/Admin/Reporting/ParkVisitationByResidencyReport.vue"),
        beforeEnter: (to, from, next) => {
          const authSource = store.getters["auth/authSource"];
          authSource !== "admin" ||
          !checkPermission("ReportManagementReportManagement")
            ? next({
                path: "/admin"
              })
            : next();
        }
      },
      {
        path: "/admin/reporting/reservationmethod",
        name: "ReservationMethodReport",
        component: () =>
          import("@/views/Admin/Reporting/ReservationMethodReport.vue"),
        beforeEnter: (to, from, next) => {
          const authSource = store.getters["auth/authSource"];
          authSource !== "admin" ||
          !checkPermission("ReportManagementReportManagement")
            ? next({
                path: "/admin"
              })
            : next();
        }
      },
      {
        path: "/admin/reporting/adminoverride",
        name: "OverrideReport",
        component: () => import("@/views/Admin/Reporting/OverrideReport.vue"),
        beforeEnter: (to, from, next) => {
          const authSource = store.getters["auth/authSource"];
          authSource !== "admin" ||
          !checkPermission("ReportManagementReportManagement")
            ? next({
                path: "/admin"
              })
            : next();
        }
      },
      {
        path: "/admin/reporting/sitemarker",
        name: "SiteMarkerReport",
        component: () => import("@/views/Admin/Reporting/SiteMarkerReport.vue"),
        beforeEnter: (to, from, next) => {
          const authSource = store.getters["auth/authSource"];
          authSource !== "admin" ||
          !checkPermission("ReportManagementReportManagement")
            ? next({
                path: "/admin"
              })
            : next();
        }
      },
      {
        path: "/admin/reporting/revenuebreakup",
        name: "RevenueBreakupReport",
        component: () =>
          import("@/views/Admin/Reporting/RevenueBreakupReport.vue"),
        beforeEnter: (to, from, next) => {
          const authSource = store.getters["auth/authSource"];
          authSource !== "admin" ||
          !checkPermission("ReportManagementReportManagement")
            ? next({
                path: "/admin"
              })
            : next();
        }
      },
      {
        path: "/admin/reporting/fielddeposit",
        name: "FieldDepositReport",
        component: () =>
          import("@/views/Admin/Reporting/FieldDepositReport.vue"),
        beforeEnter: (to, from, next) => {
          const authSource = store.getters["auth/authSource"];
          authSource !== "admin" ||
          !checkPermission("ReportManagementReportManagement")
            ? next({
                path: "/admin"
              })
            : next();
        }
      }
    ]
  },
  {
    path: "/admin/login",
    name: "AdminLogin",
    component: () => import("@/views/AdminLogin.vue")
  },
  {
    path: "/admin/forgot-login",
    name: "AdminForgotLogin",
    component: () => import("@/views/Admin/AdminForgotLogin.vue")
  },
  {
    path: "/admin/reset-password",
    name: "AdminResetPassword",
    component: () => import("@/views/Admin/AdminResetPassword.vue")
  },
  {
    path: "/admin/confirm-account",
    name: "AdminCreateAccountConfirm",
    component: () => import("@/views/Admin/AdminCreateAccountConfirm.vue")
  },
  {
    path: "/admin/account-activation/:userId",
    name: "AdminAccountActivation",
    props: true,
    component: () => import("@/views/Admin/AdminAccountActivation.vue"),
    beforeEnter: (to, from, next) => {
      const signedIn = store.getters["auth/signedIn"];
      signedIn ? next("/admin") : next();
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  let alert = store.getters["alert/errorAlert"];
  if (!alert.persist) {
    store.dispatch("alert/clear");
  }
  store.commit("auth/setLoading", false);
  if (to.path !== "/admin/login" && to.path !== "/admin/verify-login") {
    await store.dispatch("auth/isAuthenticated");
  }
  next();

  // Set focus to header on page reload - fix for keyboard only users.
  document.getElementById("app").focus();
});

export default router;
