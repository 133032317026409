/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CustomerAddress model module.
 * @module model/CustomerAddress
 * @version v1
 */
class CustomerAddress {
    /**
     * Constructs a new <code>CustomerAddress</code>.
     * @alias module:model/CustomerAddress
     */
    constructor() { 
        
        CustomerAddress.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CustomerAddress</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CustomerAddress} obj Optional instance to populate.
     * @return {module:model/CustomerAddress} The populated <code>CustomerAddress</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CustomerAddress();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('street1')) {
                obj['street1'] = ApiClient.convertToType(data['street1'], 'String');
            }
            if (data.hasOwnProperty('street2')) {
                obj['street2'] = ApiClient.convertToType(data['street2'], 'String');
            }
            if (data.hasOwnProperty('city')) {
                obj['city'] = ApiClient.convertToType(data['city'], 'String');
            }
            if (data.hasOwnProperty('state')) {
                obj['state'] = ApiClient.convertToType(data['state'], 'String');
            }
            if (data.hasOwnProperty('zipcode')) {
                obj['zipcode'] = ApiClient.convertToType(data['zipcode'], 'String');
            }
            if (data.hasOwnProperty('zipcodePlus4')) {
                obj['zipcodePlus4'] = ApiClient.convertToType(data['zipcodePlus4'], 'String');
            }
            if (data.hasOwnProperty('country')) {
                obj['country'] = ApiClient.convertToType(data['country'], 'String');
            }
            if (data.hasOwnProperty('isPrimary')) {
                obj['isPrimary'] = ApiClient.convertToType(data['isPrimary'], 'Boolean');
            }
            if (data.hasOwnProperty('addressTypeId')) {
                obj['addressTypeId'] = ApiClient.convertToType(data['addressTypeId'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
CustomerAddress.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
CustomerAddress.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
CustomerAddress.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
CustomerAddress.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
CustomerAddress.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
CustomerAddress.prototype['tenantId'] = undefined;

/**
 * @member {String} street1
 */
CustomerAddress.prototype['street1'] = undefined;

/**
 * @member {String} street2
 */
CustomerAddress.prototype['street2'] = undefined;

/**
 * @member {String} city
 */
CustomerAddress.prototype['city'] = undefined;

/**
 * @member {String} state
 */
CustomerAddress.prototype['state'] = undefined;

/**
 * @member {String} zipcode
 */
CustomerAddress.prototype['zipcode'] = undefined;

/**
 * @member {String} zipcodePlus4
 */
CustomerAddress.prototype['zipcodePlus4'] = undefined;

/**
 * @member {String} country
 */
CustomerAddress.prototype['country'] = undefined;

/**
 * @member {Boolean} isPrimary
 */
CustomerAddress.prototype['isPrimary'] = undefined;

/**
 * @member {Number} addressTypeId
 */
CustomerAddress.prototype['addressTypeId'] = undefined;






export default CustomerAddress;

