/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CustomerPhoneNumber model module.
 * @module model/CustomerPhoneNumber
 * @version v1
 */
class CustomerPhoneNumber {
    /**
     * Constructs a new <code>CustomerPhoneNumber</code>.
     * @alias module:model/CustomerPhoneNumber
     */
    constructor() { 
        
        CustomerPhoneNumber.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CustomerPhoneNumber</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CustomerPhoneNumber} obj Optional instance to populate.
     * @return {module:model/CustomerPhoneNumber} The populated <code>CustomerPhoneNumber</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CustomerPhoneNumber();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('number')) {
                obj['number'] = ApiClient.convertToType(data['number'], 'String');
            }
            if (data.hasOwnProperty('isPrimary')) {
                obj['isPrimary'] = ApiClient.convertToType(data['isPrimary'], 'Boolean');
            }
            if (data.hasOwnProperty('phoneNumberTypeId')) {
                obj['phoneNumberTypeId'] = ApiClient.convertToType(data['phoneNumberTypeId'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
CustomerPhoneNumber.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
CustomerPhoneNumber.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
CustomerPhoneNumber.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
CustomerPhoneNumber.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
CustomerPhoneNumber.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
CustomerPhoneNumber.prototype['tenantId'] = undefined;

/**
 * @member {String} number
 */
CustomerPhoneNumber.prototype['number'] = undefined;

/**
 * @member {Boolean} isPrimary
 */
CustomerPhoneNumber.prototype['isPrimary'] = undefined;

/**
 * @member {Number} phoneNumberTypeId
 */
CustomerPhoneNumber.prototype['phoneNumberTypeId'] = undefined;






export default CustomerPhoneNumber;

