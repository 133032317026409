/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PaymentTypeEnum from './PaymentTypeEnum';
import ReservationContact from './ReservationContact';
import ReservationDiscountClass from './ReservationDiscountClass';
import ReservationFee from './ReservationFee';
import ReservationStatusEnum from './ReservationStatusEnum';

/**
 * The CustomerReservationDetail model module.
 * @module model/CustomerReservationDetail
 * @version v1
 */
class CustomerReservationDetail {
    /**
     * Constructs a new <code>CustomerReservationDetail</code>.
     * @alias module:model/CustomerReservationDetail
     */
    constructor() { 
        
        CustomerReservationDetail.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CustomerReservationDetail</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CustomerReservationDetail} obj Optional instance to populate.
     * @return {module:model/CustomerReservationDetail} The populated <code>CustomerReservationDetail</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CustomerReservationDetail();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('locationId')) {
                obj['locationId'] = ApiClient.convertToType(data['locationId'], 'Number');
            }
            if (data.hasOwnProperty('locationImageUrl')) {
                obj['locationImageUrl'] = ApiClient.convertToType(data['locationImageUrl'], 'String');
            }
            if (data.hasOwnProperty('parkName')) {
                obj['parkName'] = ApiClient.convertToType(data['parkName'], 'String');
            }
            if (data.hasOwnProperty('parkStreet1')) {
                obj['parkStreet1'] = ApiClient.convertToType(data['parkStreet1'], 'String');
            }
            if (data.hasOwnProperty('parkStreet2')) {
                obj['parkStreet2'] = ApiClient.convertToType(data['parkStreet2'], 'String');
            }
            if (data.hasOwnProperty('parkCity')) {
                obj['parkCity'] = ApiClient.convertToType(data['parkCity'], 'String');
            }
            if (data.hasOwnProperty('parkState')) {
                obj['parkState'] = ApiClient.convertToType(data['parkState'], 'String');
            }
            if (data.hasOwnProperty('parkZipcode')) {
                obj['parkZipcode'] = ApiClient.convertToType(data['parkZipcode'], 'String');
            }
            if (data.hasOwnProperty('parkPhone')) {
                obj['parkPhone'] = ApiClient.convertToType(data['parkPhone'], 'String');
            }
            if (data.hasOwnProperty('loopName')) {
                obj['loopName'] = ApiClient.convertToType(data['loopName'], 'String');
            }
            if (data.hasOwnProperty('spotId')) {
                obj['spotId'] = ApiClient.convertToType(data['spotId'], 'Number');
            }
            if (data.hasOwnProperty('spotName')) {
                obj['spotName'] = ApiClient.convertToType(data['spotName'], 'String');
            }
            if (data.hasOwnProperty('lockCode')) {
                obj['lockCode'] = ApiClient.convertToType(data['lockCode'], 'String');
            }
            if (data.hasOwnProperty('classification')) {
                obj['classification'] = ApiClient.convertToType(data['classification'], 'String');
            }
            if (data.hasOwnProperty('reservationNumber')) {
                obj['reservationNumber'] = ApiClient.convertToType(data['reservationNumber'], 'String');
            }
            if (data.hasOwnProperty('daysUntilTrip')) {
                obj['daysUntilTrip'] = ApiClient.convertToType(data['daysUntilTrip'], 'String');
            }
            if (data.hasOwnProperty('orderNumber')) {
                obj['orderNumber'] = ApiClient.convertToType(data['orderNumber'], 'String');
            }
            if (data.hasOwnProperty('arrivalDate')) {
                obj['arrivalDate'] = ApiClient.convertToType(data['arrivalDate'], 'String');
            }
            if (data.hasOwnProperty('departureDate')) {
                obj['departureDate'] = ApiClient.convertToType(data['departureDate'], 'String');
            }
            if (data.hasOwnProperty('numberOfAdults')) {
                obj['numberOfAdults'] = ApiClient.convertToType(data['numberOfAdults'], 'Number');
            }
            if (data.hasOwnProperty('numberOfVehicles')) {
                obj['numberOfVehicles'] = ApiClient.convertToType(data['numberOfVehicles'], 'Number');
            }
            if (data.hasOwnProperty('equipmentType')) {
                obj['equipmentType'] = ApiClient.convertToType(data['equipmentType'], 'String');
            }
            if (data.hasOwnProperty('equipmentLength')) {
                obj['equipmentLength'] = ApiClient.convertToType(data['equipmentLength'], 'Number');
            }
            if (data.hasOwnProperty('customerModifiable')) {
                obj['customerModifiable'] = ApiClient.convertToType(data['customerModifiable'], 'Boolean');
            }
            if (data.hasOwnProperty('discounts')) {
                obj['discounts'] = ApiClient.convertToType(data['discounts'], [ReservationDiscountClass]);
            }
            if (data.hasOwnProperty('primaryOccupant')) {
                obj['primaryOccupant'] = ReservationContact.constructFromObject(data['primaryOccupant']);
            }
            if (data.hasOwnProperty('transactionDate')) {
                obj['transactionDate'] = ApiClient.convertToType(data['transactionDate'], 'String');
            }
            if (data.hasOwnProperty('reservationStatus')) {
                obj['reservationStatus'] = ReservationStatusEnum.constructFromObject(data['reservationStatus']);
            }
            if (data.hasOwnProperty('recentRefundAmount')) {
                obj['recentRefundAmount'] = ApiClient.convertToType(data['recentRefundAmount'], 'Number');
            }
            if (data.hasOwnProperty('previousParkUseFeePaid')) {
                obj['previousParkUseFeePaid'] = ApiClient.convertToType(data['previousParkUseFeePaid'], 'Number');
            }
            if (data.hasOwnProperty('paymentType')) {
                obj['paymentType'] = PaymentTypeEnum.constructFromObject(data['paymentType']);
            }
            if (data.hasOwnProperty('reservationFees')) {
                obj['reservationFees'] = ApiClient.convertToType(data['reservationFees'], [ReservationFee]);
            }
            if (data.hasOwnProperty('isDayUse')) {
                obj['isDayUse'] = ApiClient.convertToType(data['isDayUse'], 'Boolean');
            }
            if (data.hasOwnProperty('bookingType')) {
                obj['bookingType'] = ApiClient.convertToType(data['bookingType'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
CustomerReservationDetail.prototype['id'] = undefined;

/**
 * @member {Number} locationId
 */
CustomerReservationDetail.prototype['locationId'] = undefined;

/**
 * @member {String} locationImageUrl
 */
CustomerReservationDetail.prototype['locationImageUrl'] = undefined;

/**
 * @member {String} parkName
 */
CustomerReservationDetail.prototype['parkName'] = undefined;

/**
 * @member {String} parkStreet1
 */
CustomerReservationDetail.prototype['parkStreet1'] = undefined;

/**
 * @member {String} parkStreet2
 */
CustomerReservationDetail.prototype['parkStreet2'] = undefined;

/**
 * @member {String} parkCity
 */
CustomerReservationDetail.prototype['parkCity'] = undefined;

/**
 * @member {String} parkState
 */
CustomerReservationDetail.prototype['parkState'] = undefined;

/**
 * @member {String} parkZipcode
 */
CustomerReservationDetail.prototype['parkZipcode'] = undefined;

/**
 * @member {String} parkPhone
 */
CustomerReservationDetail.prototype['parkPhone'] = undefined;

/**
 * @member {String} loopName
 */
CustomerReservationDetail.prototype['loopName'] = undefined;

/**
 * @member {Number} spotId
 */
CustomerReservationDetail.prototype['spotId'] = undefined;

/**
 * @member {String} spotName
 */
CustomerReservationDetail.prototype['spotName'] = undefined;

/**
 * @member {String} lockCode
 */
CustomerReservationDetail.prototype['lockCode'] = undefined;

/**
 * @member {String} classification
 */
CustomerReservationDetail.prototype['classification'] = undefined;

/**
 * @member {String} reservationNumber
 */
CustomerReservationDetail.prototype['reservationNumber'] = undefined;

/**
 * @member {String} daysUntilTrip
 */
CustomerReservationDetail.prototype['daysUntilTrip'] = undefined;

/**
 * @member {String} orderNumber
 */
CustomerReservationDetail.prototype['orderNumber'] = undefined;

/**
 * @member {String} arrivalDate
 */
CustomerReservationDetail.prototype['arrivalDate'] = undefined;

/**
 * @member {String} departureDate
 */
CustomerReservationDetail.prototype['departureDate'] = undefined;

/**
 * @member {Number} numberOfAdults
 */
CustomerReservationDetail.prototype['numberOfAdults'] = undefined;

/**
 * @member {Number} numberOfVehicles
 */
CustomerReservationDetail.prototype['numberOfVehicles'] = undefined;

/**
 * @member {String} equipmentType
 */
CustomerReservationDetail.prototype['equipmentType'] = undefined;

/**
 * @member {Number} equipmentLength
 */
CustomerReservationDetail.prototype['equipmentLength'] = undefined;

/**
 * @member {Boolean} customerModifiable
 */
CustomerReservationDetail.prototype['customerModifiable'] = undefined;

/**
 * @member {Array.<module:model/ReservationDiscountClass>} discounts
 */
CustomerReservationDetail.prototype['discounts'] = undefined;

/**
 * @member {module:model/ReservationContact} primaryOccupant
 */
CustomerReservationDetail.prototype['primaryOccupant'] = undefined;

/**
 * @member {String} transactionDate
 */
CustomerReservationDetail.prototype['transactionDate'] = undefined;

/**
 * @member {module:model/ReservationStatusEnum} reservationStatus
 */
CustomerReservationDetail.prototype['reservationStatus'] = undefined;

/**
 * @member {Number} recentRefundAmount
 */
CustomerReservationDetail.prototype['recentRefundAmount'] = undefined;

/**
 * @member {Number} previousParkUseFeePaid
 */
CustomerReservationDetail.prototype['previousParkUseFeePaid'] = undefined;

/**
 * @member {module:model/PaymentTypeEnum} paymentType
 */
CustomerReservationDetail.prototype['paymentType'] = undefined;

/**
 * @member {Array.<module:model/ReservationFee>} reservationFees
 */
CustomerReservationDetail.prototype['reservationFees'] = undefined;

/**
 * @member {Boolean} isDayUse
 */
CustomerReservationDetail.prototype['isDayUse'] = undefined;

/**
 * @member {String} bookingType
 */
CustomerReservationDetail.prototype['bookingType'] = undefined;






export default CustomerReservationDetail;

