/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Merchandise model module.
 * @module model/Merchandise
 * @version v1
 */
class Merchandise {
    /**
     * Constructs a new <code>Merchandise</code>.
     * @alias module:model/Merchandise
     */
    constructor() { 
        
        Merchandise.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Merchandise</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Merchandise} obj Optional instance to populate.
     * @return {module:model/Merchandise} The populated <code>Merchandise</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Merchandise();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('productId')) {
                obj['productId'] = ApiClient.convertToType(data['productId'], 'Number');
            }
            if (data.hasOwnProperty('quantity')) {
                obj['quantity'] = ApiClient.convertToType(data['quantity'], 'Number');
            }
            if (data.hasOwnProperty('amountPerItem')) {
                obj['amountPerItem'] = ApiClient.convertToType(data['amountPerItem'], 'Number');
            }
            if (data.hasOwnProperty('sku')) {
                obj['sku'] = ApiClient.convertToType(data['sku'], 'String');
            }
            if (data.hasOwnProperty('orderId')) {
                obj['orderId'] = ApiClient.convertToType(data['orderId'], 'Number');
            }
            if (data.hasOwnProperty('spotReservationId')) {
                obj['spotReservationId'] = ApiClient.convertToType(data['spotReservationId'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('total')) {
                obj['total'] = ApiClient.convertToType(data['total'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
Merchandise.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
Merchandise.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
Merchandise.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
Merchandise.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
Merchandise.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
Merchandise.prototype['tenantId'] = undefined;

/**
 * @member {Number} productId
 */
Merchandise.prototype['productId'] = undefined;

/**
 * @member {Number} quantity
 */
Merchandise.prototype['quantity'] = undefined;

/**
 * @member {Number} amountPerItem
 */
Merchandise.prototype['amountPerItem'] = undefined;

/**
 * @member {String} sku
 */
Merchandise.prototype['sku'] = undefined;

/**
 * @member {Number} orderId
 */
Merchandise.prototype['orderId'] = undefined;

/**
 * @member {Number} spotReservationId
 */
Merchandise.prototype['spotReservationId'] = undefined;

/**
 * @member {String} name
 */
Merchandise.prototype['name'] = undefined;

/**
 * @member {Number} total
 */
Merchandise.prototype['total'] = undefined;






export default Merchandise;

