/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The TenantPointOfInterest model module.
 * @module model/TenantPointOfInterest
 * @version v1
 */
class TenantPointOfInterest {
    /**
     * Constructs a new <code>TenantPointOfInterest</code>.
     * @alias module:model/TenantPointOfInterest
     */
    constructor() { 
        
        TenantPointOfInterest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>TenantPointOfInterest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/TenantPointOfInterest} obj Optional instance to populate.
     * @return {module:model/TenantPointOfInterest} The populated <code>TenantPointOfInterest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TenantPointOfInterest();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('pointOfInterestId')) {
                obj['pointOfInterestId'] = ApiClient.convertToType(data['pointOfInterestId'], 'Number');
            }
            if (data.hasOwnProperty('isSearchable')) {
                obj['isSearchable'] = ApiClient.convertToType(data['isSearchable'], 'Boolean');
            }
            if (data.hasOwnProperty('isMappable')) {
                obj['isMappable'] = ApiClient.convertToType(data['isMappable'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
TenantPointOfInterest.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
TenantPointOfInterest.prototype['tenantId'] = undefined;

/**
 * @member {Number} pointOfInterestId
 */
TenantPointOfInterest.prototype['pointOfInterestId'] = undefined;

/**
 * @member {Boolean} isSearchable
 */
TenantPointOfInterest.prototype['isSearchable'] = undefined;

/**
 * @member {Boolean} isMappable
 */
TenantPointOfInterest.prototype['isMappable'] = undefined;






export default TenantPointOfInterest;

