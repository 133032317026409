/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CartItem from './CartItem';
import CartStatusEnum from './CartStatusEnum';

/**
 * The Cart model module.
 * @module model/Cart
 * @version v1
 */
class Cart {
    /**
     * Constructs a new <code>Cart</code>.
     * @alias module:model/Cart
     */
    constructor() { 
        
        Cart.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Cart</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Cart} obj Optional instance to populate.
     * @return {module:model/Cart} The populated <code>Cart</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Cart();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = CartStatusEnum.constructFromObject(data['status']);
            }
            if (data.hasOwnProperty('totalCartAmount')) {
                obj['totalCartAmount'] = ApiClient.convertToType(data['totalCartAmount'], 'Number');
            }
            if (data.hasOwnProperty('lockedUntilDate')) {
                obj['lockedUntilDate'] = ApiClient.convertToType(data['lockedUntilDate'], 'Date');
            }
            if (data.hasOwnProperty('items')) {
                obj['items'] = ApiClient.convertToType(data['items'], [CartItem]);
            }
        }
        return obj;
    }


}

/**
 * @member {String} id
 */
Cart.prototype['id'] = undefined;

/**
 * @member {module:model/CartStatusEnum} status
 */
Cart.prototype['status'] = undefined;

/**
 * @member {Number} totalCartAmount
 */
Cart.prototype['totalCartAmount'] = undefined;

/**
 * @member {Date} lockedUntilDate
 */
Cart.prototype['lockedUntilDate'] = undefined;

/**
 * @member {Array.<module:model/CartItem>} items
 */
Cart.prototype['items'] = undefined;






export default Cart;

