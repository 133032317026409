/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CaptchaVerifyResponse from '../model/CaptchaVerifyResponse';
import ConfirmUserRegistrationRequest from '../model/ConfirmUserRegistrationRequest';
import LoginRequest from '../model/LoginRequest';
import LoginResponse from '../model/LoginResponse';
import ResetPasswordConfirmationRequest from '../model/ResetPasswordConfirmationRequest';
import UserLoggedOutTokenResponse from '../model/UserLoggedOutTokenResponse';
import UserResponse from '../model/UserResponse';
import UserVerificationResponse from '../model/UserVerificationResponse';

/**
* AdminAuth service.
* @module api/AdminAuthApi
* @version v1
*/
export default class AdminAuthApi {

    /**
    * Constructs a new AdminAuthApi. 
    * @alias module:api/AdminAuthApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.token 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CaptchaVerifyResponse} and HTTP response
     */
    v1TenantTenantIdAdminAuthCaptchaVerifyPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAuthCaptchaVerifyPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'token': opts['token']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CaptchaVerifyResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/AdminAuth/CaptchaVerify', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.token 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CaptchaVerifyResponse}
     */
    v1TenantTenantIdAdminAuthCaptchaVerifyPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAuthCaptchaVerifyPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ResetPasswordConfirmationRequest} opts.resetPasswordConfirmationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserResponse} and HTTP response
     */
    v1TenantTenantIdAdminAuthConfirmResetPasswordPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['resetPasswordConfirmationRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAuthConfirmResetPasswordPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/AdminAuth/ConfirmResetPassword', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ResetPasswordConfirmationRequest} opts.resetPasswordConfirmationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserResponse}
     */
    v1TenantTenantIdAdminAuthConfirmResetPasswordPut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAuthConfirmResetPasswordPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ConfirmUserRegistrationRequest} opts.confirmUserRegistrationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserResponse} and HTTP response
     */
    v1TenantTenantIdAdminAuthConfirmUserRegistrationPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['confirmUserRegistrationRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAuthConfirmUserRegistrationPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/AdminAuth/ConfirmUserRegistration', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ConfirmUserRegistrationRequest} opts.confirmUserRegistrationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserResponse}
     */
    v1TenantTenantIdAdminAuthConfirmUserRegistrationPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAuthConfirmUserRegistrationPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} email 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserResponse} and HTTP response
     */
    v1TenantTenantIdAdminAuthForgotUsernameEmailPostWithHttpInfo(email, tenantId) {
      let postBody = null;
      // verify the required parameter 'email' is set
      if (email === undefined || email === null) {
        throw new Error("Missing the required parameter 'email' when calling v1TenantTenantIdAdminAuthForgotUsernameEmailPost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAuthForgotUsernameEmailPost");
      }

      let pathParams = {
        'email': email,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/AdminAuth/ForgotUsername/{email}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} email 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserResponse}
     */
    v1TenantTenantIdAdminAuthForgotUsernameEmailPost(email, tenantId) {
      return this.v1TenantTenantIdAdminAuthForgotUsernameEmailPostWithHttpInfo(email, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/LoginRequest} opts.loginRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LoginResponse} and HTTP response
     */
    v1TenantTenantIdAdminAuthLoginPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['loginRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAuthLoginPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LoginResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/AdminAuth/login', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/LoginRequest} opts.loginRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LoginResponse}
     */
    v1TenantTenantIdAdminAuthLoginPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAuthLoginPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.token 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserLoggedOutTokenResponse} and HTTP response
     */
    v1TenantTenantIdAdminAuthLogoutPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAuthLogoutPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'token': opts['token']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserLoggedOutTokenResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/AdminAuth/logout', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.token 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserLoggedOutTokenResponse}
     */
    v1TenantTenantIdAdminAuthLogoutPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAuthLogoutPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} userId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserResponse} and HTTP response
     */
    v1TenantTenantIdAdminAuthResendRegistrationCodeUserIdPostWithHttpInfo(userId, tenantId) {
      let postBody = null;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling v1TenantTenantIdAdminAuthResendRegistrationCodeUserIdPost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAuthResendRegistrationCodeUserIdPost");
      }

      let pathParams = {
        'userId': userId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/AdminAuth/ResendRegistrationCode/{userId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} userId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserResponse}
     */
    v1TenantTenantIdAdminAuthResendRegistrationCodeUserIdPost(userId, tenantId) {
      return this.v1TenantTenantIdAdminAuthResendRegistrationCodeUserIdPostWithHttpInfo(userId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} username 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserResponse} and HTTP response
     */
    v1TenantTenantIdAdminAuthResetPasswordUsernamePostWithHttpInfo(username, tenantId) {
      let postBody = null;
      // verify the required parameter 'username' is set
      if (username === undefined || username === null) {
        throw new Error("Missing the required parameter 'username' when calling v1TenantTenantIdAdminAuthResetPasswordUsernamePost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAuthResetPasswordUsernamePost");
      }

      let pathParams = {
        'username': username,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/AdminAuth/ResetPassword/{username}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} username 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserResponse}
     */
    v1TenantTenantIdAdminAuthResetPasswordUsernamePost(username, tenantId) {
      return this.v1TenantTenantIdAdminAuthResetPasswordUsernamePostWithHttpInfo(username, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.userId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserVerificationResponse} and HTTP response
     */
    v1TenantTenantIdAdminAuthUserVerificationGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAuthUserVerificationGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'userId': opts['userId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserVerificationResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/AdminAuth/UserVerification', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.userId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserVerificationResponse}
     */
    v1TenantTenantIdAdminAuthUserVerificationGet(tenantId, opts) {
      return this.v1TenantTenantIdAdminAuthUserVerificationGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
