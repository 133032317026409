import ParkSearchService from "@/services/ParkSearchService.js";

const state = {
  searchTerm: null,
  selectedLatitude: null,
  selectedLongitude: null,
  selectedSpotTypes: null,
  selectedProductClassifications: null,
  selectedRegions: null,
  selectedPointsOfInterest: null,
  selectedAttributes: null,
  startDate: null,
  endDate: null,
  selectedSpotId: null,
  selectedSpotIsDayUse: null,
  searchView: "list",
  selectedLoopId: null,
  selectedSpotName: null,
  onlyShowAvailable: false,
  spotSearchHasBeenClicked: false,
  spotsCount: 0
};
const getters = {
  searchTerm: state => state.searchTerm,
  selectedLatitude: state => state.selectedLatitude,
  selectedLongitude: state => state.selectedLongitude,
  selectedSpotTypes: state => state.selectedSpotTypes,
  selectedProductClassifications: state => state.selectedProductClassifications,
  selectedRegions: state => state.selectedRegions,
  selectedPointsOfInterest: state => state.selectedPointsOfInterest,
  selectedAttributes: state => state.selectedAttributes,
  startDate: state => state.startDate,
  endDate: state => state.endDate,
  selectedSpotId: state => state.selectedSpotId,
  selectedSpotIsDayUse: state => state.selectedSpotIsDayUse,
  searchView: state => state.searchView,
  selectedLoopId: state => state.selectedLoopId,
  selectedSpotName: state => state.selectedSpotName,
  onlyShowAvailable: state => state.onlyShowAvailable,
  spotSearchHasBeenClicked: state => state.spotSearchHasBeenClicked,
  spotsCount: state => state.spotsCount
};
const actions = {
  clear({ commit }) {
    commit("setSelectedPointsOfInterest", null);
    commit("setSelectedAttributes", null);
    commit("setSelectedSpotTypes", null);
    commit("setSearchTerm", null);
    commit("setSelectedLatitude", null);
    commit("setSelectedLongitude", null);
    commit("setSelectedProductClassifications", null);
    commit("setSelectedRegions", null);
    commit("setSearchDate", { key: "startDate", value: null });
    commit("setSearchDate", { key: "endDate", value: null });
    commit("setSelectedSpotId", null);
    commit("setSelectedSpotIsDayUse", null);
    commit("setSelectedLoopId", null);
    commit("setOnlyShowAvailable", false);
    commit("setSpotSearchHasBeenClicked", false);
    commit("setSelectedSpotName", null);
    commit("setSpotsCount", 0);
  },
  searchMapbox({ rootGetters }, searchTerm) {
    let mapboxUrl = process.env.VUE_APP_MAP_SEARCH_API_URL;
    let mapboxApiKey = process.env.VUE_APP_MAP_API_KEY;
    const tenantId = rootGetters["tenant/tenantId"];
    const tenant = rootGetters["tenant/tenantInfo"];
    var parkSearchService = new ParkSearchService(
      mapboxUrl,
      mapboxApiKey,
      tenantId
    );
    return parkSearchService.mapboxSearch(
      searchTerm,
      tenant.latitude,
      tenant.longitude,
      tenant.mapSearchStateFilter
    );
  },
  searchParksByName({ rootGetters }, options) {
    let mapboxUrl = process.env.VUE_APP_MAP_SEARCH_API_URL;
    let mapboxApiKey = process.env.VUE_APP_MAP_API_KEY;
    const tenantId = rootGetters["tenant/tenantId"];
    var parkSearchService = new ParkSearchService(
      mapboxUrl,
      mapboxApiKey,
      tenantId
    );
    return parkSearchService.searchParksByName(
      options.searchTerm,
      options.pageNumber,
      options.itemsPerPage
    );
  },
  searchParksByLocation({ rootGetters }, options) {
    let mapboxUrl = process.env.VUE_APP_MAP_SEARCH_API_URL;
    let mapboxApiKey = process.env.VUE_APP_MAP_API_KEY;
    const tenantId = rootGetters["tenant/tenantId"];
    var parkSearchService = new ParkSearchService(
      mapboxUrl,
      mapboxApiKey,
      tenantId
    );
    let results = parkSearchService.searchParksByLocation(
      options.latitude,
      options.longitude,
      options.pageNumber,
      options.itemsPerPage
    );
    return results;
  },
  getParks({ rootGetters }, options) {
    let mapboxUrl = process.env.VUE_APP_MAP_SEARCH_API_URL;
    let mapboxApiKey = process.env.VUE_APP_MAP_API_KEY;
    const tenantId = rootGetters["tenant/tenantId"];
    var parkSearchService = new ParkSearchService(
      mapboxUrl,
      mapboxApiKey,
      tenantId
    );
    let results = parkSearchService.searchParksByLocation(
      options.pageNumber,
      options.itemsPerPage
    );
    return results;
  },
  async searchParks({ rootGetters }, options) {
    const tenantId = rootGetters["tenant/tenantId"];
    var parkSearchService = new ParkSearchService(null, null, tenantId);
    let results = await parkSearchService.searchParks(options);
    return results;
  }
};
const mutations = {
  setSearchTerm(state, term) {
    state.searchTerm = term;
  },
  setSelectedLatitude(state, latitude) {
    state.selectedLatitude = latitude;
  },
  setSelectedLongitude(state, longitude) {
    state.selectedLongitude = longitude;
  },
  setSelectedSpotTypes(state, spotTypes) {
    state.selectedSpotTypes = spotTypes;
  },
  setSelectedProductClassifications(state, selectedProductClassifications) {
    state.selectedProductClassifications = selectedProductClassifications;
  },
  setSelectedRegions(state, selectedRegions) {
    state.selectedRegions = selectedRegions;
  },
  setSelectedPointsOfInterest(state, selectedPointsOfInterest) {
    state.selectedPointsOfInterest = selectedPointsOfInterest;
  },
  setSelectedAttributes(state, selectedAttributes) {
    state.selectedAttributes = selectedAttributes;
  },
  setSearchDate(state, param) {
    state[param.key] = param.value;
  },
  setSelectedSpotId(state, spotId) {
    state.selectedSpotId = spotId;
  },
  setSearchView(state, searchView) {
    state.searchView = searchView;
  },
  setSelectedSpotIsDayUse(state, isDayUse) {
    state.selectedSpotIsDayUse = isDayUse;
  },
  setSelectedLoopId(state, loopId) {
    state.selectedLoopId = loopId;
  },
  setSelectedSpotName(state, spotName) {
    state.selectedSpotName = spotName;
  },
  setOnlyShowAvailable(state, onlyShowAvailable) {
    state.onlyShowAvailable = onlyShowAvailable;
  },
  setSpotSearchHasBeenClicked(state, spotSearchHasBeenClicked) {
    state.spotSearchHasBeenClicked = spotSearchHasBeenClicked;
  },
  setSpotsCount(state, spotsCount) {
    state.spotsCount = spotsCount;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
