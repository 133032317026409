/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The DiscountClass model module.
 * @module model/DiscountClass
 * @version v1
 */
class DiscountClass {
    /**
     * Constructs a new <code>DiscountClass</code>.
     * @alias module:model/DiscountClass
     */
    constructor() { 
        
        DiscountClass.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>DiscountClass</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DiscountClass} obj Optional instance to populate.
     * @return {module:model/DiscountClass} The populated <code>DiscountClass</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DiscountClass();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('internalDescription')) {
                obj['internalDescription'] = ApiClient.convertToType(data['internalDescription'], 'String');
            }
            if (data.hasOwnProperty('residencyId')) {
                obj['residencyId'] = ApiClient.convertToType(data['residencyId'], 'Number');
            }
            if (data.hasOwnProperty('verificationRequired')) {
                obj['verificationRequired'] = ApiClient.convertToType(data['verificationRequired'], 'Boolean');
            }
            if (data.hasOwnProperty('priority')) {
                obj['priority'] = ApiClient.convertToType(data['priority'], 'Number');
            }
            if (data.hasOwnProperty('accountingCodeSuffix')) {
                obj['accountingCodeSuffix'] = ApiClient.convertToType(data['accountingCodeSuffix'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
DiscountClass.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
DiscountClass.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
DiscountClass.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
DiscountClass.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
DiscountClass.prototype['id'] = undefined;

/**
 * @member {String} name
 */
DiscountClass.prototype['name'] = undefined;

/**
 * @member {String} description
 */
DiscountClass.prototype['description'] = undefined;

/**
 * @member {String} tenantId
 */
DiscountClass.prototype['tenantId'] = undefined;

/**
 * @member {String} internalDescription
 */
DiscountClass.prototype['internalDescription'] = undefined;

/**
 * @member {Number} residencyId
 */
DiscountClass.prototype['residencyId'] = undefined;

/**
 * @member {Boolean} verificationRequired
 */
DiscountClass.prototype['verificationRequired'] = undefined;

/**
 * @member {Number} priority
 */
DiscountClass.prototype['priority'] = undefined;

/**
 * @member {String} accountingCodeSuffix
 */
DiscountClass.prototype['accountingCodeSuffix'] = undefined;






export default DiscountClass;

