/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PaymentTypeEnum from './PaymentTypeEnum';

/**
 * The SpotReservationSearchResult model module.
 * @module model/SpotReservationSearchResult
 * @version v1
 */
class SpotReservationSearchResult {
    /**
     * Constructs a new <code>SpotReservationSearchResult</code>.
     * @alias module:model/SpotReservationSearchResult
     */
    constructor() { 
        
        SpotReservationSearchResult.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SpotReservationSearchResult</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SpotReservationSearchResult} obj Optional instance to populate.
     * @return {module:model/SpotReservationSearchResult} The populated <code>SpotReservationSearchResult</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SpotReservationSearchResult();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('locationId')) {
                obj['locationId'] = ApiClient.convertToType(data['locationId'], 'Number');
            }
            if (data.hasOwnProperty('reservationNumber')) {
                obj['reservationNumber'] = ApiClient.convertToType(data['reservationNumber'], 'String');
            }
            if (data.hasOwnProperty('reservationStatus')) {
                obj['reservationStatus'] = ApiClient.convertToType(data['reservationStatus'], 'String');
            }
            if (data.hasOwnProperty('park')) {
                obj['park'] = ApiClient.convertToType(data['park'], 'String');
            }
            if (data.hasOwnProperty('spot')) {
                obj['spot'] = ApiClient.convertToType(data['spot'], 'String');
            }
            if (data.hasOwnProperty('loop')) {
                obj['loop'] = ApiClient.convertToType(data['loop'], 'String');
            }
            if (data.hasOwnProperty('customerName')) {
                obj['customerName'] = ApiClient.convertToType(data['customerName'], 'String');
            }
            if (data.hasOwnProperty('primaryOccupantName')) {
                obj['primaryOccupantName'] = ApiClient.convertToType(data['primaryOccupantName'], 'String');
            }
            if (data.hasOwnProperty('arrivalDate')) {
                obj['arrivalDate'] = ApiClient.convertToType(data['arrivalDate'], 'String');
            }
            if (data.hasOwnProperty('departureDate')) {
                obj['departureDate'] = ApiClient.convertToType(data['departureDate'], 'String');
            }
            if (data.hasOwnProperty('transactionDate')) {
                obj['transactionDate'] = ApiClient.convertToType(data['transactionDate'], 'String');
            }
            if (data.hasOwnProperty('imageUrl')) {
                obj['imageUrl'] = ApiClient.convertToType(data['imageUrl'], 'String');
            }
            if (data.hasOwnProperty('phone')) {
                obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
            }
            if (data.hasOwnProperty('emailAddress')) {
                obj['emailAddress'] = ApiClient.convertToType(data['emailAddress'], 'String');
            }
            if (data.hasOwnProperty('numberOfPeople')) {
                obj['numberOfPeople'] = ApiClient.convertToType(data['numberOfPeople'], 'Number');
            }
            if (data.hasOwnProperty('paymentType')) {
                obj['paymentType'] = PaymentTypeEnum.constructFromObject(data['paymentType']);
            }
            if (data.hasOwnProperty('paymentChargebackCompleted')) {
                obj['paymentChargebackCompleted'] = ApiClient.convertToType(data['paymentChargebackCompleted'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
SpotReservationSearchResult.prototype['id'] = undefined;

/**
 * @member {Number} locationId
 */
SpotReservationSearchResult.prototype['locationId'] = undefined;

/**
 * @member {String} reservationNumber
 */
SpotReservationSearchResult.prototype['reservationNumber'] = undefined;

/**
 * @member {String} reservationStatus
 */
SpotReservationSearchResult.prototype['reservationStatus'] = undefined;

/**
 * @member {String} park
 */
SpotReservationSearchResult.prototype['park'] = undefined;

/**
 * @member {String} spot
 */
SpotReservationSearchResult.prototype['spot'] = undefined;

/**
 * @member {String} loop
 */
SpotReservationSearchResult.prototype['loop'] = undefined;

/**
 * @member {String} customerName
 */
SpotReservationSearchResult.prototype['customerName'] = undefined;

/**
 * @member {String} primaryOccupantName
 */
SpotReservationSearchResult.prototype['primaryOccupantName'] = undefined;

/**
 * @member {String} arrivalDate
 */
SpotReservationSearchResult.prototype['arrivalDate'] = undefined;

/**
 * @member {String} departureDate
 */
SpotReservationSearchResult.prototype['departureDate'] = undefined;

/**
 * @member {String} transactionDate
 */
SpotReservationSearchResult.prototype['transactionDate'] = undefined;

/**
 * @member {String} imageUrl
 */
SpotReservationSearchResult.prototype['imageUrl'] = undefined;

/**
 * @member {String} phone
 */
SpotReservationSearchResult.prototype['phone'] = undefined;

/**
 * @member {String} emailAddress
 */
SpotReservationSearchResult.prototype['emailAddress'] = undefined;

/**
 * @member {Number} numberOfPeople
 */
SpotReservationSearchResult.prototype['numberOfPeople'] = undefined;

/**
 * @member {module:model/PaymentTypeEnum} paymentType
 */
SpotReservationSearchResult.prototype['paymentType'] = undefined;

/**
 * @member {Boolean} paymentChargebackCompleted
 */
SpotReservationSearchResult.prototype['paymentChargebackCompleted'] = undefined;






export default SpotReservationSearchResult;

