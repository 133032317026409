/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CancellationRequest from '../model/CancellationRequest';
import CcpPaymentPostback from '../model/CcpPaymentPostback';
import ClaimSpotRequest from '../model/ClaimSpotRequest';
import ClaimSpotResultResponse from '../model/ClaimSpotResultResponse';
import EditReservationRequest from '../model/EditReservationRequest';
import EditReservationResponse from '../model/EditReservationResponse';
import NewReservationRequest from '../model/NewReservationRequest';
import OrderResponse from '../model/OrderResponse';
import ReleaseSpotResponse from '../model/ReleaseSpotResponse';
import Response from '../model/Response';
import SpotReservation from '../model/SpotReservation';
import SpotReservationDetailResponse from '../model/SpotReservationDetailResponse';

/**
* SpotReservation service.
* @module api/SpotReservationApi
* @version v1
*/
export default class SpotReservationApi {

    /**
    * Constructs a new SpotReservationApi. 
    * @alias module:api/SpotReservationApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @param {String} customerId 
     * @param {String} reservationId 
     * @param {Object} opts Optional parameters
     * @param {module:model/CancellationRequest} opts.cancellationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OrderResponse} and HTTP response
     */
    v1TenantTenantIdSpotReservationCustomerIdCancelReservationIdPostWithHttpInfo(tenantId, customerId, reservationId, opts) {
      opts = opts || {};
      let postBody = opts['cancellationRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdSpotReservationCustomerIdCancelReservationIdPost");
      }
      // verify the required parameter 'customerId' is set
      if (customerId === undefined || customerId === null) {
        throw new Error("Missing the required parameter 'customerId' when calling v1TenantTenantIdSpotReservationCustomerIdCancelReservationIdPost");
      }
      // verify the required parameter 'reservationId' is set
      if (reservationId === undefined || reservationId === null) {
        throw new Error("Missing the required parameter 'reservationId' when calling v1TenantTenantIdSpotReservationCustomerIdCancelReservationIdPost");
      }

      let pathParams = {
        'tenantId': tenantId,
        'customerId': customerId,
        'reservationId': reservationId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = OrderResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/SpotReservation/{customerId}/cancel/{reservationId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {String} customerId 
     * @param {String} reservationId 
     * @param {Object} opts Optional parameters
     * @param {module:model/CancellationRequest} opts.cancellationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OrderResponse}
     */
    v1TenantTenantIdSpotReservationCustomerIdCancelReservationIdPost(tenantId, customerId, reservationId, opts) {
      return this.v1TenantTenantIdSpotReservationCustomerIdCancelReservationIdPostWithHttpInfo(tenantId, customerId, reservationId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} customerId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.paymentTypeId 
     * @param {module:model/EditReservationRequest} opts.editReservationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EditReservationResponse} and HTTP response
     */
    v1TenantTenantIdSpotReservationCustomerIdChangePostWithHttpInfo(customerId, tenantId, opts) {
      opts = opts || {};
      let postBody = opts['editReservationRequest'];
      // verify the required parameter 'customerId' is set
      if (customerId === undefined || customerId === null) {
        throw new Error("Missing the required parameter 'customerId' when calling v1TenantTenantIdSpotReservationCustomerIdChangePost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdSpotReservationCustomerIdChangePost");
      }

      let pathParams = {
        'customerId': customerId,
        'tenantId': tenantId
      };
      let queryParams = {
        'paymentTypeId': opts['paymentTypeId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = EditReservationResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/SpotReservation/{customerId}/change', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} customerId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.paymentTypeId 
     * @param {module:model/EditReservationRequest} opts.editReservationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EditReservationResponse}
     */
    v1TenantTenantIdSpotReservationCustomerIdChangePost(customerId, tenantId, opts) {
      return this.v1TenantTenantIdSpotReservationCustomerIdChangePostWithHttpInfo(customerId, tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SpotReservationDetailResponse} and HTTP response
     */
    v1TenantTenantIdSpotReservationDetailReservationIdGetWithHttpInfo(reservationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'reservationId' is set
      if (reservationId === undefined || reservationId === null) {
        throw new Error("Missing the required parameter 'reservationId' when calling v1TenantTenantIdSpotReservationDetailReservationIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdSpotReservationDetailReservationIdGet");
      }

      let pathParams = {
        'reservationId': reservationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SpotReservationDetailResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/SpotReservation/detail/{reservationId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SpotReservationDetailResponse}
     */
    v1TenantTenantIdSpotReservationDetailReservationIdGet(reservationId, tenantId) {
      return this.v1TenantTenantIdSpotReservationDetailReservationIdGetWithHttpInfo(reservationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/CcpPaymentPostback} opts.ccpPaymentPostback 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    v1TenantTenantIdSpotReservationPaymentPostbackPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['ccpPaymentPostback'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdSpotReservationPaymentPostbackPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/SpotReservation/payment-postback', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/CcpPaymentPostback} opts.ccpPaymentPostback 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    v1TenantTenantIdSpotReservationPaymentPostbackPost(tenantId, opts) {
      return this.v1TenantTenantIdSpotReservationPaymentPostbackPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SpotReservation} and HTTP response
     */
    v1TenantTenantIdSpotReservationReservationIdGetWithHttpInfo(reservationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'reservationId' is set
      if (reservationId === undefined || reservationId === null) {
        throw new Error("Missing the required parameter 'reservationId' when calling v1TenantTenantIdSpotReservationReservationIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdSpotReservationReservationIdGet");
      }

      let pathParams = {
        'reservationId': reservationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SpotReservation;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/SpotReservation/{reservationId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SpotReservation}
     */
    v1TenantTenantIdSpotReservationReservationIdGet(reservationId, tenantId) {
      return this.v1TenantTenantIdSpotReservationReservationIdGetWithHttpInfo(reservationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} spotId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ClaimSpotRequest} opts.claimSpotRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ClaimSpotResultResponse} and HTTP response
     */
    v1TenantTenantIdSpotReservationSpotSpotIdClaimPostWithHttpInfo(spotId, tenantId, opts) {
      opts = opts || {};
      let postBody = opts['claimSpotRequest'];
      // verify the required parameter 'spotId' is set
      if (spotId === undefined || spotId === null) {
        throw new Error("Missing the required parameter 'spotId' when calling v1TenantTenantIdSpotReservationSpotSpotIdClaimPost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdSpotReservationSpotSpotIdClaimPost");
      }

      let pathParams = {
        'spotId': spotId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ClaimSpotResultResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/SpotReservation/spot/{spotId}/claim', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} spotId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ClaimSpotRequest} opts.claimSpotRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ClaimSpotResultResponse}
     */
    v1TenantTenantIdSpotReservationSpotSpotIdClaimPost(spotId, tenantId, opts) {
      return this.v1TenantTenantIdSpotReservationSpotSpotIdClaimPostWithHttpInfo(spotId, tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} spotId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.lockCode 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReleaseSpotResponse} and HTTP response
     */
    v1TenantTenantIdSpotReservationSpotSpotIdReleaseclaimPostWithHttpInfo(spotId, tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'spotId' is set
      if (spotId === undefined || spotId === null) {
        throw new Error("Missing the required parameter 'spotId' when calling v1TenantTenantIdSpotReservationSpotSpotIdReleaseclaimPost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdSpotReservationSpotSpotIdReleaseclaimPost");
      }

      let pathParams = {
        'spotId': spotId,
        'tenantId': tenantId
      };
      let queryParams = {
        'lockCode': opts['lockCode']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ReleaseSpotResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/SpotReservation/spot/{spotId}/releaseclaim', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} spotId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.lockCode 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReleaseSpotResponse}
     */
    v1TenantTenantIdSpotReservationSpotSpotIdReleaseclaimPost(spotId, tenantId, opts) {
      return this.v1TenantTenantIdSpotReservationSpotSpotIdReleaseclaimPostWithHttpInfo(spotId, tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/EditReservationRequest} opts.editReservationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdSpotReservationValidateEditPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['editReservationRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdSpotReservationValidateEditPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/SpotReservation/validate-edit', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/EditReservationRequest} opts.editReservationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdSpotReservationValidateEditPost(tenantId, opts) {
      return this.v1TenantTenantIdSpotReservationValidateEditPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/NewReservationRequest} opts.newReservationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdSpotReservationValidatePostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['newReservationRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdSpotReservationValidatePost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/SpotReservation/validate', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/NewReservationRequest} opts.newReservationRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdSpotReservationValidatePost(tenantId, opts) {
      return this.v1TenantTenantIdSpotReservationValidatePostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
