/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import BooleanResponse from '../model/BooleanResponse';
import CustomerChangePasswordRequest from '../model/CustomerChangePasswordRequest';
import CustomerDiscounts from '../model/CustomerDiscounts';
import CustomerReservationDetailResponse from '../model/CustomerReservationDetailResponse';
import CustomerReservationDiscountsRequest from '../model/CustomerReservationDiscountsRequest';
import CustomerResponse from '../model/CustomerResponse';
import EditCustomerRequest from '../model/EditCustomerRequest';
import NewCustomerRequest from '../model/NewCustomerRequest';
import PreviousCustomerDiscountResultResponse from '../model/PreviousCustomerDiscountResultResponse';
import SpotReservationSearchResultIEnumerablePageResultResponse from '../model/SpotReservationSearchResultIEnumerablePageResultResponse';

/**
* Customer service.
* @module api/CustomerApi
* @version v1
*/
export default class CustomerApi {

    /**
    * Constructs a new CustomerApi. 
    * @alias module:api/CustomerApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/CustomerChangePasswordRequest} opts.customerChangePasswordRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BooleanResponse} and HTTP response
     */
    v1TenantTenantIdCustomerChangePasswordPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['customerChangePasswordRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdCustomerChangePasswordPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Customer/changePassword', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/CustomerChangePasswordRequest} opts.customerChangePasswordRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BooleanResponse}
     */
    v1TenantTenantIdCustomerChangePasswordPost(tenantId, opts) {
      return this.v1TenantTenantIdCustomerChangePasswordPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} customerId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/CustomerReservationDiscountsRequest} opts.customerReservationDiscountsRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PreviousCustomerDiscountResultResponse} and HTTP response
     */
    v1TenantTenantIdCustomerCustomerIdCheckPreviousDiscountsPostWithHttpInfo(customerId, tenantId, opts) {
      opts = opts || {};
      let postBody = opts['customerReservationDiscountsRequest'];
      // verify the required parameter 'customerId' is set
      if (customerId === undefined || customerId === null) {
        throw new Error("Missing the required parameter 'customerId' when calling v1TenantTenantIdCustomerCustomerIdCheckPreviousDiscountsPost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdCustomerCustomerIdCheckPreviousDiscountsPost");
      }

      let pathParams = {
        'customerId': customerId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PreviousCustomerDiscountResultResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Customer/{customerId}/check-previous-discounts', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} customerId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/CustomerReservationDiscountsRequest} opts.customerReservationDiscountsRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PreviousCustomerDiscountResultResponse}
     */
    v1TenantTenantIdCustomerCustomerIdCheckPreviousDiscountsPost(customerId, tenantId, opts) {
      return this.v1TenantTenantIdCustomerCustomerIdCheckPreviousDiscountsPostWithHttpInfo(customerId, tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} customerId 
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CustomerReservationDetailResponse} and HTTP response
     */
    v1TenantTenantIdCustomerCustomerIdReservationDetailReservationIdGetWithHttpInfo(customerId, reservationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'customerId' is set
      if (customerId === undefined || customerId === null) {
        throw new Error("Missing the required parameter 'customerId' when calling v1TenantTenantIdCustomerCustomerIdReservationDetailReservationIdGet");
      }
      // verify the required parameter 'reservationId' is set
      if (reservationId === undefined || reservationId === null) {
        throw new Error("Missing the required parameter 'reservationId' when calling v1TenantTenantIdCustomerCustomerIdReservationDetailReservationIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdCustomerCustomerIdReservationDetailReservationIdGet");
      }

      let pathParams = {
        'customerId': customerId,
        'reservationId': reservationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CustomerReservationDetailResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Customer/{customerId}/reservationDetail/{reservationId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} customerId 
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CustomerReservationDetailResponse}
     */
    v1TenantTenantIdCustomerCustomerIdReservationDetailReservationIdGet(customerId, reservationId, tenantId) {
      return this.v1TenantTenantIdCustomerCustomerIdReservationDetailReservationIdGetWithHttpInfo(customerId, reservationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} customerId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Date} opts.now 
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.itemsPerPage 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SpotReservationSearchResultIEnumerablePageResultResponse} and HTTP response
     */
    v1TenantTenantIdCustomerCustomerIdReservationsCurrentGetWithHttpInfo(customerId, tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'customerId' is set
      if (customerId === undefined || customerId === null) {
        throw new Error("Missing the required parameter 'customerId' when calling v1TenantTenantIdCustomerCustomerIdReservationsCurrentGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdCustomerCustomerIdReservationsCurrentGet");
      }

      let pathParams = {
        'customerId': customerId,
        'tenantId': tenantId
      };
      let queryParams = {
        'now': opts['now'],
        'pageNumber': opts['pageNumber'],
        'itemsPerPage': opts['itemsPerPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SpotReservationSearchResultIEnumerablePageResultResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Customer/{customerId}/reservations/current', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} customerId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Date} opts.now 
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.itemsPerPage 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SpotReservationSearchResultIEnumerablePageResultResponse}
     */
    v1TenantTenantIdCustomerCustomerIdReservationsCurrentGet(customerId, tenantId, opts) {
      return this.v1TenantTenantIdCustomerCustomerIdReservationsCurrentGetWithHttpInfo(customerId, tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} customerId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Date} opts.now 
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.itemsPerPage 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SpotReservationSearchResultIEnumerablePageResultResponse} and HTTP response
     */
    v1TenantTenantIdCustomerCustomerIdReservationsPastGetWithHttpInfo(customerId, tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'customerId' is set
      if (customerId === undefined || customerId === null) {
        throw new Error("Missing the required parameter 'customerId' when calling v1TenantTenantIdCustomerCustomerIdReservationsPastGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdCustomerCustomerIdReservationsPastGet");
      }

      let pathParams = {
        'customerId': customerId,
        'tenantId': tenantId
      };
      let queryParams = {
        'now': opts['now'],
        'pageNumber': opts['pageNumber'],
        'itemsPerPage': opts['itemsPerPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SpotReservationSearchResultIEnumerablePageResultResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Customer/{customerId}/reservations/past', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} customerId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Date} opts.now 
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.itemsPerPage 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SpotReservationSearchResultIEnumerablePageResultResponse}
     */
    v1TenantTenantIdCustomerCustomerIdReservationsPastGet(customerId, tenantId, opts) {
      return this.v1TenantTenantIdCustomerCustomerIdReservationsPastGetWithHttpInfo(customerId, tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} customerId 
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BooleanResponse} and HTTP response
     */
    v1TenantTenantIdCustomerCustomerIdSendconfirmationemailReservationIdPostWithHttpInfo(customerId, reservationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'customerId' is set
      if (customerId === undefined || customerId === null) {
        throw new Error("Missing the required parameter 'customerId' when calling v1TenantTenantIdCustomerCustomerIdSendconfirmationemailReservationIdPost");
      }
      // verify the required parameter 'reservationId' is set
      if (reservationId === undefined || reservationId === null) {
        throw new Error("Missing the required parameter 'reservationId' when calling v1TenantTenantIdCustomerCustomerIdSendconfirmationemailReservationIdPost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdCustomerCustomerIdSendconfirmationemailReservationIdPost");
      }

      let pathParams = {
        'customerId': customerId,
        'reservationId': reservationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Customer/{customerId}/sendconfirmationemail/{reservationId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} customerId 
     * @param {Number} reservationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BooleanResponse}
     */
    v1TenantTenantIdCustomerCustomerIdSendconfirmationemailReservationIdPost(customerId, reservationId, tenantId) {
      return this.v1TenantTenantIdCustomerCustomerIdSendconfirmationemailReservationIdPostWithHttpInfo(customerId, reservationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} customerId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/CustomerDiscounts} opts.customerDiscounts 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BooleanResponse} and HTTP response
     */
    v1TenantTenantIdCustomerCustomerIdVerifydiscountsPostWithHttpInfo(customerId, tenantId, opts) {
      opts = opts || {};
      let postBody = opts['customerDiscounts'];
      // verify the required parameter 'customerId' is set
      if (customerId === undefined || customerId === null) {
        throw new Error("Missing the required parameter 'customerId' when calling v1TenantTenantIdCustomerCustomerIdVerifydiscountsPost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdCustomerCustomerIdVerifydiscountsPost");
      }

      let pathParams = {
        'customerId': customerId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Customer/{customerId}/verifydiscounts', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} customerId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/CustomerDiscounts} opts.customerDiscounts 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BooleanResponse}
     */
    v1TenantTenantIdCustomerCustomerIdVerifydiscountsPost(customerId, tenantId, opts) {
      return this.v1TenantTenantIdCustomerCustomerIdVerifydiscountsPostWithHttpInfo(customerId, tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.email 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BooleanResponse} and HTTP response
     */
    v1TenantTenantIdCustomerEmailAvailabilityGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdCustomerEmailAvailabilityGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'email': opts['email']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Customer/emailAvailability', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.email 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BooleanResponse}
     */
    v1TenantTenantIdCustomerEmailAvailabilityGet(tenantId, opts) {
      return this.v1TenantTenantIdCustomerEmailAvailabilityGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/NewCustomerRequest} opts.newCustomerRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CustomerResponse} and HTTP response
     */
    v1TenantTenantIdCustomerPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['newCustomerRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdCustomerPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CustomerResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Customer', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/NewCustomerRequest} opts.newCustomerRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CustomerResponse}
     */
    v1TenantTenantIdCustomerPost(tenantId, opts) {
      return this.v1TenantTenantIdCustomerPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/EditCustomerRequest} opts.editCustomerRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CustomerResponse} and HTTP response
     */
    v1TenantTenantIdCustomerPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['editCustomerRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdCustomerPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CustomerResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Customer', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/EditCustomerRequest} opts.editCustomerRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CustomerResponse}
     */
    v1TenantTenantIdCustomerPut(tenantId, opts) {
      return this.v1TenantTenantIdCustomerPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} externalUserId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CustomerResponse} and HTTP response
     */
    v1TenantTenantIdCustomerUserExternalUserIdExternalUserIdGetWithHttpInfo(externalUserId, tenantId) {
      let postBody = null;
      // verify the required parameter 'externalUserId' is set
      if (externalUserId === undefined || externalUserId === null) {
        throw new Error("Missing the required parameter 'externalUserId' when calling v1TenantTenantIdCustomerUserExternalUserIdExternalUserIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdCustomerUserExternalUserIdExternalUserIdGet");
      }

      let pathParams = {
        'externalUserId': externalUserId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CustomerResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Customer/user/externalUserId/{externalUserId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} externalUserId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CustomerResponse}
     */
    v1TenantTenantIdCustomerUserExternalUserIdExternalUserIdGet(externalUserId, tenantId) {
      return this.v1TenantTenantIdCustomerUserExternalUserIdExternalUserIdGetWithHttpInfo(externalUserId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.username 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BooleanResponse} and HTTP response
     */
    v1TenantTenantIdCustomerUsernameAvailabilityGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdCustomerUsernameAvailabilityGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'username': opts['username']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/Customer/usernameAvailability', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.username 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BooleanResponse}
     */
    v1TenantTenantIdCustomerUsernameAvailabilityGet(tenantId, opts) {
      return this.v1TenantTenantIdCustomerUsernameAvailabilityGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
