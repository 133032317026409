/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PointOfInterestCategory from './PointOfInterestCategory';
import TenantPointOfInterest from './TenantPointOfInterest';

/**
 * The PointOfInterest model module.
 * @module model/PointOfInterest
 * @version v1
 */
class PointOfInterest {
    /**
     * Constructs a new <code>PointOfInterest</code>.
     * @alias module:model/PointOfInterest
     */
    constructor() { 
        
        PointOfInterest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PointOfInterest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PointOfInterest} obj Optional instance to populate.
     * @return {module:model/PointOfInterest} The populated <code>PointOfInterest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PointOfInterest();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('icon')) {
                obj['icon'] = ApiClient.convertToType(data['icon'], 'String');
            }
            if (data.hasOwnProperty('pointOfInterestCategory')) {
                obj['pointOfInterestCategory'] = PointOfInterestCategory.constructFromObject(data['pointOfInterestCategory']);
            }
            if (data.hasOwnProperty('tenantPointOfInterest')) {
                obj['tenantPointOfInterest'] = TenantPointOfInterest.constructFromObject(data['tenantPointOfInterest']);
            }
            if (data.hasOwnProperty('pointOfInterestCategoryId')) {
                obj['pointOfInterestCategoryId'] = ApiClient.convertToType(data['pointOfInterestCategoryId'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
PointOfInterest.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
PointOfInterest.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
PointOfInterest.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
PointOfInterest.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
PointOfInterest.prototype['id'] = undefined;

/**
 * @member {String} name
 */
PointOfInterest.prototype['name'] = undefined;

/**
 * @member {String} description
 */
PointOfInterest.prototype['description'] = undefined;

/**
 * @member {String} icon
 */
PointOfInterest.prototype['icon'] = undefined;

/**
 * @member {module:model/PointOfInterestCategory} pointOfInterestCategory
 */
PointOfInterest.prototype['pointOfInterestCategory'] = undefined;

/**
 * @member {module:model/TenantPointOfInterest} tenantPointOfInterest
 */
PointOfInterest.prototype['tenantPointOfInterest'] = undefined;

/**
 * @member {Number} pointOfInterestCategoryId
 */
PointOfInterest.prototype['pointOfInterestCategoryId'] = undefined;






export default PointOfInterest;

