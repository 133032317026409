/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ProductTypeCount model module.
 * @module model/ProductTypeCount
 * @version v1
 */
class ProductTypeCount {
    /**
     * Constructs a new <code>ProductTypeCount</code>.
     * @alias module:model/ProductTypeCount
     */
    constructor() { 
        
        ProductTypeCount.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ProductTypeCount</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProductTypeCount} obj Optional instance to populate.
     * @return {module:model/ProductTypeCount} The populated <code>ProductTypeCount</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProductTypeCount();

            if (data.hasOwnProperty('locationId')) {
                obj['locationId'] = ApiClient.convertToType(data['locationId'], 'Number');
            }
            if (data.hasOwnProperty('productTypeId')) {
                obj['productTypeId'] = ApiClient.convertToType(data['productTypeId'], 'Number');
            }
            if (data.hasOwnProperty('productTypeName')) {
                obj['productTypeName'] = ApiClient.convertToType(data['productTypeName'], 'String');
            }
            if (data.hasOwnProperty('productTypeIcon')) {
                obj['productTypeIcon'] = ApiClient.convertToType(data['productTypeIcon'], 'String');
            }
            if (data.hasOwnProperty('count')) {
                obj['count'] = ApiClient.convertToType(data['count'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} locationId
 */
ProductTypeCount.prototype['locationId'] = undefined;

/**
 * @member {Number} productTypeId
 */
ProductTypeCount.prototype['productTypeId'] = undefined;

/**
 * @member {String} productTypeName
 */
ProductTypeCount.prototype['productTypeName'] = undefined;

/**
 * @member {String} productTypeIcon
 */
ProductTypeCount.prototype['productTypeIcon'] = undefined;

/**
 * @member {Number} count
 */
ProductTypeCount.prototype['count'] = undefined;






export default ProductTypeCount;

