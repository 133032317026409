/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Order from './Order';
import OrderRefund from './OrderRefund';
import PaymentType from './PaymentType';

/**
 * The OrderPayment model module.
 * @module model/OrderPayment
 * @version v1
 */
class OrderPayment {
    /**
     * Constructs a new <code>OrderPayment</code>.
     * @alias module:model/OrderPayment
     */
    constructor() { 
        
        OrderPayment.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>OrderPayment</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/OrderPayment} obj Optional instance to populate.
     * @return {module:model/OrderPayment} The populated <code>OrderPayment</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrderPayment();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('datePaid')) {
                obj['datePaid'] = ApiClient.convertToType(data['datePaid'], 'Date');
            }
            if (data.hasOwnProperty('amount')) {
                obj['amount'] = ApiClient.convertToType(data['amount'], 'Number');
            }
            if (data.hasOwnProperty('costOfSale')) {
                obj['costOfSale'] = ApiClient.convertToType(data['costOfSale'], 'Number');
            }
            if (data.hasOwnProperty('paymentTypeId')) {
                obj['paymentTypeId'] = ApiClient.convertToType(data['paymentTypeId'], 'Number');
            }
            if (data.hasOwnProperty('tpeOrderId')) {
                obj['tpeOrderId'] = ApiClient.convertToType(data['tpeOrderId'], 'String');
            }
            if (data.hasOwnProperty('ccpOrderToken')) {
                obj['ccpOrderToken'] = ApiClient.convertToType(data['ccpOrderToken'], 'String');
            }
            if (data.hasOwnProperty('lastFour')) {
                obj['lastFour'] = ApiClient.convertToType(data['lastFour'], 'String');
            }
            if (data.hasOwnProperty('ccpOrderStatus')) {
                obj['ccpOrderStatus'] = ApiClient.convertToType(data['ccpOrderStatus'], 'String');
            }
            if (data.hasOwnProperty('cardholderName')) {
                obj['cardholderName'] = ApiClient.convertToType(data['cardholderName'], 'String');
            }
            if (data.hasOwnProperty('orderId')) {
                obj['orderId'] = ApiClient.convertToType(data['orderId'], 'Number');
            }
            if (data.hasOwnProperty('order')) {
                obj['order'] = Order.constructFromObject(data['order']);
            }
            if (data.hasOwnProperty('paymentType')) {
                obj['paymentType'] = PaymentType.constructFromObject(data['paymentType']);
            }
            if (data.hasOwnProperty('orderRefunds')) {
                obj['orderRefunds'] = ApiClient.convertToType(data['orderRefunds'], [OrderRefund]);
            }
            if (data.hasOwnProperty('htmL5RedirectUrl')) {
                obj['htmL5RedirectUrl'] = ApiClient.convertToType(data['htmL5RedirectUrl'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
OrderPayment.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
OrderPayment.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
OrderPayment.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
OrderPayment.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
OrderPayment.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
OrderPayment.prototype['tenantId'] = undefined;

/**
 * @member {Date} datePaid
 */
OrderPayment.prototype['datePaid'] = undefined;

/**
 * @member {Number} amount
 */
OrderPayment.prototype['amount'] = undefined;

/**
 * @member {Number} costOfSale
 */
OrderPayment.prototype['costOfSale'] = undefined;

/**
 * @member {Number} paymentTypeId
 */
OrderPayment.prototype['paymentTypeId'] = undefined;

/**
 * @member {String} tpeOrderId
 */
OrderPayment.prototype['tpeOrderId'] = undefined;

/**
 * @member {String} ccpOrderToken
 */
OrderPayment.prototype['ccpOrderToken'] = undefined;

/**
 * @member {String} lastFour
 */
OrderPayment.prototype['lastFour'] = undefined;

/**
 * @member {String} ccpOrderStatus
 */
OrderPayment.prototype['ccpOrderStatus'] = undefined;

/**
 * @member {String} cardholderName
 */
OrderPayment.prototype['cardholderName'] = undefined;

/**
 * @member {Number} orderId
 */
OrderPayment.prototype['orderId'] = undefined;

/**
 * @member {module:model/Order} order
 */
OrderPayment.prototype['order'] = undefined;

/**
 * @member {module:model/PaymentType} paymentType
 */
OrderPayment.prototype['paymentType'] = undefined;

/**
 * @member {Array.<module:model/OrderRefund>} orderRefunds
 */
OrderPayment.prototype['orderRefunds'] = undefined;

/**
 * @member {String} htmL5RedirectUrl
 */
OrderPayment.prototype['htmL5RedirectUrl'] = undefined;






export default OrderPayment;

