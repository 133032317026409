import AdminUserApi from "@/generatedapiclients/src/api/AdminUserApi.js";
import AdminAuthApi from "@/generatedapiclients/src/api/AdminAuthApi.js";

export default class AdminUserService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._userApi = new AdminUserApi();
    this._authApi = new AdminAuthApi();
  }

  async search(searchCriteria, paging) {
    return this._userApi.v1TenantTenantIdAdminAdminUserSearchGet(
      this._tenantId,
      {
        ...searchCriteria,
        ...paging
      }
    );
  }

  async getUser(userId) {
    const response = await this._userApi.v1TenantTenantIdAdminAdminUserUserIdGet(
      userId,
      this._tenantId
    );
    return response.user;
  }

  async getUsers(adminUsersRequest) {
    const response = await this._userApi.v1TenantTenantIdAdminAdminUserUsersPost(
      this._tenantId,
      {
        adminUsersRequest: adminUsersRequest
      }
    );
    return response.data;
  }

  async getUserStatuses() {
    const response = await this._userApi.v1TenantTenantIdAdminAdminUserUserstatusesGet(
      this._tenantId
    );
    return response;
  }

  async updateUser(userRequest) {
    const response = await this._userApi.v1TenantTenantIdAdminAdminUserPut(
      this._tenantId,
      {
        userRequest
      }
    );
    return response;
  }

  async addUser(userRequest) {
    const response = await this._userApi.v1TenantTenantIdAdminAdminUserPost(
      this._tenantId,
      {
        userRequest
      }
    );
    return response;
  }

  async getUserByUsername(username) {
    const response = await this._userApi.v1TenantTenantIdAdminAdminUserUsernameUserNameGet(
      username,
      this._tenantId
    );
    return response.user;
  }

  async getUsersByEmail(email) {
    const response = await this._userApi.v1TenantTenantIdAdminAdminUserEmailEmailGet(
      email,
      this._tenantId
    );
    return response.data;
  }

  async getUserRoles() {
    const response = await this._userApi.v1TenantTenantIdAdminAdminUserUserrolesGet(
      this._tenantId
    );
    return response.data;
  }

  async changeUserPassword(userId, currentPassword, newPassword) {
    const response = await this._userApi.v1TenantTenantIdAdminAdminUserChangePasswordPost(
      this._tenantId,
      {
        adminChangePasswordRequest: {
          userId,
          currentPassword,
          newPassword
        }
      }
    );
    return response;
  }

  async deleteUser(userId) {
    const response = await this._userApi.v1TenantTenantIdAdminAdminUserUserIdDelete(
      userId,
      this._tenantId
    );
    return response;
  }

  async deactivateUser(userId) {
    const response = await this._userApi.v1TenantTenantIdAdminAdminUserDeactivateUserIdPost(
      userId,
      this._tenantId
    );
    return response;
  }

  async activateUser(userId) {
    const response = await this._userApi.v1TenantTenantIdAdminAdminUserActivateUserIdPost(
      userId,
      this._tenantId
    );
    return response;
  }
  async forgotUsername(email) {
    await this._authApi.v1TenantTenantIdAdminAuthForgotUsernameEmailPost(
      email,
      this._tenantId
    );
  }

  async forgotPassword(username) {
    const response = await this._authApi.v1TenantTenantIdAdminAuthResetPasswordUsernamePost(
      username,
      this._tenantId
    );
    return response.user;
  }

  async forgotPasswordConfirmation(resetPasswordConfirmationRequest) {
    const response = await this._authApi.v1TenantTenantIdAdminAuthConfirmResetPasswordPut(
      this._tenantId,
      { resetPasswordConfirmationRequest }
    );
    return response;
  }

  async confirmUserRegistration(confirmUserRegistrationRequest) {
    const response = await this._authApi.v1TenantTenantIdAdminAuthConfirmUserRegistrationPost(
      this._tenantId,
      { confirmUserRegistrationRequest }
    );
    return response;
  }

  async resendRegistrationCode(userId) {
    await this._authApi.v1TenantTenantIdAdminAuthResendRegistrationCodeUserIdPost(
      userId,
      this._tenantId
    );
  }
  async userVerification(userId) {
    const response = await this._authApi.v1TenantTenantIdAdminAuthUserVerificationGet(
      this._tenantId,
      { userId }
    );
    return response.verified;
  }

  async updateUserRole(userRoleRequest) {
    const response = await this._userApi.v1TenantTenantIdAdminAdminUserUserRolePut(
      this._tenantId,
      { userRoleRequest }
    );
    return response;
  }
  async updateUserRoles(userRoleRequest) {
    const response = await this._userApi.v1TenantTenantIdAdminAdminUserUserRolesPut(
      this._tenantId,
      { userRoleRequest }
    );
    return response;
  }

  async logout(token) {
    const response = await this._authApi.v1TenantTenantIdAdminAuthLogoutPost(
      this._tenantId,
      {
        token
      }
    );
    return response;
  }
}
