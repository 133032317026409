/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AdminSpotSearchResponse from '../model/AdminSpotSearchResponse';
import CustomerSearchResponse from '../model/CustomerSearchResponse';
import SpotReservationSearchResponse from '../model/SpotReservationSearchResponse';
import SpotReservationSearchResultIEnumerableResponse from '../model/SpotReservationSearchResultIEnumerableResponse';

/**
* AdminSearch service.
* @module api/AdminSearchApi
* @version v1
*/
export default class AdminSearchApi {

    /**
    * Constructs a new AdminSearchApi. 
    * @alias module:api/AdminSearchApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.customerId 
     * @param {String} opts.reservationNumber 
     * @param {String} opts.confirmationNumber 
     * @param {String} opts.tpeOrderNumber 
     * @param {String} opts.customerFirstName 
     * @param {String} opts.customerLastName 
     * @param {String} opts.customerPhoneNumber 
     * @param {String} opts.customerEmail 
     * @param {String} opts.primaryOccupantFirstName 
     * @param {String} opts.primaryOccupantLastName 
     * @param {String} opts.arrivalDate 
     * @param {String} opts.departureDate 
     * @param {String} opts.transactionDate 
     * @param {String} opts.occupancyDate 
     * @param {Array.<Number>} opts.reservationStatusIds 
     * @param {Array.<Number>} opts.regionIds 
     * @param {Array.<Number>} opts.locationIds 
     * @param {Array.<Number>} opts.loopIds 
     * @param {String} opts.spot 
     * @param {String} opts.today 
     * @param {Boolean} opts.paymentChargedback 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SpotReservationSearchResultIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSearchCheckinGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSearchCheckinGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'CustomerId': opts['customerId'],
        'ReservationNumber': opts['reservationNumber'],
        'ConfirmationNumber': opts['confirmationNumber'],
        'TpeOrderNumber': opts['tpeOrderNumber'],
        'CustomerFirstName': opts['customerFirstName'],
        'CustomerLastName': opts['customerLastName'],
        'CustomerPhoneNumber': opts['customerPhoneNumber'],
        'CustomerEmail': opts['customerEmail'],
        'PrimaryOccupantFirstName': opts['primaryOccupantFirstName'],
        'PrimaryOccupantLastName': opts['primaryOccupantLastName'],
        'ArrivalDate': opts['arrivalDate'],
        'DepartureDate': opts['departureDate'],
        'TransactionDate': opts['transactionDate'],
        'OccupancyDate': opts['occupancyDate'],
        'ReservationStatusIds': this.apiClient.buildCollectionParam(opts['reservationStatusIds'], 'multi'),
        'RegionIds': this.apiClient.buildCollectionParam(opts['regionIds'], 'multi'),
        'LocationIds': this.apiClient.buildCollectionParam(opts['locationIds'], 'multi'),
        'LoopIds': this.apiClient.buildCollectionParam(opts['loopIds'], 'multi'),
        'Spot': opts['spot'],
        'Today': opts['today'],
        'PaymentChargedback': opts['paymentChargedback']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SpotReservationSearchResultIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSearch/checkin', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.customerId 
     * @param {String} opts.reservationNumber 
     * @param {String} opts.confirmationNumber 
     * @param {String} opts.tpeOrderNumber 
     * @param {String} opts.customerFirstName 
     * @param {String} opts.customerLastName 
     * @param {String} opts.customerPhoneNumber 
     * @param {String} opts.customerEmail 
     * @param {String} opts.primaryOccupantFirstName 
     * @param {String} opts.primaryOccupantLastName 
     * @param {String} opts.arrivalDate 
     * @param {String} opts.departureDate 
     * @param {String} opts.transactionDate 
     * @param {String} opts.occupancyDate 
     * @param {Array.<Number>} opts.reservationStatusIds 
     * @param {Array.<Number>} opts.regionIds 
     * @param {Array.<Number>} opts.locationIds 
     * @param {Array.<Number>} opts.loopIds 
     * @param {String} opts.spot 
     * @param {String} opts.today 
     * @param {Boolean} opts.paymentChargedback 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SpotReservationSearchResultIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminSearchCheckinGet(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminSearchCheckinGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.customerId 
     * @param {String} opts.reservationNumber 
     * @param {String} opts.confirmationNumber 
     * @param {String} opts.tpeOrderNumber 
     * @param {String} opts.customerFirstName 
     * @param {String} opts.customerLastName 
     * @param {String} opts.customerPhoneNumber 
     * @param {String} opts.customerEmail 
     * @param {String} opts.primaryOccupantFirstName 
     * @param {String} opts.primaryOccupantLastName 
     * @param {String} opts.arrivalDate 
     * @param {String} opts.departureDate 
     * @param {String} opts.transactionDate 
     * @param {String} opts.occupancyDate 
     * @param {Array.<Number>} opts.reservationStatusIds 
     * @param {Array.<Number>} opts.regionIds 
     * @param {Array.<Number>} opts.locationIds 
     * @param {Array.<Number>} opts.loopIds 
     * @param {String} opts.spot 
     * @param {String} opts.today 
     * @param {Boolean} opts.paymentChargedback 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SpotReservationSearchResultIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSearchCheckoutGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSearchCheckoutGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'CustomerId': opts['customerId'],
        'ReservationNumber': opts['reservationNumber'],
        'ConfirmationNumber': opts['confirmationNumber'],
        'TpeOrderNumber': opts['tpeOrderNumber'],
        'CustomerFirstName': opts['customerFirstName'],
        'CustomerLastName': opts['customerLastName'],
        'CustomerPhoneNumber': opts['customerPhoneNumber'],
        'CustomerEmail': opts['customerEmail'],
        'PrimaryOccupantFirstName': opts['primaryOccupantFirstName'],
        'PrimaryOccupantLastName': opts['primaryOccupantLastName'],
        'ArrivalDate': opts['arrivalDate'],
        'DepartureDate': opts['departureDate'],
        'TransactionDate': opts['transactionDate'],
        'OccupancyDate': opts['occupancyDate'],
        'ReservationStatusIds': this.apiClient.buildCollectionParam(opts['reservationStatusIds'], 'multi'),
        'RegionIds': this.apiClient.buildCollectionParam(opts['regionIds'], 'multi'),
        'LocationIds': this.apiClient.buildCollectionParam(opts['locationIds'], 'multi'),
        'LoopIds': this.apiClient.buildCollectionParam(opts['loopIds'], 'multi'),
        'Spot': opts['spot'],
        'Today': opts['today'],
        'PaymentChargedback': opts['paymentChargedback']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SpotReservationSearchResultIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSearch/checkout', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.customerId 
     * @param {String} opts.reservationNumber 
     * @param {String} opts.confirmationNumber 
     * @param {String} opts.tpeOrderNumber 
     * @param {String} opts.customerFirstName 
     * @param {String} opts.customerLastName 
     * @param {String} opts.customerPhoneNumber 
     * @param {String} opts.customerEmail 
     * @param {String} opts.primaryOccupantFirstName 
     * @param {String} opts.primaryOccupantLastName 
     * @param {String} opts.arrivalDate 
     * @param {String} opts.departureDate 
     * @param {String} opts.transactionDate 
     * @param {String} opts.occupancyDate 
     * @param {Array.<Number>} opts.reservationStatusIds 
     * @param {Array.<Number>} opts.regionIds 
     * @param {Array.<Number>} opts.locationIds 
     * @param {Array.<Number>} opts.loopIds 
     * @param {String} opts.spot 
     * @param {String} opts.today 
     * @param {Boolean} opts.paymentChargedback 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SpotReservationSearchResultIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminSearchCheckoutGet(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminSearchCheckoutGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.firstName 
     * @param {String} opts.lastName 
     * @param {String} opts.address 
     * @param {String} opts.city 
     * @param {String} opts.state 
     * @param {String} opts.zip 
     * @param {String} opts.phone 
     * @param {String} opts.email 
     * @param {String} opts.username 
     * @param {String} opts.reservationNumber 
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.itemsPerPage 
     * @param {Boolean} opts.sortDescending 
     * @param {String} opts.sortField 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CustomerSearchResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSearchCustomersGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSearchCustomersGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'FirstName': opts['firstName'],
        'LastName': opts['lastName'],
        'Address': opts['address'],
        'City': opts['city'],
        'State': opts['state'],
        'Zip': opts['zip'],
        'Phone': opts['phone'],
        'Email': opts['email'],
        'Username': opts['username'],
        'ReservationNumber': opts['reservationNumber'],
        'PageNumber': opts['pageNumber'],
        'ItemsPerPage': opts['itemsPerPage'],
        'SortDescending': opts['sortDescending'],
        'SortField': opts['sortField']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CustomerSearchResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSearch/customers', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.firstName 
     * @param {String} opts.lastName 
     * @param {String} opts.address 
     * @param {String} opts.city 
     * @param {String} opts.state 
     * @param {String} opts.zip 
     * @param {String} opts.phone 
     * @param {String} opts.email 
     * @param {String} opts.username 
     * @param {String} opts.reservationNumber 
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.itemsPerPage 
     * @param {Boolean} opts.sortDescending 
     * @param {String} opts.sortField 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CustomerSearchResponse}
     */
    v1TenantTenantIdAdminAdminSearchCustomersGet(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminSearchCustomersGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.customerId 
     * @param {String} opts.reservationNumber 
     * @param {String} opts.confirmationNumber 
     * @param {String} opts.tpeOrderNumber 
     * @param {String} opts.customerFirstName 
     * @param {String} opts.customerLastName 
     * @param {String} opts.customerPhoneNumber 
     * @param {String} opts.customerEmail 
     * @param {String} opts.primaryOccupantFirstName 
     * @param {String} opts.primaryOccupantLastName 
     * @param {String} opts.arrivalDate 
     * @param {String} opts.departureDate 
     * @param {String} opts.transactionDate 
     * @param {String} opts.occupancyDate 
     * @param {Array.<Number>} opts.reservationStatusIds 
     * @param {Array.<Number>} opts.regionIds 
     * @param {Array.<Number>} opts.locationIds 
     * @param {Array.<Number>} opts.loopIds 
     * @param {String} opts.spot 
     * @param {String} opts.today 
     * @param {Boolean} opts.paymentChargedback 
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.itemsPerPage 
     * @param {Boolean} opts.sortDescending 
     * @param {String} opts.sortField 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SpotReservationSearchResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSearchReservationsGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSearchReservationsGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'CustomerId': opts['customerId'],
        'ReservationNumber': opts['reservationNumber'],
        'ConfirmationNumber': opts['confirmationNumber'],
        'TpeOrderNumber': opts['tpeOrderNumber'],
        'CustomerFirstName': opts['customerFirstName'],
        'CustomerLastName': opts['customerLastName'],
        'CustomerPhoneNumber': opts['customerPhoneNumber'],
        'CustomerEmail': opts['customerEmail'],
        'PrimaryOccupantFirstName': opts['primaryOccupantFirstName'],
        'PrimaryOccupantLastName': opts['primaryOccupantLastName'],
        'ArrivalDate': opts['arrivalDate'],
        'DepartureDate': opts['departureDate'],
        'TransactionDate': opts['transactionDate'],
        'OccupancyDate': opts['occupancyDate'],
        'ReservationStatusIds': this.apiClient.buildCollectionParam(opts['reservationStatusIds'], 'multi'),
        'RegionIds': this.apiClient.buildCollectionParam(opts['regionIds'], 'multi'),
        'LocationIds': this.apiClient.buildCollectionParam(opts['locationIds'], 'multi'),
        'LoopIds': this.apiClient.buildCollectionParam(opts['loopIds'], 'multi'),
        'Spot': opts['spot'],
        'Today': opts['today'],
        'PaymentChargedback': opts['paymentChargedback'],
        'PageNumber': opts['pageNumber'],
        'ItemsPerPage': opts['itemsPerPage'],
        'SortDescending': opts['sortDescending'],
        'SortField': opts['sortField']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SpotReservationSearchResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSearch/reservations', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.customerId 
     * @param {String} opts.reservationNumber 
     * @param {String} opts.confirmationNumber 
     * @param {String} opts.tpeOrderNumber 
     * @param {String} opts.customerFirstName 
     * @param {String} opts.customerLastName 
     * @param {String} opts.customerPhoneNumber 
     * @param {String} opts.customerEmail 
     * @param {String} opts.primaryOccupantFirstName 
     * @param {String} opts.primaryOccupantLastName 
     * @param {String} opts.arrivalDate 
     * @param {String} opts.departureDate 
     * @param {String} opts.transactionDate 
     * @param {String} opts.occupancyDate 
     * @param {Array.<Number>} opts.reservationStatusIds 
     * @param {Array.<Number>} opts.regionIds 
     * @param {Array.<Number>} opts.locationIds 
     * @param {Array.<Number>} opts.loopIds 
     * @param {String} opts.spot 
     * @param {String} opts.today 
     * @param {Boolean} opts.paymentChargedback 
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.itemsPerPage 
     * @param {Boolean} opts.sortDescending 
     * @param {String} opts.sortField 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SpotReservationSearchResponse}
     */
    v1TenantTenantIdAdminAdminSearchReservationsGet(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminSearchReservationsGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.locationId 
     * @param {String} opts.spotName 
     * @param {Array.<Number>} opts.areaIds 
     * @param {Array.<Number>} opts.loopIds 
     * @param {Array.<Number>} opts.productClassificationIds 
     * @param {Array.<Number>} opts.spotTypeIds 
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.itemsPerPage 
     * @param {Boolean} opts.sortDescending 
     * @param {String} opts.sortField 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AdminSpotSearchResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSearchSpotsGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSearchSpotsGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'LocationId': opts['locationId'],
        'SpotName': opts['spotName'],
        'AreaIds': this.apiClient.buildCollectionParam(opts['areaIds'], 'multi'),
        'LoopIds': this.apiClient.buildCollectionParam(opts['loopIds'], 'multi'),
        'ProductClassificationIds': this.apiClient.buildCollectionParam(opts['productClassificationIds'], 'multi'),
        'SpotTypeIds': this.apiClient.buildCollectionParam(opts['spotTypeIds'], 'multi'),
        'PageNumber': opts['pageNumber'],
        'ItemsPerPage': opts['itemsPerPage'],
        'SortDescending': opts['sortDescending'],
        'SortField': opts['sortField']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AdminSpotSearchResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSearch/spots', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.locationId 
     * @param {String} opts.spotName 
     * @param {Array.<Number>} opts.areaIds 
     * @param {Array.<Number>} opts.loopIds 
     * @param {Array.<Number>} opts.productClassificationIds 
     * @param {Array.<Number>} opts.spotTypeIds 
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.itemsPerPage 
     * @param {Boolean} opts.sortDescending 
     * @param {String} opts.sortField 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AdminSpotSearchResponse}
     */
    v1TenantTenantIdAdminAdminSearchSpotsGet(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminSearchSpotsGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
